// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".error_error__21KV4 {\n  margin: 20px; }\n\n.error_link__3OcQm {\n  cursor: pointer;\n  text-decoration: underline; }\n", ""]);
// Exports
exports.locals = {
	"error": "error_error__21KV4",
	"link": "error_link__3OcQm"
};
module.exports = exports;
