// import React, { useEffect } from 'react';

// function handleEnterKeyPress(callback) {
//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       callback();
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('keydown', handleKeyPress);
//     return () => {
//       window.removeEventListener('keydown', handleKeyPress);
//     };
//   }, []);

//   return null; // This component doesn't render anything
// }

// export default handleEnterKeyPress;
function handleEnterKeyPress(callback) {
  return (event) => {
    if (event.key === 'Enter') {
      callback();
    }
  };
}

export default handleEnterKeyPress;