import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import ReadPdf from 'react-read-pdf'; 
// import { ReadPdf } from 'react-read-pdf'
import { PDFReader, MobilePDFReader  } from 'react-read-pdf';

const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pdfViewer: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%',
    height: '100%',
  },
  actionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
}));

const PDFModal = ({ pdf, open, handleClose, extraButton }) => {
  // console.log('PDF Preview', pdf)
  const classes = useStyles();
  const isMobile = window.innerWidth < 768;
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    if (pdf && pdf.length) {
      // console.log('PDF!!', pdf)
      const pdfBlob = base64ToBlob(pdf.split(',')[1], 'application/pdf');
      const url = URL.createObjectURL(pdfBlob);
      // console.log('Our URL:', url)
      setPdfUrl(url);
    
      // Cleanup
      return () => URL.revokeObjectURL(url);
    }
  }, [pdf]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      
      <Fade in={open}>
        <div className={classes.pdfViewer}>
            {/* PDF: {pdfUrl.toString()} */}
            <div className={classes.actionRow}>
             <div>
              {extraButton ? extraButton : ''}
             </div>
            <Button onClick={handleClose} variant="contained" size="small" color="secondary" >Close</Button>
            </div>
         
          {/* { (pdfUrl && pdfUrl.length > 50) ? <MobilePDFReader url={pdfUrl} /> : null } */}
          {isMobile ? (
            // Use react-read-pdf for mobile users
            (pdfUrl && pdfUrl.length > 50) ? <MobilePDFReader url={pdfUrl} /> : null
          ) : (
            // Fallback to the <object> tag for desktop users
            <object
              data={pdf}
              type="application/pdf"
              width="100%"
              height="95%"
            >
              <p>Your browser does not support PDFs. Please download the PDF to view it: <a href={pdf}>Download PDF</a>.</p>
            </object>
            // (pdfUrl && pdfUrl.length > 50) ? <PDFReader url={pdfUrl} /> : null
          )}
          
        </div>
      </Fade>
    </Modal>
  );
};

export default PDFModal;
