import React from 'react'
import { connect } from 'react-redux'
import { Button, CircularProgress, IconButton, Chip } from '@material-ui/core';
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import styled from 'styled-components'
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import NewLineItemComponent from './NewLineItemArea'
import TextField from '@material-ui/core/TextField';
import EditLineItemModal from './EditLineItemModal';
import EstimateListComponent from './LineItemList';
import EditIcon from '@material-ui/icons/Edit';
import EditEstimateDetails from './EditEstimateDetails';
import DeleteIcon from '@material-ui/icons/Delete';
import ConvertToWO from './ConvertToWO'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import SendToCustomerModal from './SendToCustomerModal'
import ImportExportIcon from '@material-ui/icons/ImportExport';
import DepositComponent from './DepositComponent'
import CloseIcon from '@material-ui/icons/CloseOutlined'

const TopRow = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-tems: center;
`;

const TopRowItem = styled.div`
  display: flex;
  align-self: ${props => (props.align === 'start' ? 'flex-start' : 'flex-end')};
`;

const ControlRow = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  font-weight: 400;
  line-height: 1.5;
  color: #bbb;
  font-size: 16;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.00938em;
`;

const ShowPDFTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content; space-between;
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflow:'hidden'
  },
  modal: {
    position:'absolute',
    top:'10%',
    left:'10%',
    overflow:'hidden',
    height:'100%',
    display:'block'
  },
  estimateList: {
    marginTop: '375px'
  },
  estimateListRow: {
    cursor: 'pointer'
  },
  paddedStuff: {
    paddingBottom: 20
  }
}));

const defaultValues = {
  name: '',
  estimateName: '',
  list: [],
  job: null,
  estimate: null,
  counter: 0,
  depositPercent: 50
}

const formatOption = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.address) {
    return `${opt.name} - ${opt.address} ${opt.address2 ? opt.address2 : ''} ${opt.city}, ${opt.state} ${opt.zip}`
  } else {
    return ''
  }
}

function currencyFormat(num) {
  // console.log('The Num: ', num)
  if (!num) num = 0
  // console.log('NUM: ', num)
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const newProjectMock = { name: 'Create New Project', _id: 'new' }

const CreateNewEstimateModal = (props) => {
  // console.log('NEW ESTIMATE MODAL PROPS::: ', props)
  // console.log('PROPS.clients::"', props.clients)
  const classes = useStyles()
  const [state, setState] = React.useState(defaultValues)
  const [associatedProject, setAssociatedProject] = React.useState({})
  const [projectList, setProjectList] = React.useState([newProjectMock ])
  const [loading, setLoading] = React.useState(false)
  const [jobSite, setJobSite] = React.useState({})
  const [billingAddress, setBillingAddress] = React.useState({})
  const [client, setClient] = React.useState({})
  const [jobSites, setJobSites] = React.useState([])
  const [clients, setClients] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    const fetchClients = async () => {
      // setLoading(true)
      axios({
        method: 'get',
        url: `${props.url}/api/customer/clients`
      }).then(response => {
        // console.log('Got our clients for new estimate; ', response.data)
        // setLoading(false)
        if (isMounted) {
          setClients(response.data)
          // setFilteredRows(response.data)
        }
      })
    }
    fetchClients()
    return () => {
      isMounted = false
    }
  }, [props.open])

  const handleClose = () => {
    // console.log('CLOSE')
      props.closeModal()
  }

  const updateJobSiteAddress = (e, js) => {
    setJobSite(js)
  }

  const updateBillingAddress = (e, addy) => {
    setBillingAddress(addy)
  }

  const getJobSites = async (customer) => {
        setLoading(true)
          axios({
        method: 'get',
        url: `${props.url}/api/customer/getAllJobSites?id=${customer._id}`
      }).then(response => {
      console.log('Got our job sites; ', response.data)
        setLoading(false)
        setJobSites(response.data.jobSites)
        setProjectList(response.data.projects)
      })

  }

  const updateClient = (e, newClient) => {
    if (newClient && newClient._id) {
      getJobSites(newClient)
      setClient(newClient)
    } else {
      setClient({})
      setJobSites([])
    }
  }
  const formatOptionForProject = (opt) => {
    // console.log('Format this: ', opt)
    if (opt && opt.name) {
      return `${opt.name}`
    } else {
      return 'Un-Named Project'
    }
  }

  const updateProject = (e, proj) => {
    // console.log('Prev associated: ', associatedProject)
    // console.log('state.associated', state)
    if (proj && proj._id === 'new') {
      // console.log('NEW PROJECT', proj)
      setAssociatedProject(proj)
      setState({...state, associatedProject: proj })
    } else {
      // console.log(proj)
      setAssociatedProject(proj)
      setState({...state, associatedProject: proj })
    }
  }
  
  const updateSalesRep = (e, rep) => {
    if (rep && rep._id) {
      // inputRef.current.salesRep = rep
      setState({...state, salesRep: rep})
    }
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'salesperson') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.salesReps.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          setState({...state, salesRep: newArr[0]})
        }
      }
    }
  }

  const handleUpdateDepositPercentage = (data) => {
    setState({...state, depositPercent: data})
  }

  const handleNewEstimate = () => {
    // Save the estimate now
  // console.log('save estimate', state)
    setLoading(true)
    let obj = {
      client: client,
      name: state.estimateName,
      depositPercent: state.depositPercent,
      jobSite: jobSite,
      billingAddress: billingAddress
    }
    // console.log('Assoicated Project', associatedProject)
    if (associatedProject && associatedProject._id) obj.project = associatedProject._id
    if (associatedProject && associatedProject._id === 'new') {
      obj.projectName = state.projectName
      obj.salesRep = state.salesRep
    }
    if (state.salesRep) obj.salesRep = state.salesRep
  // console.log('NEW ESTIMATE TO CREATE: ', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/addNew`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to new proposal ', response)
      setLoading(false)
      // setEstimate(response.data)
      setState(defaultValues)
      setAssociatedProject({})
      setBillingAddress({})
      setJobSite({})
      props.viewEstimate(response.data)
      // props.history.push(`/estimates/${response.data._id}`, { state: response.data } )
    }).catch(e => {
      // console.log('Errors:: ', e)
    })
  }

const activeClients = [...clients].filter(item => (item.active))
const ourProjects = [
  ...projectList,
  newProjectMock
]
return (
  <Dialog className={classes.modal} open={props.open} fullWidth={true} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">
          Create New Estimate 
        </DialogTitle>
        <DialogContent >
        <ControlRow>
           
                What Is This Estimate Name?
            
          </ControlRow>
          <TextField
            label="Estimate Name"
            variant="outlined"
            type="text"
            style={{marginBottom: 15 }}
            value={state.estimateName}
            onChange={e => setState({...state, estimateName: e.target.value})}
            fullWidth
          />
        <ControlRow>
            {/* <DialogContentText> */}
                Who Is The Associated Client?
            {/* </DialogContentText> */}
        </ControlRow>

        <Autocomplete
            id="Client"
            options={activeClients || []}
            getOptionLabel={(option) => {
              // console.log('options: ', option)
              return option.displayName ? `${option.displayName} (${option.agId})` : ''
            }}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // style={{marginBottom: 10, marginTop: 15}}
            onChange={updateClient}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {client}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Customer" />}
          />
          <ControlRow>
            {/* <DialogContentText> */}
                Which JobSite Is This Estimate For?
            {/* </DialogContentText> */}
          </ControlRow>
              { loading ? <CircularProgress />: <Autocomplete
                id="JobSite"
                options={jobSites}
                getOptionLabel={(option) => (formatOption(option) || '')}
                // style={{ width: 200 }}
                className={classes.paddedStuff}
                // style={{marginBottom: 10, marginTop: 15}}
                onChange={updateJobSiteAddress}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {jobSite || {}}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} variant="outlined" label="Job Site Address" />}
              />
            }
          <ControlRow>
            {/* <DialogContentText> */}
                What Is The Billing Address?
            {/* </DialogContentText> */}
        </ControlRow>
          <Autocomplete
            id="Billing Address"
            options={jobSites}
            getOptionLabel={(option) => (formatOption(option) || '')}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // style={{marginBottom: 10, marginTop: 15}}
            onChange={updateBillingAddress}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {billingAddress || {}}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Billing Address" />}
          />
          {
            props.project ? null : (
              <>
                <ControlRow>
                    {/* <DialogContentText> */}
                        Is This Associated To A Project?
                    {/* </DialogContentText> */}
                </ControlRow>
                <Autocomplete
                id="Associated Project"
                options={ourProjects}
                getOptionLabel={(option) => (formatOptionForProject(option) || '')}
                // style={{ width: 200 }}
                className={classes.paddedStuff}
                // style={{marginBottom: 10, marginTop: 15}}
                onChange={updateProject}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {associatedProject || ''}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} variant="outlined" label="Project" />}
              />
              </>
              
            )
          }
          
          {
            (associatedProject && associatedProject._id === 'new') ? (
              <>
              <TextField
                label="Project Name"
                variant="outlined"
                className={classes.paddedStuff}
                type="text"
                value={state.projectName}
                onChange={e => setState({...state, projectName: e.target.value})}
                fullWidth
              />
           
            </>
            ) : null
          }
              <ControlRow>
                    {/* <DialogContentText> */}
                        Who Is The Sales Rep?
                    {/* </DialogContentText> */}
                </ControlRow>
              <Autocomplete
              id={`salesperson`}
              options={props.salesReps || []}
              className={classes.paddedStuff}
              getOptionLabel={(option) => {
                // console.log('Options: ', option)
                return option.displayName || ''
              }}
              // style={{ width: 200 }}
              // style={{marginBottom: '20px'}}
              onChange={updateSalesRep}
              disabled={loading}
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {state.salesRep}
              // ref={inputRef.salesperson}
              // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
              renderInput={(params) => <TextField {...params} label="Salesperson" helperText="Who Is The Assigned Salesperson?" onKeyDown={handleTabInteraction} variant="outlined" />}
            />
          <ControlRow>
            <DepositComponent updatePercentage={handleUpdateDepositPercentage} style={{width: '100%'}} />
          </ControlRow>
          <Button
            variant="contained"
            color="primary"
            style={{marginBottom: 10, marginTop: 15}}
            onClick={handleNewEstimate}
            disabled={!state.estimateName || !jobSite._id || loading || !state.salesRep || !client._id || !billingAddress}
            fullWidth
          >Create</Button>
        
          </DialogContent>
    </Dialog>
  )
 }

 const mapStateToProps = (state) => {
  // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    clients: state.clients,
    salesReps: state.salesReps
  }
}

export default connect(mapStateToProps)(CreateNewEstimateModal)