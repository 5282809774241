import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
// Testing Only
import Icon from '@material-ui/core/Icon'
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import * as Muicon from "@material-ui/icons";
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name' },
  { id: 'entity', numeric: false, disablePadding: false, label: 'Entity' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'salesRep', numeric: false, disablePadding: false, label: 'Sales Rep Name' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created' },
  { id: 'startDate', numeric: false, disablePadding: false, label: 'Est. Start Date' },
  { id: 'endDate', numeric: false, disablePadding: false, label: 'Target Completion Date' },
  { id: 'updated_at', numeric: false, disablePadding: false, label: 'Last Update' },
  { id: 'lastNote', numeric: false, disablePadding: false, label: 'Last Note' }
];

function EnhancedTableHead(props) {
  // console.log('Got our props for ', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 10
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rowPad: {
    height: '40px !important',
    // padding: 'px'
  }
}));

const LastProjectNote = (props) => {
  if (props.project && props.project.projectNotes && props.project.projectNotes.length) {
    let note = props.project.projectNotes[0]['content']
    return (
      <div style={{ maxWidth: 250, maxHeight: 250, overflow: 'hidden', textOverflow: 'ellipsis' }} >
        <div dangerouslySetInnerHTML={{__html: note }} ></div>
      </div>
    )
    // if (note && note.length > 50) {
    //   return <div dangerouslySetInnerHTML={{__html: note }} ></div>
    // } else {
    //   return note
    // }
  } else {
    return null
  }
}

function EnhancedTable(props) {
  // console.log('PROJECT List Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [state, setState] = React.useState({
    searchMatches: 0,
    project: {}
  })
  const [showProjectDetailsModal, setShowProjectDetailsModal] = React.useState(false)
  const [activeEntity, setActiveEntity] = React.useState({})

  React.useEffect(() => {
    setRows(props.projects)
    setFilteredRows(props.projects)
  }, [props.projects])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  // // console.log('handle click ', name)
  // // console.log('Index: ', selectedIndex)
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storeSearchMatches = (d) => {
    // console.log('Search matches: ', d)
    // setState({...state, searchMatches: d.length})
  }
  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const openProjectModal = (project) => {
    setState({...state, project: project})
    setShowProjectDetailsModal(true)
  }
  const handleCloseModal = (d) => {
    // setState({...state, project: { _id: null } })
    setShowProjectDetailsModal(false)
  }

  const updateProjectDetails = (d, updateType) => {
    // console.log('modify pRoject: ', d)
    if (updateType === 'deleted') {
      let projList = props.projects
      // console.log(projList)
      let index = projList.findIndex(item => item._id.toString() === d._id.toString())
      // console.log('INDEX:', index)
      // console.log('DELETE: ', projList[index])
      if (index > -1) {
        // console.log('Deleting: ', index)
        // projList[index] = d
        projList.splice(index, 1)
        // console.log('AFter Splice: ', projList)
        setRows(projList)
        setFilteredRows(projList)
        // setState({...state, project: d })
      }
    }
    if (updateType === 'saved') {
      let projList = props.projects
      let index = projList.findIndex(item => item._id === d._id)
      if (index > -1) {
        projList[index] = d
        setRows(projList)
        setFilteredRows(projList)
      }
      setState({...state, project: d })
    }
  }


  const TestIcon = () => {
    let OurIcon = Muicon['WbIncandescentTwoTone']
  // console.log('Our Icon: ', OurIcon)
    return <OurIcon />
  }

  const handleRefresh = () => {
    let proj = {...state.project}
    // console.log('Refresh: ', proj)
    proj.refresh = true
    proj.isActive = false
    setState({...state, project: proj })
  }

  // const handleUpdateVisibleEntity = (entityChosen) => {
  //   // console.log('UPdate Visibile Projects::: ', entityChosen)
  //   // console.log('Current Active Entity', activeEntity)
  //   let projectList = props.projects
  //   if (entityChosen.name === 'All Assigned') {
  //     // console.log('Show all projects')
  //     // Show all available Customers
  //     // console.log('We have a bunch!! %d', rows.length)
  //     filterOut(projectList, searchValue)
  //     setRows(projectList)
  //   } else {
  //   // console.log('Only show %s projects', entityChosen.name)
  //     // Filter by the active Entity
  //     let filteredProjects = projectList.filter(item => { 
  //       // console.log('Entity: ', item.entity)
        
  //       return item.entity === entityChosen._id 
  //     })
  //     // console.log('New Projects: ', filteredProjects)
  //     filterOut(filteredProjects, searchValue)
  //     setRows(filteredProjects)
  //   }
  //   setActiveEntity(entityChosen)
  // }

  return (
    <div className={classes.root}>
     <Paper className={classes.paper}>
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={3} xs={6}>
          Total Projects: {rows.length}
          </Grid>
          <Grid item lg={4}>
          {/* <Button
          variant="contained"
          // style="secondary"
          style={{ color: '#222'}}
          // className={classes.button}
          size="small"
          startIcon={<PersonAddIcon  color="primary" />}
          disabled={loading}
          // onClick={handleSnoutWeevil }
          onClick={handleAddEmployee}
          // onClick={handleDeleteService}
          // onClick={offerDeleteOption}
          // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
        >
          Add Employee
        </Button>  */}
          </Grid>
        </Grid>
        <Grid container style={{padding: '10px'}}>
          {/* <Grid item lg={12} xs={12}>
            <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} />
          </Grid> */}
          <Grid item lg={6} xs={6}>
            <div className={classes.search}>
                  <div >
                    <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                  </div>
                  {/* <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input> */}
                  <TextField
                      name="search" 
                      value={ searchValue } 
                      onChange={ handleChange } 
                      type="text"
                      label="Search"
                      type="search"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                  />
                  {/* <div className={classes.fakeLink} onClick={toggleDeepSearch}>Go Deeper</div> */}
              </div>
              {/* <div className={classes.search}>
                <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div>
                <input name="search" value={ searchValue } onChange={ handleChange } type="text"></input>
              </div> */}
          </Grid>
          <Grid item lg={6}>
          {/* <FormControlLabel
                    control={<Checkbox checked={showAllEmployees} onChange={setShowAllOrNot} name="showAllEmployees" />}
                    label="Include Inactive"
                  /> */}
          </Grid>

        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            options={{
              rowStyle: {height: 55}
            }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let url = ''
                  if (row && row.photos && row.photos.length) url = row.photos[0]['url']
                  // setsearchMatches(rows.length)

                    return (
                    
                      <TableRow
                        hover
                        // onClick={event => handleClick(event, row._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        className={classes.rowPad}
                        selected={isItemSelected}
                      >
                       <TableCell component="th" id={labelId} scope="row" padding="none">
                          <Link 
                            style={{pointer: 'cursor'}}
                            target="_blank"
                            to={{
                                  pathname: '/projects/' + row._id,
                                  // search: '?sort=name',
                                  // hash: '#the-hash',
                                  state: { project: row }
                                }}> {row.name || 'Un-Named Project'}
                                
                          </Link>
                        </TableCell>

                        <TableCell align="left">
                          
                          {  row.client ? row.client.displayName : ''}
                          {(props.client && props.client._id) ? props.client.displayName : ''}
                        </TableCell>
                        <TableCell align="left">
                        { row.entity ? <img alt="Logo" src={`${process.env.PUBLIC_URL}/${row.entity}_Logo.png`} style={{margin: '5px', maxWidth: 170, maxHeight: 40 }} /> : ''}
                        </TableCell>
                        <TableCell align="left">
                          {row.status}
                        </TableCell>
                        <TableCell align="left">
                        {row.salesRep && row.salesRep.displayName ? row.salesRep.displayName : ''}
                        </TableCell>
                        <TableCell align="left"> {moment(row.created_at).format('MMM DD, YYYY')} </TableCell>
                        <TableCell align="left">
                        {row.startDate ? moment(row.startDate).format('MM/DD/YY') : ''}
                        </TableCell>
                        <TableCell align="left">
                        {row.endDate ? moment(row.endDate).format('MM/DD/YY') : ''}
                        </TableCell>
                        <TableCell align="left">
                        {row.updated_at ? moment(row.updated_at).format('MMM DD, YYYY') : ''}
                        </TableCell>
                        <TableCell align="left" >
                          <LastProjectNote project={row} />
                        </TableCell>

                        
                      </TableRow>
                    );
 
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          // onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(EnhancedTable)

