// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".registrationFinish_title__3xfUA {\n  padding-left: 30px;\n  padding-top: 20px; }\n\n.registrationFinish_content__1i5a0 {\n  padding-bottom: 10px; }\n\n.registrationFinish_right__i01AT {\n  float: right; }\n", ""]);
// Exports
exports.locals = {
	"title": "registrationFinish_title__3xfUA",
	"content": "registrationFinish_content__1i5a0",
	"right": "registrationFinish_right__i01AT"
};
module.exports = exports;
