import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import AbsentModal from './AbsentModal'
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InviteUserModal from './InviteUserModal'
import ChooseAssignedCrews from './ChooseAssignedCrews'
import FiberPinIcon from '@material-ui/icons/FiberPin';
import SetPINModal from './SetPINModal'
import AssignedEntityComponent from '../Entities/AssignedEntityComponent'
import ProtectedButton from '../../actions/protectedButtons'
import CompensationComponent from './CompensationComponent'
import IncidentHistoryComponent from './EmployeeIncident'
import WarningIcon from '@material-ui/icons/Warning';
import NewIncidentModal from './NewIncidentModal'
import PhotoUploadComponent from '../Uploads/PhotoUploadComponent'
import QRCode from 'qrcode'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { socket } from '../SocketIo/Socket'
import BadgeModal from './BadgeModal'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  gridItem: {
    width: '800px'
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(2),
  }
}));

const EmployeeDetailsModal = (props) => {
  // console.log('Got our Employee Modal modal', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showAbsentModal, setShowAbsentModal] = React.useState(false)
  const [showInviteUserModal, setShowInviteUserModal] = React.useState(false)
  const [showPayChangeReason, setShowPayChangeReason] = React.useState(false)
  const [userProfile, setUserProfile] = React.useState({})
  const [assignedCrew, setAssignedCrew] = React.useState(false);
  const [crews, setCrews] = React.useState([])
  const [incidents, setEmployeeIncidents] = React.useState([])
  const categories = [
    'Monthly Maintenance', 'Landscapes', 'Specialty', 'Clean-Ups', 'Irrigation', 'Tree Trimming', 'Lighting', 'Pest Control', 'Water Feature', 'Flowers', 'Other'
  ]

  const [state, setState] = React.useState({
    employee: {},
    isDriver: false,
    isBilingual: false,
    isActive: false,
    isTemp: false,
    isH2B: false,
    hireDate: '',
    trackSales: false,
    driverNumber: '',
    canReviewSites: false,
    isTemporaryBadge: false,
    requiresUpdate: false,
    counter: 0,
    qrCode: '',
    isSprayTech: false,
    isIrrigationTech: false,
    isPoolTech: false,
    associatedEmployee: {}
  });

  const [showPINModal, setShowPINModal] = React.useState(false)
  const [showIncidentReportModal, setShowIncidentReportModal] = React.useState(false)
  const [badgeData, setBadgeData] = React.useState({})
  const [showBadgeModal, setShowBadgeModal] = React.useState(false)

  const employeeTypes = [
    'Floater',
    'Crewman',
    'Lead Man',
    'Foreman',
    'Bobcat Operator',
    'Crane Operator',
    'Supervisor',
    'Manager',
    'El Jefe',
    'Yard Crew',
    'Mechanic',
    'Office Staff',
    'Sales',
    'General Labor',
    'Operator',
    'Tech'
  ]

  const divisions = [
    'Customer Service',
    'Specialty',
    'Irrigation and Lighting',
    'Maintenance',
    'Landscape Install',
    'Hardscape',
    'Yard',
    'Pools',
    'Concrete',
    'Trucking',
    'Crane',
    'Mechanic',
    'New River Nursery',
    'Carefree Nursery',
    'Concrete Yard'
  ]

React.useEffect(() => {
  // if (props.tasks && props.tasks.length) setTasks(props.tasks)
  if (props.user && props.user._id) {
    socket.on(`${props.user._id}_badgeupdates`, function (msg) {
      console.log('GOT A SOCKET MESSAGE: ', msg)
      if (msg && msg.type) {
        if (msg.type === 'Preview') {
          displayThePreview(msg)
          props.notification({
            type: 'info',
            title: 'Employee Badge Created',
            message: 'Please Review The Badge Before We Order It'
          })
        }

        }
      })
    
    }

    return () => {
      socket.off(`${props.user._id}_badgeupdates`)
    }
  }, [])

  const displayThePreview = (msg) => {
    console.log('Display the preview image', msg)
    setShowBadgeModal(true)
    setBadgeData(msg)
  }

React.useEffect(() => {
  if (state.employee && state.employee._id) {
    let qrURL = `https://dflportal.com/view/employee/${state.employee._id}`
  // console.log('state.employee changed - generate  a qr code!!')
    var opts = {
      errorCorrectionLevel: 'H',
      type: 'image/png',
      quality: 0.5,
      margin: 3,
      color: {
        dark: "#000", // "#516e76",
        light: "#FFF", // "#accacd80", // "#ACCACD"
      }
    }
    // let testURL = 'https://dflportal.com/view/updateemployeephone'
  // console.log('Opts:', opts)
    QRCode.toDataURL(qrURL, opts, function (err, url) {
    // console.log('ERR:', err)
    // console.log('URL: ', url)
      if (err) throw err
    // console.log('Set the state:', url)
      setState(prevState => ({...prevState, qrCode: url, counter: state.counter++ }))
    })
  }
}, [state.employee])



const getEmployeeDetails = () => {
  axios
  .get(
    `${props.url}/api/employees/getEmployeeDetails?id=${props.employee._id}`
    )
  .then(({ data }) => {
    console.log('Employee Details', data)
    if (data.employee) {
      let employee = data.employee
      let associatedEmployee = null
      if (employee?.associatedEmployee) {
        associatedEmployee = props.employees.find(e => (e._id === employee.associatedEmployee))
        // console.log('364', associatedEmployee)
      }
      let isSprayTech = (employee && employee.skills && employee.skills.includes('Spray Tech')) ? true : false
      let isIrrigationTech = (employee && employee.skills && employee.skills.includes('Irrigation Tech')) ? true : false
      let isPoolTech = (employee && employee.skills && employee.skills.includes('Pool')) ? true : false
      let hasInventoryAccess = (employee && employee.skills && employee.skills.includes('Inventory')) ? true : false
      let hasEquipmentCheckIn = (employee && employee.skills && employee.skills.includes('Equipment')) ? true : false
      if (employee && employee.user) {
        setUserProfile(employee.user)
      } else {
        setUserProfile({})
      }
      // setState({...state, employee: employee })
      if (employee.crew) {
        let ourIndex = props.crews.map(function (e) { 
          // console.log('WHHH?? ', e)
          return e._id
        }).indexOf(employee.crew)
      // console.log('OUR CREW INDEX: ', ourIndex)
        let updatedState = {...state,
          employee: employee,
          isH2B: employee.isH2B,
          isDriver: employee.isDriver || false,
          isBilingual: employee.isBilingual || false,
          driverNumber: employee.driverNumber,
          assignedCrew: props.crews[ourIndex],
          hireDate: employee.hireDate || '',
          isActive: employee.activeEmployee || false,
          trackSales: employee.trackSales || false,
          isIrrigationTech: isIrrigationTech,
          hasInventoryAccess,
          isSprayTech: isSprayTech,
          hasEquipmentCheckIn: hasEquipmentCheckIn,
          canReviewSites: employee.canReviewSites,
          isTemporaryBadge: employee.isTemporaryBadge,
          associatedEmployee,
          isPoolTech
          
        }
      // console.log('State to update to: ', updatedState)
        setState(updatedState)
        setAssignedCrew(props.crews[ourIndex])
      } else {
      // console.log('Clear Assigned Crew!!!')
      let updatedState = {...state,
        employee: employee,
        isH2B: employee.isH2B,
        isDriver: employee.isDriver || false,
        isBilingual: employee.isBilingual || false,
        isActive: employee.activeEmployee || false,
        driverNumber: employee.driverNumber,
        assignedCrew: null,
        hireDate: employee.hireDate || '',
        trackSales: employee.trackSales || false,
        isIrrigationTech: isIrrigationTech,
        isSprayTech: isSprayTech,
        canReviewSites: employee.canReviewSites,
        isTemporaryBadge: employee.isTemporaryBadge,
        hasInventoryAccess,
        hasEquipmentCheckIn,
        associatedEmployee,
        isPoolTech
      }
      // console.log("Update STATE", updatedState)
        setState(updatedState)
        
        setAssignedCrew({})
      }
      if (data.crews) setCrews(data.crews)
    }    
  }).catch(function (err) {
  // console.log('Error getting employee details', err)
  });

}

// let testURL = 'https://dflportal.com/view/updateemployeephone'
// let qrCode2 = ''
// QRCode.toDataURL(testURL, opts, function (err, url) {
//   // console.log('ERR:', err)
//   // console.log('URL: ', url)
//     if (err) throw err
//   // console.log('Set the state:', url)
//   qrCode2 = url
//   })
  React.useEffect(() => {
  // console.log('Use effect EMPLOYEE MODAL Details', props)
  // console.log(state)
    // if (props.employee && props.employee._id) {
    //   const employee = props.employee
    //   let isSprayTech = (employee && employee.skills && employee.skills.includes('Spray Tech')) ? true : false
    //   let isIrrigationTech = (employee && employee.skills && employee.skills.includes('Irrigation Tech')) ? true : false
    //   let hasInventoryAccess = (employee && employee.skills && employee.skills.includes('Inventory')) ? true : false
    //   let hasEquipmentCheckIn = (employee && employee.skills && employee.skills.includes('Equipment')) ? true : false
    //   let isPoolTech = (employee && employee.skills && employee.skills.includes('Pool')) ? true : false
    //   let associatedEmployee = null
    //   if (props?.employee?.associatedEmployee) {
    //     associatedEmployee = props.employees.find(e => (e._id === props.employee.associatedEmployee))
    //     console.log('364', associatedEmployee)
    //   }
    //   if (props.employee.crew) {
    //     let ourIndex = props.crews.map(function (e) { 
    //       // console.log('WHHH?? ', e)
    //       return e._id
    //     }).indexOf(props.employee.crew)
    //   // console.log('OUR CREW INDEX: ', ourIndex)
    //     let updatedState = {...state,
    //       employee: props.employee,
    //       isH2B: props.employee.isH2B || false,
    //       isDriver: props.employee.isDriver || false,
    //       isBilingual: props.employee.isBilingual || false,
    //       driverNumber: props.employee.driverNumber,
    //       assignedCrew: props.crews[ourIndex],
    //       hireDate: props.employee.hireDate || '',
    //       isActive: props.employee.activeEmployee || false,
    //       trackSales: props.employee.trackSales || false,
    //       isIrrigationTech: isIrrigationTech,
    //       isSprayTech: isSprayTech,
    //       canReviewSites: props.employee.canReviewSites || false,
    //       isTemporaryBadge: props.employee?.isTemporaryBadge || false,
    //       hasInventoryAccess,
    //       hasEquipmentCheckIn,
    //       associatedEmployee,
    //       isPoolTech
    //     }
    //   console.log('State to update to: ', updatedState)
    //     setState(updatedState)
    //     setAssignedCrew(props.crews[ourIndex])
    //   } else {
    //   // console.log('Clear Assigned Crew!!!')
    //   let updatedState = {...state,
    //     employee: props.employee,
    //     isH2B: props.employee.isH2B || false,
    //     isDriver: props.employee.isDriver || false,
    //     isBilingual: props.employee.isBilingual || false,
    //     isActive: props.employee.activeEmployee || false,
    //     driverNumber: props.employee.driverNumber,
    //     hireDate: props.employee.hireDate || '',
    //     assignedCrew: null,
    //     trackSales: props.employee.trackSales || false,
    //     isIrrigationTech: isIrrigationTech,
    //     isSprayTech: isSprayTech,
    //     canReviewSites: props.employee.canReviewSites || false,
    //     isTemporaryBadge: props.employee?.isTemporaryBadge || false,
    //     hasInventoryAccess,
    //     associatedEmployee,
    //     hasEquipmentCheckIn,
    //     isPoolTech
    //   }
    //   console.log('State to update to:', updatedState)
    //     setState(updatedState)
    //     setAssignedCrew({})
    //   }
      
    // }
    if (props.employee && props.employee._id) getEmployeeDetails()
    // let service = props.service
    // let assignedCrew   
  }, [props?.employee]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }
// const handleChange = (event) => {
//   setRange(event.target.value);
//   let newStartDate = moment().subtract(event.target.value, 'days')
// // console.log('New start Date', newStartDate)
//   setStartDate(newStartDate)
//   setOpen(false);
// };

// const handleClose = () => {
//   setOpen(false);
//   // props.onUpdate()
// }

// const handleOpen = () => {
//   setOpen(true);
// };

const handleSubmit = (theReason) => {
// console.log('Update this employee!', state)
// console.log('The Reason', theReason)
  setLoading(true)
  saveOurChanges()
  // if (theReason && theReason.notes) {
  //   saveOurChanges(theReason)
  // } else if (state.employee.pay !== props.employee.pay) {
  //   setShowPayChangeReason(true)
  // } else {
  //   saveOurChanges()
  // }
}

const saveOurChanges = (theReason) => {
console.log('handle save 426', state)
  // console.log(props)
  let details = null
  if (theReason && theReason.notes) details = theReason.notes
  let newSkills = []
  if (state.isIrrigationTech) newSkills.push('Irrigation Tech')
  if (state.isSprayTech) newSkills.push('Spray Tech')
  if (state.hasInventoryAccess) newSkills.push('Inventory')
  if (state.hasEquipmentCheckIn) newSkills.push('Equipment')
  if (state.isPoolTech) newSkills.push('Pool Mtc')
  let skillsSet = new Set(newSkills)
  console.log('New skills', newSkills)
  let theEmployee = {
    ...state.employee,
    skills: Array.from(skillsSet)
  }
  if (state?.associatedEmployee?._id) theEmployee.associatedEmployee = state.associatedEmployee._id
  console.log('The employee', theEmployee)
  let obj = {
    details: details,
    employee: theEmployee
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/employees/updateEmployee`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update employee ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved Changes to this Employee!',
        message: 'Well Done!!'
      })
      // setState({...state,
      //   name: response.data.name,
      //   category: response.data.category || 0,
      //   description: response.data.description
      // })
      // handleCloseModal()
      // props.updateParent('update', response.data)
      handleCloseModal()
      // setOpen(false)
    } else if (response.data && response.data.message) {
    // console.log('Error message', response.data)
    // console.log('notificaiton boject', props.notification)
      props.notification({
        type: 'warning',
        title: 'Update Error!',
        autoClose: 5550,
        message: response.data.message
      })
    } else {
    // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up updating this employee...not sure why!'
      })
    }
    
  }).catch(err => {
  // console.log('Error saving workorder: ', err)
  })
}

const handleCloseModal = () => {
  props.updateParent('closeModal')
}

const handleMarkAbsent = () => {
// console.log('OPen Absent Modal for EMployee; ', state.employee)
  setShowAbsentModal(true)
}

const handleInviteUser = () => {
  setShowInviteUserModal(true)
}
const updateFromAbsentModal = (d) => {
// console.log('Update from our Absnet Modal: ', d)
  if (d === 'closeModal') setShowAbsentModal(false)
}

const updateFromInviteEmployeeModal = (d) => {
// console.log('Update from Invite user', d)
  setShowInviteUserModal(false)
}

const handleSetPIN = () => {
  setShowPINModal(true)
}

const handleReportIncident = () => {
  setShowIncidentReportModal(true)
}

const handleOrderBadge = () => {
  // const msg = `Are you sure you wish to order this employee a new badge?`
  // if (window.confirm(msg)) {
    console.log('Order that badge!!')
    setLoading(true)
    axios({
      method: 'post',
      url:  `${props.url}/api/employees/orderBadge?type=create`,
      data: state.employee,
    }).then(response => {
    console.log('Axios response to order employee badge', response)
      setTimeout(() => {
        setLoading(false)
      }, 4500)

        props.notification({
          type: 'success',
          title: 'Badge Creation In Process',
          message: 'The badge preview will be available momentarily'
        })
      })
  // }
}

const OrderNewBadgeButton = () => {
  let bgColor = 'rgba(25,160,25, .8)'
  let ttTitle = "Order Employee Badge"
  let invalidForBadge = true
  if (state.employee && state.employee.firstName && state.employee.lastName && state.employee.type && state.employee.division && state.employee.profile_photo_url) invalidForBadge = false
  return (
    <Tooltip title={ttTitle}>
      <Button onClick={handleOrderBadge} disabled={invalidForBadge || loading} variant="contained" color="secondary" edge='end' alt={ttTitle} aria-label={ttTitle}>
        <AssignmentIndIcon /> View ID Badge
      </Button>
    </Tooltip>
  )
}

const CloseButton = () => {
  return (
    <IconButton onClick={handleCloseModal} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
      <CloseIcon />
    </IconButton>
  )
}

const MarkAbsentButton = () => {
  return (
    <Tooltip title="Mark Absent">
      <IconButton onClick={handleMarkAbsent} style={{ 'color': '#fff', 'backgroundColor': 'rgba(140,30,25, .8)', 'position': 'absolute', 'top': '15px', 'right': '55%' }} edge='end' alt="Mark Absent" aria-label="Mark Absent">
        <AlarmAddIcon />
      </IconButton>
    </Tooltip>
  )
}

const SetPINButton = () => {
  let bgColor = 'rgba(85,130,215, .8)'
  let ttTitle = "Set Employee PIN"
  if (props.employee.employeePIN) {
    bgColor = 'rgba(155,30,115, .8)'
    ttTitle = "Update Employee PIN"
  }
  return (
    <Tooltip title={ttTitle}>
      <IconButton onClick={handleSetPIN} style={{ 'color': '#fff', 'backgroundColor': bgColor, 'position': 'absolute', 'top': '15px', 'right': '25%' }} edge='end' alt={ttTitle} aria-label={ttTitle}>
        <FiberPinIcon />
      </IconButton>
    </Tooltip>
  )
}
const UserInviteButton = () => {
  return (
    <Tooltip title="Invite User">
      <IconButton onClick={handleInviteUser} style={{ 'color': '#fff', 'backgroundColor': 'rgba(75,65,85, .8)', 'position': 'absolute', 'top': '15px', 'right': '35%' }} edge='end' alt="Invite Use" aria-label="Invite User">
        <PersonAddIcon />
      </IconButton>
    </Tooltip>
  )
}

const ReportIncidentButton = () => {
  let bgColor = 'rgba(185,10,25, .8)'
  let ttTitle = "Report Incident"
  return (
    <Tooltip title={ttTitle}>
      <IconButton onClick={handleReportIncident} style={{ 'color': '#fff', 'backgroundColor': bgColor, 'position': 'absolute', 'top': '15px', 'right': '45%' }} edge='end' alt={ttTitle} aria-label={ttTitle}>
        <WarningIcon />
      </IconButton>
    </Tooltip>
  )
}

const setServiceName = (name) => {
  setState({...state, name: name})
}

const updateEmployeeDivision = (id, value) => {
  // console.log('Update employee: ', id)
// console.log('Value: ', value)
  setState({...state, employee: {...state.employee, division: value}})
}

const updateEmployeeType = (id, value) => {
  // console.log('Update employee: ', id)
// console.log('Value: ', value)
  setState({...state, employee: {...state.employee, type: value}})
}

const updateAssignedUser = (e, user) => {
// console.log('uPDate assigned user:', user)
// console.log("state", state)
  if (user && user._id) {
    setUserProfile(user)
    let emp = state.employee
    emp.user = user._id
    setState({...state, employee: emp })
  } else {
    setUserProfile({})
    let emp = state.employee
    emp.user = null
    setState({...state, employee: emp })
  }
}

const updateAssociatedEmployee = (e, emp) => {
  console.log('This is the associated employee', emp)
  setState(prevState => ({...prevState, associatedEmployee: emp }))
}

const updateAssignedCrew = (event, crew) => {
// console.log('Update crew')
// console.log('Event: ', event)
// console.log('Option: ', crew)
  if (crew && crew._id) {
    setState({
      ...state,
      assignedCrew: crew,
      employee: {
        ...state.employee,
        crew: crew._id,
        crewName: crew.name
      }
    })
    setAssignedCrew(crew)
  } else {
    setState({
      ...state,
      assignedCrew: {},
      employee: {
        ...state.employee,
        crew: null,
        crewName: 'None'
      }
    })
    setAssignedCrew({})
  }
  
  // console.log(event.target.id)
  // let split = event.target.id.split('-')
  // let crewNum = split[3]
  // let optIndex = split[1]
  // console.log('Crew Numb: ', crewNum)
  // console.log('Opt Index: ', optIndex)
  // // // console.log(state.jobDetails[optIndex])
  // // let jobDetail = state.service[optIndex]
  // let newCrew = props.crews[crewNum]
  // console.log('nEW Crew:', newCrew)
  // let newWO = state.workorder
  // newWO.crewNumber = newCrew['agValue']
  // console.log('New Crew: ', newCrew)
  // console.log('For this job: ', jobDetail)
  // let newJobDetails = state.jobDetails
  // let ourIndex = state.jobDetails.map(function (e) { 
  //   // console.log('WHHH?? ', e)
  //   return e.number
  // }).indexOf(jobDetail.number)
  // newJobDetails[ourIndex]['crew'] = newCrew
  // newJobDetails[ourIndex]['requiresUpdate'] = true
  // setState({...state, employee: {...employee, crew}})
}

const EmployeeUserDetails = () => {
  let html = (
  <Grid container spacing={2} className={classes.gridItem}>
  <Grid item lg={6} xs={12}>
        <TextField
          
          className={classes.paddedStuff}
          margin="dense"
          id="Username"
          label="Employee Username"
          type="text"
          // onChange={(e) => setState({...state, employee: {...state.employee, number: e.target.value}})}
          value={userProfile.username}
          fullWidth
          disabled
      />
    </Grid>
    <Grid item lg={6} xs={12}>
        <TextField
          
          className={classes.paddedStuff}
          margin="dense"
          id="UserEmail"
          label="Employee User Email"
          type="text"
          // onChange={(e) => setState({...state, employee: {...state.employee, number: e.target.value}})}
          value={userProfile.email}
          fullWidth
          disabled
      />
    </Grid>
    </Grid>
    )
    if (userProfile && userProfile._id) {
      return html
    } else {
      return ''
    }
}

const saveCrews = (d) => {
// console.log('Save CREWS: ', d)
  let crewIdList = []
  d.map(item => crewIdList.push(item._id))
// console.log('Crews Associated w/ Employee:', crewIdList)
  setState({...state, employee: {...state.employee, crewsSupervised: crewIdList }})
}

const handleUpdateAssignedEntity = (newEntity) => {
  // Set employee entity
  if (newEntity && newEntity._id) {
    setState({...state, employee: {...state.employee, entity: newEntity._id }})
  } else {
    setState({...state, employee: {...state.employee, entity: null }})
  }
}

const handleSavePayChange = (theReason) => {
  setShowPayChangeReason(false)
  handleSubmit(theReason)
}

const handleCloseIncidentModal = () => {
// console.log('Close it')
  setShowIncidentReportModal(false)
}

const handleIncidentResponse = (data) => {
// console.log('Add this incident:', data)
  let newIncidents = [...incidents]
  newIncidents.unshift(data)
  setEmployeeIncidents(newIncidents)
  setShowIncidentReportModal(false)
  // let updatedRequiredUpdate = state.requiresUpdate
  // setState({...state, employee: newEmp, counter: state.counter++ })
}

const handleUpdateResponse = (resp) => {
  // console.log('response from upload:', resp)
  // console.log('Save this shit to the employee profile photo')
  if (resp && resp.filesUploaded && resp.filesUploaded.length) {
    let ourFile = resp.filesUploaded[0]
    let item = {...state.employee}
    item.profile_photo_url = ourFile.url
    setState({...state, employee: item })
  }
}
let dateInLocal = new Date()
if (state.employee && state.employee.hireDate) {
  // console.log('State Employee', state.employee)
  dateInLocal = new Date(state.employee.hireDate).toISOString().split('T')[0];
  // console.log('Date in lOcal', dateInLocal)
  // let momentDate = moment(state.employee.hireDate).add(7, 'hours').format('YYYY-MM-DD')
  // console.log('MOment date', momentDate)
}

const handleUpdateFromBadgeData = (d) => {
  if (d === 'closeModal') setShowBadgeModal(false)
}

  console.log('850', state)
  return (
    <div>
      <BadgeModal open={showBadgeModal} badgeData={badgeData} employee={state.employee} updateParent={handleUpdateFromBadgeData} />
      <AbsentModal open={showAbsentModal} employee={state.employee} updateParent={updateFromAbsentModal} />
      <InviteUserModal open={showInviteUserModal} employee={state.employee} updateParent={updateFromInviteEmployeeModal} />
      <SetPINModal open={showPINModal} employee={state.employee} closeModal={e => setShowPINModal(false)} />
      <NewIncidentModal open={showIncidentReportModal} employee={state.employee} updateParent={handleIncidentResponse} closeModal={handleCloseIncidentModal} />
        <Dialog
          // fullScreen={false}
          // maxWidth={'lg'}
          // width='lg'
          fullScreen
          // maxwidth='lg'
          // style={{width: '90hw'}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">Employee Details --  {state.employee.displayName}</DialogTitle>
          <DialogContent>
            <MarkAbsentButton />
            { (userProfile && userProfile._id) ? '' : <UserInviteButton /> }
            
            <ProtectedButton type="Compensation" kind="view" perms={props.perms}>
              <ReportIncidentButton />
            </ProtectedButton>
              
            <ProtectedButton type="Employees" kind="update" perms={props.perms}>
              <SetPINButton />
            </ProtectedButton>
             
            
          <CloseButton />
            <EmployeeUserDetails />
            <Grid container spacing={2} className={classes.gridItem}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <PhotoUploadComponent
                  message={`Click Here to Select Employee Profile Photo (Or Drop Image File Here)`}
                  employee={ state.employee }
                  updateParent={handleUpdateResponse}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ProfilePhoto employee={state.employee} />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                  <img src={state.qrCode} width="120" />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <OrderNewBadgeButton />
                {/* {
                  !state.employee.lastBadgeOrderDate ? <OrderNewBadgeButton /> : `Badge Ordered On ${moment(state.employee.lastBadgeOrderDate).format('MM/DD/YYYY')}`
                } */}
              </Grid>
            <Grid item lg={3} xs={12}>
                  <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Number"
                    label="Employee Number"
                    type="text"
                    onChange={(e) => setState({...state, employee: {...state.employee, number: e.target.value}})}
                    value={state.employee.number}
                    fullWidth
                />
              </Grid>
              <Grid item lg={3} xs={12}>
                  <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Number"
                    label="Workday Employee ID"
                    type="text"
                    onChange={(e) => setState(prevState => ({...prevState, employee: {...prevState.employee, workdayId: e.target.value}}))}
                    value={state?.employee?.workdayId || ''}
                    fullWidth
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                  <TextField
                        InputLabelProps={{
                          shrink: true,
                        }}
                    className={classes.paddedStuff}
                    margin="dense"
                    id="DisplayName"
                    label="Display Name"
                    type="text"
                    onChange={(e) => setState({...state, employee: {...state.employee, displayName: e.target.value}})}
                    value={state.employee.displayName}
                    fullWidth

                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Grid container spacing={2} className={classes.gridItem}>
                  <Grid item lg={6} xs={12}>
                      <TextField
                         InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.paddedStuff}
                        margin="dense"
                        id="FirstName"
                        label="First Name"
                        type="text"
                        onChange={(e) => setState({...state, employee: {...state.employee, firstName: e.target.value}})}
                        value={state.employee.firstName}
                        fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                      <TextField
                         InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.paddedStuff}
                        margin="dense"
                        id="LastName"
                        label="Last Name"
                        type="text"
                        onChange={(e) => setState({...state, employee: {...state.employee, lastName: e.target.value}})}
                        value={state.employee.lastName}
                        fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Grid container spacing={2} className={classes.gridItem}>
                  <Grid item lg={6} xs={12}>
                      <TextField
                         InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.paddedStuff}
                        margin="dense"
                        id="PrimaryPhone"
                        label="Primary Phone"
                        type="text"
                        onChange={(e) => setState({...state, employee: {...state.employee, primaryPhone: e.target.value}})}
                        value={state.employee.primaryPhone}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                      <TextField
                         InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.paddedStuff}
                        margin="dense"
                        id="SecondaryPhone"
                        label="Secondary Phone"
                        type="text"
                        onChange={(e) => setState({...state, employee: {...state.employee, secondaryPhone: e.target.value}})}
                        value={state.employee.secondaryPhone}
                        fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                     InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Email"
                    label="Email Address"
                    type="text"
                    onChange={(e) => setState({...state, employee: {...state.employee, email: e.target.value}})}
                    value={state.employee.email || ''}
                    fullWidth
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Grid container spacing={2} className={classes.gridItem}>
                  <Grid item lg={4} xs={12}>
                      <TextField
                         InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.paddedStuff}
                        margin="dense"
                        id="Address"
                        label="Address"
                        type="text"
                        onChange={(e) => setState({...state, employee: {...state.employee, address: e.target.value}})}
                        value={state.employee.address || ''}
                        fullWidth
                    />
                  </Grid>
                  <Grid item lg={3} xs={6}>
                    <TextField
                         InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.paddedStuff}
                        margin="dense"
                        id="City"
                        label="City"
                        type="text"
                        onChange={(e) => setState({...state, employee: {...state.employee, city: e.target.value}})}
                        value={state.employee.city || ''}
                        fullWidth
                    />
                  </Grid>
                  <Grid item lg={2} xs={6}>
                  <TextField
                         InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.paddedStuff}
                        margin="dense"
                        id="State"
                        label="State"
                        type="text"
                        onChange={(e) => setState({...state, employee: {...state.employee, state: e.target.value}})}
                        value={state.employee.state || ''}
                        fullWidth
                    />
                  </Grid>
                  <Grid item lg={2} xs={6}>
                      <TextField
                         InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.paddedStuff}
                        margin="dense"
                        id="Zipcode"
                        label="Zipcode"
                        type="text"
                        onChange={(e) => setState({...state, employee: {...state.employee, zip: e.target.value}})}
                        value={state.employee.zip || ''}
                        fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AssignedEntityComponent entity={state.employee.entity} title={'Assigned Entity'} disabled={loading} user={props.user} updateParent={handleUpdateAssignedEntity} />
              </Grid>
              <Grid item lg={12} xs={12}>
              <Autocomplete
                id="Division"
                options={divisions}
                // getOptionLabel={(option) => option.name}
                // style={{ width: 200 }}
                onChange={updateEmployeeDivision}
                // onChange={ (e) => updateEmployee(e.target.id, e.target.value) }
                value={divisions.find(div => div === state.employee.division) || null}
                // onChange={ e => setState({...state, employee: {...state.employee, division: e.target.value}, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Division" variant="outlined" />}
              />
              </Grid>
              <Grid item lg={12} xs={12}>
              <Autocomplete
                id="EmployeeType"
                options={employeeTypes}
                // getOptionLabel={(option) => option.name}
                // style={{ width: 200 }}
                onChange={updateEmployeeType}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value={employeeTypes.find(tp => tp === state.employee.type) || null}
                // onChange={ e => setState({...state, employee: {...state.employee, employeeType: e.target.value}, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Employee Type" variant="outlined" />}
              />
              </Grid>
              <Grid item lg={12} xs={12}>
                { ((state.employee.crewsSupervised && state.employee.crewsSupervised.length) || state.employee.type === 'Supervisor') ? <ChooseAssignedCrews saveCrews={saveCrews} employee={props.employee} /> : null }
              </Grid>
              <Grid item lg={12} xs={12}>
                <Autocomplete
                  id="AssignedCrew"
                  options={props.crews}
                  getOptionLabel={(option) => option.name}
                  // style={{ width: 200 }}
                  onChange={updateAssignedCrew}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {assignedCrew}
                  // onChange={ e => setState({...state, employee: {...state.employee, employeeType: e.target.value}, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Assigned Crew" variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Autocomplete
                  id="AssignedUsers"
                  options={props.users}
                  getOptionLabel={(option) => `${option.name || 'No Name'}  (${option.username})`}
                  // style={{ width: 200 }}
                  onChange={updateAssignedUser}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {userProfile}
                  // onChange={ e => setState({...state, employee: {...state.employee, employeeType: e.target.value}, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Assigned User" variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <ProtectedButton type="Compensation" kind="view" perms={props.perms}>
                  <IncidentHistoryComponent employee={state.employee} incidents={incidents} setIncidents={setEmployeeIncidents} />
                </ProtectedButton>
              </Grid>
              <Grid item lg={12} xs={12}>             
                <ProtectedButton type="Compensation" kind="view" perms={props.perms}>               
                  <CompensationComponent employee={state.employee} />
                </ProtectedButton>
              </Grid>
              <Grid item lg={12} xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Temp Worker</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isTemp}
                      onChange={ e => setState({...state, isTemp: !state.isTemp, employee: {...state.employee, isTemp: !state.isTemp } }) }
                      name="isTemp" />}
                      label="Is Temporary Worker"
                    />
                  </FormGroup>
                  
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">H2B Worker</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isH2B}
                      onChange={ e => setState({...state, isH2B: !state.isH2B, employee: {...state.employee, isH2B: !state.employee.isH2B } }) }
                      name="isH2B" />}
                      label="Is H2B Worker"
                    />
                  
                  </FormGroup>
                  
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    <TextField
                      id="date"
                      label="Hire Date"
                      type="date"
                      value={state.employee.hireDate ? dateInLocal : null } // "2017-05-24"}
                      className={classes.textField}
                      variant="outlined"
                      onChange={ e => setState({...state, employee: {...state.employee, hireDate: e.target.value} }) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormGroup>

                </FormControl>
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Driver License</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isDriver}
                      onChange={ e => setState({...state, isDriver: !state.isDriver, employee: {...state.employee, isDriver: !state.isDriver } }) }
                      name="driver" />}
                      label="Driver"
                    />
                  </FormGroup>
                  <FormHelperText>Employee Is Licensed & Insured</FormHelperText>
                </FormControl>
               
              </Grid>
              <Grid item lg={4} xs={6}>
              <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    { state.isDriver ? 
                    <TextField
                      id="driverLicenseNumber"
                      label="Driver License #"
                      type="text"
                      value={state.employee.driverNumber } // "2017-05-24"}
                      className={classes.textField}
                      // variant="outlined"
                      onChange={ e => setState({...state, employee: {...state.employee, driverNumber: e.target.value} }) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    : '' }
                  </FormGroup>

                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    { state.isDriver ? 
                    <TextField
                      id="date"
                      label="Expiration Date"
                      type="date"
                      value={moment(state.employee.dlExpDate).format('YYYY-MM-DD') } // "2017-05-24"}
                      className={classes.textField}
                      // variant="outlined"
                      onChange={ e => setState({...state, employee: {...state.employee, dlExpDate: e.target.value} }) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    : '' }
                  </FormGroup>

                </FormControl>
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12}></Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Spray Tech</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isSprayTech}
                      onChange={ e => setState({...state, isSprayTech: !state.isSprayTech  }) }
                      name="isSprayTech" />}
                      label={ state.isSprayTech ? 'Is Spray Tech' : 'Not a Spray Tech'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Irrigation Tech</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isIrrigationTech}
                      onChange={ e => setState({...state, isIrrigationTech: !state.isIrrigationTech}) }
                      name="isIrrigationTech" />}
                      label={ state.isIrrigationTech ? 'Is Irrigation Tech' : 'Not a Irrigation Tech'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Inventory Access (Can Scan QR Codes)</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.hasInventoryAccess}
                      onChange={ e => setState({...state, hasInventoryAccess: !state.hasInventoryAccess}) }
                      name="hasInventoryAccess" />}
                      label="Can Scan Inventory"
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Equipment Tracking (Can Check In)</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.hasEquipmentCheckIn}
                      onChange={ e => setState({...state, hasEquipmentCheckIn: !state.hasEquipmentCheckIn}) }
                      name="hasEquipmentCheckIn" />}
                      label="Can Check-In Gear"
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Pool Mtc Tech</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isPoolTech}
                      onChange={ e => setState({...state, isPoolTech: !state.isPoolTech}) }
                      name="isPoolTech" />}
                      label="Is Pool Tech"
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>          
              <Grid item lg={12} sm={12} md={12} xs={12}></Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormControl component="SprayTechLicense" className={classes.formControl}>
                    <FormLabel component="SprayTechLicense">Spray Tech License</FormLabel>
                    <FormGroup>
                      <TextField
                      id="sprayLicenseNumber"
                      label="Spray Tech #"
                      type="text"
                      fullWidth
                      value={state.employee.sprayTechLicense } // "2017-05-24"}
                      className={classes.textField}
                      // variant="outlined"
                      onChange={ e => setState({...state, employee: {...state.employee, sprayTechLicense: e.target.value} }) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    </FormGroup>
                    <FormHelperText>Employee Is A Certified Spray Tech</FormHelperText>
                  </FormControl>
               
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                { state.employee.sprayTechLicense ?
                  <FormControl component="SprayTechLicense" className={classes.formControl}>
                    <FormLabel component="SprayTechLicense">Spray Tech License</FormLabel>
                    <FormGroup>
                      <TextField
                        id="sprayLicenseExpiration"
                        label="Spray License Expiration Date"
                        type="date"
                        fullWidth
                        value={moment(state.employee.sprayLicenseExpiration).format('YYYY-MM-DD') } // "2017-05-24"}
                        className={classes.textField}
                        // variant="outlined"
                        onChange={ e => setState({...state, employee: {...state.employee, sprayLicenseExpiration: e.target.value} }) }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                    : '' }
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Bilingual</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isBilingual}
                      onChange={ e => setState({...state, isBilingual: !state.isBilingual, employee: {...state.employee, isBilingual: !state.isBilingual } }) }
                      name="bilingual" />}
                      label="Is Bilingual"
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Active Employee</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isActive}
                      onChange={ e => setState({...state, isActive: !state.isActive, employee: {...state.employee, activeEmployee: !state.isActive } }) }
                      name="Active" />}
                      label="Active"
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Track Sales</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.trackSales}
                      onChange={ e => setState({...state, trackSales: !state.trackSales, employee: {...state.employee, trackSales: !state.trackSales } }) }
                      name="Active" />}
                      label={state.trackSales ? 'Track Sales' : 'Do Not Track Sales'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Allow Site Reviews</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.canReviewSites}
                      onChange={ e => setState({...state, canReviewSites: !state.canReviewSites, employee: {...state.employee, canReviewSites: !state.canReviewSites } }) }
                      name="Active" />}
                      label={state.canReviewSites ? 'Can Review Sites' : 'Not Able to Review'}
                    />
                  </FormGroup>
                  
                </FormControl>
                
              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Is A Temporary Badge</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isTemporaryBadge}
                      onChange={ e => setState({...state, isTemporaryBadge: !state.isTemporaryBadge, employee: {...state.employee, isTemporaryBadge: !state.isTemporaryBadge } }) }
                      name="Active" />}
                      label={state.isTemporaryBadge ? 'Is A Temporary Badge' : 'Not A Temporary Badge'}
                    />
                  </FormGroup>
                  
                </FormControl>
                
              </Grid>
              {
                state.isTemporaryBadge && (
                  <Grid item lg={12} xs={12}>
                     <Autocomplete
                      id="AssociatedEmployee"
                      options={props.employees}
                      getOptionLabel={(option) => `${option.displayName || 'No Name'}  (${option.displayName})`}
                      // style={{ width: 200 }}
                      onChange={updateAssociatedEmployee}
                      // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                      value = {state.associatedEmployee}
                      // onChange={ e => setState({...state, employee: {...state.employee, employeeType: e.target.value}, requiresUpdate: true }) }
                      renderInput={(params) => <TextField {...params} label="Associated Employee" variant="outlined" />}
                    />
                  </Grid>
                )
              }

              
            </Grid>
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const ProfilePhoto = (props) => {
  if (props.employee && props.employee.profile_photo_url) {
    let cleanURL = ''
    let employee = props.employee
    if (employee.profile_photo_url.includes('https')) {
      cleanURL = employee.profile_photo_url
    } else {
      cleanURL = `https://${employee.profile_photo_url}`
    }
  // console.log('Clean URL:', cleanURL)
    return (
      <img src={cleanURL} width={280} />
    )
  } else {
    return null
  }
}


const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    users: state.users,
    perms: state.perms,
    employees: state.employees
  }
}

export default connect(mapStateToProps)(EmployeeDetailsModal)