// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".customer_customer__12rF5 {\n  margin: 20px; }\n", ""]);
// Exports
exports.locals = {
	"customer": "customer_customer__12rF5"
};
module.exports = exports;
