import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Sector, Legend } from 'recharts';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { currencyFormat } from '../../services/common'
// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 },
// ];

const COLORS = [
  '#789ca4', // your base color
  '#a47884', // complementary color
  '#7ba478', // complementary color
  '#a48f78', // triadic color
  '#a4788d', // triadic color
  '#8da478', // triadic color
  '#78a48f', // analogous color
  '#788da4', // analogous color
  '#8478a4', // analogous color
  '#a48478', // analogous color
  '#78a4a4', // monochromatic color
  '#a4a478', // monochromatic color
  '#8ca4a4', // monochromatic color
  '#a4a48c', // monochromatic color
  '#9ca4a4', // monochromatic color
  '#a4a49c', // monochromatic color
  '#a49ca4', // monochromatic color
  '#a4a49c', // monochromatic color
  '#9ca4a4', // monochromatic color
  '#a4a48c'  // monochromatic color
];

const renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy  + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomTooltip = (props) => {
// console.log('Custom tooltip:', props)
  const { active, payload, label } = props
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)', padding: 2, borderRadius: 3 }}>
        <p className="label">{`${payload[0].name} : ${currencyFormat(payload[0].value)}`}</p>
      </div>
    );
  }
  return null;
};

const MyPieChart = (props) => {
// console.log('Pie Chart', props)
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const data = props.data
  
  const handleOpen = (data,) => {
  // console.log('Handle open: ', data)
    setSelectedData(data);
    setOpen(true);
    props.filterProjects(data)
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <PieChart width={400} height={500}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onClick={handleOpen}
          label={renderLabel}
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend />
      </PieChart>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Project By Status Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedData && `${selectedData.name}: ${currencyFormat(selectedData.value)}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyPieChart;
