import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: '480px',
    overflow: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
  },
  tableRow: {
    '&:not(:last-child)': {
      borderBottom: `${theme.spacing(2)}px solid ${theme.palette.background.default}`,
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
}));

const determineDefaultCategory = (description) => {
  const lowerDesc = description.toLowerCase();
  if (lowerDesc.includes('labor') && !lowerDesc.includes('labor not included') && !lowerDesc.includes('does not include labor')) {
    return 'labor';
  } else if (lowerDesc.includes('equipment')) {
    return 'equipment';
  }
  return 'materials';
};

const handleFocus = (event) => event.target.select();

const InvoiceCreationDialog = ({ open, onClose, selectedWorkorders, workorders, onCreateInvoice, variables }) => {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState({});
  const [invoiceAmount, setInvoiceAmount] = useState({});
  const [invoicePercentage, setInvoicePercentage] = useState({});
  const [invoiceType, setInvoiceType] = useState({});
  const [invoiceQty, setInvoiceQty] = useState({});
  const [itemCategory, setItemCategory] = useState({});

  useEffect(() => {
    const initialSelectedItems = {};
    const initialInvoiceAmount = {};
    const initialInvoicePercentage = {};
    const initialInvoiceType = {};
    const initialInvoiceQty = {};
    const initialItemCategory = {};

    selectedWorkorders.forEach(id => {
      const workorder = workorders.find(w => w._id === id);
      initialSelectedItems[id] = { workorder: false };
      initialInvoiceAmount[id] = { workorder: workorder.price };
      initialInvoicePercentage[id] = { workorder: 100 };
      initialInvoiceType[id] = { workorder: 'amount' };
      initialInvoiceQty[id] = { workorder: 1 };
      initialItemCategory[id] = { workorder: '' };

      if (workorder.supplies && workorder.supplies.length > 0) {
        workorder.supplies.forEach((supply, index) => {
          initialSelectedItems[id][index] = false;
          initialInvoiceAmount[id][index] = supply.pricePer;
          initialInvoicePercentage[id][index] = 100;
          initialInvoiceType[id][index] = 'amount';
          initialInvoiceQty[id][index] = supply.qty;
          initialItemCategory[id][index] = determineDefaultCategory(supply.description);
        });
      }
    });

    setSelectedItems(initialSelectedItems);
    setInvoiceAmount(initialInvoiceAmount);
    setInvoicePercentage(initialInvoicePercentage);
    setInvoiceType(initialInvoiceType);
    setInvoiceQty(initialInvoiceQty);
    setItemCategory(initialItemCategory);
  }, [selectedWorkorders, workorders]);

  const calculateWorkorderInvoiceAmount = (workorderId) => {
    let total = 0;
    const workorderItems = selectedItems[workorderId] || {};

    if (workorderItems.workorder) {
      total += calculateLineItemAmount(workorderId, 'workorder', workorders.find(w => w._id === workorderId).price, invoiceQty[workorderId]?.workorder || 1);
    } else if (workorders.find(w => w._id === workorderId).supplies) {
      workorders.find(w => w._id === workorderId).supplies.forEach((item, index) => {
        if (workorderItems[index]) {
          total += calculateLineItemAmount(workorderId, index, item.pricePer, invoiceQty[workorderId]?.[index] || 0);
        }
      });
    }
    return total;
  };

  const calculateRemainingAmount = (workorderId, itemIndex) => {
    const workorder = workorders.find(w => w._id === workorderId);
    if (itemIndex === 'workorder') {
      return workorder.price - (workorder.invoicedAmount || 0);
    } else {
      const supplyItem = workorder.supplies[itemIndex];
      return supplyItem.totalPrice - (supplyItem.invoicedAmount || 0);
    }
  };

  const handleInputChange = (workorderId, itemIndex, field, value) => {
    const remainingAmount = calculateRemainingAmount(workorderId, itemIndex);
    const workorder = workorders.find(w => w._id === workorderId);
    const totalPrice = itemIndex === 'workorder' ? workorder.price : workorder.supplies[itemIndex].totalPrice;
  
    let newValue = parseFloat(value) || 0;
    const qty = invoiceQty[workorderId]?.[itemIndex] || 1;
  
    if (field === 'amount') {
      newValue = Math.min(newValue, remainingAmount / qty);
    } else if (field === 'percentage') {
      const maxPercentage = (remainingAmount / totalPrice) * 100;
      newValue = Math.min(newValue, maxPercentage);
    } else if (field === 'qty') {
      const maxQty = itemIndex === 'workorder' ? 1 : workorder.supplies[itemIndex].qty;
      newValue = Math.min(newValue, maxQty);
    }
  
    const updateState = (stateSetter) => {
      stateSetter(prev => ({
        ...prev,
        [workorderId]: {
          ...prev[workorderId],
          [itemIndex]: newValue
        }
      }));
    };
  
    if (field === 'amount' || field === 'percentage') {
      updateState(field === 'amount' ? setInvoiceAmount : setInvoicePercentage);
    } else if (field === 'qty') {
      updateState(setInvoiceQty);
    }
  };

  const calculateLineItemAmount = (workorderId, itemIndex, totalPrice, qty) => {
    const type = invoiceType[workorderId]?.[itemIndex];
    const value = type === 'percentage' 
      ? invoicePercentage[workorderId]?.[itemIndex] || 0
      : invoiceAmount[workorderId]?.[itemIndex] || 0;

    let amount;
    if (type === 'percentage') {
      amount = (totalPrice * value * qty) / 100;
    } else {
      amount = value * qty;
    }

    const remainingAmount = calculateRemainingAmount(workorderId, itemIndex);
    return Math.min(amount, remainingAmount);
  };

  const invoiceTotal = useMemo(() => {
    let total = 0;
    selectedWorkorders.forEach(workorderId => {
      const workorder = workorders.find(w => w._id === workorderId);
      if (!workorder) return; // Skip if workorder is not found

      const workorderItems = selectedItems[workorderId] || {};

      if (workorderItems.workorder) {
        total += calculateLineItemAmount(workorderId, 'workorder', workorder.price, invoiceQty[workorderId]?.workorder || 1);
      } else if (workorder.supplies) {
        workorder.supplies.forEach((item, index) => {
          if (workorderItems[index]) {
            total += calculateLineItemAmount(workorderId, index, item.pricePer, invoiceQty[workorderId]?.[index] || 0);
          }
        });
      }
    });
    return total;
  }, [selectedItems, invoiceAmount, invoicePercentage, invoiceType, invoiceQty, selectedWorkorders, workorders]);

  const handleItemSelect = (workorderId, itemIndex) => {
    setSelectedItems(prev => ({
      ...prev,
      [workorderId]: {
        ...prev[workorderId],
        [itemIndex]: !prev[workorderId][itemIndex],
      }
    }));
  };

  const handleTypeChange = (workorderId, itemIndex, newType) => {
    setInvoiceType(prev => ({
      ...prev,
      [workorderId]: {
        ...prev[workorderId],
        [itemIndex]: newType
      }
    }));
  };

  const handleCreateInvoice = () => {
    const lineItems = [];
    let invoiceDetails = []
    let uniqueJobs = []
    let uniqueEstimates = []
    const jobIds = new Set();  
    const estimateIds = new Set();
    let chosenWOs = []
    selectedWorkorders.forEach(workorderId => {
      // let workord
      const workorder = workorders.find(w => w._id === workorderId);
      chosenWOs.push(workorder)
      // Track unique jobs that are not associated with any estimates
      if (workorder.job && !jobIds.has(workorder.job._id)) {
        // Check if the job does not have an associated estimate
        if (!workorder.job.estimate || Object.keys(workorder.job.estimate).length === 0) {
            jobIds.add(workorder.job._id);
            uniqueJobs.push(workorder.job); // Assuming workorder.job is the job object
        }
      }

      // Track unique estimates
      if (workorder.estimate && !estimateIds.has(workorder.estimate._id)) {
          estimateIds.add(workorder.estimate._id);
          uniqueEstimates.push(workorder.estimate); // Assuming workorder.estimate is the estimate object
      }

      if (workorder.estimate) {
        invoiceDetails.push({
          workorder: workorder
        })
      }
      if (selectedItems[workorderId].workorder) {
        lineItems.push({
          workorder: workorderId,
          description: `Workorder #${workorder.number}`,
          amount: calculateLineItemAmount(workorderId, 'workorder', workorder.price, invoiceQty[workorderId]?.workorder || 1),
          quantity: invoiceQty[workorderId]?.workorder || 1,
        });
      } else if (workorder.supplies) {
        workorder.supplies.forEach((supplyItem, index) => {
          
        
          if (selectedItems[workorderId][index]) {
            // console.log('Supply item to include', supplyItem)
            const item = selectedItems[workorderId][index]
            let amount = calculateLineItemAmount(workorderId, index, supplyItem.pricePer, invoiceQty[workorderId]?.[index] || 0)
            // console.log('Do we include this item', item)
            console.log(amount)
            lineItems.push({
              workorder: workorderId,
              workorderNumber: workorder.number,
              description: supplyItem.description,
              amount: amount,
              quantity: invoiceQty[workorderId]?.[index] || 0,
              unitPrice: Number(supplyItem.pricePer) || 0,
              itemId: supplyItem.inventoryItem,
              supplyIndex: index,
              category: itemCategory[workorderId][index]
            });
          } else {
            // console.log('Ignore this one....', supplyItem)
          }
        });
      }
    });
    // console.log('Invoice Details:', invoiceDetails)
    // console.log('Categories:', itemCategory)
    // console.log('Unique Jobs:', uniqueJobs);      // Log the unique jobs
    // console.log('Unique Estimates:', uniqueEstimates);
    let convertedVariables = convertVariables(chosenWOs, lineItems, variables, uniqueEstimates, uniqueJobs)
    onCreateInvoice(lineItems, convertedVariables);
  };

  const handleCategoryChange = (workorderId, itemIndex, category) => {
    // console.log('Hnadle Category Change...', workorderId, itemIndex, category)
    setItemCategory(prev => ({
      ...prev,
      [workorderId]: {
        ...prev[workorderId],
        [itemIndex]: category
      }
    }));
  };

  // console.log(itemCategory)
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullScreen>
      <DialogTitle>Create Invoice</DialogTitle>
      <DialogContent>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Typography variant="h6">
            Invoice Total: ${invoiceTotal.toFixed(2)}
          </Typography>
        </Box>
        {selectedWorkorders.map(id => {
          const workorder = workorders.find(w => w._id === id);
          const workorderInvoiceAmount = calculateWorkorderInvoiceAmount(id);
          // console.log("wrokorder", workorder)
          return (
            <Accordion key={id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Workorder #{workorder.number} - {workorder.jobName}</Typography>
                <Typography>Amount on Invoice: ${workorderInvoiceAmount.toFixed(2)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">Select</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Total QTY</TableCell>
                        <TableCell>Amount Per (Total)</TableCell>
                        <TableCell>Previously Invoiced</TableCell>
                        <TableCell>Invoice QTY</TableCell>
                        <TableCell>Invoice Type</TableCell>
                        <TableCell>Amount/%</TableCell>
                        <TableCell>Amount On This Invoice</TableCell>
                        <TableCell>Category</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedItems[id]?.workorder || false}
                            onChange={() => handleItemSelect(id, 'workorder')}
                          />
                        </TableCell>
                        <TableCell>Full Workorder</TableCell>
                        <TableCell>1</TableCell>
                        <TableCell>{workorder.price || 0}</TableCell>
                        <TableCell>
                            {workorder?.invoicedAmount || 0}
                        </TableCell>
                        <TableCell>
                          1
                        </TableCell>
                        
                        <TableCell>
                          <FormControl>
                            <Select
                              value={invoiceType[id]?.workorder || 'amount'}
                              onChange={(e) => handleTypeChange(id, 'workorder', e.target.value)}
                              disabled={!selectedItems[id]?.workorder}
                            >
                              <MenuItem value="amount">Amount</MenuItem>
                              <MenuItem value="percentage">Percentage</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={constrainValue(
                              invoiceType[id]?.workorder === 'percentage' 
                                ? invoicePercentage[id]?.workorder || 0 
                                : invoiceAmount[id]?.workorder || 0,
                              0,
                              invoiceType[id]?.workorder === 'percentage' 
                                ? (calculateRemainingAmount(id, 'workorder') / workorder.price) * 100
                                : calculateRemainingAmount(id, 'workorder')
                            )}
                            onFocus={handleFocus}
                            onChange={(e) => {
                              const max = invoiceType[id]?.workorder === 'percentage' 
                                ? (calculateRemainingAmount(id, 'workorder') / workorder.price) * 100
                                : calculateRemainingAmount(id, 'workorder');
                              const constrainedValue = constrainValue(e.target.value, 0, max);
                              handleInputChange(id, 'workorder', invoiceType[id]?.workorder, constrainedValue);
                            }}
                            disabled={!selectedItems[id]?.workorder}
                            inputProps={{
                              min: 0,
                              max: invoiceType[id]?.workorder === 'percentage' 
                                ? (calculateRemainingAmount(id, 'workorder') / workorder.price) * 100
                                : calculateRemainingAmount(id, 'workorder')
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {calculateLineItemAmount(id, 'workorder', workorder.price, invoiceQty[id]?.workorder || 1).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {/* <RadioGroup
                            className={classes.radioGroup}
                            value={itemCategory[id]?.workorder || ''}
                            onChange={(e) => handleCategoryChange(id, 'workorder', e.target.value)}
                          >
                            <FormControlLabel value="materials" control={<Radio />} label="Materials" />
                            <FormControlLabel value="equipment" control={<Radio />} label="Equipment" />
                            <FormControlLabel value="labor" control={<Radio />} label="Labor" />
                          </RadioGroup> */}
                        </TableCell>
                      </TableRow>
                      {workorder.supplies && workorder.supplies.map((item, index) => {
                        console.log('SUpply item...', item)
                        if (Number(item.totalPrice) > 0) {
                        return (
                        <TableRow key={index}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedItems[id]?.[index] || false}
                              onChange={() => handleItemSelect(id, index)}
                            />
                          </TableCell>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>{item.qty}</TableCell>
                          
                          <TableCell>{item.pricePer} {Number(item.qty) > 1 ? `(${item.totalPrice})` : ''}</TableCell>
                          <TableCell>
                            { item?.invoicedAmount || 0 }
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={invoiceQty[id]?.[index] || 0}
                              onChange={(e) => handleInputChange(id, index, 'qty', e.target.value)}
                              onFocus={handleFocus}
                              disabled={!selectedItems[id]?.[index] || workorder.supplies[index].qty === '1'}
                              inputProps={{ 
                                min: 0, 
                                max: Math.min(
                                  workorder.supplies[index].qty, 
                                  Math.floor(calculateRemainingAmount(id, index) / workorder.supplies[index].pricePer)
                                ) 
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <FormControl>
                              <Select
                                value={invoiceType[id]?.[index] || 'amount'}
                                onChange={(e) => handleTypeChange(id, index, e.target.value)}
                                disabled={!selectedItems[id]?.[index]}
                              >
                                <MenuItem value="amount">Amount</MenuItem>
                                <MenuItem value="percentage">Percentage</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={constrainValue(
                                invoiceType[id]?.[index] === 'percentage'
                                  ? invoicePercentage[id]?.[index] || 0
                                  : invoiceAmount[id]?.[index] || 0,
                                0,
                                invoiceType[id]?.[index] === 'percentage'
                                  ? (calculateRemainingAmount(id, index) / item.totalPrice) * 100
                                  : calculateRemainingAmount(id, index) / (invoiceQty[id]?.[index] || 1)
                              )}
                              onFocus={handleFocus}
                              onChange={(e) => {
                                const max = invoiceType[id]?.[index] === 'percentage'
                                  ? (calculateRemainingAmount(id, index) / item.totalPrice) * 100
                                  : calculateRemainingAmount(id, index) / (invoiceQty[id]?.[index] || 1);
                                const constrainedValue = constrainValue(e.target.value, 0, max);
                                handleInputChange(id, index, invoiceType[id]?.[index], constrainedValue);
                              }}
                              disabled={!selectedItems[id]?.[index]}
                              inputProps={{
                                min: 0,
                                max: invoiceType[id]?.[index] === 'percentage'
                                  ? (calculateRemainingAmount(id, index) / item.totalPrice) * 100
                                  : calculateRemainingAmount(id, index) / (invoiceQty[id]?.[index] || 1)
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {calculateLineItemAmount(id, index, item.pricePer, invoiceQty[id]?.[index] || 0).toFixed(2)}
                          </TableCell>
                          <TableCell>
                          <RadioGroup
                              className={classes.radioGroup}
                              value={itemCategory[id]?.[index] || ''}
                              disabled={!selectedItems[id]?.workorder}
                              onChange={(e) => handleCategoryChange(id, index, e.target.value)}
                            >
                              <FormControlLabel value="materials" control={<Radio />} label="Materials" />
                              <FormControlLabel value="equipment" control={<Radio />} label="Equipment" />
                              <FormControlLabel value="labor" control={<Radio />} label="Labor" />
                            </RadioGroup>
                          </TableCell>
                        </TableRow>
                      ) 
                      } else {
                        return null
                      }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateInvoice} color="primary" disabled={invoiceTotal <= 0}>
          Create Invoice
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const convertVariables = (workorders, lineItems, variables, estimates, jobs) => {
  let convertedVariables = {...variables}
  let previouslyInvoiced = 0
  let totalAmount = 0
  let totalEstimateAmount = 0
  let materialsTotal = 0
  let equipmentTotal = 0
  let laborTotal = 0
  // console.log('Convert these variables based on estimates and jobs', variables)
  // console.log('estimates', estimates)
  // console.log('Jobs', jobs)
  // console.log('Workorders', workorders)
  // console.log('line items', lineItems)
  workorders.forEach(wo => {
    convertedVariables.jobName = wo.jobName
    previouslyInvoiced += Number(wo.invoicedAmount)
  })
  estimates.forEach(est => {
    convertedVariables.estimateNumber = est.number
    totalEstimateAmount += Number(est.totalPrice)
    convertedVariables.estimateName = est.name
  })
  lineItems.forEach(line => {
    // console.log('Gotta figure out the total previously invoiced...', line)
    // previouslyInvoiced += Number(line.invoicedAmount)
    if (line.category === 'materials') materialsTotal += Number(line.amount)
    if (line.category === 'equipment') equipmentTotal += Number(line.amount)
    if (line.category === 'labor') laborTotal += Number(line.amount)
    totalAmount += Number(line.amount)
  })
  // console.log('Total', totalAmount)
  // console.log('Previous invoices...', previouslyInvoiced)
  convertedVariables.invoiceAmount = currencyFormat(totalAmount)
  convertedVariables.totalEstimateAmount = currencyFormat(totalEstimateAmount)
  convertedVariables.previouslyInvoiced = currencyFormat(previouslyInvoiced)
  convertedVariables.remainingBalance = currencyFormat(totalEstimateAmount - totalAmount - previouslyInvoiced)
  convertedVariables.materialsTotal = currencyFormat(materialsTotal)
  convertedVariables.laborTotal = currencyFormat(laborTotal)
  convertedVariables.equipmentTotal = currencyFormat(equipmentTotal)
  // console.log('Converted Variables', convertedVariables)
  return convertedVariables
}

const constrainValue = (value, min, max) => {
  const numValue = Number(value);
  if (isNaN(numValue)) return min;
  return Math.max(min, Math.min(numValue, max));
};

export default InvoiceCreationDialog;