import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ViewUploadDetails from './ViewUploadDetails';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  // { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'size', numeric: false, disablePadding: false, label: 'Size' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Uploaded' },
  { id: 'user', numeric: false, disablePadding: false, label: 'Uploaded By' },
  // { id: 'updated_at', numeric: false, disablePadding: false, label: 'Last Update' },
  { id: '___', numeric: true, disablePadding: false, label: 'Action' }
];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 10
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rowPad: {
    height: '40px !important',
    // padding: 'px'
  }
}));

function EnhancedTable(props) {
  // console.log('File Upload List Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [state, setState] = React.useState({
    searchMatches: 0,
    project: {},
    index: 0
  })
  const [showProjectDetailsModal, setShowProjectDetailsModal] = React.useState(false)
  const [showUploadDetails, setShowUploadDetails] = React.useState(false)
  const [activeUpload, setActiveUpload] = React.useState({})

  React.useEffect(() => {
  // console.log('Files Changed File Upload List: ', props.files)
    if (props.files && props.files.length) {
      setRows(props.files)
      if (searchValue) {
        // console.log('Search Value is set: ', searchValue)
        filterOut(props.files, searchValue)
      } else {
        // console.log('Search Value not set')
        setFilteredRows(props.files)
      }
      setPage(0)
    }
  }, [props])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  // // console.log('handle click ', name)
  // // console.log('Index: ', selectedIndex)
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storeSearchMatches = (d) => {
    // console.log('Search matches: ', d)
    // setState({...state, searchMatches: d.length})
  }
  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const openProjectModal = (project) => {
    setState({...state, project: project})
    setShowProjectDetailsModal(true)
  }
  const handleCloseModal = (d) => {
    // setState({...state, project: { _id: null } })
    setShowProjectDetailsModal(false)
  }

  const updateProjectDetails = (d, updateType) => {
    // console.log('modify pRoject: ', d)
    if (updateType === 'deleted') {
      let projList = props.projects
      // console.log(projList)
      let index = projList.findIndex(item => item._id.toString() === d._id.toString())
      // console.log('INDEX:', index)
      // console.log('DELETE: ', projList[index])
      if (index > -1) {
        // console.log('Deleting: ', index)
        // projList[index] = d
        projList.splice(index, 1)
        // console.log('AFter Splice: ', projList)
        setRows(projList)
        setFilteredRows(projList)
        // setState({...state, project: d })
      }
    }
    if (updateType === 'saved') {
      let projList = props.projects
      let index = projList.findIndex(item => item._id === d._id)
      if (index > -1) {
        projList[index] = d
        setRows(projList)
        setFilteredRows(projList)
      }
      setState({...state, project: d })
    }
  }

  const openUploadDetails = (row, index) => {
    // setState({...state,})
    setActiveUpload(row)
    setShowUploadDetails(true)
    setState({...state, activeUpload: row, index: index})
  }

  const closeUploadDetailsModal= () => {
    setShowUploadDetails(false)
  }

  const updateUpload = (d) => {
    handleNextItem()
    props.updateUpload(d)
  }

  const handlePrevItem = () => {
    // console.log('Previous', activeUpload)
  // console.log(props.files)
    let index = props.files.findIndex(item => (item._id === activeUpload._id))
    let item = {}
  // console.log(index)
    if (index > -1) {
      if (index === 0) {
        item = props.files[props.files.length - 1]
        if (item && item._id) {
          // console.log(item)
          setActiveUpload(item)
          setState({...state, index: props.files.length - 1})
        }
      } else {
        index--
        item = props.files[index]
        if (item && item._id) {
          // console.log(item)
          setActiveUpload(item)
          setState({...state, index: index})
        } else {
          item = props.files[props.files.length]
          if (item && item._id) {
            // console.log(item)
            setActiveUpload(item)
            setState({...state, index: index})
          }
        }
      }
    } else {
      let item = props.files[state.index--]
      if (item && item._id) {
        // console.log(item)
        setActiveUpload(item)
        setState({...state, index: index})
      }
    }
  }
  const handleNextItem = () => {
    // console.log('Next', activeUpload)
    // console.log(props.files)
    if (props.files && props.files.length && props.files.length > 1) {
      let index = props.files.findIndex(item => (item._id === activeUpload._id))
      // console.log(index)
      if (index > -1) {
        index++
        let item = props.files[index]
        if (item && item._id) {
          // console.log(item)
          setActiveUpload(item)
          setState({...state, index: index})
        } else {
          item = props.files[0]
          if (item && item._id) {
            // console.log(item)
            setActiveUpload(item)
            setState({...state, index: 0})
          }
        }
      } else {
        if (state.index === 0) state.index = props.files.length - 1
        if (state.index++ > props.files.length) state.index = 0
        let item = props.files[state.index]
        if (item && item._id) {
          // console.log(item)
          setActiveUpload(item)
          setState({...state, index: index})
        }
      }
    } else {
      // console.log('No Files left')
      setShowUploadDetails(false)
      setActiveUpload({})
      props.notification({
        title: 'No Files Left',
        type: 'success',
        message: `Great Job! You've Gotten to the Bottom of the Pile.`
      })
    }
  }

  const handleDelete = (item, index) => {
    if (window.confirm(`Are You Sure You Want To Delete This Upload? - ${item.name}`)) {
      props.deleteItem(item)
      // let list = props.files
      // list.splice(index, 1)
      // setFilteredRows(list)
      // setRows(list)
      
      // setState({...state, counter: state.counter++ })
    }
  }

  function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }

  const handleDeleteUpload = (data) => {
  // console.log('Delete this motherfuker!!', data)
    props.deleteItem(data)
  }

  return (
    <div className={classes.root}>
      <ViewUploadDetails open={showUploadDetails} clients={props.clients} vendors={props.vendors} purchaseOrders={props.purchaseOrders} totalFiles={props.files.length} index={state.index} upload={activeUpload} file={activeUpload} handleNextItem={handleNextItem} handlePrevItem={handlePrevItem} closeModal={closeUploadDetailsModal} updateUpload={updateUpload} handleDeleteUpload={handleDeleteUpload} />
     <Paper className={classes.paper}>
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={12} xs={12} style={{textAlign: 'center'}}>
            <h2>Un-Tagged Files Uploaded</h2>
          </Grid>
          <Grid item lg={3} xs={6}>
          Total Non-Tagged Files: {rows.length}
          </Grid>
          <Grid item lg={4}>
          {/* <Button
          variant="contained"
          // style="secondary"
          style={{ color: '#222'}}
          // className={classes.button}
          size="small"
          startIcon={<PersonAddIcon  color="primary" />}
          disabled={loading}
          // onClick={handleSnoutWeevil }
          onClick={handleAddEmployee}
          // onClick={handleDeleteService}
          // onClick={offerDeleteOption}
          // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
        >
          Add Employee
        </Button>  */}
          </Grid>
        </Grid>
        <Grid container style={{padding: '10px'}}>
         
          <Grid item lg={6} xs={6}>

            <div className={classes.search}>
                <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div>
                <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input>
              </div>
          </Grid>
          <Grid item lg={6}>
          {/* <FormControlLabel
                    control={<Checkbox checked={showAllEmployees} onChange={setShowAllOrNot} name="showAllEmployees" />}
                    label="Include Inactive"
                  /> */}
          </Grid>

        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            options={{
              rowStyle: {height: 55}
            }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let url = ''
                  if (row && row.photos && row.photos.length) url = row.photos[0]['url']
                  // setsearchMatches(rows.length)

                    return (
                    
                      <TableRow
                        hover
                        // onClick={event => handleClick(event, row._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        className={classes.rowPad}
                        selected={isItemSelected}
                      >
                       <TableCell component="th" id={labelId} scope="row" padding="none">
                        <span className={classes.fakeLink} onClick={() => openUploadDetails(row, index)}>{row.name || 'Un-Named Vendor'}</span>
                        {/* {row.name} */}
                      </TableCell>
                      <TableCell align="left">
                          {humanFileSize(row.size, true)}
                        </TableCell>
                        {/* <TableCell align="left">
                          
                          { row.address } {row. city}, {row.state}
                        </TableCell>
                        <TableCell align="left">
                          
                          { row.description }
                        </TableCell> */}
                        <TableCell align="left"> {moment(row.created_at).format('MM/DD/YY [at] hh:mm:ss a')} </TableCell>
                        <TableCell align="left">
                          {row.userName || row.created_by}
                        </TableCell>
                        {/* <TableCell align="left">
                        {row.updated_at ? moment(row.updated_at).format('MMM DD, YYYY') : ''}
                        </TableCell> */}
                        <TableCell align="right">
                          <IconButton
                            label="Delete"
                            onClick={e => handleDelete(row, index)}
                            // startIcon=
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                        </TableCell>
                      </TableRow>
                    );
 
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // onChangePage={handleChangePage}
          onPageChange={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(EnhancedTable)
