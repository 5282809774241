import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, Checkbox }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListOfExistingOptions from './ListOfOptions'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import NewSizeModal from '../../Settings/ProposalSettings/GenericOptionModal'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const filter = createFilterOptions();

const ModifyOptionMultiComponent = (props) => {
// console.log('ModifyOptionMultiComponent 28', props)
  const [showModal, setShowModal] = React.useState(false)
  const [listOfShit, setListOfShit] = React.useState([])
  const [itemEditing, setItemEditing] = React.useState({})
  const [optionToEdit, setOptionToEdit] = React.useState({})
  const [sizes, setSizes] = React.useState(props.sizes || [])

  React.useEffect(() => {
    if (props.item && props.item.options && props.item.options.length) {
      setListOfShit(props.item.options)
    } else {
      setListOfShit([])
    }
    if (props.chosenScopes && props.chosenScopes.length) {
      // console.log('Set Sizes based on scopes', props.chosenScopes)
      // Iterate over chosenScopes and szies to find where they intersect
      //
      let sizesToSet = []
      props.chosenScopes.map(scope => {
        props.sizes.findIndex(size => {
          if (size?.scopes?.includes(scope._id)) {
            // console.log('Include this one', size)
            // console.log('scope._id:', scope._id)
            sizesToSet.push(size)
          }
        })
      })
      // console.log('Sizes to set:', sizesToSet)
      let sizeSet = new Set(sizesToSet)
      // console.log('size set', sizeSet)
    }
    // setItemToEdit(props.item)
  }, [props.item])



  const handleOpenNewSizeModal = () => {
    setOptionToEdit(null)
    setShowModal(true)
  }

  const saveOurData = (data) => {
    let obj = {
      option: data,
      item: props.item,
      scope: props.scope
    }
  // console.log('Object to send:', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/company/modifyTakeoffOptions`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Update our form options', response.data)
      if (response.data && response.data.item && response.data.item._id) {
      // console.log('If our ID does NOT match update it!!!')
      props.updateParent(response.data.item)
      } else if (response.data && response.data._id) {
      // console.log('This should be our updated item w/ options now....')
        props.updateParent(response.data)
      }
      // // console.log(props)
      //   let list = [...listOfShit]
      //   list.unshift(response.data)
      // // console.log('Update the list:', list)
      //   setListOfShit(list)
      // } else if (response.data.noItem) {
      // // console.log('This is either an old item or a brand new item so we gotta hold this shit', data)
      //   let list = [...listOfShit]
      //   let line = {
      //     name: data.name,
      //     size: data.size.name,
      //     sizeId: data.size._id,
      //     notes: data.notes
      //   }
      //   list.unshift(line)
      // // console.log('Update the list:', list)
      //   setListOfShit(list)
      //   props.updateParent(data)
      // }
    })
  }

  const handleCloseNewOptionModal = (data) => {
    setShowModal(false)
    if (data) {
    // console.log('Gotta save this somehow', data)
      saveOurData(data)
      // props.updateParent(data)
    }
  }

  const handleEditAnItem = (opt) => {
  console.log('Edit this item: - open the modal', opt)
    setShowModal(true)
    setOptionToEdit(opt)
    // props.editThisItem(itemToEdit)
  }

  const handleDeleteAnItem = (item) => {
    console.log('Delete Option', item)
    let obj = {
      option: item,
      item: props.item
    }
  // console.log('Object to send:', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/company/modifyTakeoffOptions?delete=true`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    console.log('Update the options', response.data)
      if (response.data && response.data.item && response.data.item._id) {
      // console.log('If our ID does NOT match update it!!!')
      } else if (response.data && response.data._id) {
        console.log('This should be our updated item w/ options now....', response.data)
        props.updateParent(response.data)
      }
    })
  }

  const handleUpdateFromModal = async (optionsToSave) => {
    setShowModal(false)
    console.log('Save these', optionsToSave)
  // console.log('Object to send:', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/company/modifyTakeoffOptions?bulk=true`,
      data: optionsToSave,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      console.log('Response ', response.data)
      props.notification({
        title: 'Success!',
        message: 'Saved those Options',
        type: 'success'
      })
      props.saveOptions(response.data.item)
    })
  }
  
  // console.log('Item to EDIT:', itemToEdit)
  return (
    <React.Fragment>
      <Modal scope={props.scope} optionToEdit={optionToEdit} item={props.item} sizes={props.sizes} open={showModal} closeModal={handleCloseNewOptionModal} updateParent={handleUpdateFromModal} />
      {
        (props.item && props.item._id) ? <TheButton handleOpenNewSizeModal={handleOpenNewSizeModal} /> : null
      }
    </React.Fragment>
  )
}

const defaultState = {
  size: '',
  name: '',
  notes: '',
  options: []
}

const Modal = (props) => {
  // Sizes, Prices etc
// console.log('Add Option MODAL PROPS:', props)
  const [sizes, setSizes] = React.useState([])
  const [state, setState] = React.useState(defaultState)
  const scope = props.scope || {}

  React.useEffect(() => {
    if (props.optionToEdit && props.optionToEdit._id) {
      setState(props.optionToEdit)
    } else {
      setState(defaultState)
    }
    if (props.item && props.item._id) updateOurOptions()
  }, [props])

  const updateOurOptions = () => {
    const item = props.item
    const filteredSizes = [...props.sizes].filter(size => {
      // console.log('Size to filter', size)
      let isIncluded = false
      if (size.scopes && size.scopes.length) {
        if (item && item.scopeIds && item.scopeIds.length) {
          item.scopeIds.map(scopeId => {
           if (size.scopes.includes(scopeId)) {
            isIncluded = true
           }
          })
        }
      } else {
        isIncluded = true
      }
      // console.log('Return it...')
      if (isIncluded) return size
    })
    // console.log('Sizes to include: ', filteredSizes)
  
    let filteredOptions = []
    filteredSizes.map(sizeItem => {
      // console.log('Size item:', sizeItem)
      let newItem = {
        size: sizeItem.name,
        sizeId: sizeItem._id,
        name: sizeItem.name,
        msrp: '',
        included: false,
        takeOff: props.item._id,
        notes: ''
      }
      // console.log('Item to save', newItem)
      filteredOptions.push(newItem)
    })
    // console.log('Filtered Options', filteredOptions)
  
    if (item && item.options && item.options.length) {
      item.options.map(opt => {
        // console.log('Item option to verify is in our size list', opt)
        let index = filteredOptions.findIndex(op => {
          // console.log('OPPPP', op)
          // console.log(opt.sizeId, '   --    ', op.sizeId)
          return (op.sizeId === opt.sizeId)
        })
        // console.log('312: ', index)
        if (index < 0) {
          // console.log('Use this:::', opt)
          filteredOptions.push({
            optionId: opt._id,
            size: opt.size,
            sizeId: opt.sizeId,
            name: opt.name,
            msrp: opt.msrp || '',
            included: true,
            takeOff: props.item._id,
            notes: opt.notes
          })
        } else {
          // console.log("mOdify our otpion", index)
          // console.log(filteredOptions[index])
          filteredOptions[index].included = true
          filteredOptions[index].optionId = opt._id
          filteredOptions[index].name = opt.name
          filteredOptions[index].msrp = opt.msrp
        }
      })
    }
    // console.log('Filtered Options 116', filteredOptions)
    setState({...state, options: filteredOptions })
  }

  const handleClose = () => {

    props.closeModal()
  }

  const handleSave = () => {
    // console.log('Save our new options:', state)
    let optsToSave = state.options.filter(item => (item.included))
    // console.log('These are the only ones to worry about', optsToSave)
    props.updateParent(optsToSave)
    // props.closeModal(state)
  }

  return (
    <Dialog
    open={props.open}
    onClose={handleClose}
    fullWidth
    maxWidth='md'
    
    >
      <DialogTitle>Modify Options ({props.item ? props.item.name : ''}) - {props.scope.name} </DialogTitle>
      <DialogContent>
        <NewOptionConfiguration item={props.item} sizes={props.sizes} setParentState={setState} parentState={state} />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={handleSave}
          >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const NewOptionConfiguration = ({ sizes, setParentState, parentState, item }) => {
  // console.log('Multi Option Config', item)
  // console.log('Parent State', parentState)
// console.log('Sizes::', sizes)
  const [state, setState] = React.useState({
    size: {},
    name: '',
    notes: '',
    option: '',
    options: []
  })
  const [showNewSizeModal, setShowNewSizeModal] = React.useState(false)
  const type = 'size'

  const handleUpdateSize = (e, newSize) => {
  // console.log('Update size', newSize)
    if (typeof newSize === 'string') {
    // console.log('User typed this shit!!')
    } else if (newSize && newSize.inputValue) {
    // console.log('We hvae an input value')
      // setState({...state, size: newSize })
      setShowNewSizeModal(true)
    } else {
    // console.log('using an existing size')
      // setState({...state, size: newSize })
      setParentState({...parentState, size: newSize})
    }
    // setState({...state, size: newSize })
    // setParentState({...parentState, size: newSize})
  }

  const updateOption = (data) => {
  // console.log("192", data)
    setShowNewSizeModal(false)
    setState({...state, size: data })
    setParentState({...parentState, size: data})
  }

  const handleCloseOptionModal = () => {
    setShowNewSizeModal(false)
  }

  const sizeObj = {
    name: 'Size'
  }

  const handleDeleteOption = (d) => {
  // console.log('Delete this bitch')
  }

  const handleUpdateFromList = (newList) => {
    // console.log('Replace our options with this:', newList)
    setParentState({...parentState, options: newList })
  }


  return (
    <div>
      <NewSizeModal open={showNewSizeModal} type={'size'} dataToUse={sizeObj} closeModal={handleCloseOptionModal} deleteOption={handleDeleteOption} updateParent={updateOption} />
      <TableOfSizes sizes={sizes} options={parentState.options} item={item} updateParent={handleUpdateFromList} />     
    </div>
  )
}

const TheButton = ({ handleOpenNewSizeModal }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={handleOpenNewSizeModal}
    >
      Modify Options
    </Button>
  )
}

const TableOfSizes = ({ sizes, item, options, updateParent }) => {


  const updateItem = (type, val, index) => {
    console.log(`${type}: ${val}`)
    let newList = [...options]
    let itemToUpdate = {...newList[index]}
    itemToUpdate[type] = val
    itemToUpdate.included = true
    console.log('Item updated', itemToUpdate)
    newList[index] = itemToUpdate
    // setState({...state, list: newList })
    updateParent(newList)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Included</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Size</TableCell>
          <TableCell>MSRP</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          [...options].map((inputItem, index) => {
            // console.log('Input Line:', inputItem)
            return (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={inputItem.included}
                    // onChange={onSelectAllClick}
                    onChange={e => updateItem('included', !inputItem.included, index)}
                    inputProps={{ 'aria-label': 'select-item' }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="name"
                    value={inputItem.name}
                    onChange={e => updateItem('name', e.target.value, index)}
                    fullWidth
                    variant="outlined"
                    // onBlur={handleUpdateParent}
                    label="Option Name"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="size"
                    value={inputItem.size}
                    disabled
                    // onChange={updateItem}
                    fullWidth
                    variant="outlined"
                    // onBlur={handleUpdateParent}
                    label="Option Size"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="msrp"
                    value={inputItem.msrp}
                    onChange={e => updateItem('msrp', e.target.value, index)}
                    fullWidth
                    variant="outlined"
                    // onBlur={handleUpdateParent}
                    label="Option MSRP"
                  />
                </TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )
}

const mapStateToProps = (state) => {
// console.log('Map State to Props add options', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ModifyOptionMultiComponent)