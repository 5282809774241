// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".logout_logout__2pFMB {\n  margin: 50px;\n  text-align: center; }\n\n.logout_circle__2ITvR {\n  margin-left: 30px; }\n", ""]);
// Exports
exports.locals = {
	"logout": "logout_logout__2pFMB",
	"circle": "logout_circle__2ITvR"
};
module.exports = exports;
