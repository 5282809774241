import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import { Editor } from 'react-draft-wysiwyg';
// import {EditorState} from "draft-js";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import SizeOptions from './SizeOptions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  gridItem: {
    width: '800px'
  },
  defaultInputStuff: {
    fontSize: '18px',
    fontFamily: 'Amiri',
    color: 'rgb(153,153,153)'
  }
}));

const ScopeDetailsModal = (props) => {
  // console.log('Got our ServiceDetailsModal modal', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
const [open, setOpen] = React.useState(false);
const categories = [
  'Monthly Maintenance', 'Landscapes', 'Specialty', 'Clean-Ups', 'Irrigation', 'Tree Trimming', 'Lighting', 'Pest Control', 'Water Feature', 'Flowers', 'Other'
]
const [statementName, setStatementName] = React.useState('Nov2020')
const [state, setState] = React.useState({
  name: '',
  description: '',
  category: '',
  serviceCode: '',
  _id: '',
  scopes: []
});
const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
const [content, setContentState] = React.useState({})

// React.useEffect(() => {
//   // console.log('PROPS.item changed', props)
//   // setState({...state, name: props.item.name })
//   // setDialogValue({...dialogValue, name: props.item.name, description: props.item.name})
//   // let html = '<h1>Test</h1>'
//   // setContentState(content)
//   const html = `<p></p><p></p><br/><br/><br/>Test This Shit out<br/>`;
//   const contentBlock = htmlToDraft(html);
//   if (contentBlock) {
//     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
//     const editorState = EditorState.createWithContent(contentState);
//     setContentState(contentState)
//     setEditorState(editorState)
//   }
// }, [props])

  React.useEffect(() => {
    // console.log('Use effect Option Details', props)
    let option = props.option
    if (option && option._id) {
      if (option._id === 'new') {
        // console.log("SET NEW")
        setContentState({})
        setEditorState()
        setState({...state,
          name: '',
          htmlContent: {},
          description: '',
          notes: '',
          _id: 'new'
        })
      } else {
        // console.log('Existing: ', option)
        let contentBlock = null
        if (option.htmlContent) contentBlock = htmlToDraft(option.htmlContent)
        // console.log(contentBlock)
        // const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setContentState(contentState)
          setEditorState(editorState)
        }

        // setContentState(html)
        setState({...state,
          name: option.name,
          description: option.description,
          notes: option.notes,
          htmlContent: contentBlock,
          _id: option._id
        })
      }
    }
  }, [props.option]);
// const handleChange = (event) => {
//   setRange(event.target.value);
//   let newStartDate = moment().subtract(event.target.value, 'days')
// // console.log('New start Date', newStartDate)
//   setStartDate(newStartDate)
//   setOpen(false);
// };

// const handleClose = () => {
//   setOpen(false);
//   // props.onUpdate()
// }

// const handleOpen = () => {
//   setOpen(true);
// };
const handleDeleteService = () => {
  // Delete Service
  props.deleteOption(state)
}

const handleSubmit = () => {
  // console.log('Update this option!', state)
  setLoading(true)
  // console.log('handle save', state)
  // console.log('Save Content: ', content)
  let stuff = editorState ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : null
  // console.log(html)
  // setContentState(html)
  // console.log('Make our own??', stuff)
  // console.log(props)
  let obj = {
    option: state,
    content: stuff
    // scope: props.option
  }
  // console.log('Send this: ', obj)
  axios({
    method: 'post',
    url:  `${props.url}/api/settings/updateGenericOption?type=${props.type}`,
    data: obj
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to update generic option ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response && response.data && response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved that Option!',
        message: 'Well Done!!'
      })
      // setState({...state,
      //   name: response.data.name,
      //   category: response.data.category || 0,
      //   description: response.data.description
      // })
      // handleCloseModal()
      props.updateParent(response.data)
      // setOpen(false)
    } else if (response && response.data && response.data.err) {
    // console.log('Got an error code: ', response.data.err)
      let err = response.data.err
      props.notification({
        type: 'danger',
        title: 'Uh Oh! Houston, We Have a Problem...Error Code ' + err.code,
        message: err.errmsg
      })
    } else {
    // console.log('No fucking reponse.data???')
    }
  }).catch(err => {
  // console.log('Error saving Service: ', err)
    props.notification({
      type: 'danger',
      title: 'Uh Oh! Houston, We Have a Problem...',
      message: err.message
    })
  })
}

const handleCloseModal = () => {
  props.closeModal('closeModal')
}

const setServiceName = (name) => {
  setState({...state, name: name})
}


const onEditorStateChange = (editorStateChange) => {
  // console.log(editorStateChange)
  setEditorState(editorStateChange)
}

const handleBlur = (data) => {
  // console.log(data)
  let test = convertToRaw(editorState.getCurrentContent())
  // console.log("BLUR", test)
  if (test && test.blocks && test.blocks.length) {
    let ourString = ''
    test.blocks.map(item => {
      ourString += item.text + ' '
    })
    // console.log('Text Only: ', ourString)
    setState({...state, description: ourString})
  }
  let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  // console.log(html)
  setContentState(html)
  // let currentContent = editorState.getCurrentContent()
  // if (currentContent){
  // // console.log('content:', currentContent)
  //   let toStr = "";
  //   for (var i=0; i< currentContent.blocks.length; i++){
  //       toStr+=currentContent.blocks[i].text+" "
  //   }
  // // console.log(toStr);
  //   setState({...state, description: toStr })
  // }
  // props.updateContent(html)
}
// const onContentStateChange = (contentState) => {
//   // console.log(contentState)
//   setContentState(contentState)
// }

const handleUpdateFromSizeOption = (data) => {
// console.log('Size options update', data)
  setState({...state, scopes: data})
  // if (props.)
}
  return (
    <div>
        <Dialog
          // fullScreen={false}
          maxWidth={'lg'}
          width='lg'
          // maxwidth='lg'
          // style={{width: 700}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">{props.dataToUse.name} Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} className={classes.gridItem}>
            <Grid item lg={10} xs={10}></Grid>
            <Grid item lg={2} xs={2}>
              <Button
                variant="contained"
                style={{backgroundColor: '#ed4545'}}
                className={classes.button}
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => { if (window.confirm(`Are you sure you wish to delete this ${props.dataToUse.name}?`)) handleDeleteService() } }
                // onClick={handleDeleteService}
                // onClick={offerDeleteOption}
                // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
              >
                Delete
              </Button> 
            </Grid>
             <Grid item lg={12} xs={12}>
                <TextField
                  id={`${props.dataToUse.name} Name`}
                  label={`${props.dataToUse.name} Name`}
                  type="text"
                  fullWidth
                  value={state.name}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setServiceName(e.target.value) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                {/* <TextField
                  id="Option Description"
                  label="Option Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.description}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, description: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
                  <Editor
                    // initialContentState={content}
                    editorStyle={{ border: "2px solid #aaa", minHeight: '150px' }}
                    editorState={editorState}
                    wrapperClassName="wrapper"
                    editorClassName={classes.defaultInputStuff}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'textAlign', 'history', 'colorPicker'],
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      colorPicker: {
                        colors: ['rgb(153, 153, 153)', 'rgb(0, 0, 0)', 'rgb(172, 202, 205)', 'rgb(119, 156, 164)', 'rgb(81,110,118)', 'red', 'yellow', 'green', 'blue', 'purple', 'pink', 'orange', 'gray', 'white']
                      }
                    }}
                    onEditorStateChange={onEditorStateChange}
                    // onContentStateChange={onContentStateChange}
                    onBlur={handleBlur}
                  />
              </Grid>
              <Grid item lg={12} xs={12}>
                {
                  props.type === 'size' ? (
                    <SizeOptions item={props.option} updateSizeOptions={handleUpdateFromSizeOption} />
                  ) : null
                }
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  id={`${props.dataToUse.name} Notes`}
                  label={`${props.dataToUse.name} Notes`}
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.notes}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, notes: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ScopeDetailsModal)