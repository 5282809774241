import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PlanInputItem from './PlanInputItem'
import SupplyInventoryInputItem from './SupplyInventoryInputItem'
import SupplierComponent from './SupplierComponent'
import { Editor } from 'react-draft-wysiwyg';
// import {EditorState} from "draft-js";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const editorStyles = makeStyles(theme => ({
  editorStyle: {
    fontFamily: 'Amiri',
    fontSize: 18,
    color: 'rgb(153, 153, 153)'
  }
}))

const GenericCustomizer = ({ html, updateOption, isMultiple }) => {
  // console.log('Introduction ', introduction)
  const classes = editorStyles()
  React.useEffect(() => {
  // console.log('HTML has changed!!', html)
    // let isHTMLAnArray = Array.isArray(html)
    // console.log('Array: ', isHTMLAnArray)
    // if (html && html.length && isMultiple && isHTMLAnArray) {
    //   // console.log('Add this to our canvas', introduction.htmlContent)
    //   // Convert our HTML into multiple paragraphs
    //   let newHTML = `<p>`
    //   for (const item of html) {
    //     newHTML += item
    //     newHTML += `</p>`
    //   // console.log('NEW HTML:', newHTML)
    //   }
    // // console.log('NEW HTML:', newHTML)
    //   let contentBlock = htmlToDraft(newHTML)
    //   // console.log(contentBlock)
    //   // const contentBlock = htmlToDraft(html);
    //   if (contentBlock) {
    //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    //     const editorState = EditorState.createWithContent(contentState);
    //     setContentState(contentState)
    //     setEditorState(editorState)
    //   }
    // } else 
    if (html && html.htmlContent) {
      // console.log('Add this to our canvas', introduction.htmlContent)
      let contentBlock = htmlToDraft(html.htmlContent)
      // console.log(contentBlock)
      // const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setContentState(contentState)
        setEditorState(editorState)
      }
    } else if (typeof html === 'Object') {
    // console.log("HTML OBJECT!!!", html)
    } else if (html) {
      let contentBlock = htmlToDraft(html)
      // console.log(contentBlock)
      // const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setContentState(contentState)
        setEditorState(editorState)
      }
    } else {
      setContentState({})
      setEditorState(EditorState.createEmpty())
    }
  }, [html])

  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [content, setContentState] = React.useState({})
  const onEditorStateChange = (editorStateChange) => {
    // console.log(editorStateChange)
    setEditorState(editorStateChange)
  }
  
  const handleBlur = (data) => {
    // console.log(data)
    let test = convertToRaw(editorState.getCurrentContent())
    // console.log("BLUR", test)
    let ourString = ''
    if (test && test.blocks && test.blocks.length) {
      test.blocks.map(item => {
        ourString += item.text + ' '
      })
      // console.log('Text Only: ', ourString)
      // setState({...state, description: ourString})
    }
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // console.log(html)
    setContentState(html)
    updateOption({ html: html, text: ourString })
  }

  return (
    <Editor
      // toolbar={{
      //   fontFamily: {
      //     options: ['Roboto', 'Playfair', 'Inspiration', 'Amiri', 'TimesNewRoman']
      //   },
      //   color: {
      //     options: ['#656', '#000']
      //   }
      // }}
      toolbar={{
        options: ['inline', 'blockType', 'list', 'textAlign', 'history', 'colorPicker'],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        colorPicker: {
          colors: ['rgb(153, 153, 153)', 'rgb(0, 0, 0)', 'rgb(172, 202, 205)', 'rgb(119, 156, 164)', 'rgb(81,110,118)', 'red', 'yellow', 'green', 'blue', 'purple', 'pink', 'orange', 'gray', 'white']
        }
    }}
      // initialContentState={content}
      editorStyle={{ border: "2px solid #aaa", minHeight: '150px' }}
      editorState={editorState}
      wrapperClassName="wrapper"
      editorClassName={classes.editorStyle}
      onEditorStateChange={onEditorStateChange}
      // onContentStateChange={onContentStateChange}
      onBlur={handleBlur}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const detailsByOptionType = {
  'objective': {
    title: 'Objective',
    subTitle: 'What is the objective of this proposal? We need to give our vision to the customer of what we are trying to achieve.',
    header: 'Choose From Pre-Made Objectives:',
    helper: 'You may customize the objective however you want, these are just designed to give you a starting point.',
    inputLabel: 'Objective'
  },
  // 'objectives': {
  //   title: 'Objective',
  //   subTitle: 'What is the objective of this proposal? We need to give our vision to the customer of what we are trying to achieve.',
  //   header: 'Choose From Pre-Made Objectives:',
  //   helper: 'You may customize the objective however you want, these are just designed to give you a starting point.',
  //   inputLabel: 'Objective'
  // },
  'introduction': {
    title: 'Introduction/Greeting',
    subTitle: 'How would you like to introduce us to this customer?',
    header: 'Choose From Pre-Made Introductions:',
    helper: 'You may customize the introduction however you want, these are just designed to give you a starting point.',
    inputLabel: 'Introduction'
  },
  'objectives': {
    title: 'Proposal Notes',
    subTitle: 'What Notes Would You Like to Add Before the Scope of Work?',
    header: 'Choose From Pre-Made Notes:',
    helper: 'You may customize the notes however you want, these are just designed to give you a starting point.',
    inputLabel: 'Proposal Notes'
  },
  'proposalNotes': {
    title: 'Proposal Notes',
    subTitle: 'What Notes Would You Like to Add Before the Scope of Work?',
    header: 'Choose From Pre-Made Notes:',
    helper: 'You may customize the notes however you want, these are just designed to give you a starting point.',
    inputLabel: 'Proposal Notes'
  },
  'finalParagraph': {
    title: 'Final Paragraph',
    subTitle: 'What Would You Like to Add Towards the End of the Proposal?',
    header: 'Choose From Pre-Made Final Paragraphs:',
    helper: 'You may customize the Final Paragraphs however you want, these are just designed to give you a starting point.',
    inputLabel: 'Final Paragraph'
  },
  'disclosures': {
    title: 'Disclosures',
    subTitle: 'What Disclosures Would You Like to Add to the Proposal?',
    header: 'Choose From Pre-Made Disclosures:',
    helper: 'You may customize the Disclosures however you want, these are just designed to give you a starting point.',
    inputLabel: 'Disclosures'
  },
  'closing': {
    title: 'Closing',
    subTitle: `This is like the opposite of the Introduction. The very end before the salesperson's information`,
    header: 'Choose From Pre-Made Closings:',
    helper: 'You may customize the closing however you want, these are just designed to give you a starting point.',
    inputLabel: 'Closing'
  },
  'areas': {
    title: 'Areas',
    subTitle: `This will be the areas covered by the proposal`,
    header: 'Enter The Areas Covered:',
    helper: '',
    inputLabel: 'Areas'
  },
  'paymentSchedule': {
    title: 'Payment Schedule',
    subTitle: `This will be the Payment Schedule for this proposal`,
    header: 'Choose the Appropriate Payment Schedule:',
    helper: '',
    inputLabel: 'Payment Schedule'
  }
}

const defaultValues = {
  title: 'Title',
  subTitle: 'Sub-Title',
  header: 'Header',
  helper: 'Helper',
  inputLabel: 'Input Label'
}

const GenericModal = (props) => {
// console.log('GenericModal Props:', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [htmlOnly, setHTMLOnly] = React.useState('')
  const [state, setState] = React.useState(defaultValues)
  const [options, setOptions] = React.useState([])
  const [selectedOption, setSelectedOption] = React.useState({})
  // const [textStuff, setTextStuff] = 
  React.useEffect(() => {
    let isMounted = true
    if (props.open) {
      setLoading(true)
      // console.log('Fetch The right thing!!!!', props)
      if (props && props.type) {
        // console.log(props.type)
        let opts = detailsByOptionType[props.type]
        setState({...state, ...opts})
        // console.log('Route: ', route)
        if (props.estimate && props.estimate[props.type] && !props.isMultiple) {
          // Set our existing HTML
          setHTMLOnly(props.estimate[props.type])
        } else {
          setHTMLOnly('')
        }
        axios.get(`${props.url}/api/settings/getGenericOptions?type=${props.type}`).then((response, status, data) => {
          setLoading(false)
          if (response && response.data) {
            if (isMounted) {
              // console.log('Set OPTIONS: ', response.data)
              setOptions(response.data)
            }
          }
        })
      }
      

    }
    return () => {
      isMounted = false
    }
  }, [props.open])

  const handleClose = () => {
    setSelectedOption(null)
    props.closeModal()
  }
  const handleSubmit = () => {
    // console.log('Handle submit called 261', htmlOnly)
    setSelectedOption(null)
    props.handleSaveOption({ html: htmlOnly, type: props.type, isMultiple: props.isMultiple })
  }

  const updateOption = (e, newOpt) => {
    // console.log("Change:::", newOpt)
    if (newOpt) {
      setSelectedOption(newOpt)
      setHTMLOnly(newOpt)
    } else {
      setSelectedOption(null)
      setHTMLOnly(null)
    }
  }

  const customizeOption = (data) => {
    console.log('Customize this option: ', data)
    if (data && data.html) {
      let newOpt = selectedOption
      console.log('new Option:', newOpt)
      newOpt.description = data.text
      newOpt.htmlContent = data.html
      setHTMLOnly(data.html)
      setSelectedOption(newOpt)
    }
  }

  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">

      <DialogTitle id="form-dialog-title">{state.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {state.subTitle}
        </DialogContentText>
      <LabelText>{state.header}</LabelText>
      <FormControl fullWidth >
       <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: 8 }}>
          {state.helper}
        </Grid>
        {
          props.type === 'areas' ? null : (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                id={state.inputLabel}
                options={options || []}
                getOptionLabel={(option) => {
                  // console.log('options: ', option)
                  return option.name ? `${option.name}` : ''
                }}
                disabled={props.type === 'areas'}
                // style={{ width: 200 }}
                className={classes.paddedStuff}
                // style={{marginBottom: 10, marginTop: 15}}
                onChange={updateOption}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {selectedOption}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} variant="outlined" label={state.inputLabel} />}
              />
            </Grid>
          )
        }
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GenericCustomizer html={htmlOnly} updateOption={customizeOption} isMultiple={props.isMultiple} />
        </Grid>
      </Grid>
      </FormControl>
     
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
 
   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    user: state.user
  }
}

export default connect(mapStateToProps)(GenericModal)
