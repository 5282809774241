import React from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SupplierComponent from '../../Proposals/SupplierComponent'
import { Button, CircularProgress } from '@material-ui/core';
// import ChooseEntityComponent from '../../Entities/ChooseEntityComponent'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const chemicals = [
  {
    name: 'Round Up'
  },
  {
    name: 'TRIBUNE Non Selective'
  },
  {
    name: 'AXXE'
  },
  {
    name: 'SCYTHE'
  }
]
const DeleteFileButton = (props) => {
  // console.log('Delete file button', props)
  const handleDelete = () => {
    const message = `Are you sure you wish to delete this upload? ${props.upload.name || ''} -- This is NOT reversible.`
    if (window.confirm(message)) {
      props.handleDelete(props.upload)
    }
  }
  return <Button startIcon={<DeleteForeverIcon />} size="small" variant="outlined" onClick={ handleDelete } style={{ textAlign: 'right', backgroundColor: 'rgba(213, 41, 12, 0.75)' }}>Delete</Button>
}
const docTypes = {
  'Vendor Quote': {
    number: {
      name: 'Quote Number',
      placeholder: 'Quote #2323',
      helperText: 'What Is The Quote Number?'
    },
    amount: {
      name: 'Quote Amount',
      placeholder: '23.34',
      helperText: 'How Much Is the Quote Total?'
    },
    vendor: {
      name: 'Vendor Name',
      placeholder: 'Please Choose The Vendor',
      helperText: 'Choose The Related Vendor'
    }
  },
  'Vendor Invoice': {
    number: {
      name: 'Invoice Number',
      placeholder: 'Invoice #2323',
      helperText: 'What Is The Invoice Number?'
    },
    amount: {
      name: 'Invoice Amount',
      placeholder: '$23.34',
      helperText: 'How Much Is the Invoice Total?'
    },
    vendor: {
      name: 'Vendor Name',
      placeholder: 'Please Choose The Vendor',
      helperText: 'Choose The Related Vendor'
    }
  },
  'Customer Invoice': {
    number: {
      name: 'Invoice Number',
      placeholder: 'Invoice #2323',
      helperText: 'What Is The Invoice Number?'
    },
    amount: {
      name: 'Invoice Amount',
      placeholder: '$23.34',
      helperText: 'How Much Is the Invoice Total?'
    }
  },
  'Purchase Order': {
    number: {
      name: 'PO Number',
      placeholder: 'PO #2323',
      helperText: 'What Is The PO Number?'
    },
    amount: {
      name: 'PO Amount',
      placeholder: '$23.34',
      helperText: 'How Much Is the PO Total?'
    },
    vendor: {
      name: 'Vendor Name',
      placeholder: 'Please Choose The Vendor',
      helperText: 'Choose The Related Vendor'
    }
  },
  'Photos': {
    number: {
      name: 'Photo Number',
      placeholder: 'Photo #2323',
      helperText: 'What Is The Photo Number?'
    }
  },
  'Plans': {
    number: {
      name: 'Plans Number',
      placeholder: 'Plans #2323',
      helperText: 'What Is The Plans Number?'
    }
  },
  'Estimates': {
    number: {
      name: 'Estimate Number',
      placeholder: 'Estimate #2323',
      helperText: 'What Is The Estimate Number?'
    },
    amount: {
      name: 'Estimate Amount',
      placeholder: '$23.34',
      helperText: 'How Much Is the Estimate Total?'
    }
  },
  'Spray Log': {
    number: {
      name: 'Spray Tech Number',
      placeholder: 'Spray Tech #',
      helperText: 'Who Is the Spray Tech?'
    }
  },
  'ADOT': {
    number: {
      name: 'Crew Number',
      placeholder: 'Crew #',
      helperText: 'What Is The Crew?'
    }
  },
  'Dump': {
    number: {
      name: 'Crew Number',
      placeholder: 'Crew #',
      helperText: 'What Is The Crew?'
    }
  },
  'Other': {
    number: {
      name: 'Other Reference Number',
      placeholder: 'RefId #2323',
      helperText: 'What Is The Reference Number?'
    }
  }
}

const unitsOfMeasurement = [
  {
    name: 'Ounce'
  },
  {
    name: 'Gallon'
  },
  {
    name: 'Ton'
  }
]


const UploadDetailsInputArea = (props) => {
  // console.log('UPldoad Details: ', props)
  const inputRef = React.useRef({})
  const [state, setState] = React.useState({
    counter: 0,
    uploadType: '',
    invoiceStatus: '',
    notes: 'test',
    number: '',
    amount: 0,
    client: {},
    project: {},
    workorder: {},
    vendor: null,
    invoiceType: 'Client Project/Job',
    entity: {},
    sprayTechs: [],
    sprayTech: {},
    chemUsed: {},
    jobSite: {},
    unit: {},
    crewNumber: '',
    amount: '',
    date: moment(Date.now()).format('YYYY-MM-DD')
  })

  const [docTypeDetails, setDocTypeDetails] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [project, setProject] = React.useState({ name: '' })
  const [projects, setProjects] = React.useState([])
  const [job, setJob] = React.useState({ jobName: '' })
  const [jobs, setJobs] = React.useState([])
  const [jobSites, setJobsites] = React.useState([])
  const [sprayTech, setSprayTech] = React.useState({})
  const [jobSite, setJobsite] = React.useState({})
  const [activeEntity, setEntity] = React.useState({})
  // function setNativeValue(element, value) {
  //   let lastValue = element.value;
  //   element.value = value;
  //   let event = new Event("input", { target: element, bubbles: true });
  //   // React 15
  //   event.simulated = true;
  //   // React 16
  //   let tracker = element._valueTracker;
  //   if (tracker) {
  //       tracker.setValue(lastValue);
  //   }
  //   element.dispatchEvent(event);
  //   setState({...state, counter: state.counter++})
  // }

  // React.useEffect(() => {
  // // console.log("Props.employees CHanged", props.employees)
  //   if (props.employees && props.employees.length) {
  //   // console.log('Find Spray TEchs!')
  //     // Find Spray Techs
  //     let techs = []
  //     props.employees.map(emp => {
  //     // console.log('Spray Tech??', emp)
  //       if (emp && emp.sprayTechLicense) {
  //       // console.log('WE found one!!', emp)
  //         techs.push(emp)
  //       }
  //     })
  //   // console.log('Set thos espray techs now', techs)
  //     setSprayTechs(techs)
  //     setState({...state, sprayTeches: techs })
  //   }
  // }, [props.employees])

  React.useEffect(() => {
  // console.log('UPLOAD INPUT USE EFFECT', props.upload)
    let newState ={}
    if (props.upload && props.upload._id) {
    // console.log('Set Our Active Upload:', props.upload)
      inputRef.current = {
        documentType: props.upload.documentType,
        notes: props.upload.notes || '',
        number: props.upload.number || '',
        amount: props.upload.amount || 0,
        vendor: props.upload.vendor || null,
        invoiceStatus: props.upload.status || null,
        workorder: null,
        client: null,
        project: null
      }
      if (props.upload.workorder) {
        inputRef.current.workorder = props.upload.workorder
        // setJob()
      }
      let documentType = props.upload.documentType
      if (props.upload && props.upload.scanType) {
        if (props.upload.scanType === 'sprayLog') {
          documentType = 'Spray Log'
        }
        if (props.upload.scanType === 'adotOnCall') {
          documentType = 'ADOT'
        }
        updateDocTypes(documentType)
        newState.uploadType = documentType
      }

      if (props.upload && props.upload.documentType) updateDocTypes(props.upload.documentType)
      if (props.upload.client) {
        // console.log('Set Client: ', props.upload.client)
        let index = props.clients.findIndex(item => (item._id === props.upload.client))
        // if (index > -1) setClient(props.clients[index])
        if (index > -1) {
          // console.log('Set it now:', props.clients[index])
          updateCustomer(null, props.clients[index], true)
        }
      }
      // setProject(props.upload.project)
      let vendor = null
      if (props.upload.vendor) {
        vendor = props.upload.vendor
        newState.vendor = props.upload.vendor
      }
      if (props.upload.notes) {
        newState.notes = props.upload.notes
      }
      if (props.upload.amount) {
        newState.amount = props.upload.amount
      }
      if (props.upload.number) {
        newState.number = props.upload.number
      }
      if (props.upload.invoiceStatus) {
        newState.invoiceStatus = props.upload.invoiceStatus
      }
      // let customerNameElement = document.getElementById('customername')
      // if (customerNameElement) setNativeValue(customerNameElement, props.upload)
      // let projectNameElement = document.getElementById('projectname')
      // if (projectNameElement) setNativeValue(projectNameElement, '')
      // let numberElement = document.getElementById('number')
      // if (numberElement) setNativeValue(numberElement, props.upload.number)
      // let amountElement = document.getElementById('amount')
      // if (amountElement) setNativeValue(amountElement, props.upload.amount)
      // // let statusElement = document.getElementById('status')
      // // if (statusElement) setNativeValue(statusElement, '')
      // let notesElement = document.getElementById('notes')
      // if (notesElement) setNativeValue(notesElement, props.upload.notes)

      // {...state, counter: state.counter++, uploadType: documentType, vendor, notes: props.upload.notes, amount: props.upload.amount, number: props.upload.number, invoiceStatus: props.upload.status }
    // console.log('Set New State on Uploads:', newState)
      setState({...state, ...newState})
    } else {
      // console.log('Clear this bitch!')
      inputRef.current = {}
      // let customerNameElement = document.getElementById('customername')
      // if (customerNameElement) setNativeValue(customerNameElement, '')
      // let projectNameElement = document.getElementById('projectname')
      // if (projectNameElement) setNativeValue(projectNameElement, '')
      // let numberElement = document.getElementById('number')
      // if (numberElement) setNativeValue(numberElement, '')
      // let amountElement = document.getElementById('amount')
      // if (amountElement) setNativeValue(amountElement, '')
      // let statusElement = document.getElementById('status')
      // if (statusElement) setNativeValue(statusElement, '')
      // let notesElement = document.getElementById('notes')
      // if (notesElement) setNativeValue(notesElement, '')
      setProject({ name: '', _id: null })
      setJob({ jobName: '' })
      // inputRef.current.project = null
      setClient({ displayName: '', _id: null })
      // inputRef.current.client = null
      setState({...state, counter: state.counter++, uploadType: '' })
    }
  }, [props.upload])

  const updateRef = (e) => {
    // console.log('UPdateREf: ', e.target.value)
    // console.log(e.target.id)
    inputRef.current[e.target.id] = e.target.value
    handleUpdateParent()
    setState({...state, [e.target.id]: e.target.value })
  }

  const updateDocTypes = (type) => {
  // console.log('Change Doc type;', type)
    let details = docTypes[type]
  // console.log('Details: ', details)
    setDocTypeDetails(docTypes[type])
  }

  const handleDocumentChange = (e) => {
  // console.log('Doc Change', e.target.value)
    updateDocTypes(e.target.value)
    inputRef.current.documentType = e.target.value
    if (e.target.value === 'Spray Log') {
    // console.log('Spray Log')
      setState({...state, uploadType: e.target.value })
      handleUpdateParent()
    } else if (e.target.value === 'ADOT') {
    // console.log('ADOT On-Call')
      setState({...state, uploadType: e.target.value })
      handleUpdateParent()
    } else {
      if (e.target.value === 'Vendor Invoice') inputRef.current.invoiceType = 'Client Project/Job'
      setState({...state, uploadType: e.target.value })
      handleUpdateParent()
    }

  }

  const updateCustomer = async (e, customer, setExistingProject, setThisJob) => {
    // console.log('update customer')
    if (customer && customer._id) {
      setLoading(true)
      setClient(customer)
      inputRef.current.client = customer._id
      // console.log('Get associated projects', customer)
      const response = await axios.get(`${props.url}/api/projects/listProjects?clientId=${customer._id}&getJobsites=true`)
      // Grabbign Projects and Jobs/Wos
      // if (response && response.data && response.data.length) {
      //   setProjects(response.data)
      //   if (setExistingProject && props.upload.project) {
      //     // console.log('Set Project as well', props.upload.project)
      //     let index = response.data.findIndex(item => (item._id === props.upload.project))
      //     // console.log('Index', index)
      //     // console.log('PROJECT OPTIONS: ', response.data)
      //     if (index > -1) {
      //       // console.log('CHOSEN PROJECT', response.data[index])
      //       setProject(response.data[index])
      //       inputRef.current.project = props.upload.project
      //       // setState({...state, project: props.upload.project._id })
      //     } else {
      //       inputRef.current.project = null
      //       setProject({ name: '', _id: null })
      //     }
      //   } else {
      //     inputRef.current.project = null
      //     setProject({ name: '', _id: null })
      //   }
      // }
      setLoading(false)
    // console.log('HMI Jobsites and projects;', response.data)
      if (response.data && response.data.jobs) {
        setJobs(response.data.jobs)
        if (setThisJob) {
          // console.log('Set This JOb:', setThisJob)
          let index = response.data.jobs.findIndex(item => (item._id === setThisJob))
        // console.log('Index: ', index)
          if (index > -1) {
            // console.log('Set this: ', response.data.jobs[index])
            updateJob(null, response.data.jobs[index])
          }
        }
      }
      if (response.data.jobSites && response.data.jobSites.length) {
        setJobsites(response.data.jobSites)
        if (response.data.jobSites.length === 1) {
          setJobsite(response.data.jobSites[0])
          setState({...state, jobSite: response.data.jobSites[0] })
        }
      }
      if (response && response.data && response.data.projects) {
        setProjects(response.data.projects)
        if (setExistingProject && props.upload.project) {
          // console.log('Set Project as well', props.upload.project)
          let index = response.data.projects.findIndex(item => (item._id === props.upload.project))
          // console.log('Index', index)
          // console.log('PROJECT OPTIONS: ', response.data)
          if (index > -1) {
            // console.log('CHOSEN PROJECT', response.data[index])
            setProject(response.data.projects[index])
            inputRef.current.project = props.upload.project
            // setState({...state, project: props.upload.project._id })
          } else {
            inputRef.current.project = null
            setProject({ name: '', _id: null })
          }
        } else {
          inputRef.current.project = null
          setProject({ name: '', _id: null })
        }
        if (props.upload.workorder) {
          // console.log('Find a workorder')

        }
      }
    } else {
      setProject({ name: '', _id: null })
      inputRef.current.project = null
      setClient({ displayName: '', _id: null })
      inputRef.current.client = null
    }
  }

  const updateAutocomplete = (e, newVal) => {
    const itemId = e.target.id.split('-')[0]
  // console.log('UPdate our autocompete', itemId)
  // console.log('Set this:', newVal)
    if (newVal) {
      setState({...state, [itemId]: newVal })
    } else {
      setState({...state, [itemId]: {} })
    }
  }

  const updateJobsite = (e, newSite) => {
    if (newSite && newSite._id) {
      setJobsite(newSite)
    } else {
      setJobsite({})
    }
  }

  const updateSprayTech = (e, newTech) => {
    if (newTech && newTech._id) {
      setSprayTech(newTech)
    } else {
      setSprayTech({})
    }
  }
  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      }
      if (e.target.id === 'purchaseorder') {
        let newArr = []
        let string = e.target.value
        props.purchaseOrders.filter(option => {
          let label = ''
          if (option && option.number) label += `PO #${option.number} for ${currencyFormat(option.amount)}`
          if (option.vendor && option.vendor._id) label += `       Vendor: ${option.vendor.name}`
          if (option.client && option.client._id) label += `       Client: ${option.client.displayName}`
                    
          let str = label.toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(option)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updatePONumber(string, newArr[0])
        }
      }
    }
  }

  const updateProject = (e, project) => {
    if (project && project._id) {
      setProject(project)
      inputRef.current.project = project._id
    } else {
      inputRef.current.project = null
      setProject({ name: '', _id: null })
    }
    handleUpdateParent()
  }

  const updateJob = (e, job) => {
    if (job && job._id) {
      setJob(job)
      inputRef.current.workorder = job._id
    } else {
      inputRef.current.workorder = null
      setJob({ name: '', _id: null })
    }
    handleUpdateParent()
  }

  const handleChosenSupplier = (vendor) => {
    // console.log('Our Vendor: ', vendor)
    if (vendor && vendor._id) {
      inputRef.current.vendor = vendor._id
      setState({...state, vendor: vendor })
    }
    handleUpdateParent()
  }

  const handleUpdateParent = () => {
    // console.log('Update Praent:: 396', inputRef.current)
   props.updateParent(inputRef.current)
  }

  const updateStatus = (e) => {
    // console.log('Update status:', e)
    // console.log(e.target.id)
    // console.log(e.target.value)
    inputRef.current.status = e.target.value
    handleUpdateParent()
    setState({...state, invoiceStatus: e.target.value })
  }

  const closeSupplierModal = () => {
    // console.log('Close Supplier Modal')
  }

  function currencyFormat (num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const updateInvoiceType = (e) => {
    // console.log('Update Invoice type: ', e.target.value)
    inputRef.current.invoiceType = e.target.value
    // handleUpdateParent()
    setState({...state, invoiceType: e.target.value })
  }

  const updatePONumber = (e, po) => {
    // console.log('Update PO: ', po)
    let values = {...state}
    // console.log('Current State', values)
    // , vendor: po.vendor, amount: po.amount }
    if (po && po._id) inputRef.current.po = po._id
    if (po && po.client) {
      values.client = po.client
      inputRef.current.invoiceType = 'Client Project/Job'
      // setState({...state, invoiceType: 'Client Project/Job' })
      values.invoiceType = 'Client Project/Job'
      // updateCustomer(null, po.client, null, po.workorder)
    }
    if (po && po.vendor) {
      inputRef.current.vendor = po.vendor._id
      values.vendor = po.vendor
    }
    if (po && po.amount) {
      inputRef.current.amount = po.amount
      values.amount = po.amount
    }
    if (po && po.invoiceNumber) {
      // console.log('Add INvoice Number from PO: ', po)
      inputRef.current.number = po.invoiceNumber
      // setState({...state, number: po.invoiceNumber})
      values.number = po.invoiceNumber
    }
    if (po && po.description) {
      inputRef.current.notes = po.description
      values.notes = po.description
      // setState({...state, notes: po.description })
    }
    if (po && po.poType) {
      inputRef.current.invoiceType = po.poType
      values.invoiceType = po.poType
    }
    // if (po && po.entity && po.entity._id) {
    //   inputRef.current.entity = po.entity._id
    //   values.entity = po.entity._id
    // } else if (po && po.entity) {
    //   inputRef.current.entity = po.entity
    //   values.entity = po.entity
    // }
      // setState({...state, vendor: vendor })
      // updateJob(null, po.workorder)
      // console.log('Set State:', values)
      // inputRef.current.workorder = po.workorder
      setState({...state, ...values})
      if (po && po.client) updateCustomer(null, po.client, null, po.workorder)
  }

  
const handleUpdateVisibleEntity = (activeEntity) => {
  // console.log('Entity to assigN: ', activeEntity)
  inputRef.current.entity = activeEntity._id
  setEntity(activeEntity)
  setState({...state, entity: activeEntity._id })
}

const handleDeleteUpload = (data) => {
  // console.log('Delete this bitch!!', data)
  
  props.handleDeleteUpload(data)
}
  return (
    <Grid container spacing={1}>
      <DeleteFileButton upload={props.upload} handleDelete={handleDeleteUpload} />
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FormControl fullWidth >
          <InputLabel >Document Type</InputLabel>
          <Select
            id="uploadType"
            value={state.uploadType || ''}
            onChange={handleDocumentChange}
            // onBlur={handleUpdateParent}
            // variant="outlined"
            // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
          >
            <MenuItem value={'Vendor Quote'}>Vendor Quote</MenuItem>
            <MenuItem value={'Vendor Invoice'}>Vendor Invoice</MenuItem>
            <MenuItem value={'Customer Invoice'}>Customer Invoice</MenuItem>
            <MenuItem value={'Purchase Order'}>Purchase Order</MenuItem>
            <MenuItem value={'Photos'}>Photos</MenuItem>
            <MenuItem value={'Plans'}>Plans</MenuItem>
            <MenuItem value={'Estimates'}>Estimates</MenuItem>
            <MenuItem value={'Dump'}>Dump Receipt</MenuItem>
            <MenuItem value={'Spray Log'}>Spray Log</MenuItem>
            <MenuItem value={'ADOT'}>ADOT Timesheet</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
          <FormHelperText>What Type Of File Is This?</FormHelperText>
        </FormControl>
      </Grid>
      {
            state.uploadType === 'Vendor Invoice' ? (
              <React.Fragment>
                <Grid item lg={12} sm={12}>
                  <Autocomplete
                    id={`purchaseorder`}
                    options={props.purchaseOrders || []}
                    getOptionLabel={(option) => {
                      // console.log('PO Options: ', option)
                      let label = ''
                      if (option && option.number) label += `PO #${option.number} for ${currencyFormat(option.amount)}`
                      if (option.vendor && option.vendor._id) label += `       Vendor: ${option.vendor.name}`
                      if (option.client && option.client._id) label += `       Client: ${option.client.displayName}`
                      
                      return label
                    }}
                    fullWidth
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updatePONumber}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    // value = {p}
                    // ref={inputRef.client}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="PO Number" helperText="Do You Have a PO Number?" onKeyDown={handleTabInteraction} />}
                  />
                </Grid>
                <Grid item lg={12} sm={12}>
                  <TextField
                      select
                      id="invoiceType"
                      value={state.invoiceType}
                      style={{ marginTop: 10 }}
                      label="Invoice Type"
                      onChange={updateInvoiceType}
                      fullWidth
                      // onChange={(e) => setState({...state, invoiceType: e.target.value })}
                    >
                      <MenuItem value={'Client Project/Job'}>Client Project/Job</MenuItem>
                      {/* <MenuItem value={'Yard Stock'}>Yard Stock</MenuItem> */}
                      <MenuItem value={'Tools'}>Tools</MenuItem>
                      <MenuItem value={'Inventory'}>Inventory</MenuItem>
                      <MenuItem value={'Marketing'}>Marketing</MenuItem>
                      <MenuItem value={'Repair'}>Repair</MenuItem>
                      <MenuItem value={'Office'}>Office</MenuItem>
                      <MenuItem value={'Other'}>Other</MenuItem>
                    </TextField>
                </Grid>
            </React.Fragment>
            ) : null
      }
      {
        (state.uploadType && (state.uploadType !== 'Spray Log' && state.uploadType !== 'ADOT' && state.uploadType !== 'Dump')) ? (
          <>
          <Grid item lg={12} sm={12}>
            <SupplierComponent label="Vendor Name" vendors={props.vendors} vendor={state.vendor} placeholder="Vendor Name" helperText="Which Vendor Is This From/For?" single={true} closeModal={closeSupplierModal} updateParent={handleChosenSupplier} />
          </Grid>
          {/* {
            docTypeDetails['vendor'] ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`vendorname`}
                  options={props.vendors || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.name || ''
                  }}
                  fullWidth
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateCustomer}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {client}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Vendor" helperText="Who Is The Vendor?" onKeyDown={handleTabInteraction} />}
                />
              </Grid>
            ) : null
          } */}
          {
            (state.invoiceType === 'Client Project/Job' || state.uploadType === 'Photos') ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`customername`}
                  options={props.clients || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  fullWidth
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateCustomer}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {client}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} />}
                />
              </Grid>
            ) : null
          }
          
      {
        loading ? (
          <CircularProgress color="primary" size={ 24 } />
        ) : (client && projects && projects.length) ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              id={`projectname`}
              options={projects || []}
              getOptionLabel={(option) => {
                // console.log('Options: ', option)
                return `${option.name}` || ''
              }}
              fullWidth
              // style={{ width: 200 }}
              // style={{marginBottom: '20px'}}
              onChange={updateProject}
              disabled={loading}
              // onBlur={handleUpdateParent}
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {project}
              // ref={inputRef.client}
              // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
              renderInput={(params) => <TextField {...params} label="Project" helperText="Which Project?" onKeyDown={handleTabInteraction} />}
            />
          </Grid>
          ) : null
        }
        {
          (client && jobs && jobs.length) ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              id={`job`}
              options={jobs || []}
              getOptionLabel={(option) => {
                // console.log('Options: ', option)
                return `${option.jobName ? option.jobName : ''} ${option.number ? ` (${option.number})` : ''} ${option.scheduledDate ? ` - ${moment(option.scheduledDate).format('MM/DD/YY [at] hh:mma')}` : ''}` || ''
              }}
              fullWidth
              // style={{ width: 200 }}
              // style={{marginBottom: '20px'}}
              onChange={updateJob}
              disabled={loading}
              // onBlur={handleUpdateParent}
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {job}
              // ref={inputRef.client}
              // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
              renderInput={(params) => <TextField {...params} label="Workorder" helperText="Which Workorder?" onKeyDown={handleTabInteraction} />}
            />
          </Grid>
          ) : null
        }
      <Grid item lg={12} md={12} sm={12} xs={12}>
      {
          docTypeDetails['number'] ? (
          <TextField
                  id="number"
                  label={docTypeDetails['number']['name']}
                  // style={{ margin: 8 }}
                  // value={inputRef.projectName}
                  onChange={updateRef}
                  // onChange={e=> setState({...state, projectName: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder={docTypeDetails['number']['placeholder']}
                  helperText={docTypeDetails['number']['helperText']}
                  fullWidth
                  // onBlur={handleUpdateParent}
                  value={state.number}
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  // variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
          ) : null
        }
              </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {
          docTypeDetails['amount'] ? (
            <TextField
                  id="amount"
                  label={docTypeDetails['amount']['name']}
                  // style={{ margin: 8 }}
                  // value={inputRef.projectName}
                  onChange={updateRef}
                  // onChange={e=> setState({...state, projectName: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder={docTypeDetails['amount']['placeholder']}
                  helperText={docTypeDetails['amount']['helperText']}
                  fullWidth
                  value={state.amount}
                  // onBlur={handleUpdateParent}
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  // variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
          ) : null
        }
      </Grid>
      {
        state.uploadType === 'Vendor Invoice' ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl fullWidth >
              <InputLabel >Invoice Status</InputLabel>
              <Select
                id="status"
                // onBlur={handleUpdateParent}
                value={state.invoiceStatus}
                // onChange={e => setState({...state, invoiceStatus: e.target.value })}
                onChange={updateStatus}
                // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
              >
                <MenuItem value={'Due'}>Due</MenuItem>
                <MenuItem value={'Paid'}>Paid</MenuItem>
              </Select>
              <FormHelperText>Has The Invoice Been Paid?</FormHelperText>
            </FormControl>
          </Grid>
        ) : null
      }
      {
        props.upload.scanType === 'ADOT' ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>ADOT</Grid>
        ) : null
      }
      <Grid item lg={12} md={12} sm={12} xs={12}>

        <TextField
              id="notes"
              label={'Notes'}
              // style={{ margin: 8 }}
              // value={inputRef.projectName}
              onChange={updateRef}
              // onBlur={handleUpdateParent}
              // onChange={e=> setState({...state, projectName: e.target.value})}
              // ref={inputRef['projectName']}
              placeholder={'Notes About This File'}
              helperText={'What Is This File?'}
              fullWidth
              value={state.notes}
              // value={inputRef.current['projectName']}
              margin="normal"
              // variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />

      </Grid>
      </>
        ) : null
      }
      {
        (state.uploadType === 'Spray Log') ? (
          <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`customername`}
                  options={props.clients || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  fullWidth
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateCustomer}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {client}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} />}
                />
                
              </Grid>
              {
                  (client && jobSites.length) ? (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Autocomplete
                        id={`jobSite`}
                        options={jobSites || []}
                        getOptionLabel={(option) => {
                          // console.log('Options: ', option)
                          if (option && option.name) {
                            return `${option.name} - ${option.address} ${option.city}`
                          } else {
                            return `Please Choose a Jobsite`
                          }
                        }}
                        fullWidth
                        // style={{ width: 200 }}
                        // style={{marginBottom: '20px'}}
                        onChange={updateAutocomplete}
                        disabled={loading}
                        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                        value = {state.jobSite}
                        // ref={inputRef.client}
                        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                        renderInput={(params) => <TextField {...params} label="Jobsite" helperText="Which Jobsite?" onKeyDown={handleTabInteraction} />}
                      />
                      
                    </Grid>
                        ) : null
                }
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`sprayTech`}
                  options={props.employees || []}
                  filterOptions={(options) =>
                    options.filter(({ sprayTechLicense }) => {
                    // console.log('Filter spray tech', sprayTechLicense)
                      if (sprayTechLicense) return true
                    })
                  }
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    if (option && option.firstName) {
                    // console.log("spray tech opto", option)
                      return `${option.firstName} ${option.lastName} (${option.sprayTechLicense} - EXP: ${moment(option.sprayLicenseExpiration).format('MM/DD/YY')})`
                    } else {
                      return `Please Choose a Spray Tech`
                    }
                  }}
                  fullWidth
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateAutocomplete}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.sprayTech}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Spray Tech" helperText="Who Is The Spray Tech?" onKeyDown={handleTabInteraction} />}
                />  
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`chemUsed`}
                  options={chemicals || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    if (option && option.name) {
                      return `${option.name}`
                    } else {
                      return `Please Choose a Chemical`
                    }
                  }}
                  fullWidth
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateAutocomplete}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.chemUsed}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Chemical Used" helperText="Which Chemical?" onKeyDown={handleTabInteraction} />}
                />

                 
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  variant="standard"
                  label="Amount Used"
                />  
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Autocomplete
                    id={`unit`}
                    options={unitsOfMeasurement || []}
                    getOptionLabel={(option) => {
                      // console.log('Options: ', option)
                      if (option && option.name) {
                        return `${option.name}`
                      } else {
                        return `Please Choose a Unit of Measurement`
                      }
                    }}
                    fullWidth
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateAutocomplete}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {state.unit}
                    // ref={inputRef.client}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Unit of Measurement" helperText="What Unit of Measurement?" onKeyDown={handleTabInteraction} />}
                  />
              </Grid>
            </>
        ) : null
      }
      {
        (state.uploadType === 'ADOT') ? (
          <ADOTOnCall state={state} updateRef={updateRef} />
        ) : null
      }
      {
        (state.uploadType === 'Dump') ? (
          <DumpReceipts state={state} updateRef={updateRef} />
        ) : null
      }
    </Grid>
  )
}

const ADOTOnCall = ({ state, updateRef }) => {
// console.log('Adot on call props', state)
  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="date"
          label="Work Date"
          type="date"
          // style={{ margin: 8 }}
          // value={inputRef.projectName}
          onChange={updateRef}
          // onChange={e=> setState({...state, projectName: e.target.value})}
          // ref={inputRef['projectName']}
          placeholder="Date"
          helperText="When Was The Timesheet Dated?"
          fullWidth
          // onBlur={handleUpdateParent}
          value={state.date}
          // value={inputRef.current['projectName']}
          margin="normal"
          // variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="crewNumber"
          label="Crew Number"
          // style={{ margin: 8 }}
          // value={inputRef.projectName}
          onChange={updateRef}
          // onChange={e=> setState({...state, projectName: e.target.value})}
          // ref={inputRef['projectName']}
          placeholder="Crew Number"
          helperText="Which Crew Was On-Site?"
          fullWidth
          // onBlur={handleUpdateParent}
          value={state.crewNumber}
          // value={inputRef.current['projectName']}
          margin="normal"
          // variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        Labor Hours
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        Supervision Hours
      </Grid>
    </>
  )
}

const DumpReceipts = ({ state, updateRef }) => {
// console.log('Dump reciepts', state)
  // const state = props.state
  // const updateRef = props.updateRef
  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="date"
          label="Dump Date"
          type="date"
          // style={{ margin: 8 }}
          // value={inputRef.projectName}
          onChange={updateRef}
          // onChange={e=> setState({...state, projectName: e.target.value})}
          // ref={inputRef['projectName']}
          placeholder="Date"
          helperText="When Was The Receipt Dated?"
          fullWidth
          // onBlur={handleUpdateParent}
          value={state.date}
          // value={inputRef.current['projectName']}
          margin="normal"
          // variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="crewNumber"
          label="Crew Number"
          // style={{ margin: 8 }}
          // value={inputRef.projectName}
          onChange={updateRef}
          // onChange={e=> setState({...state, projectName: e.target.value})}
          // ref={inputRef['projectName']}
          placeholder="Crew Number"
          helperText="Which Crew Was On-Site?"
          fullWidth
          // onBlur={handleUpdateParent}
          value={state.crewNumber}
          // value={inputRef.current['projectName']}
          margin="normal"
          // variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="amount"
          label="Dump Fee"
          // style={{ margin: 8 }}
          // value={inputRef.projectName}
          onChange={updateRef}
          // onChange={e=> setState({...state, projectName: e.target.value})}
          // ref={inputRef['projectName']}
          placeholder="Amount"
          helperText="How Much Were We Charged?"
          fullWidth
          // onBlur={handleUpdateParent}
          value={state.amount}
          // value={inputRef.current['projectName']}
          margin="normal"
          // variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    employees: state.employees
  }
}

export default connect(mapStateToProps)(UploadDetailsInputArea)