import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
// import {EditorState} from "draft-js";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './milestoneEditor.css'

const MilestoneEditor = (props) => {
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [content, setContentState] = React.useState({})

  const onEditorStateChange = (editorStateChange) => {
    // console.log(editorStateChange)
    setEditorState(editorStateChange)
  }

  const handleBlur = (data) => {
    // console.log(data)
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // console.log(html)
    setContentState(html)
    props.updateContent(html)
  }

  const onContentStateChange = (contentState) => {
    // console.log(contentState)
    setContentState(contentState)
  }

  return (
      <Editor
        // initialContentState={content}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        helperText={props.helperText}
        // onContentStateChange={onContentStateChange}
        onBlur={handleBlur}
        
      />

  )
}
export default MilestoneEditor
