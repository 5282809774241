// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".login_login__2TN2v {\n  margin: 20px;\n  text-align: left; }\n\n.login_title__1cwQp {\n  padding-left: 30px;\n  padding-top: 20px; }\n\n.login_content__2Mm3o {\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0; }\n\n.login_logo__1v7pv {\n  margin-left: 0;\n  margin-right: 0; }\n\n.login_space__2YSma {\n  margin-right: 5px; }\n\n.login_google__2ZB-O {\n  margin-top: 20px !important; }\n\n.login_github__4JScH {\n  margin-top: 10px !important; }\n", ""]);
// Exports
exports.locals = {
	"login": "login_login__2TN2v",
	"title": "login_title__1cwQp",
	"content": "login_content__2Mm3o",
	"logo": "login_logo__1v7pv",
	"space": "login_space__2YSma",
	"google": "login_google__2ZB-O",
	"github": "login_github__4JScH"
};
module.exports = exports;
