import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { setCrew } from '../../actions/connectionActions'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress }  from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EventIcon from '@material-ui/icons/Event';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid'
import InvoiceOptionsModal from '../Customer/InvoiceOptionsModal'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: 20,
    color: '#777'
    // color: "purple",
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "green"
    // },
    // "&:hover .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "red"
    // },
    // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "purple"
    // }
  }
}));

// import { withStyles } from "@material-ui/core/styles";

// const styles = {
//   root: {
//     background: "black"
//   },
//   input: {
//     color: "#999",
//     fontSize: '1.2em'
//   }
// };

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const RightSideButtons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const SideButtons = styled(Button)`
  margin: 5px;
  padding: 5px;
`;

const WorkorderDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: 'space-between';
  width: 60%;
  height: 100%;
`;

const BigText = styled(Box)`
  font-size: 1.3em;
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: wrap;
  color: #bbb;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 8px;
`;

const Label = styled.span`
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  color: #999;
`;

const Details = styled.span`
  font-size: 1.4em;
  color: #777;
  display: flex;
`;

const NotesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const OfficeNotes = styled(Box)`
  display: flex;
  flex-grow: 1;
  padding: 5px;
  margin: 5px;
`;

const TechNotes = styled(Box)`
  display: flex;
  flex-grow: 1;
  padding: 5px;
  margin: 5px;
`;

const ManagerNotes = styled(Box)`
  display: flex;

  flex-grow: 1;
  padding: 5px;
  margin: 5px;
`;

const ImageArea = styled.div`
  text-align: center;
  margin: 10px;
  padding: 15px;
  border-radius: 15px;
  border: 2px solid grey;
`;

const ImageContainer = styled(Box)`

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const PhotoBox = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 10px;
  align-self: stretch;
  max-height: 500px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #777;
`;

const PhotoItem = styled.img`
  max-width: 90%;
  max-height: 90%;
  border: 2px solid #ccc;
  border-radius: 15px;

`;

const ViewWorkorder = (props) => {
  console.log('ViewWorkorder Component', props)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    serviceDescription: '',
    price: '',
    crews: [],
    services: [],
    workorder: {},
    service: {},
    showInvoiceModal: false,
    pdf: null,
    completedDate: null
  })
  const [workorder, setWorkorder] = React.useState({})
  const [photos, setPhotos] = React.useState([])
  
  const fetchUploads = async (wo) => {
    if (wo && wo._id) {
      setLoading(true)
      const response = await axios.get(`${props.url}/api/jobs/getUploadedPhotos?id=${wo._id}`)
    // console.log(response)
      setLoading(false)
      if (response.data && response.data.length) {
      // console.log('Got our photos')
        setPhotos(response.data)
      }
    }
  }

  React.useEffect(() => {
    setState({...state, scheduledDate: props.workorder.scheduledDate, managerNotes: props.workorder.managerNotes, service: { name: props.workorder.serviceName }, crew: props.crew, crews: props.crews, services: props.services, workorder: props.workorder, price: props.workorder.price, serviceDescription: props.workorder.serviceDescription})
    fetchUploads(props.workorder)
    setWorkorder(props.workorder)
  }, [props.workorder])

  const handleCloseModal = () => {
    setPhotos([])
    props.updateParent('closeModal')
  }

  const handleUpdateWorkorder = (wo) => {
  // console.log('Edit WO: ', wo)
  }

  const handleChangeDescription = (e) => {
    setState({...state, serviceDescription: e.target.value})
  }

  const handleSave = async (keepModalOpen) => {
  // console.log('Save our changes')
    let wo = props.workorder
    wo.price = state.price
    wo.serviceDescription = state.serviceDescription
    wo.serviceCode = state.service.serviceCode
    wo.serviceName = state.service.name
    wo.crew = state.crew._id
    wo.crewName = state.crew.name
    wo.foreman = state.crew.foremanName
    wo.managerNotes = state.managerNotes
    wo.completedDate = state.completedDate
    wo.scheduledDate = state.scheduledDate
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/saveWOChangesFromAssignment`,
      data: wo,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to update: ', response)
      if (keepModalOpen) {
        // Dont close it
      } else {
        props.updateParent('closeModal')
      }
    })
  }

  const updateService = (event, newService) => {
    // console.log('New service', newService)
    setState({...state, service: newService})
  }
  
  const updateCrew = (event, newCrew) => {
    // console.log('Update crew: ', crew)
    // console.log('newCrew Crew: ', newCrew)
    setState({...state, crew: newCrew })
    // if (newCrew && newCrew.agValue) {
    //   let newWO = state.workorder
    //   newWO.crewName = newCrew['name']
    //   newWO.crewNumber = newCrew['agValue']
    //   newWO.crew = newCrew
    //   setState({...state, workorder: newWO, crew: newCrew})
    // } else {
    //   // setState({...state, crew: {name: ' '}})
    // }
  }

  const crewNameAndForeman = (crew) => {
    // console.log('Crew Name: ', crew)
    let crewName = crew.name
    if (crew) {
      if (crew && crew.foremanName) {
        crewName += ' (' + crew.foremanName + ')'
      }
      if (crew && crew.division) {
        crewName += ' - ' + crew.division
      }
      return crewName
    } else {
      return ''
    }
  }

  const handleCompleteWithInvoice = async () => {
    // Invoice It
  // console.log('Complete AND Invoice')
    handleSave(true)
    let wo = props.workorder
    wo.price = state.price
    wo.serviceDescription = state.serviceDescription
    wo.serviceCode = state.service.serviceCode
    wo.serviceName = state.service.name
    wo.crew = state.crew._id
    wo.crewName = state.crew.name
    wo.foreman = state.crew.foremanName
    wo.managerNotes = state.managerNotes
    setWorkorder(wo)
    let obj = {
      workorder: wo,
      job: wo.job
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/completeWorkorder`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to update: ', response)
      if (response && response.data) {
      // console.log('Display oru PDF!')
        setState({...state, pdf: response.data, workorder: wo, showInvoiceModal: true})
      } else {
      // console.log('WTF no pdf??')
        setState({...state, workorder: wo, showInvoiceModal: true}) 
      }
      props.notification({
        type: 'success',
        title: 'Workorder Completed Without Invoicing!',
        message: 'Great job, even though we didn\'t make any money we cleaned up some data. ;)'
      })
      props.updateParent('closeModal')
    }).catch(err => {
      props.notification({
        type: 'warning',
        title: 'Error...Uh Oh!',
        message: 'We have an error ' + JSON.stringify(err)
      })
    })
  }

  const completeWithoutInvoice = async () => {
  // console.log('Complete Without Invoice')
    let wo = props.workorder
    wo.price = state.price
    wo.serviceDescription = state.serviceDescription
    wo.serviceCode = state.service.serviceCode
    wo.serviceName = state.service.name
    wo.crew = state?.crew?._id
    wo.crewName = state?.crew?.name
    wo.foreman = state?.crew?.foremanName
    wo.managerNotes = state.managerNotes
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/completeWithoutInvoicing`,
      data: wo,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to update: ', response)
      props.notification({
        type: 'success',
        title: 'Workorder Completed Without Invoicing!',
        message: 'Great job, even though we didn\'t make any money we cleaned up some data. ;)'
      })
      props.updateParent('closeModal')
    }).catch(err => {
      props.notification({
        type: 'warning',
        title: 'Error...Uh Oh!',
        message: 'We have an error ' + JSON.stringify(err)
      })
    })
  }

  const handleUpdateMgrNotes = (e) => {
    setState({...state, managerNotes: e.target.value})
  }

  const updateInvoiceStatus = (d) => {
  // console.log('Update Invoice status', d)
  }

  const updateFromInvoiceModal = (d) => {
  // console.log('Job Details Response from invoice modal', d)
    if (d === 'Close') setState({...state, showInvoiceModal: false})
  }

  const openWO = () => {
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/openWO`,
      data: {
        _id: props.workorder._id
      },
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to update: ', response)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Workorder Re-Opened!',
          message: `Tell those guys to get this job done, man!`
        })
        // props.updateParent('closeModal')
        let wo = props.workorder
        wo.workComplete = false
        setWorkorder(wo)
        setState({...state, workorder: wo }) 
      }
      if (response.data && response.data.error) {
        props.notification({
          type: 'warning',
          title: 'Error Re-Opening Workorder...Uh Oh!',
          message: 'We have an error ' + JSON.stringify(response.data.message)
        })
      }

    }).catch(err => {
      props.notification({
        type: 'warning',
        title: 'Error...Uh Oh!',
        message: 'We have an error ' + JSON.stringify(err)
      })
    })
  }
  return (
    <div>
       <InvoiceOptionsModal workorder={workorder} pdf={state.pdf} updateInvoiceStatus={updateInvoiceStatus} jobDetailsResponse={updateFromInvoiceModal} open={state.showInvoiceModal} />
          <Dialog
          // fullScreen={false}
          // maxWidth={lg}
          // width='md'
          fullWidth={true}
          maxWidth = {'lg'}
          // maxwidth='md'
          // style={{width: '90hw'}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">
          <Container>
            <Title>
              {props.workorder.jobName} - {moment(props.workorder.scheduledDate).format('ddd MM/DD/YYYY')}
              <RightSideButtons>
                { workorder.workComplete ? (
                  <SideButtons
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<LockOpenIcon />}
                    onClick={openWO}
                  >Open Workorder</SideButtons>
                ) : null }
                  <SideButtons
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<EventIcon />}
                    onClick={completeWithoutInvoice}
                  >Complete (No Invoice)</SideButtons>

                  <SideButtons
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={handleCompleteWithInvoice}
                    startIcon={<AssignmentIndIcon />}
                  >Complete & Invoice</SideButtons>

              </RightSideButtons>
            </Title>
           
          </Container>

           </DialogTitle>
          <DialogContent>
                <Container>
                  <WorkorderDetails>
                    <BigText>
                      <Grid container>
                        <Grid item lg={4} md={12} sm={12}><Label>Client Name: </Label></Grid>
                        <Grid item lg={8} md={12} sm={12}>
                            <Link 
                              style={{pointer: 'cursor'}}
                              target="_blank"
                              to={{
                                pathname: '/clients/' + props.workorder.client
                              }}>   <Details>{props.workorder.customerName} </Details>
                            </Link> 
                        
                        </Grid>
                        <Grid item lg={4} md={12} sm={12}><Label>Job Name: </Label></Grid>
                        <Grid item lg={8} md={12} sm={12}><Details>{props.workorder.jobName} </Details></Grid>
                        <Grid item lg={4} md={12} sm={12}><Label>Workorder: </Label></Grid>
                        <Grid item lg={8} md={12} sm={12}><Details> #{props.workorder.number}</Details></Grid>
                        <Grid item lg={4} md={12} sm={12}><Label>Job Status: </Label></Grid>
                        <Grid item lg={8} md={12} sm={12}><Details>{props.workorder.status}</Details></Grid>

                        <Grid item lg={4} md={12} sm={12}> <Label>Job Site Address:</Label></Grid>
                        <Grid item lg={8} md={12} sm={12}><Details> {props.workorder.jobSiteAddress}</Details></Grid>
                        <Grid item lg={4} md={12} sm={12}> <Label>Service Type: </Label></Grid>
                       
                        <Grid item lg={8} md={12} sm={12}>
                          <Autocomplete
                            id={`svc-${state.serviceIndex}`}
                            options={props.services}
                            getOptionLabel={(option) => option.name}
                            // style={{ width: 200 }}
                            classes={classes}
                            onChange={updateService}
                            // onChange={ (e) => updateCrew(workorder, e.target.id, e.target.value, serviceIndex) }
                            value = {state.service}
                            // onChange={ e => setState({...state, crew: e.target.value, requiresUpdate: true }) }
                            renderInput={(params) => <TextField {...params} 
                            
                            />}
                          />
                        </Grid>
                        <Grid item lg={4} md={12} sm={12}> <Label>Assigned Crew: </Label></Grid>
                       
                        <Grid item lg={8} md={12} sm={12}>
                          <Autocomplete
                            id={`crew-${state.serviceIndex}`}
                            options={state.crews}
                            classes={classes}
                            getOptionLabel={(option) => crewNameAndForeman(option)}
                            // style={{ width: 200 }}
                            InputProps={{ className: classes.input }}
                            onChange={updateCrew}
                            // onChange={ (e) => updateCrew(workorder, e.target.id, e.target.value, serviceIndex) }
                            value = {state.crew}
                            // onChange={ e => setState({...state, crew: e.target.value, requiresUpdate: true }) }
                            renderInput={(params) => <TextField {...params} />}
                          />
                          
                        </Grid>
                        <Grid item lg={4} md={12} sm={12}> <Label>Price: $</Label></Grid>
                        <Grid item lg={8} md={12} sm={12}>
                          <Details>
                            <TextField
                              value={state.price}
                              inputProps={{style: {fontSize: 25, color: '#777'}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 20}}} // font size of input label
                              onChange = { (e) => setState({...state, price: e.target.value })}
                            />
                          </Details>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12}> <Label>Completed Date: </Label></Grid>
                        <Grid item lg={8} md={12} sm={12}>
                          <Details>
                            {/* <TextField
                              type="date"
                              value={moment(state.completedDate).format('mm/dd/yyyy')}
                              inputProps={{style: {fontSize: 25, color: '#777'}}} // font size of input text
                              InputLabelProps={{style: {fontSize: 20}}} // font size of input label
                              onChange = { (e) => setState({...state, completedDate: e.target.value })}
                            /> */}
                              <TextField
                                id="date"
                                // label="Invoice Date"
                                type="date"
                                fullWidth
                                value={moment(state.scheduledDate).format('YYYY-MM-DD') } // "2017-05-24"}
                                className={classes.textField}
                                // variant="outlined"
                                onChange={ e => setState({...state, scheduledDate: e.target.value}) }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                          </Details>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            label="Supply Request"
                          >Supply Request</Button>
                        </Grid>
                      </Grid>
              
                    </BigText>
                    <TextField
                           multiline
                           rows={4}
                           style={{width: '80%', marginTop: '40px'}}
                           variant="outlined"
                          label="Customer Description"
                          value={state.serviceDescription}
                          onChange={handleChangeDescription}
                          />
                        
                  </WorkorderDetails>
                  <NotesContainer>
                    <OfficeNotes>
                      
                      <TextField
                           multiline
                           rows={4}
                           style={{width: '100%'}}
                           variant="outlined"
                          label="Office Notes"
                          value={props.workorder.serviceNotes}
                          disabled
                          />
                    </OfficeNotes>
                    <TechNotes>
                        <TextField
                           multiline
                           rows={4}
                           value={props.workorder.fieldNotes}
                           style={{width: '100%'}}
                           variant="outlined"
                          label="Field Notes"
                          disabled
                          />
                      </TechNotes>
                    <ManagerNotes >
                        <TextField
                           multiline
                           rows={4}
                           value={state.managerNotes}
                           onChange={handleUpdateMgrNotes}
                           style={{width: '100%'}}
                           variant="outlined"
                          label="Manager Notes"
                          />
                    </ManagerNotes>
                    
                  </NotesContainer>
                  
                </Container>
                
                    {
                      photos.length ? (
                      <ImageArea>
                         <div style={{color: '#555', fontSize: '18px', fontWeight: '800', textAlign: 'center'}}>Photos From The Field</div>
                        <ImageContainer>
                        { photos.map(photo => {
                        return (
                          <PhotoBox>
                              {photo.type}<br/>
                              <PhotoItem key={photo.url} src={photo.url} />
                          </PhotoBox>
                        )
                      })}
                       </ImageContainer>
                       </ImageArea>
                      ) : ''

                    }
               
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </DialogActions>
            </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    client: state.client,
    services: state.services
  }
}

export default connect(mapStateToProps)(ViewWorkorder)