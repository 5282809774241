import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import {
  Switch,
  FormControlLabel
} from '@material-ui/core'
import MakePaymentModal from './MakePaymentModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InvoiceOptionsModal from './InvoiceOptionsModal'
import Paymentlist from './Paymentlist'
import EditInvoiceDateModal from './EditInvoiceDateModal'
import EventNoteIcon from '@material-ui/icons/EventNote';
import ComplexInvoiceDisplayDescription from '../Invoices/ComplexInvoiceDisplay'
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons'
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }
const QuickBooksInvoiceDetails = (props) => {
  // console.log('QB Props: ', props)
  if (props.invoice && props.invoice.qbInvNumber) {
    return <div >QB Invoice Number: {props.invoice.qbInvNumber}</div>

  } else {
    return <TextField
    label="Quickbooks Invoice Number"
    // helperText="Check Number/Direct Deposit ID"
    type="Text"
    fullWidth
    ref={props.qbInvNumber}
    // disabled={state.isInvoiced || state.isComplete}
    // value= {state.qbInvNumber}
    // onChange={ e => setQBNumber(e.target.value) }
    // defaultValue="Default Value"
    onBlur={e => props.setQBNumber(e.target.value) }
  />
  }
}

const InvoiceDetail = (props) => {
  console.log('Invoice Detail: ', props)
  const classes = useStyles();
  let servicesSelected = []
  const [loading, setLoading] = React.useState(false)
  const [invoice, setinvoice] = React.useState({
    invoice: '',
    SiteName: '',
    address: ''
  })
  const [open, setOpen] = React.useState(false)
  const [modifyInvoiceDateOpen, setModifyInvoiceDateOpen] = React.useState(false)
  // const [invoiceDetails, setinvoiceDetails] = React.useState([])
  const [crewList, setCrewList] = React.useState(props.crewlist)
  const [payments, setPayments] = React.useState([])
  const [serviceList, setServiceList] = React.useState(props.serviceList)
  const [state, setState] = React.useState({
    notes: ' ',
    description: ' ',
    crew: ' ',
    date: moment(Date.now()).format('MM/DD/YYYY'),
    time: ' ',
    price: ' ',
    invoice: {},
    showInvoiceModal: false,
    pdf: '',
    showPaymentModal: false,
    payments: [],
    showQbInvInput: false,
    displayAllLineItems: false
  })

  const qbInvNumber = React.useRef(null)

  React.useEffect(() => {
    // console.log('Use Effect invoice Details')
    // setinvoice(props.invoice)
    setOpen(props.open)
    // setServiceList(props.serviceList)
    // setCrewList(props.crewList)
    // getPaymentsRelatedToInvoice(props.invoice)
    // servicesSelected = []
  }, [props.open])

  React.useEffect(() => {
    console.log('Use Effect invoice Details', props)
    if (props?.invoice?._id) {
      console.log('210...')
      const newState = {
        displayAllLineItems: props.invoice?.displayAllLineItems || false,
        showQbInvInput: !props.invoice.qbInvNumber,
        qbInvNumber: props.invoice.qbInvNumber || null,
        pdf: props.pdf || '',
        payments: []
      };
      setinvoice(props.invoice);
      setServiceList(props.serviceList || []);
      setCrewList(props.crewList || []);
      getPaymentsRelatedToInvoice(props.invoice);

      setState((prevState) => ({ ...prevState, ...newState }));
    }

    servicesSelected = []
  }, [props.invoice])


  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 const getPaymentsRelatedToInvoice = (invoice) => {
  // console.log('Get related payments; ', invoice)
  if (invoice && invoice._id) {
    
    axios({
    method: 'get',
    url:  `${props.url}/api/payment/getInvoicePayments?invoice=${invoice._id}`
    // data: data,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {
  
    //   }
    // },
    }).then(response => {
      // console.log('Axios response: ', response)
      if (response && response.data) {
        setPayments(response.data)
        setState(prevState => ({...prevState, payments: response.data}))
      }
    })
  }
}

const handleClose = () => {
  // console.log('Closing this out - clear the crew and notes')
  // setPayments([])
  // setState({...state, payments: []})
  // setinvoice({
  //   invoice: '',
  //   SiteName: '',
  //   address: ''
  // })
  // setinvoiceDetails([])
  // setOpen(false)
  // // console.log(state)
  props.invoiceDetailsResponse('Close')
}

// const handleConfirm = (data, callback) => {
//   // console.log('CONFIRMED!!', data)
//   if (data && data.updateType && data.updateType === 'complete') {
//     // console.log('Complete this invoice!!')
//     axios({
//       method: 'post',
//       url:  `${props.url}/api/customer/markServiceAsComplete`,
//       data: data,
//       // validateStatus: (status) => {
//       //   // console.log('Validate status: ', status)
//       //   if (status && status === 500) {
  
//       //   }
//       // },
//     }).then(response => {
//       // console.log('Axios response: ', response)
//       props.notification({
//         type: 'success',
//         title: 'Service Completed!',
//         message: 'We Have Updated Arbor Gold!   Hopefully. :)'
//       })
//       if (data && data.service && data.service.servicetype && data.service.servicetype.Value === '423') {
//         // console.log('This is MAINTENANCE NO INVOICE')
//       } else {
//         // console.log('Open window about INVOICING')
//         if (response && response.data) {
//           // console.log('Display oru PDF!')
//           setState({...state, pdf: response.data, showInvoiceModal: true})
//         } else {
//           // console.log('WTF no pdf??')
//           setState({...state, showInvoiceModal: true}) 
//         }
//       }
//       // props.invoiceDetailsResponse(response)
//       // setOpen(false)
//     })
//   } else {
//     // console.log('Save this invoice')
//     axios({
//       method: 'post',
//       url:  `${props.url}/api/customer/scheduleinvoiceNow`,
//       data: data,
//       // validateStatus: (status) => {
//       //   // console.log('Validate status: ', status)
//       //   if (status && status === 500) {
  
//       //   }
//       // },
//     }).then(response => {
//       // console.log('Axios response: ', response)
//       props.notification({
//         type: 'success',
//         title: 'invoice Saved!',
//         message: 'We Have Updated Arbor Gold!   Hopefully. :)'
//       })
//       // IF this was new we need to add our WorkNumber
//       if (data.service && data.service.number) {
//         // console.log('Existin invoice - dont need to do anything')
//         return callback(response)
//       } else {
//         // console.log('New invoice -- add the number we got back')
//         if (response && response.data && response.data.scheduledinvoice && response.data.scheduledinvoice.ReturnObject) {
//           let num =response.data.scheduledinvoice.ReturnObject[0]
//           // console.log('Our new WORK NUM', num)
//           return callback(num)
//         }
//       }
//       // props.invoiceDetailsResponse(response)
//       // setOpen(false)
//     })
//   }
// }

// const updateOurinvoice = (data, updateType, callback) => {
//   // console.log('Update the invoice from invoice Details; ', data)
//   // console.log(servicesSelected)
//   // console.log('Type of Update:', updateType)
//   if (updateType === 'select') {
//     servicesSelected.push(data)
//   } else if (updateType === 'unselect') {
//     // Remove item from selected list
//     let index = servicesSelected.findIndex(item =>  (parseInt(item['number']) === parseInt(data['number'])))
//     // console.log('Index of item to remvove from serices selected: ', index)
//     servicesSelected.splice(index, 1)
//   } else {
//       // Find this invoice and update it...
//     // console.log(state)
//     // console.log(invoice)
//     let updateObject = {
//       invoice,
//       service: data,
//       updateType: updateType
//     }
//     handleConfirm(updateObject, (num) => {
//       // console.log('Got a new workorder num', num)
//       // console.log(state)
//       // console.log(invoice)
//       return callback(num)
//     })
//   }

// }
const updateFromInvoiceModal = (d) => {
  // console.log('Close invoice modal', d)
  setState({...state, showInvoiceModal: false})
}

// const handleInvoiceSelected = () => {
//   if (servicesSelected.length > 0) {
//     setLoading(true)
//     // console.log('invoice these: ', servicesSelected)
//     let updateObject = {
//       invoice,
//       service: servicesSelected,
//       updateType: 'invoiceGroup'
//     }
//     axios({
//       method: 'post',
//       url:  `${props.url}/api/customer/groupInvoice`,
//       data: updateObject,
//       // validateStatus: (status) => {
//       //   // console.log('Validate status: ', status)
//       //   if (status && status === 500) {

//       //   }
//       // },
//     }).then(response => {
//       setLoading(false)
//       // console.log('Axios response to bulk invoice: ', response)
//       if (response && response.data) {
//         // console.log('Display oru PDF!')
//         setState({...state, pdf: response.data, showInvoiceModal: true})
//       } else {
//         // console.log('WTF no pdf??')
//         setState({...state, showInvoiceModal: true}) 
//       }
//       props.notification({
//         type: 'success',
//         title: 'Invoice Generated',
//         message: 'We Have Updated Arbor Gold!   Hopefully. :)'
//       })
//     })
//   } else {
//     // console.log('No services selected')
//     props.notification({
//       type: 'warning',
//       title: 'Oopsie',
//       message: 'You Must Select Services To Invoice First'
//     })
//   }
// }
const handleViewInvoice = () => {
  // console.log('Load invoice now', props)
  setLoading(true)
  let data = {
    client: props.client,
    invoice: props.invoice
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/invoice/viewInvoice`,
    data: data
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setLoading(false)
    // console.log('Axios response to view invoice: ', response)
    if (response && response.data) {
      setState({...state, pdf: response.data, showInvoiceModal: true})
    }
  }).catch(error => {
    // console.log('Error getting invoice to view', error)
  })
}
const handlePayment = () => {
  // console.log('Make Payment - open modal now')
  setState({...state, showPaymentModal: true})
}
const deleteInvoice = () => {
  // console.log('Delete Invoice', invoice)
  setLoading(true)
  axios({
    method: 'post',
    url:  `${props.url}/api/invoice/deleteInvoice`,
    data: props,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setLoading(false)
    // console.log('Axios response: DELTE INVOICE', response)
    if (response?.data?.success) {
      props.notification({
        type: 'success',
        title: 'Invoice Deleted!',
        message: `What Invoice? I Don't Know About No Stinking Invoice??`
      })
      props.invoiceDetailsResponse('Deleted', props.invoice)
    } else if (response.data && response.data.existingPayments) {
      props.notification({
        type: 'warning',
        title: 'Invoice Cannot Be Deleted!',
        message: 'This Invoice has payments against it already...cannot delete.'
      })
    } else {
      props.notification({
        type: 'warning',
        title: 'Invoice Cannot Be Deleted!',
        message: 'There is an error here....'
      })
    }
  }).catch(err => {
    setLoading(false)
    // console.log('Eror deleting invoice: ', err)
  })
}

const deletePayment = () => {
  // console.log('Delete Payment', props.invoice)
  setLoading(true)
  axios({
    method: 'post',
    url: `${props.url}/api/payment/deletePayment`,
    data: props,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setLoading(false)
    // console.log('Axios response: DELETE PAYMENT', response)
    if (response && response.data && response.data.name === 'Error') {
      props.notification({
        type: 'warning',
        title: 'Payment Cannot Be Deleted!',
        message: 'This is most likely a reference issue...Not Sure.'
      })
    } else {
      props.notification({
        type: 'success',
        title: 'Payment Deleted!',
        message: 'That Payment Has Been Deleted!'
      })
      props.invoiceDetailsResponse('Deleted', props.invoice)
    }
  }).catch(err => {
    setLoading(false)
    // console.log('Eror deleting invoice: ', err)
  })
}

const closeMakePaymentModal = (d, pmtData) => {
  // console.log('Close make payment: ', d)
  // console.log('Payment Data: ', pmtData)
  let pmt = null
  if (pmtData && pmtData.payment) pmt = pmtData.payment
  if (pmt && pmt._id) {
    // console.log('Add this payment to our payments: ', pmt)
    let pmts = payments
    // console.log('Payments: ', pmts)
    pmts.unshift(pmt)
    // console.log('After push: ', pmts)
    let updatedInvoice = invoice
    // console.log('Invoice before Payment: ', invoice)
    let balance = parseFloat(updatedInvoice.balance)
    balance = balance - pmt.amount
    // console.log('Updted balance!', balance)
    updatedInvoice.balance = balance
    // console.log('Invoice to set: ', updatedInvoice)
    props.invoiceDetailsResponse('Payment Made', updatedInvoice)
    setinvoice(updatedInvoice)
    setPayments(pmts)
    setState({...state, payments: pmts, showPaymentModal: false})
    // console.log(state)
    // console.log(payments)
  } else {
    setState({...state, showPaymentModal: false})
  }
}
const updateInvoiceStatus = () => {
  let newInvoice = invoice
  newInvoice.status = 'Not Paid'
  newInvoice.officeApproved = true
  setinvoice(newInvoice)
  setState({...state, approved: true})
}

const waiveLateFee = (invoice) => {
  // console.log('Waive this late fee: ', invoice)
  setLoading(true)
  axios({
    method: 'post',
    url: `${props.url}/api/payment/waiveLateFee`,
    data: {
      user: props.user,
      invoiceId: invoice._id,
      client: props.client
    },
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setLoading(false)
    // console.log('Axios response: waiveLateFee', response)
    if (response && response.data && response.data._id) {
      setinvoice(response.data)
    }
  }).catch(err => {
    // console.log('Erro: ', err)
  })
}

const editInvoiceDate = () => {
  // console.log('Update Invoice Date')
  setModifyInvoiceDateOpen(true)
}

const updateInvoiceDate = (d, updateDate) => {
  if (d === 'Close') setModifyInvoiceDateOpen(false)
  if (d === 'Save') {
    setModifyInvoiceDateOpen(false)
    let newInvoice = invoice
    newInvoice.dueDate = updateDate
    setinvoice(newInvoice)
    axios({
      method: 'post',
      url: `${props.url}/api/invoice/updateInvoiceDate`,
      data: newInvoice,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response: UPDATE INVOICE DATE', response)
      if (response && response.data && response.data.name === 'Error') {
        props.notification({
          type: 'warning',
          title: 'Payment Cannot Be Deleted!',
          message: 'This is most likely a reference issue...Not Sure.'
        })
      } else {
        props.notification({
          type: 'success',
          title: 'Update Success',
          message: 'Invoice Date Has Been Updated!'
        })
      }
    }).catch(err => {
      // console.log('Eror updating invoice date', err)
    })
  }
}

const setQBNumber = (d) => {
  // console.log('Set QB: ', d)
  let newInvoice = invoice
  newInvoice.qbInvNumber = d
  setinvoice(newInvoice)
  axios({
    method: 'post',
    url: `${props.url}/api/invoice/updateQuickbooksInvoiceNumber`,
    data: newInvoice,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response: UPDATE INVOICE DATE', response)
    setState({...state, loading: false})
    // if (response && response.data && response.data.name === 'Error') {
    //   props.notification({
    //     type: 'warning',
    //     title: 'Payment Cannot Be Deleted!',
    //     message: 'This is most likely a reference issue...Not Sure.'
    //   })
    // } else {
    //   props.notification({
    //     type: 'success',
    //     title: 'Update Success',
    //     message: 'Invoice Has Been Updated!'
    //   })
    // }
  }).catch(err => {
    // console.log('Eror updating invoice', err)
  })
}

const handleQbInvNumber = () => {
  // console.log('Show QB Inv Number Input', invoice.qbInvNumber)
  qbInvNumber.current = invoice.qbInvNumber
  let newInv = invoice
  newInv.qbInvNumber = ''
  setinvoice(newInv)
  setState({...state, showQbInvInput: true})
  // console.log('New: ', qbInvNumber)
}
const handledisplayAllLineItemsChange = () => {
  let newStatus = !state.displayAllLineItems
  let updateData = {
    displayAllLineItems: newStatus
  }
  setState(prevState => ({...prevState, ...updateData }))
  updateData.invoiceId = invoice._id
  axios({
    method: 'post',
    url: `${props.url}/api/invoice/updateInvoiceDetails`,
    data: updateData,

  }).then(response => {

  }).catch(err => {
    // console.log('Eror updating invoice', err)
  })
}

console.log('Invoice Details CUSTOMER 682', state)
  return (
    <div className={classes.root}>
      <EditInvoiceDateModal invoiceDate={invoice.dueDate} open={modifyInvoiceDateOpen} updateParent={updateInvoiceDate} />
      <MakePaymentModal closeModal={closeMakePaymentModal} deposits={props.deposits} credits={props.credits} client={props.client} paymentProfiles={props.paymentProfiles} invoice={invoice} open={state.showPaymentModal} />
      <InvoiceOptionsModal client={props.client} invoice={invoice} entity={props.entity} contacts={props.contacts} pdf={state.pdf} updateInvoiceStatus={updateInvoiceStatus} jobDetailsResponse={updateFromInvoiceModal} open={state.showInvoiceModal} />
     {/* <Button size="small" variant="contained" color="secondary" onClick={() => { handleOpen() }}>Add Location</Button> */}
      <Dialog
        // fullScreen={true}
        maxWidth='md'
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Invoice Details  {invoice.invoiceNumber}</DialogTitle>
        <DialogContent> 
            <Grid container className={classes.grid}>
              <Grid item lg={12} xs={12}>
                {props.client.displayName}
              </Grid>
              <Grid item lg={12} xs={12}>
                Job Number: {invoice.jobNumber}
              </Grid>
              <Grid item lg={12} xs={12}>
               Amount: {invoice.amount ? currencyFormat(parseFloat(invoice.amount)) : '0.00'}
              </Grid>
              <Grid item lg={12} xs={12}>
               Balance: {invoice.balance ? currencyFormat(parseFloat(invoice.balance)) : '0.00'}
              </Grid>
              <Grid item lg={12} xs={12}>
               Job Name: {invoice.jobName}
              </Grid>
              <Grid item lg={12} xs={12}>
               Status: {invoice.status}
              </Grid>
              <Grid item lg={12} xs={12}>
               Created: {moment(invoice.created_at, 'YYYY-MM-DDThh:mm:ss.000Z').format('MM/DD/YYYY [at] hh:mm a')}
              </Grid>
              <Grid item lg={12} xs={12}>
               Due Date: {moment(invoice.dueDate, 'YYYY-MM-DDThh:mm:ss').format('MM/DD/YYYY')} 
               <IconButton onClick={editInvoiceDate}  alt="Edit Due Date" aria-label="Edit Due Date">
                  <EventNoteIcon style={{ fontSize: "small" }} />
                </IconButton>
              </Grid>
              <Grid item lg={6} xs={12} onDoubleClick={handleQbInvNumber}>
                <QuickBooksInvoiceDetails  ourRef={qbInvNumber} invoice={invoice} setQBNumber={setQBNumber} showQbInvInput={state.showQbInvInput} />
                {/* {
                  state.showQbInvInput ? (
                    <TextField
                    label="Quickbooks Invoice Number"
                    // helperText="Check Number/Direct Deposit ID"
                    type="Text"
                    fullWidth
                    ref={qbInvNumber}
                    // disabled={state.isInvoiced || state.isComplete}
                    // value= {state.qbInvNumber}
                    // onChange={ e => setQBNumber(e.target.value) }
                    // defaultValue="Default Value"
                    onBlur={e => setQBNumber(e.target.value) }
                  />
                  ) : (
                    `QB Invoice Number: ${invoice.qbInvNumber}`
                  )
                }
                  { invoice.qbInvNumber ? (
                      `QB Invoice Number: ${invoice.qbInvNumber}`
                    ) : (
                    <TextField
                      label="Quickbooks Invoice Number"
                      // helperText="Check Number/Direct Deposit ID"
                      type="Text"
                      fullWidth
                      ref={qbInvNumber}
                      // disabled={state.isInvoiced || state.isComplete}
                      // value= {state.qbInvNumber}
                      // onChange={ e => setQBNumber(e.target.value) }
                      // defaultValue="Default Value"
                      onBlur={e => setQBNumber(e.target.value) }
                    />
                  )
                    } */}
              </Grid>
              <Grid item lg={12} xs={12}> 
                <FormControlLabel
                    control={
                      <Switch
                        checked={state.displayAllLineItems}
                        onChange={handledisplayAllLineItemsChange}
                        name="displayAllLineItems"
                        color="primary"
                      />
                    }
                    label={state.displayAllLineItems ? `Click to Hide Line Item Details (Do Not Show All Line Items on Invoice)` : `Click to Show Line Item Details (Display All Line Items on Invoice)`}
                  />
              </Grid>
            </Grid>
            
            { invoice.declineFee ? (
              <Grid container className={classes.grid}>
                <Grid item xs={12}>
                  Decline Fee Information
                </Grid>
              </Grid> 
            ) : ''}
            { invoice.lateFee ? (
              <Grid container className={classes.grid}>
                 <Grid item lg={12}>
                 <strong>Late Fee Information:</strong>
                 </Grid>
                
                <Grid item lg={12}>
                  Late Fees Charged: {currencyFormat(parseFloat(invoice.lateFeeAmount || 0))}

                  <Button onClick={() => { if (window.confirm('Are you sure you wish to waive this late fee?')) waiveLateFee(invoice) } } size="small"style={{color: '#c62828'}}>
                    Waive Late Fee
                </Button> 
                </Grid>
              </Grid> 
            ) : ''}
            <Grid container className={classes.grid}>
              <Grid item xs={12}>
                {
                  (props?.invoice.multiJobInvoice && props?.invoice?.htmlContent) ? <ComplexInvoiceDisplayDescription invoice={props.invoice} /> :  `${invoice.description}`
                }
              </Grid>
            </Grid>
            <Grid container className={classes.grid}>
              <Grid item lg={4} xs={12}>
                <Button onClick={handlePayment} disabled={invoice.status === 'Paid'} variant="outlined" color="primary">
                  Make Payment
                </Button>
              </Grid>
              <Grid item lg={4} xs={12}>
                <Button onClick={handleViewInvoice} disabled={loading} variant="outlined" color="primary">
                { loading ? <CircularProgress color="primary" size={ 36 } /> : '' } View Invoice 
                </Button>
              </Grid>
              <Grid item lg={4} xs={12}>
                <ProtectedButton type="Invoices" kind="delete" perms={props.perms}>
                  <Button onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteInvoice() } } disabled={(invoice.status === 'Paid' && invoice.amount > 0.1) || loading} variant="outlined" style={{color: '#c62828'}}>
                    Delete Invoice
                  </Button>
                </ProtectedButton>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                Online Invoice Payment URL: <a href={`http://${window.location.host}/online/invoice/${invoice._id}/1`} target="_blank">Click Here to Pay Online</a>
              </Grid>
            </Grid>
            <Grid container className={classes.grid}>
              <Grid item lg={12} xs={12}>
                <Paymentlist client={props.client} stuff={state} />
              </Grid>
            </Grid>
            {/* {JSON.stringify(invoice)} */}
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Save
          </Button> */}
        </DialogActions>
    </Dialog>
    </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      perms: state.perms
    }
  }
  
  export default React.memo(connect(mapStateToProps)(InvoiceDetail))