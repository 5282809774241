import React, { useState, useEffect, useCallback, useMemo, useReducer, useRef } from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import axios from 'axios'
// import InvoiceDetails from './InvoiceDetail'
import { Paper, CircularProgress, IconButton } from '@material-ui/core';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import VisibilityIcon from '@material-ui/icons/Visibility';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'invoiceNumber', numeric: false, disablePadding: true, label: 'Invoice Number' },
  { id: 'jobName', numeric: false, disablePadding: true, label: 'Job Name (Number)' },
  { id: 'amount', numeric: false, disablePadding: true, label: 'Amount' },
  { id: 'taxAmount', numeric: false, disablePadding: true, label: 'Tax Amount' },
  { id: 'lateFeeAmount', numeric: false, disablePadding: true, label: 'Late Fee Amount' },
  { id: 'totalDue', numeric: false, disablePadding: true, label: 'Total Due' },
  { id: 'balance', numeric: false, disablePadding: true, label: 'Balance' },
  // { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'notes', numeric: false, disablePadding: true, label: 'Notes' },
  { id: 'created_at', numeric: false, disablePadding: true, label: 'Created' },
  { id: 'dueDate', numeric: false, disablePadding: true, label: 'Due Date' },
  // { id: 'crewname', numeric: false, disablePadding: false, label: 'Crew' }
];

const EnhancedTableHead = React.memo(function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = useCallback((property) => (event) => {
    onRequestSort(event, property);
  }, [onRequestSort]);

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));

const defaultStateValues = {
  pdf: ''
}


const initialState = {
  order: 'asc',
  orderBy: '-created',
  selected: [],
  page: 0,
  rowsPerPage: 10,
  searchValue: '',
  searchMatches: 0,
  invoiceDetailsOpen: false,
  loading: false,
  invoice: {},
  crewList: [],
  serviceList: [],
  filteredRows: [],
  showOpenInvoicesToggle: false,
  pdf: '',
  rows: [],
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ORDER':
      return { ...state, order: action.payload };
    case 'SET_ORDER_BY':
      return { ...state, orderBy: action.payload };
    case 'SET_SELECTED':
      return { ...state, selected: action.payload };
    case 'SET_PAGE':
      return { ...state, page: action.payload };
    case 'SET_ROWS_PER_PAGE':
      return { ...state, rowsPerPage: action.payload };
    case 'SET_SEARCH_VALUE':
      return { ...state, searchValue: action.payload };
    case 'SET_SEARCH_MATCHES':
      return { ...state, searchMatches: action.payload };
    case 'SET_INVOICE_DETAILS_OPEN':
      return { ...state, invoiceDetailsOpen: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_INVOICE':
      return { ...state, invoice: action.payload };
    case 'SET_CREW_LIST':
      return { ...state, crewList: action.payload };
    case 'SET_SERVICE_LIST':
      return { ...state, serviceList: action.payload };
    case 'SET_FILTERED_ROWS':
      return { ...state, filteredRows: action.payload };
    case 'SET_SHOW_OPEN_INVOICES_TOGGLE':
      return { ...state, showOpenInvoicesToggle: action.payload };
    case 'SET_PDF':
      return { ...state, pdf: action.payload };
    case 'SET_ROWS':
      return { ...state, rows: action.payload };
    default:
      return state;
  }
}

function InvoiceList(props) {
  // console.log('invoice List Props: ', props)
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useRef(true);

  const { client, url, notification, stateData, invoicesProvided } = props;

  const getInvoicesForClient = useCallback(async (client) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await axios.get(`${url}/api/invoice/getAllClientInvoices?id=${client._id}`);
      if (response && response.data) {
        dispatch({ type: 'SET_ROWS', payload: response.data });
        dispatch({ type: 'SET_FILTERED_ROWS', payload: response.data });
      } else {
        throw new Error('No data received');
      }
    } catch (error) {
      notification({
        type: 'warning',
        title: 'We Ran Into A Problem!',
        message: 'An Error Occurred Trying to Get Invoices from Arbor Gold - please hang tight while we attempt to resolve it.'
      });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [url, notification]);

  useEffect(() => {
    if (client && client._id && !invoicesProvided) getInvoicesForClient(client);
    socket.on(`${client._id}-invoiceList`, () => getInvoicesForClient(client));
    return () => {
      isMounted.current = false;
      socket.off(`${client._id}-invoiceList`);
    };
  }, [client, invoicesProvided, getInvoicesForClient]);

  useEffect(() => {
    if (stateData?.itemToOpen === 'PDF' && stateData?.pdf) {
      if (stateData?.depositInvoice) openModal(stateData.depositInvoice, stateData.pdf);
    }
  }, [stateData]);

  useEffect(() => {
    if (props?.invoices?.length) {
      dispatch({ type: 'SET_FILTERED_ROWS', payload: props.invoices });
      dispatch({ type: 'SET_ROWS', payload: props.invoices });
    }
  }, [props?.invoices]);

  const handleRequestSort = useCallback((event, property) => {
    const isDesc = state.orderBy === property && state.order === 'desc';
    dispatch({ type: 'SET_ORDER', payload: isDesc ? 'asc' : 'desc' });
    dispatch({ type: 'SET_ORDER_BY', payload: property });
  }, [state.order, state.orderBy]);

  const handleChangePage = useCallback((event, newPage) => {
    dispatch({ type: 'SET_PAGE', payload: newPage });
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    dispatch({ type: 'SET_ROWS_PER_PAGE', payload: parseInt(event.target.value, 10) });
    dispatch({ type: 'SET_PAGE', payload: 0 });
  }, []);

  const filterOut = useCallback((r, searchText) => {
    const newArr = r.filter(o => 
      Object.values(o).some(value => 
        value && value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    dispatch({ type: 'SET_FILTERED_ROWS', payload: newArr });
  }, []);

  const handleChange = useCallback((name) => (event) => {
    dispatch({ type: 'SET_PAGE', payload: 0 });
    dispatch({ type: 'SET_SEARCH_VALUE', payload: event.target.value });
    if (event.target.value === '') {
      dispatch({ type: 'SET_FILTERED_ROWS', payload: state.rows });
    } else {
      filterOut(state.rows, event.target.value);
    }
  }, [state.rows, filterOut]);

  const openModal = useCallback((invoice, pdf) => {
    dispatch({ type: 'SET_INVOICE', payload: invoice });
    dispatch({ type: 'SET_INVOICE_DETAILS_OPEN', payload: true });
    if (pdf) {
      dispatch({ type: 'SET_PDF', payload: pdf });
    }
  }, []);

  const showPaymentDetails = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const response = await axios.get(`${url}/api/invoice/getAllInvoicesAndAssociatedPayments?id=${client._id}`);
      dispatch({ type: 'SET_ROWS', payload: response.data });
      dispatch({ type: 'SET_FILTERED_ROWS', payload: response.data });
    } catch (error) {
      notification({
        type: 'error',
        title: 'Error',
        message: 'Failed to fetch payment details'
      });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [url, client._id, notification]);

  const showOpenInvoices = useCallback(() => {
    const newToggleState = !state.showOpenInvoicesToggle;
    dispatch({ type: 'SET_SHOW_OPEN_INVOICES_TOGGLE', payload: newToggleState });
    if (newToggleState) {
      const openInvoices = state.rows.filter(item => 
        item && (item.status === 'Not Paid' || item.status === 'Partially Paid')
      );
      dispatch({ type: 'SET_FILTERED_ROWS', payload: openInvoices });
    } else {
      dispatch({ type: 'SET_FILTERED_ROWS', payload: state.rows });
    }
  }, [state.showOpenInvoicesToggle, state.rows]);



  function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = state.rows.map(n => n._id);
      // console.log(newSelecteds)
      state.setSelected(newSelecteds);
      return;
    }
    state.setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = state.selected.indexOf(name);
    // console.log('handle click ', name)
    // console.log('Index: ', selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.selected.slice(1));
    } else if (selectedIndex === state.selected.length - 1) {
      newSelected = newSelected.concat(state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.selected.slice(0, selectedIndex),
        state.selected.slice(selectedIndex + 1),
      );
    }

    state.setSelected(newSelected);
  };

 
  const isSelected = name => state.selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const invoiceDetailsResponse = (s, t) => {
    // console.log('Response from our invoice details!!', s)
    if (s === 'Deleted') {
      // console.log('Deleted invoice - remove it from the list', t)
      let index = state.rows.findIndex(row =>  (row._id.toString() === t._id.toString()))
      // console.log('INDEX: ', index)
      // console.log(rows[index])
      let invoices = state.rows
      invoices.splice(index, 1)
      state.setRows(invoices)
      state.setinvoiceDetailsOpen(false)
    } else if (s === 'Payment Made') {
      // console.log('Payment made baby!!!', t)
      let index = state.rows.findIndex(row =>  (row['_id'] === t['_id']))
      // console.log('INDEX: ', index)
      // console.log(rows[index])
      let invoices = state.rows
      invoices[index] = t
      // .splice(index, 1)
      // invoices.push(t)
      // console.log('Invoices with updated payment...', invoices)
      state.setRows(invoices)
      // setFilteredRows(invoices)
    } else {
      state.setinvoiceDetailsOpen(false)
    }
  }


  
  const NotesDetails = (data) => {
    let row = data.row
    // console.log('Notes Details: ', row)
   let html = ''
   if (row.transactionId) {
     html = (
       `Transaction ID: ${row.transactionId} (${row.accountType} - ${row.accountNumber})`
     )
   } else if (row.accountType && row.accountNumber) {
     html = (
       `${row.accountType} - ${row.accountNumber}`
     )
   } else {
     html = row.notes
   }
   return html
  }
  
  const PaymentDetails = (props) => {
    if (props.payments && props.payments.length > 0) {
      return props.payments.map(pmt => (
        <TableRow style={{backgroundColor: '#ccc'}}>
          <TableCell></TableCell>
          <TableCell padding="none">Payment On Invoice # {pmt.invoiceNumber}: </TableCell>
          <TableCell padding="none">{currencyFormat(pmt.amount || 0)}</TableCell>
          <TableCell padding="none">{(pmt.totalCharge && pmt.totalCharge > 0) ? currencyFormat(pmt.totalCharge) : ''}</TableCell>
          <TableCell padding="none">{pmt.status}</TableCell>
          <TableCell colSpan={2} padding="none"><NotesDetails row={pmt} /></TableCell>
          <TableCell padding="none">{moment(pmt.paidDate).format('MM/DD/YYYY')}</TableCell>
        </TableRow>

      ))
    } else {
      return null
    }
    
  }
  const chipBgColor = (status) => {
    let bgColor = '#ccc'
    if (status === 'Pending') bgColor='#ddd'
    if (status === 'Not Paid') bgColor='#f1c40f'
    if (status === 'Paid') bgColor='green'
    if (status === 'Bad Debt') bgColor='red'
    if (status === 'Show All') bgColor='#617563'
    if (status === 'Show Open') bgColor = '#dbd800'
    return bgColor
  }


  return (
    <div className={classes.root}>
      {/* <InvoiceDetails pdf={state.pdf} client={props.client} deposits={props.deposits} entity={props.entity} contacts={props.contacts} credits={props.credits} paymentProfiles={props.paymentProfiles} invoice={invoice} crewList={crewList} serviceList={serviceList} invoiceDetailsResponse={invoiceDetailsResponse} open={invoiceDetailsOpen} /> */}
      { state.loading?  <CircularProgress color="primary" size={ 36 } /> : 
      <Paper className={classes.paper}>
       
          Total Invoices {state.rows.length}
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          <Tooltip title="View Payment Details" placement="top">
            <IconButton onClick={showPaymentDetails}  alt="Show Payment Details" aria-label="Show Payment Details">
                <VisibilityIcon />
            </IconButton>
          </Tooltip>
          {
            state.showOpenInvoicesToggle ? (
              <Tooltip title="Show All Invoices" placement="top" style={{marginLeft: 50}}>
                <Chip onClick={showOpenInvoices}  style={{color: '#fff', background: chipBgColor('Show All') }} size="small" label="Show All Invoices" />
                {/* <IconButton onClick={showOpenInvoices}  alt="Show All Invoices" aria-label="Show All Invoices">
                    <AttachMoneyIcon />
                </IconButton> */}
              </Tooltip>
            ) : (
              <Tooltip title="Show Open Invoices" placement="top" style={{marginLeft: 50}}>
                {/* <IconButton onClick={showOpenInvoices}  alt="Show Open Invoices" aria-label="Show Open Invoices">
                    <AttachMoneyIcon />
                </IconButton> */}
                <Chip onClick={showOpenInvoices}  style={{color: '#fff', background: chipBgColor('Show Open') }} size="small" label="Show Open Invoices" />
              </Tooltip>
            )
          }


        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <div className={classes.search}>
            <div >
              <SearchIcon /> Search { state.searchValue ? `(${state.filteredRows.length} Matches)` : ''}
            </div>
            <input name="search" value={ state.searchValue } onChange={ handleChange('search') } type="text"></input>
          </div>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={state.selected.length}
              order={state.order}
              orderBy={state.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={state.rows.length}
            />
            <TableBody>
              {stableSort(state.filteredRows, getSorting(state.order, state.orderBy))
                .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                .map((row, index) => {
                  // console.log('Invoice LIST map: ', row)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let isPastDue = false
                  if (row.status === 'Not Paid' && (moment(row.dueDate) < moment(new Date()))) isPastDue = true
                  // setsearchMatches(rows.length)
                  // console.log(row)
                  // console.log('Is Past DUe: ', isPastDue)
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        // onClick={event => handleClick(event, row.number)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                        component="tr"
                        style={(isPastDue) ? { padding: '3px', backgroundColor: 'rgba(223, 38, 38, .4)', border: '1px solid #d7393f' } : null}
                      >
                        <TableCell component="td" id={labelId} scope="row" padding="none" >
                          <span className={classes.fakeLink} onClick={() => openModal(row)}>{row.invoiceNumber}</span>
                          &nbsp;&nbsp;&nbsp;&nbsp;<Chip style={{color: '#fff', background: chipBgColor(row.status) }} size="small" label={row.status} />
                        </TableCell>
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          { row.jobName } ({ row.jobNumber})
                        </TableCell>
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          { row.amount ? currencyFormat(parseFloat(row.amount)) : '$0.00' }
                        </TableCell>
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          { row.taxAmount ? currencyFormat(parseFloat(row.taxAmount)) : '$0.00' }
                        </TableCell>
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          { row.lateFeeAmount ? currencyFormat(parseFloat(row.lateFeeAmount)) : '$0.00' }
                        </TableCell>
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          { row.totalDue ? currencyFormat(parseFloat(row.totalDue)) : '$0.00' }
                        </TableCell>
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          { row.balance ? currencyFormat(parseFloat(row.balance)) : '$0.00' }
                        </TableCell>
                      
                        {/* <TableCell component="td" id={labelId} scope="row" padding="none">
                          {row.status}
                        </TableCell> */}
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          {row.notes}
                        </TableCell>
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          {moment.utc(row.created_at).local().format('MM/DD/YYYY')}
                        </TableCell>
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          {moment.utc(row.dueDate).local().format('MM/DD/YYYY')}
                        </TableCell>
                      </TableRow>
                      { row.payments ? <PaymentDetails payments={row.payments} /> : '' }
                    </React.Fragment>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (state.dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={state.filteredRows.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          // onPageChange={handleChangePage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      }
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default React.memo(connect(mapStateToProps)(InvoiceList))

