import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Input,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const HerbicideDetails = ({ state, setState }) => {
  const handleChange = (field) => (event) => {
    setState({
      ...state,
      herbicideDetails: {
        ...state.herbicideDetails,
        [field]: event.target.value
      }
    });
  };

  const handleArrayChange = (field) => (event, newValue) => {
    setState({
      ...state,
      herbicideDetails: {
        ...state.herbicideDetails,
        [field]: newValue
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="outlined"
          label="Active Ingredient"
          value={state.herbicideDetails?.activeIngredient || ''}
          onChange={handleChange('activeIngredient')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="outlined"
          label="Formulation"
          value={state.herbicideDetails?.formulation || ''}
          onChange={handleChange('formulation')}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          options={[]}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Target Weeds"
              placeholder="Add target weeds"
              helperText={`Press Enter When Finished Typing`}
            />
          )}
          value={state.herbicideDetails?.targetWeeds || []}
          onChange={handleArrayChange('targetWeeds')}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          options={[]}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Application Methods"
              placeholder="Add application methods"
              helperText={`Press Enter When Finished Typing`}
            />
          )}
          value={state.herbicideDetails?.applicationMethod || []}
          onChange={handleArrayChange('applicationMethod')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          label="Safety Precautions"
          multiline
          rows={4}
          value={state.herbicideDetails?.safetyPrecautions || ''}
          onChange={handleChange('safetyPrecautions')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="outlined"
          label="EPA Registration Number"
          value={state.herbicideDetails?.epaRegistrationNumber || ''}
          onChange={handleChange('epaRegistrationNumber')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="outlined"
          label="Manufacturer"
          value={state.herbicideDetails?.manufacturer || ''}
          onChange={handleChange('manufacturer')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="category-label">Herbicide Type</InputLabel>
          <Select
            labelId="category-label"
            value={state.herbicideDetails?.category || ''}
            onChange={handleChange('category')}
            label="Chemical Type"
          >
            {['Selective', 'Non-selective', 'Pre-emergent', 'Post-emergent', 'Surfactant', 'Turf/Broadleaf', 'Dye'].map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {/* <Grid item xs={12}>
        <Autocomplete
          multiple
          options={[]}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Compatible Crops"
              placeholder="Add compatible crops"
            />
          )}
          value={state.herbicideDetails?.compatibleCrops || []}
          onChange={handleArrayChange('compatibleCrops')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="outlined"
          label="Re-Entry Interval"
          value={state.herbicideDetails?.reEntryInterval || ''}
          onChange={handleChange('reEntryInterval')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="outlined"
          label="Pre-Harvest Interval"
          value={state.herbicideDetails?.preharvest_interval || ''}
          onChange={handleChange('preharvest_interval')}
        />
      </Grid> */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          label="MSDS URL"
          value={state.herbicideDetails?.msds_url || ''}
          onChange={handleChange('msds_url')}
        />
      </Grid>
    </Grid>
  );
};

export default HerbicideDetails;