const moment = require('moment')

function formatPhoneNumber (phoneNumberString) {
    if (!phoneNumberString) phoneNumberString = ''
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  function currencyFormatWithOutFlair (num) {
    if (!num) num = 0
    return parseFloat(num).toFixed(2)
  }
  
  function currencyFormat (num) {
    if (!num) num = 0
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  function validateEmail(str) {
    const email = str.trim()
    const res = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return res.test(String(email).toLowerCase());
  }

  // Case Insensitive Search care of Chat GPT-4 6/24/2023
function desc(a, b, orderBy, isDate) {
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  } else {
    let valA = a[orderBy] == null ? "" : String(a[orderBy]).toLowerCase();
    let valB = b[orderBy] == null ? "" : String(b[orderBy]).toLowerCase();

    if (valB < valA) {
      return -1;
    }
    if (valB > valA) {
      return 1;
    }
    return 0;
  }
}

const handleFocus = (event) => event.target.select();

function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}


function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy && orderBy.includes('_at')) {
    // console.log('Last Updated')
    // console.log('Date SOrT!!!!')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const isEven = (num) => {
  if (!num) num = 0
  if (num % 2 === 0) return true
  return false
}

const isEvenOrOddWeek = () => {
  let weekOfTheYear = moment(new Date()).week()
  let evenWeek = isEven(weekOfTheYear)
  let weekType = evenWeek ? 'Even' : 'Odd'
  console.log('Return Week Type:', weekType)
  return weekType
}

const daysOfTheWeek = [
  {
    name: 'None',
    num: 0
  },
  {
    name: 'Sunday',
    num: 1
  },
  {
    name: 'Monday',
    num: 2
  },
  {
    name: 'Tuesday',
    num: 3
  },
  {
    name: 'Wednesday',
    num: 4
  },
  {
    name: 'Thursday',
    num: 5
  },
  {
    name: 'Friday',
    num: 6
  },
  {
    name: 'Saturday',
    num: 7
  }
]

const mtcFrequencies = [
  {
    name: 'Daily',
    servicesPerYear: 260,
    num: 260
  },
  {
    name: 'Every Other Day (M-W-F)',
    servicesPerYear: 156,
    num: 156
  },
  {
    name: 'Twice a Week',
    servicesPerYear: 104,
    num: 104
  },
  {
    name: 'Weekly',
    servicesPerYear: 52,
    num: 52
  },
  {
    name: 'Bi-Weekly',
    servicesPerYear: 26,
    num: 26
  },
  {
    name: 'Monthly',
    servicesPerYear: 12,
    num: 12
  },
  {
    name: 'Quarterly',
    servicesPerYear: 4,
    num: 4
  },
  {
    name: 'Semi-Annually',
    servicesPerYear: 2,
    num: 2
  },
  {
    name: 'Annually',
    servicesPerYear: 1,
    num: 1
  },
  {
    name: 'On Demand',
    servicesPerYear: 0,
    num: 0
  }
]
module.exports = { formatPhoneNumber,
  currencyFormat, currencyFormatWithOutFlair, validateEmail, desc, stableSort, getSorting, isEven, humanFileSize, isEvenOrOddWeek, 
  daysOfTheWeek, 
  mtcFrequencies,
  handleFocus
 }
