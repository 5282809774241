import socketIO from 'socket.io-client'

const url = `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`
const socket = socketIO(url, { transports: ['websocket'] })

socket.on('broadcast', (message) => {
// console.log('Socket Broadcast being received ', message)
})
const join = callback => {
  socket.on('join', (message) => {
    callback(message)
  })
}

const send = (channel, message) => {
  // Send message to server
// console.log('SOCKET SEND CALLED: %S: %S', channel, message)
  socket.emit(channel, message)
}

export {
  join,
  send,
  socket
}
