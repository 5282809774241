import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PlanInputItem from './PlanInputItem'
import SupplyInventoryInputItem from './SupplyInventoryInputItem'
import SupplierComponent from './SupplierComponent'
import { Editor } from 'react-draft-wysiwyg';
// import {EditorState} from "draft-js";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { validateEmail } from '../../services/common'

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SendToCustomerModal = (props) => {
// console.log('Send To Customer Modal:', props)
  const classes = useStyles();

  const [dialogValue, setDialogValue] = React.useState(defaultStateValues);
  const [state, setState] = React.useState({
    fromEmail: ''
  })
  const [recipientList, setRecipientList] = React.useState([])
  const [contact, setContact] = React.useState({})
  const [item, setItem] = React.useState({})
  const [open, toggleOpen] = React.useState(false);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [content, setContentState] = React.useState({})

  React.useEffect(() => {
    // console.log('PROPS.item changed', props)
    // setState({...state, name: props.item.name })
    // setDialogValue({...dialogValue, name: props.item.name, description: props.item.name})
    // let html = '<h1>Test</h1>'
    // setContentState(content)
    const html = `<p></p><p></p><br/><br/><br/>${props.entity.name}<br />${props.entity.billingAddress}<br />${props.entity.billingCity}, ${props.entity.billingState}, ${props.entity.billingZip}<br />${props.entity.telephone}`;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setContentState(contentState)
      setEditorState(editorState)
    }
  }, [props])

  React.useEffect(() => {
    // Check for a contact
    recipientList.map(item => {
      if (item && item._id) setContact(item)
    })
  }, [recipientList])

  const [tabOpenValue, setTabOpenValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setTabOpenValue(newValue);
  }

  const handleClose = () => {
    setDialogValue(defaultStateValues);

    props.handleCloseModal();
  };

  const handleSave = (event) => {
    event.preventDefault();
    const msg = `Are you sure you wish to send this estimate out to ${recipientList.length} email address${recipientList.length > 1 ? 'es' : ''}?`
    if (window.confirm(msg)) sendItOut()
  }

  const sendItOut = () => {
  // console.log('Send this shit!!!', recipientList)
    let invalidEmailsDetected = false
    let hasAtLeastOneContact = false
    let message = 'Invalid Email:  '
    for (const item of recipientList) {
    // console.log('Recipient', item)
      if (item && item._id) hasAtLeastOneContact = true
      let isValid = validateEmail(item.email)
    // console.log('IS Valid:', isValid)
      if (!isValid) {
        invalidEmailsDetected = true
        message += item.email
      }
    }
    if (invalidEmailsDetected) {
      props.notification({
        title: 'Invalid Emails',
        message: message,
        type: 'warning'
      })
    } else {
      let data = {
        estimateId: props.estimate._id,
        recipientList,
        emailContent: content,
        fromEmail: `${state.fromEmail}`
      }
    // console.log(data)
      axios({
        method: 'post',
        url:  `${props.url}/api/proposal/sendEstimate`,
        data: data,
      }).then(response => {
        // console.log('Sent Email: ', response.data)
        if (response && response.data && response.data.success) {
          props.notification({
            type: 'success',
            title: 'Proposal Sent!',
            message: 'You Will Be Notified When The Customer Accepts The Proposal.'
          })
          handleClose()
        }
        if (response && response.data && response.data.error) {
          props.notification({
            type: 'warning',
            title: 'System Error Sending Proposal',
            message: response.data.message
          })
        }
  
      }).catch(err => {
        // // console.log('Error caught when uploading pic: ', err)
        props.notification({
          type: 'warning',
          title: 'Failed to Send Estimate!',
          message: err.message
        })
      })
    }

    // console.log(dialogValue)
    // console.log(props)
    // // console.log(dialogValue)
    // // console.log('Got it all?')
    // handleClose();
  };

  const handleCategoryChange = (e) => {
    setDialogValue({...dialogValue, category: e.target.value})
  }
  const handleUpdateUOM = (e, newOne) => {
    // // console.log('New UOM: ', newOne)
    setDialogValue({...dialogValue, unitsOfMeasure: newOne})
  }

  const updatePlantDetails = (d, c) => {
    // console.log('Update Plant Details: ', d)
    // console.log('c: ', c)
    if (c && c.unitsOfMeasure && c.unitsOfMeasure.length) d.unitsOfMeasure = c.unitsOfMeasure
    setState({...state, inputDetails: d})
  }

  // const handleSave = () => {
  //   // console.log('Save this shit!!!', state)
  //   // console.log(dialogValue)
  //   // console.log(props)

  // }

  const contacts = [
    {
      displayName: 'Smith, Tom'
    }
  ]

  const handleContactChange = (e, newItem) => {
    // console.log('Contact change: ', newItem)
    setRecipientList(newItem)
  }

  const senderOptions = () => {
    let options = [
      {
        firstName: 'The',
        lastName: 'Office',
        email: 'Office Email'
      }
    ]
    return options
  }

  const onEditorStateChange = (editorStateChange) => {
    // console.log(editorStateChange)
    setEditorState(editorStateChange)
  }

  const handleBlur = (data) => {
    // console.log(data)
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // console.log(html)
    setContentState(html)
    // props.updateContent(html)
  }
  const onContentStateChange = (contentState) => {
    // console.log(contentState)
    setContentState(contentState)
  }

  const handleVerifyEmail = (e) => {
    let email = e.target.value
    // console.log('Verify Email: ', email)
    let cleanEmail = email
    if (email.includes('@')) {
      cleanEmail = email.split('@')[0]
    }
    // console.log('clean email: ', cleanEmail)
    setState({...state, fromEmail: cleanEmail, emailValidated: true})
  }
  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
 
      <DialogTitle id="form-dialog-title">Send Estimate To Customer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please Choose Who Should Receive This Estimate
        </DialogContentText>

        {/* <LabelText>Choose/Enter The Sender (Who Is Sending This Email?):</LabelText>
      <FormControl fullWidth >
      <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            fullWidth
            options={senderOptions}
            // onChange={handleUpdateUOM}
            onChange={handleContactChange}
            disableCloseOnSelect
            style={{marginTop: 25}}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                 {option.firstName} {option.lastName} - {option.email}
              </React.Fragment>
            )}
            
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Recipient List" placeholder="Who Should Receive A Copy of This Estimate?" />
            )}
          />  */}

      {/* </FormControl> */}
      <LabelText>Choose/Enter The Name of the Receipient(s):</LabelText>
      <FormControl fullWidth >
      {/* <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            fullWidth
            options={props.contacts}
            // onChange={handleUpdateUOM}
            onChange={handleContactChange}
            // disableCloseOnSelect
            style={{marginTop: 25}}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                 {option.firstName} {option.lastName} - {option.email}
              </React.Fragment>
            )}
            
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Recipient List" placeholder="Who Should Receive A Copy of This Estimate?" />
            )}
          /> 
        New ONe */}
        <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        fullWidth
        options={props.contacts || []}
        // onChange={handleUpdateUOM}
        // onChange={handleContactChange}
        // // disableCloseOnSelect
        value={recipientList}
        // onInputChange={handleInputChange}
        onChange={(e, newValue) => {
        // console.log('EMail change 282', e.target.value)
        // console.log('New Value:', newValue)
          if (typeof newValue === 'string') {
          // console.log('283', newValue)
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
          // console.log('INPUTVALUE 286', newValue.inputValue)
          } else {
          // console.log('288 New Value', e.target.value)
            if (newValue && newValue.length) {
              let thisOne = newValue[newValue.length -1]
            // console.log('This ONe!', thisOne)
              if (thisOne && thisOne.email) {
                setRecipientList(newValue)
              } else {
              // console.log('New Email address', thisOne)
                // Verify its a legit email
                let isValidEmail = validateEmail(thisOne)
              // console.log('Valid email??', isValidEmail)
                if (isValidEmail) {
                  let newItem = {
                    firstName: thisOne,
                    lastName: '',
                    email: thisOne
                  }
                  let recips = recipientList
                  recips.push(newItem)
                // console.log('Updated recipient list', recips)
                  setRecipientList(recips)
                } else {
                  props.notification({
                    type: 'warning',
                    title: 'Invalid Email Address',
                    message: `Sorry, but ${thisOne} is not a valid email address.`
                  })
                }
                
              
              }
            } else {
            // console.log('Empty array')
              setRecipientList([])
            }
          }
        }}
        freeSolo
        style={{marginTop: 25}}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
              {option.firstName} {option.lastName} - {option.email}
          </React.Fragment>
        )}
        
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Recipient List" placeholder="Who Should Receive A Copy of This Email?" helperText={`After selecting at least one Contact, you can type in other email addresses. Press ENTER to save the email address (It Should Be In Grey)`} />
        )}
      /> 
      </FormControl>
      <LabelText>Choose/Enter The From Email Address:</LabelText>
      <FormControl fullWidth >
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
              variant="outlined"
              label="From Email Address"
              fullWidth
              select
              value={state.fromEmail}
              onChange={e=>setState({...state, fromEmail: e.target.value})}
              // onBlur={handleVerifyEmail}
            >
              <MenuItem value={`${props.user.email}`}>
                  Your Email ({`${props.user.email}`})
              </MenuItem>
              <MenuItem value={`sales@dflaz.com`}>
                  Sales Email (sales@dflaz.com)
              </MenuItem>
              <MenuItem value={`info@benhartlandscaping.com`}>
                  Benhart Email (info@benhartlandscaping.com)
              </MenuItem>
              <MenuItem value={`office@hmiofaz.com`}>
                  HMI Email (office@hmiofaz.com)
              </MenuItem>
              {
                (props.estimate && props.estimate.salesRep && props.estimate.salesRep.email) ? (
                  <MenuItem value={`${props.estimate.salesRep.email}`}>
                      Sales Rep Email ({`${props.estimate.salesRep.email}`})
                  </MenuItem>
                ) : null
              }
              
              </TextField>
          </Grid>
          {/* <Grid item lg={3} xs={12} style={{fontSize: 24, textAlign: 'left', marginTop: 5}}>
            @{props.entity.domain}
          </Grid> */}
        </Grid>
      </FormControl>
      { ((contact && contact._id) && recipientList.length && state.fromEmail) ? <FormControl fullWidth >

    
        <LabelText>Enter The Message You'd Like Them To Receive In The Email:</LabelText>
        <div style={{ border: '3px solid gray', marginTop: 10, marginBottom: 20, minHeight: 400}} >
        <Editor
          // initialContentState={content}
          editorState={editorState}
          spellCheck={true}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          // onContentStateChange={onContentStateChange}
          onBlur={handleBlur}
        />
        {/* <textarea
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          /> */}
        </div>
      </FormControl> : null }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          // type="submit"
          color="primary"
          onClick={handleSave}
          disabled={!state.fromEmail || (!contact || !contact._id) || (!recipientList || recipientList.length < 1)}
        >
          Send
        </Button>
      </DialogActions>
   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    user: state.user,
    contacts: state.contacts
  }
}

export default connect(mapStateToProps)(SendToCustomerModal)
