import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, Menu }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AssignedEntityComponent from '../Entities/AssignedEntityComponent'
import PhotoUploadComponent from '../Uploads/PhotoUploadComponent'
import QRCode from 'qrcode'
import RepairComponent from './RepairsComponent'
import AddRepairButton from './NewRepair'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  gridItem: {
    width: '800px'
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  }
}));

const EquipmentModal = (props) => {
  console.log('Got our Equipment Modal modal', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const categories = [
    'Monthly Maintenance', 'Landscapes', 'Specialty', 'Clean-Ups', 'Irrigation', 'Tree Trimming', 'Lighting', 'Pest Control', 'Water Feature', 'Flowers', 'Other'
  ]
  const [statementName, setStatementName] = React.useState('Nov2020')
  const [state, setState] = React.useState({
    equipment: {},
    isActive: false,
    assignedCrew: {},
    entity: {},
    qrCode: '',
    counter: 0,
    customQR: ''
  });

  const equipmentTypes = [
    'Vehicle',
    'Trailer',
    'Mower',
    'Trimmer',
    'Jackhammer',
    'Cell Phone',
    'Tablet',
    'Key',
    'Keycard',
    'Other'
  ]

  const divisions = [
    'Customer Service',
    'Specialty',
    'Irrigation and Lighting',
    'Maintenance',
    'Landscape Install',
    'Hardscape',
    'Pool/Spa',
    'Yard',
    'Commercial',
    'ADOT',
    'Maricopa County'
  ]

  React.useEffect(() => {
  // console.log('Use effect EQUIPMENT MODAL Details', props)
    // let service = props.service
    // let assignedCrew  
    let newState = {}
    if (props.equipment && props.equipment._id) {
      if (props.equipment.crew) {
        let ourIndex = props.crews.map(function (e) {
          return e._id
        }).indexOf(props.equipment.crew)
      // console.log('OUR CREW INDEX: ', ourIndex)
        newState.assignedCrew = props.crews[ourIndex]
      }
        newState.equipment = props.equipment
        newState.isActive = props.equipment.isActive
      // Set Entity
      // if (props.equipment.entity) {
      //   let entIndex = props.entities.findIndex(item => (item._id === props.equipment.entity))
      //   if (entIndex > -1) {
      //     newState.entity = props.entities[entIndex]
      //   }
      // }
     
    }
  // console.log('Set the state', newState)
    setState({...state, ...newState})
  }, [props.equipment]);

  React.useEffect(() => {
    if (state.equipment && state.equipment._id) {
      let qrURL = `https://dflportal.com/qr/equipment/${state.equipment._id}`
    // console.log('state.equipment changed - generate  a qr code!!')
      var opts = {
        errorCorrectionLevel: 'H',
        type: 'image/png',
        quality: 0.5,
        margin: 3,
        color: {
          dark: "#000", // "#516e76",
          light: "#FFF", // "#accacd80", // "#ACCACD"
        }
      }
    // console.log('Opts:', opts)
      QRCode.toDataURL(qrURL, opts, function (err, url) {
      // console.log('ERR:', err)
      // console.log('URL: ', url)
        if (err) throw err
      // console.log('Set the state:', url)
        setState({...state, qrCode: url, counter: state.counter++ })
      })
    }
  }, [state.equipment])

const handleSubmit = () => {
// console.log('Update this equipment!', state)
  setLoading(true)
  // console.log(props)
  let equip = state.equipment

// console.log('handle save', equip)
  let obj = {
    equipment: equip
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/equipment/updateEquipment`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update service ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved Changes to that Equipment!',
        message: 'Well Done!!'
      })
      // setState({...state,
      //   name: response.data.name,
      //   category: response.data.category || 0,
      //   description: response.data.description
      // })
      // handleCloseModal()
      // props.updateParent('update', response.data)
      handleCloseModal()
      // setOpen(false)
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: response.data.message
      })
    }
    
  }).catch(err => {
  // console.log('Error saving workorder: ', err)
  })
}

const handleCloseModal = () => {
  props.updateParent('closeModal')
}

const CloseButton = () => {
  return (
    <IconButton onClick={handleCloseModal} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
      <CloseIcon />
    </IconButton>
  )
}

const setServiceName = (name) => {
  setState({...state, name: name})
}

const updateEquipmentDivision = (id, value) => {
  // console.log('Update equipment: ', id)
// console.log('Value: ', value)
  setState({...state, equipment: {...state.equipment, division: value}})
}

const updateEquipmentType = (id, value) => {
  // console.log('Update equipment: ', id)
// console.log('Value: ', value)
  setState({...state, equipment: {...state.equipment, type: value}})
}

const updateAssignedCrew = (event, crew) => {
// console.log('Update crew')
// console.log('Event: ', event)
// console.log('Option: ', crew)
  if (crew && crew._id) {
    setState({
      ...state,
      assignedCrew: crew,
      equipment: {
        ...state.equipment,
        crew: crew._id,
        crewName: crew.name
      }
    })
  } else {
    setState({
      ...state,
      assignedCrew: {},
      equipment: {
        ...state.equipment,
        crew: null,
        crewName: 'None'
      }
    })
  }
  // console.log(event.target.id)
  // let split = event.target.id.split('-')
  // let crewNum = split[3]
  // let optIndex = split[1]
  // console.log('Crew Numb: ', crewNum)
  // console.log('Opt Index: ', optIndex)
  // // // console.log(state.jobDetails[optIndex])
  // // let jobDetail = state.service[optIndex]
  // let newCrew = props.crews[crewNum]
  // console.log('nEW Crew:', newCrew)
  // let newWO = state.workorder
  // newWO.crewNumber = newCrew['agValue']
  // console.log('New Crew: ', newCrew)
  // console.log('For this job: ', jobDetail)
  // let newJobDetails = state.jobDetails
  // let ourIndex = state.jobDetails.map(function (e) { 
  //   // console.log('WHHH?? ', e)
  //   return e.number
  // }).indexOf(jobDetail.number)
  // newJobDetails[ourIndex]['crew'] = newCrew
  // newJobDetails[ourIndex]['requiresUpdate'] = true
  // setState({...state, equipment: {...equipment, crew}})
}

const handleUpdateAssignedEntity = (newEntity) => {
  // Set employee entity
  if (newEntity && newEntity._id) {
    setState({...state, equipment: {...state.equipment, entity: newEntity._id }})
  } else {
    setState({...state, equipment: {...state.equipment, entity: null }})
  }
}

const handleUpdateResponse = (resp) => {
// console.log('We saved the photo to this euqipment...', resp)
  if (resp && resp.filesUploaded && resp.filesUploaded.length) {
    let ourFile = resp.filesUploaded[0]
    let item = {...state.equipment}
    item.pictureURL = ourFile.url
    setState({...state, equipment: item })
  }
}

const handleChangeStatus = (e) => {
  setState({...state, equipment: {...state.equipment, status: e.target.value }})
}
  const handleAddRepair = (data) => {
    console.log('Add Repair..', data)
  }

  return (
    <div>
        <Dialog
          // fullScreen={false}
          // maxWidth={'lg'}
          // width='lg'
          fullScreen
          // maxwidth='lg'
          // style={{width: '90hw'}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">Equipment Details -  {state.equipment.name}</DialogTitle>
          <DialogContent>
          <CloseButton />
            <img src={state.equipment.pictureURL} width="200" />
            <img src={state.qrCode} width="120" />
            <Grid container spacing={2} className={classes.gridItem}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <PhotoUploadComponent
                  message={`Click Here to Add a Photo (Or Drop Image File Here)`}
                  equipment={ state.equipment }
                  updateParent={handleUpdateResponse}
                />
            </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    value={state.equipment.status ? state.equipment.status : 'Equipment Status'}
                    onChange={handleChangeStatus}
                    inputProps={{ 'aria-label': 'Status' }}
                    fullWidth
                    select
                    label="Status"
                    variant="outlined"
                    placeholder="Equipment Status"
                  >
                    <MenuItem value={"In Service"}>
                      In Service
                    </MenuItem>
                    <MenuItem value={"Out for Repair"}>
                      Out for Repair
                    </MenuItem>
                    <MenuItem value={"On Customer Site"}>
                      On Customer Site
                    </MenuItem>
                    <MenuItem value={"On Loan"}>
                      On Loan
                    </MenuItem>
                  </TextField>
              </Grid>
              <Grid item xs={12}>
                <AddRepairButton 
                  equipmentId={state?.equipment._id} 
                  equipmentName={state?.equipment.name}
                  onAddRepair={handleAddRepair}
                />
              </Grid>
            <Grid item lg={3} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Number"
                    label="Number"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, number: e.target.value}})}
                    value={state.equipment.number}
                    fullWidth
                />
              </Grid>
              <Grid item lg={9} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Name"
                    label="Name"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, name: e.target.value}})}
                    value={state.equipment.name}
                    fullWidth
                />
              </Grid>
              {
                state.equipment.type === 'Cell Phone' ? (
                  <React.Fragment>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      autoFocus
                      className={classes.paddedStuff}
                      margin="dense"
                      id="Manufacturer"
                      label="Manufacturer"
                      type="text"
                      onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
                      value={state.equipment.manufacturer}
                      fullWidth
                  />
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      autoFocus
                      className={classes.paddedStuff}
                      margin="dense"
                      id="Model"
                      label="Model"
                      type="text"
                      onChange={(e) => setState({...state, equipment: {...state.equipment, model: e.target.value}})}
                      value={state.equipment.model}
                      fullWidth
                    />
                  </Grid>
                  
                  <Grid item lg={4} xs={12}></Grid>
                  <Grid item lg={4} xs={12}>
                    <TextField
                      autoFocus
                      className={classes.paddedStuff}
                      margin="dense"
                      id="Number"
                      label="Cell Number"
                      type="text"
                      onChange={(e) => setState({...state, equipment: {...state.equipment, phoneNumber: e.target.value}})}
                      value={state.equipment.phoneNumber}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <TextField
                      autoFocus
                      className={classes.paddedStuff}
                      margin="dense"
                      id="IMEI"
                      label="IMEI"
                      type="text"
                      onChange={(e) => setState({...state, equipment: {...state.equipment, imei: e.target.value}})}
                      value={state.equipment.imei}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <TextField
                      autoFocus
                      className={classes.paddedStuff}
                      margin="dense"
                      id="MAC"
                      label="MAC Address"
                      type="text"
                      onChange={(e) => setState({...state, equipment: {...state.equipment, mac: e.target.value}})}
                      value={state.equipment.mac}
                      fullWidth
                    />
                  </Grid>
              </React.Fragment>
                ) : ''
              }
              {
                (state.equipment.type === 'Vehicle' || state.equipment.type === 'Trailer' || state.equipment.type === 'Mower' || state.equipment.type === 'Trimmer' || state.equipment.type === 'Jackhammer') ? (
                  <React.Fragment>
               <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Year"
                    label="Year"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, year: e.target.value}})}
                    value={state.equipment.year}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Manufacturer"
                    label="Manufacturer"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
                    value={state.equipment.manufacturer}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                    <TextField
                      autoFocus
                      className={classes.paddedStuff}
                      margin="dense"
                      id="Make"
                      label="Make"
                      type="text"
                      onChange={(e) => setState({...state, equipment: {...state.equipment, make: e.target.value}})}
                      value={state.equipment.make}
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Model"
                    label="Model"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, model: e.target.value}})}
                    value={state.equipment.model}
                    fullWidth
                />
              </Grid>
              
              
              </React.Fragment>
                ) : '' }

              {
                (state.equipment.type === 'Vehicle' || state.equipment.type === 'Trailer') ? (
                  <React.Fragment>
                    <Grid item lg={6} xs={12}>
                        <TextField
                          autoFocus
                          className={classes.paddedStuff}
                          margin="dense"
                          id="Plate"
                          label="Plate"
                          type="text"
                          onChange={(e) => setState({...state, equipment: {...state.equipment, plate: e.target.value}})}
                          value={state.equipment.plate}
                          fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <TextField
                          autoFocus
                          className={classes.paddedStuff}
                          margin="dense"
                          id="Mileage"
                          label="Mileage"
                          type="text"
                          onChange={(e) => setState({...state, equipment: {...state.equipment, mileage: e.target.value}})}
                          value={state.equipment.mileage}
                          fullWidth
                      />
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <TextField
                          autoFocus
                          className={classes.paddedStuff}
                          margin="dense"
                          id="VIN"
                          label="VIN"
                          type="text"
                          onChange={(e) => setState({...state, equipment: {...state.equipment, vin: e.target.value}})}
                          value={state.equipment.vin}
                          fullWidth
                      />
                    </Grid>
                    {/* <Grid item lg={12} xs={12}>
                        <TextField
                          autoFocus
                          className={classes.paddedStuff}
                          margin="dense"
                          id="customQR"
                          label="QR Code"
                          type="text"
                          onChange={(e) => setState({...state, customQR: e.target.value, equipment: {...state.equipment, qrCode: e.target.value}})}
                          value={state.customQR}
                          fullWidth
                      />
                    </Grid> */}


                    
                  </React.Fragment>
                ) : null

              }
               {/* {
                (state.equipment.type === 'Key' || state.equipment.type === 'Keycard') ? (
                  <React.Fragment>
                    <Grid item lg={4} xs={12}>
                        <TextField
                          autoFocus
                          className={classes.paddedStuff}
                          margin="dense"
                          id="keytype"N
                          label="Type of Key"
                          type="text"
                          onChange={(e) => setState({...state, equipment: {...state.equipment, keyType: e.target.value}})}
                          value={state.equipment.keyType}
                          fullWidth
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <TextField
                          autoFocus
                          className={classes.paddedStuff}
                          margin="dense"
                          id="keytype"
                          label="Type of Key"
                          type="text"
                          onChange={(e) => setState({...state, equipment: {...state.equipment, keyType: e.target.value}})}
                          value={state.equipment.keyType}
                          fullWidth
                      />
                    </Grid>
                  </React.Fragment>
                ) : null
              } */}
              <Grid item lg={12} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Location"
                    label="Location"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, location: e.target.value}})}
                    value={state.equipment.location}
                    fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AssignedEntityComponent entity={state.equipment.entity} title={'Assigned Entity'} disabled={loading} user={props.user} updateParent={handleUpdateAssignedEntity} />
              </Grid>
              <Grid item lg={12} xs={12}>
              <Autocomplete
                id="Division"
                options={divisions}
                // getOptionLabel={(option) => option.name}
                // style={{ width: 200 }}
                onChange={updateEquipmentDivision}
                // onChange={ (e) => updateEquipment(e.target.id, e.target.value) }
                value = {state.equipment.division}
                // onChange={ e => setState({...state, equipment: {...state.equipment, division: e.target.value}, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Division" variant="outlined" />}
              />
              </Grid>
              <Grid item lg={12} xs={12}>
              <Autocomplete
                id="EquipmentType"
                options={props.equipmentTypes}
                // getOptionLabel={(option) => option.name}
                // style={{ width: 200 }}
                onChange={updateEquipmentType}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.equipment.type}
                // onChange={ e => setState({...state, equipment: {...state.equipment, equipmentType: e.target.value}, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Equipment Type" variant="outlined" />}
              />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Autocomplete
                  id="AssignedCrew"
                  options={props.crews}
                  getOptionLabel={(option) => option.name}
                  // style={{ width: 200 }}
                  onChange={updateAssignedCrew}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.assignedCrew}
                  // onChange={ e => setState({...state, equipment: {...state.equipment, equipmentType: e.target.value}, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Assigned Crew" variant="outlined" />}
                />
              </Grid>

              {/* <Grid item lg={12} >
                <TextField
                  id="Description"
                  label="Description"
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  // className={classes.textSaveLineBreaks}
                  value={state.equipment.description}
                  onChange={e => setState({...state, crew: {...state.equipment, description: e.target.value}})}
                  // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                  // defaultValue="Default Value"
              />


              </Grid> */}
              <Grid item lg={12} >
                <TextField
                  id="notes"
                  label="Notes"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  // className={classes.textSaveLineBreaks}
                  value={state.equipment.notes}
                  onChange={e => setState({...state, equipment: {...state.equipment, notes: e.target.value}})}
                  // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                  // defaultValue="Default Value"
              />


              </Grid>
              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Active Equipment</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isActive}
                      onChange={ e => setState({...state, isActive: !state.isActive, equipment: {...state.equipment, isActive: !state.isActive } }) }
                      name="Active" />}
                      label={state.isActive ? 'Is Active' : 'Inactive'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} >
              {/* <RepairComponent /> */}
            </Grid>
            
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(EquipmentModal)