import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import ListOfOpenEstimates from './ListOfOpenEstimates'
import { CircularProgress, Grid, Paper } from '@material-ui/core'
import ChooseEntityComponent from '../../Entities/ChooseEntityComponent'

const ProjectDashbaord = (props) => {
  const [state, setState] = React.useState({
    list: [],
    counter: 0
  })
  const [loading, setLoading] = React.useState(false)
  const [list, setList] = React.useState([])
  const [activeEntity, setActiveEntity] = React.useState(null)

  React.useEffect(() => {
    let isMounted = true
    const fetchEstimates = async () => {
      setLoading(true)
    // console.log('Fetch estimates!')
      try {
        const response = await axios.get(`${props.url}/api/proposal/getOpenEstimates`)
      // console.log('response data', response.data)
        setLoading(false)

        if (response && response.data && response.data.length && isMounted) {
          // console.log('Set our list:', response.data)
          // let cleanProposals = []
          // let num = 0
          // for (const item of response.data) {
          //   num++
          // // console.log('Item #', num)
          // // console.log(item)
          //   let contractor = false
          //   if (item && item.client && item.client.contractor) contractor = true
          //   item.client.contractor || false
          //   let line = {
          //     name: item.name,
          //     number: item.number,
          //     contractor: contractor,
          //     clientName: clientName,
          //     amount: item.totalPrice,
          //     created_by: item.created_by,
          //     created_at: item.created_at,
          //     status: item.status || '',
          //     last_update: item.updated_at || null,
          //     updated_by: item.updated_by ? .name || null
          //   }
          // // console.log('LINE:', line)
          //   cleanProposals.push(line)
          // }
          // console.log('set the state:', cleanProposals.length)
          setState({ list: response.data })
          setList(response.data)
        }
      } catch (e) {
      // console.log('Error pulling open estimates', e)
      }
    }
    fetchEstimates()
    return () => {
      isMounted = false
    }
  }, [])

  const handleResponseFromStatusUpdate = (data) => {
  // console.log('Handle Response:', data)
    let newList = state.list
    if (data && data._id) {
      if (data && data.lost) {
        // Filter this out
      // console.log('Lost')
        let index = newList.findIndex(item => (item._id === data._id))
      // console.log('index', index)
        if (index > -1) {
          newList.splice(index, 1)
        }
      } else {
      // console.log('Not lost')

        let index = newList.findIndex(item => (item._id === data._id))
      // console.log('index', index)
        if (index > -1) {
          newList[index] = data
          // setState({...state, list: newList })
        }
      }
    // console.log('Updat ethe list:', newList)
    }
    if (data && data.length) {
    // console.log('Go through and update each one!', data)
      for (const item of data) {
        if (item && item._id) {
          if (item && item.lost) {
            // Filter this out
          // console.log('Lost')
            let index = newList.findIndex(searchItem => (item._id === searchItem._id))
          // console.log('index', index)
            if (index > -1) {
              newList.splice(index, 1)
            }
          } else {
          // console.log('Not lost')
    
            let index = newList.findIndex(searchItem => (item._id === searchItem._id))
          // console.log('index', index)
            if (index > -1) {
              newList[index] = data
              // setState({...state, list: newList })
            }
          }
        }
      }
    }
  // console.log('Set New List', newList)
    setList(newList)
    setState({...state, list: newList, counter: state.counter++ })
  }

  const handleUpdateVisibleEntity = (activeEntity) => {
    setActiveEntity(activeEntity)
  }

  let theList = list
  if (activeEntity && activeEntity._id) {
    let filteredEstimates = list.filter(item => { return item.entity === activeEntity._id })
    theList = filteredEstimates
  }
  return (
    <div style={{ margin: 10 }}>
      {
        (props.user && props.user.entities && props.user.entities.length > 1) ? (
          <Paper style={{ padding: 20, margin: 10 }}>
            <Grid container >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {
                  loading ? <CircularProgress size={85} color="primary" /> : <ListOfOpenEstimates state={state} list={theList} updateParent={handleResponseFromStatusUpdate} />
                }
              </Grid>
            </Grid>

          </Paper>

        ) : ''
      }

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ProjectDashbaord)