import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Editor } from 'react-draft-wysiwyg';
// import {EditorState} from "draft-js";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { validateEmail } from '../../services/common'
import EmailIcon from '@material-ui/icons/Email';

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: '',
  subject: `DFLAZ Invoice`
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SendToCustomerModal = (props) => {
// console.log('EMAIL STATEMENT MODAL', props)
  const classes = useStyles();

  const [dialogValue, setDialogValue] = React.useState(defaultStateValues);
  const [state, setState] = React.useState({})
  const [recipientList, setRecipientList] = React.useState([])
  const [item, setItem] = React.useState({})
  const [open, toggleOpen] = React.useState(false);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [content, setContentState] = React.useState({})

  React.useEffect(() => {
  // console.log('PROPS CHANGED EMAIL STATEMENT', props)
    // setState({...state, name: props.item.name })
    // setDialogValue({...dialogValue, name: props.item.name, description: props.item.name})
    // let html = '<h1>Test</h1>'
    // setContentState(content)
    if (props.entity && props.entity._id) {
      const html = `<p></p><p></p><br/><br/><br/>${props.entity.name}<br />${props.entity.billingAddress}<br />${props.entity.billingCity}, ${props.entity.billingState}, ${props.entity.billingZip}<br />${props.entity.telephone}`;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setContentState(contentState)
        setEditorState(editorState)
      }
    }
    if (props.invoice && props.invoice._id) {
      setState({...state, subject: `DFLAZ Invoice #${props.invoice.invoiceNumber}`})
    }
  }, [props.invoice])

  const [tabOpenValue, setTabOpenValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setTabOpenValue(newValue);
  }

  const handleClose = () => {
    setDialogValue(defaultStateValues);

    props.handleCloseModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log('Send this shit!!!', recipientList)
    // console.log(dialogValue)
    // console.log(props)
    // // console.log(dialogValue)
    // // console.log('Got it all?')
    // handleClose();
    let data = {
      recipientList,
      invoice: props.invoice,
      client: props.client,
      emailContent: content,
      pdf: props.pdf,
      subject: state.subject
    }
    // console.log(data)
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/emailStatement`,
      data: data,
    }).then(response => {
      // console.log('Sent Email: ', response.data)
      if (response && response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Email Sent!',
          message: 'You did a great job!'
        })
        handleClose()
      }
      if (response && response.data && response.data.error) {
        props.notification({
          type: 'warning',
          title: 'System Error Sending Statement',
          message: response.data.message
        })
      }

    }).catch(err => {
      // // console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Send Statement!',
        message: err.message
      })
    })
  };

  const contacts = [
    {
      displayName: 'Smith, Tom'
    }
  ]

  const handleContactChange = (e, newItem) => {
  // console.log('Contact change: ', newItem)
  // console.log(e.target.value)
    if (e.target.value && e.target.value === 0) {
    // console.log('Got a contact', newItem)
      setRecipientList(newItem)
    } else {
    // console.log('Got a new email address')
      let theItem = {
        firstName: '',
        lastName: '',
        email: e.target.value
      }
      let newRecips = recipientList
      newRecips.unshift(theItem)
    // console.log('New list:', newRecips)
      setRecipientList(newRecips)
    }
  }

  const senderOptions = () => {
    let options = [
      {
        firstName: 'The',
        lastName: 'Office',
        email: 'Office Email'
      }
    ]
    return options
  }

  const onEditorStateChange = (editorStateChange) => {
    // console.log(editorStateChange)
    setEditorState(editorStateChange)
  }

  const handleBlur = (data) => {
    // console.log(data)
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // console.log(html)
    setContentState(html)
    // props.updateContent(html)
  }
  const onContentStateChange = (contentState) => {
    // console.log(contentState)
    setContentState(contentState)
  }

  const handleVerifyEmail = (e) => {
    let email = e.target.value
    // console.log('Verify Email: ', email)
    let cleanEmail = email
    if (email.includes('@')) {
      cleanEmail = email.split('@')[0]
    }
    // console.log('clean email: ', cleanEmail)
    setState({...state, fromEmail: cleanEmail, emailValidated: true})
  }

  const handleInputChange = (e, newInput) => {
  // console.log('New INput:', newInput)
  // console.log('Val:', e.target.value)
  }
  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.isModalOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
    <form onSubmit={handleSubmit}>
      <DialogTitle id="form-dialog-title">Email Statement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please Choose Who Should Receive This Email
        </DialogContentText>
      <LabelText>Choose/Enter The Name/Email of the Receipient(s):</LabelText>
      <FormControl fullWidth >
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        fullWidth
        options={props.contacts || []}
        // onChange={handleUpdateUOM}
        // onChange={handleContactChange}
        // // disableCloseOnSelect
        value={recipientList}
        // onInputChange={handleInputChange}
        onChange={(e, newValue) => {
        // console.log('EMail change 282', e.target.value)
        // console.log('New Value:', newValue)
          if (typeof newValue === 'string') {
          // console.log('283', newValue)
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
          // console.log('INPUTVALUE 286', newValue.inputValue)
          } else {
          // console.log('288 New Value', e.target.value)
            if (newValue && newValue.length) {
              let thisOne = newValue[newValue.length -1]
            // console.log('This ONe!', thisOne)
              if (thisOne && thisOne.email) {
                setRecipientList(newValue)
              } else {
              // console.log('New Email address', thisOne)
                // Verify its a legit email
                let isValidEmail = validateEmail(thisOne)
              // console.log('Valid email??', isValidEmail)
                if (isValidEmail) {
                  let newItem = {
                    firstName: thisOne,
                    lastName: '',
                    email: thisOne
                  }
                  let recips = recipientList
                  recips.push(newItem)
                // console.log('Updated recipient list', recips)
                  setRecipientList(recips)
                } else {
                  props.notification({
                    type: 'warning',
                    title: 'Invalid Email Address',
                    message: `Sorry, but ${thisOne} is not a valid email address.`
                  })
                }
                
              
              }
            } else {
            // console.log('Empty array')
              setRecipientList([])
            }
          }
        }}
        freeSolo
        style={{marginTop: 25}}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
              {option.firstName} {option.lastName} - {option.email}
          </React.Fragment>
        )}
        
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Recipient List" placeholder="Who Should Receive A Copy of This Email?" helperText="You can also enter an email address and press ENTER" />
        )}
      /> 

      </FormControl>
      <LabelText>Enter the Subject:</LabelText>
      <FormControl fullWidth >
        <TextField
        variant='outlined'
        value={state.subject}
        label="Email Subject"
        fullWidth
        onChange={e => setState({...state, subject: e.target.value })}
        helperText="What Should The Email Subject Be?"
        />
      </FormControl>
      <FormControl fullWidth >

    
        <LabelText>Enter The Message You'd Like Them To Receive In The Email:</LabelText>
        <div style={{ border: '3px solid gray', marginTop: 10, marginBottom: 20, minHeight: 400}} >
        <Editor
          // initialContentState={content}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
          // onContentStateChange={onContentStateChange}
          onBlur={handleBlur}
        />
        {/* <textarea
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          /> */}
        </div>
      </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={!recipientList.length || !content}
        >
          Send
        </Button>
      </DialogActions>
    </form>
   </Dialog>
  )
}

const EmailStatement = (props) => {
// console.log('Email Statement MOdal:', props)
  const [isSendStatementModalOpen, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleEmailStatement = () => {
    setOpen(true)
  }

  const handleCloseTheModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant="contained"
        disabled={loading}
        color="primary"
        size="small"
        startIcon={<EmailIcon />}
        onClick={handleEmailStatement}
      >
        { loading ? <CircularProgress color="primary" size={ 12 } /> : 'Email Statement'} 
      </Button>
      <SendToCustomerModal isModalOpen={isSendStatementModalOpen} {...props} handleCloseModal={handleCloseTheModal} />
    </>
  )
}
const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    user: state.user,
    contacts: state.contacts,
    client: state.client
  }
}

export default connect(mapStateToProps)(EmailStatement)
