import React from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SupplierComponent from '../Proposals/SupplierComponent'
import { Button, CircularProgress } from '@material-ui/core';
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AssignedEntityComponent from '../Entities/AssignedEntityComponent'
import SupplierPriceUpdateComponent from './SupplierPriceUpdateComponent'
import Chip from '@material-ui/core/Chip';
import heic2any from 'heic2any'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import IconButton from '@material-ui/core/IconButton';

const DeleteFileButton = (props) => {
  // console.log('Delete file button', props)
  const handleDelete = () => {
    const message = `Are you sure you wish to delete this upload? ${props.upload.name || ''} -- This is NOT reversible.`
    if (window.confirm(message)) {
      props.handleDelete(props.upload)
    }
  }
  return <Button startIcon={<DeleteForeverIcon />} size="small" variant="outlined" onClick={ handleDelete } style={{ textAlign: 'right', backgroundColor: 'rgba(213, 41, 12, 0.75)' }}>Delete</Button>
}
const docTypes = {
  'Vendor Quote': {
    number: {
      name: 'Quote Number',
      placeholder: 'Quote #2323',
      helperText: 'What Is The Quote Number?'
    },
    amount: {
      name: 'Quote Amount',
      placeholder: '23.34',
      helperText: 'How Much Is the Quote Total?'
    },
    vendor: {
      name: 'Vendor Name',
      placeholder: 'Please Choose The Vendor',
      helperText: 'Choose The Related Vendor'
    }
  },
  'Vendor Invoice': {
    number: {
      name: 'Invoice Number',
      placeholder: 'Invoice #2323',
      helperText: 'What Is The Invoice Number?'
    },
    amount: {
      name: 'Invoice Amount',
      placeholder: '$23.34',
      helperText: 'How Much Is the Invoice Total?'
    },
    vendor: {
      name: 'Vendor Name',
      placeholder: 'Please Choose The Vendor',
      helperText: 'Choose The Related Vendor'
    }
  },
  'Customer Invoice': {
    number: {
      name: 'Invoice Number',
      placeholder: 'Invoice #2323',
      helperText: 'What Is The Invoice Number?'
    },
    amount: {
      name: 'Invoice Amount',
      placeholder: '$23.34',
      helperText: 'How Much Is the Invoice Total?'
    }
  },
  'Purchase Order': {
    number: {
      name: 'PO Number',
      placeholder: 'PO #2323',
      helperText: 'What Is The PO Number?'
    },
    amount: {
      name: 'PO Amount',
      placeholder: '$23.34',
      helperText: 'How Much Is the PO Total?'
    },
    vendor: {
      name: 'Vendor Name',
      placeholder: 'Please Choose The Vendor',
      helperText: 'Choose The Related Vendor'
    }
  },
  'Photos': {
    number: {
      name: 'Photo Number',
      placeholder: 'Photo #2323',
      helperText: 'What Is The Photo Number?'
    }
  },
  'Plans': {
    number: {
      name: 'Plans Number',
      placeholder: 'Plans #2323',
      helperText: 'What Is The Plans Number?'
    }
  },
  'Estimates': {
    number: {
      name: 'Estimate Number',
      placeholder: 'Estimate #2323',
      helperText: 'What Is The Estimate Number?'
    },
    amount: {
      name: 'Estimate Amount',
      placeholder: '$23.34',
      helperText: 'How Much Is the Estimate Total?'
    }
  },
  'Other': {
    number: {
      name: 'Other Reference Number',
      placeholder: 'RefId #2323',
      helperText: 'What Is The Reference Number?'
    }
  }
}

const defaultInputRefValues = {
  documentType: null,
  notes: '',
  number: '',
  amount: 0,
  vendor: null,
  invoiceStatus: null,
  workorder: null,
  client: null,
  project: null
}

const defaultStateVals = {
  counter: 0,
  uploadType: '',
  invoiceStatus: '',
  notes: 'test',
  number: '',
  amount: 0,
  client: {},
  project: {},
  workorder: {},
  vendor: null,
  invoiceType: 'Client Project/Job',
  entity: {}
}

const UploadDetailsInputArea = (props) => {
  console.log('UPldoad Details: ', props)
  const inputRef = React.useRef({})
  const [state, setState] = React.useState(defaultStateVals)

  const [docTypeDetails, setDocTypeDetails] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [project, setProject] = React.useState({ name: '' })
  const [projects, setProjects] = React.useState([])
  const [job, setJob] = React.useState({ jobName: '' })
  const [jobs, setJobs] = React.useState([])
  const [activeEntity, setEntity] = React.useState({})
  const [purchaseOrder, setPurchaseOrder] = React.useState({})
  const [possibleUploadMatches, setPossibleUploadMatches] = React.useState([])
  const [showPossibleDupe, setShowPossibleDupe] = React.useState(false)
  const [pdf, setPDF] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true);
  const [purchaseOrders, setPurchaseOrders] = React.useState([]);
  
  React.useEffect(() => {
    setIsLoading(true);
    resetComponent();
    if (props.purchaseOrders && props.purchaseOrders.length > 0) {
      loadDataFromPurchaseOrder();
    }
    setIsLoading(false);
  }, [props.upload, props.purchaseOrders]);
  
  const loadDataFromPurchaseOrder = () => {
    console.log('Load Data from PO...196')
    if (props.upload && props.upload.purchaseOrder) {
      const po = props.purchaseOrders.find(po => po._id === props.upload.purchaseOrder);
      if (po) {
        console.log('Associated PO', po)
        updatePONumber(null, po);
      }
    }
  };

  const resetComponent = () => {
    console.log('Reset Component')
    setState(defaultStateVals);
    inputRef.current = defaultInputRefValues;
    setDocTypeDetails({});
    setLoading(false);
    setClient({});
    setProject({ name: '', _id: null });
    setProjects([]);
    setJob({ jobName: '' });
    setJobs([]);
    setEntity({});
    setPurchaseOrder({});
    setPossibleUploadMatches([]);
    setShowPossibleDupe(false);
    setPDF({});
  };

  const updateRef = (e) => {
    // console.log('UPdateREf: ', e.target.value)
    // console.log(e.target.id)
    inputRef.current[e.target.id] = e.target.value
    handleUpdateParent()
    setState({...state, [e.target.id]: e.target.value })
  }

  const updateDocTypes = (type) => {
    let details = docTypes[type]
    // console.log('Details: ', details)
    setDocTypeDetails(docTypes[type])
  }

  const handleDocumentChange = (e) => {
    updateDocTypes(e.target.value);
    inputRef.current.documentType = e.target.value;
    if (e.target.value === 'Vendor Invoice') {
      inputRef.current.invoiceType = 'Client Project/Job';
    } else {
      inputRef.current.invoiceType = '';
      inputRef.current.po = null;
      setPurchaseOrder({});
    }
    setState(prevState => ({
      ...prevState,
      uploadType: e.target.value,
      invoiceType: e.target.value === 'Vendor Invoice' ? 'Client Project/Job' : '',
    }));
    handleUpdateParent();
  };

  const updateCustomer = async (e, customer, setExistingProject, setThisJob) => {
    if (customer && customer._id) {
      setLoading(true);
      setClient(customer);
      inputRef.current.client = customer._id;
  
      try {
        const response = await axios.get(`${props.url}/api/projects/listProjects?clientId=${customer._id}`);
        if (response.data && response.data.projects) {
          setProjects(response.data.projects);
          if (setExistingProject) {
            const project = response.data.projects.find(p => p._id === setExistingProject._id);
            if (project) {
              updateProject(null, project);
            }
          }
        }
        if (response.data && response.data.jobs) {
          setJobs(response.data.jobs);
          if (setThisJob) {
            const job = response.data.jobs.find(j => j._id === setThisJob);
            if (job) {
              updateJob(null, job);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching customer data:', error);
      }
  
      setLoading(false);
    } else {
      setProject({ name: '', _id: null });
      setJob({ jobName: '' });
      setClient({ displayName: '', _id: null });
      inputRef.current.project = null;
      inputRef.current.client = null;
      inputRef.current.workorder = null;
    }
  };
  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      }
      if (e.target.id === 'purchaseorder') {
        let newArr = []
        let string = e.target.value
        props.purchaseOrders.filter(option => {
          let label = ''
          if (option && option.number) label += `PO #${option.number} for ${currencyFormat(option.amount)}`
          if (option.vendor && option.vendor._id) label += `       Vendor: ${option.vendor.name}`
          if (option.client && option.client._id) label += `       Client: ${option.client.displayName}`
                    
          let str = label.toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(option)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updatePONumber(string, newArr[0])
        }
      }
    }
  }

  const updateProject = (e, projectPassed) => {
    console.log('Update project', projectPassed)
    let newState = {}
    if (projectPassed && projectPassed._id) {
      setProject(projectPassed)
      newState.project = projectPassed
      inputRef.current.project = projectPassed._id
    } else {
      inputRef.current.project = null
      setProject({ name: '', _id: null })
      newState.project = null
    }
    console.log('Set State 353:', newState)
    setState(prevState => ({...prevState, ...newState}));
    // setState({...state, ...newState})
    handleUpdateParent()
  }

  const updateJob = (e, job) => {
    if (job && job._id) {
      setJob(job)
      inputRef.current.workorder = job._id
    } else {
      inputRef.current.workorder = null
      setJob({ name: '', _id: null })
    }
    handleUpdateParent()
  }

  const handleChosenSupplier = (vendor) => {
    // console.log('Our Vendor: ', vendor)
    if (vendor && vendor._id) {
      inputRef.current.vendor = vendor._id
      setState({...state, vendor: vendor })
    }
    handleUpdateParent()
  }

  const handleUpdateParent = () => {
  // console.log('Update Praent:: 396', inputRef.current)
   props.updateParent(inputRef.current)
  }

  const updateStatus = (e) => {
    // console.log('Update status:', e)
    // console.log(e.target.id)
    // console.log(e.target.value)
    inputRef.current.status = e.target.value
    handleUpdateParent()
    setState({...state, invoiceStatus: e.target.value })
  }

  const closeSupplierModal = () => {
    // console.log('Close Supplier Modal')
  }

  function currencyFormat (num) {
    if (!num) num = 0
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const updateInvoiceType = (e) => {
    // console.log('Update Invoice type: ', e.target.value)
    inputRef.current.invoiceType = e.target.value
    // handleUpdateParent()
    setState({...state, invoiceType: e.target.value })
  }



  const fetchPDF = async (url, type) => {
    // console.log('FETCH: ', url)
    let cleanURL = ''
    if (url.includes('https')) {
      cleanURL = url
    } else {
      cleanURL = `https://${url}`
    }
    // console.log('CLEAN: ', cleanURL)
    await axios.get(cleanURL, {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/octet-stream',
        'Accept': 'application/pdf, application-octet-stream'
      },
      responseType: 'blob'
    }).then((response) => {
    // }).then(response => response.arrayBuffer())
    //   .then(blob => {
    //   // console.log('Blob: ', blob)
        // let body = response.data
        // let base64Version = Buffer.from(body).toString('base64')
        // console.log('Base 64', base64Version)
        // // let blob = response.data.blob()
        // let newStuff = 'data:application/pdf;base64,' + base64Version
        // console.log(newStuff)
        // console.log('response:', response)
        if (type === 'pdf') {
          let newBlob = new Blob([response.data], {type: "application/pdf"})
          // console.log(newBlob)
          const data = window.URL.createObjectURL(newBlob);
          // console.log(data)
          // var link = document.createElement('a');
          // link.href = data;
          // link.download="file.pdf";
          // link.click();
          setPDF(data)
        }
        if (type === 'heic') {
        // console.log('WE got our data...now we need to get a buffer', response.data)
          let newBlob = new Blob([response.data], {type: "image/heic"})
          heic2any({
            blob: newBlob,
            toType: "image/jpeg",
            quality: 0.7
          })
          .then((result) => {
          // console.log('HEIC Response...')
              let reader = new FileReader();
              reader.onload = function(event) {
                  let base64String = event.target.result;
                // console.log('Base 64...', base64String)
                  setPDF(base64String)
                  // Set as src of img tag
                  // document.getElementById("myImage").src = base64String;
              };
              reader.readAsDataURL(result);
          })
          .catch((error) => {
              console.error(error);
          });
          // console.log('Got a blob!!!!', newBlob)
          // let base64Version = Buffer.from(newBlob).toString('base64')
          // console.log('Base64:', base64Version)
         
          // // console.log(newBlob)
          // const data = window.URL.createObjectURL(newBlob);
          // setPDF(data)
        }
      })
    // console.log(response)
    // if (response && response.data) {
    // }
  }

  const verifyMatchingUploads = async (po) => {
    // Find the vendor invoice associated with this po and open it up
    const response = await axios.get(`${props.url}/api/uploads/getUploadsForPO?id=${po._id}`)
    console.log("Uploads found!!", response)
    if (response.data && response.data.length) {
      setPossibleUploadMatches(response.data)
      // const uploads = props.uploads
      // console.log('Got %d uploads', uploads.length)
      // console.log(uploads)
      // const upload = uploads[0]
      // let theURL = ''
      // if (upload && upload.url) {
      //     theURL = upload.url
      //   if (theURL && theURL.includes('https://')) {
      //     // console.log('Hooray!!', theURL)
      //   } else {
      //     theURL = `https://${upload.url}`
      //   }
      // }
      // if (upload && upload.type === 'application/pdf') {
      //   // console.log('Display our PDF')
      //   fetchPDF(theURL, 'pdf')
      // } else if (props.upload && props.upload.name && (props.upload.name.toLowerCase().includes('heic'))) {
      // // console.log('fetch the HEIC!!')
      //   fetchPDF(theURL, 'heic')
      // }
    }
  }

  const updatePONumber = (e, po) => {
    console.log('Update PO Number...', po)
    if (po && po._id) {
      const newState = {
        uploadType: 'Vendor Invoice',
        invoiceType: po.poType || 'Client Project/Job',
        vendor: po.vendor,
        amount: po.amount,
        number: po.invoiceNumber,
        notes: po.description,
        entity: po.entity,
        client: po.client,
        project: po.project,
      };
      console.log('528:::', newState)
      setState(prevState => ({...prevState, ...newState}));
      
      inputRef.current = {
        ...inputRef.current,
        po: po._id,
        vendor: po.vendor?._id,
        amount: po.amount,
        number: po.invoiceNumber,
        notes: po.description,
        invoiceType: po.poType,
        entity: po.entity?._id,
        entityName: po.entity?.name,
        client: po.client?._id,
        project: po.project?._id,
      };
  
      if (po.client) {
        updateCustomer(null, po.client, po.project, po.workorder);
      }
  
      setPurchaseOrder(po);
      setEntity(props.entities.find(e => e._id === po.entity));
    }
  };
  
const handleUpdateVisibleEntity = (activeEntity) => {
  // console.log('Entity to assigN: ', activeEntity)
  inputRef.current.entity = activeEntity._id
  setEntity(activeEntity)
  setState({...state, entity: activeEntity._id })
}

const handleUpdateAssignedEntity = (entity) => {
// console.log('Update assigend entit:', entity)
  inputRef.current.entity = entity._id
  setEntity(entity)
  setState({...state, entity: entity._id })
}

const handleDeleteUpload = (data) => {
  // console.log('Delete this bitch!!', data)
  
  props.handleDeleteUpload(data)
}

const handleIt = () => {
  setPossibleUploadMatches([])
}

const yesTheyMatch = () => {
  // Open a modal and ask them which one to keep
  setShowPossibleDupe(true)
}

const handleChooseFile = (fileChosen) => {
  console.log('Choose file:', fileChosen)
  if (fileChosen === 'Right') {
    // Po as it is is all good - delete the bulk upload we are on???
    props.handleDeleteUpload(props.upload)
  }
  if (fileChosen === 'Left') {
    // PO needs the other file on it
    props.swapUploadOnPO(purchaseOrder, props.upload)
  }
  setShowPossibleDupe(false)
  handleIt()
  // Delete the one upload
  // Assign the other uplaod as teh PO upload

}

const handleCloseChooseModal = () => {
  setShowPossibleDupe(false)
}

  // if (possibleUploadMatches && possibleUploadMatches.length) {
  //   return (
  //     <Grid container spacing={1}>
  //       <ChooseWhichImageToKeep open={showPossibleDupe} chooseFile={handleChooseFile} closeModal={handleCloseChooseModal} />
  //       {/* <DeleteFileButton upload={props.upload} handleDelete={handleDeleteUpload} /> */}
  //       <Grid item lg={12} md={12} sm={12} xs={12}>
  //         <FormControl fullWidth >
  //           <InputLabel >Do These Match</InputLabel>
  //           <Button onClick={yesTheyMatch} >Yes</Button>
  //           <Button onClick={handleIt}>No</Button>
  //         </FormControl>
  //       </Grid>
  //       <Grid item lg={12} md={12} sm={12} xs={12}>
  //         <OtherUploadsImage uploads={possibleUploadMatches} />      
                
  //       </Grid>
  //     </Grid>
  //   )
  // } else {

    return (
      <Grid container spacing={1}>
        <DeleteFileButton upload={props.upload} handleDelete={handleDeleteUpload} />
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FormControl fullWidth >
            <InputLabel >Document Type</InputLabel>
            <Select
              id="uploadType"
              value={state.uploadType || ''}
              onChange={handleDocumentChange}
              // onBlur={handleUpdateParent}
              // variant="outlined"
              // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
            >
              <MenuItem value={'Vendor Quote'}>Vendor Quote</MenuItem>
              <MenuItem value={'Vendor Invoice'}>Vendor Invoice</MenuItem>
              <MenuItem value={'Customer Invoice'}>Customer Invoice</MenuItem>
              <MenuItem value={'Purchase Order'}>Purchase Order</MenuItem>
              <MenuItem value={'Photos'}>Photos</MenuItem>
              <MenuItem value={'Plans'}>Plans</MenuItem>
              <MenuItem value={'Estimates'}>Estimates</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
            <FormHelperText>What Type Of File Is This?</FormHelperText>
          </FormControl>
        </Grid>
        {
              state.uploadType === 'Vendor Invoice' ? (
                <React.Fragment>
                  <Grid item lg={12} sm={12}>
                    <Autocomplete
                      id={`purchaseorder`}
                      options={props.purchaseOrders || []}
                      // renderOption={(option) => {
                      //   // console.log('Render Option!!', option)
                      //   let bgColor = '#fff'
                      //   if (option.status === 'Approved') bgColor = '#312'
                      //   if (option.status === 'Pending Approval') bgColor = '#228'
                      //   if (option.status === 'Reconciled') bgColor = 'red'
                      //   if (option.status === 'Denied') bgColor = 'pink'
                      //   let label = ''
                      //   if (option && option.number) label += `PO #${option.number} for ${currencyFormat(option.amount)}`
                      //   if (option.project && option.project._id) label += `  Project: ${option.project.name}`
                      //   if (option.vendor && option.vendor._id) label += `       Vendor: ${option.vendor.name}`
                      //   if (option.client && option.client._id) label += `       Client: ${option.client.displayName}`
                      //   return (
                      //     <React.Fragment>
                      //   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: '2px solid #ccc', borderColor: '#ccc', width: '100%', padding: 15, borderRadius: 10, margin: 5 }}>
                      //   <div>
                      //     {label}
                      //   </div>
                      //   <div>
                      //     {
                      //         option.status === 'Reconciled' ? <Chip style={{color: '#fff', background: '#39a33d', marginLeft: 10 }} size="small" label="Reconciled" /> : null
                      //       }
                      //       {
                      //         option.status === 'Pending Approval' ? <Chip style={{color: '#fff', background: '#808080', marginLeft: 10 }} size="small" label="Pending Approval" /> : null
                      //       }
                      //       {
                      //         option.status === 'Approved' ? <Chip style={{color: '#fff', background: '#3B719F', marginLeft: 10 }} size="small" label="Approved" /> : null
                      //       }
                      //       {
                      //         option.status === 'Denied' ? <Chip style={{color: '#fff', background: '#911', marginLeft: 10 }} size="small" label="Denied" /> : null
                      //       }
                      //     </div>
                        
                      //   </div>
                      //   </React.Fragment>
                      //   )

                      // }}
                      getOptionLabel={(option) => {
                        // console.log('PO Options: ', option)
                        let label = ''
                        if (option && option.number) label += `PO #${option.number} for ${currencyFormat(option.amount)}`
                        if (option.project && option.project._id) label += `  Project: ${option.project.name}`
                        if (option.vendor && option.vendor._id) label += `       Vendor: ${option.vendor.name}`
                        if (option.client && option.client._id) label += `       Client: ${option.client.displayName}`
                        label += '   -- Status: ' + option.status
                        return label
                      }}
                      fullWidth
                      // style={{ width: 200 }}
                      // style={{marginBottom: '20px'}}
                      onChange={updatePONumber}
                      disabled={loading}
                      // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                      value = {purchaseOrder}
                      // ref={inputRef.client}
                      // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                      renderInput={(params) => <TextField {...params} label="PO Number" helperText="Do You Have a PO Number?" onKeyDown={handleTabInteraction} />}
                    />
                  </Grid>
                  
                  
                  <Grid item lg={12} sm={12}>
                    <TextField
                        select
                        id="invoiceType"
                        value={state.invoiceType}
                        style={{ marginTop: 10 }}
                        label="Invoice Type"
                        onChange={updateInvoiceType}
                        fullWidth
                        // onChange={(e) => setState({...state, invoiceType: e.target.value })}
                      >
                        <MenuItem value={'Client Project/Job'}>Client Project/Job</MenuItem>
                        {/* <MenuItem value={'Yard Stock'}>Yard Stock</MenuItem> */}
                        <MenuItem value={'Tools'}>Tools</MenuItem>
                        <MenuItem value={'Inventory'}>Inventory</MenuItem>
                        <MenuItem value={'Marketing'}>Marketing</MenuItem>
                        <MenuItem value={'Repair'}>Repair</MenuItem>
                        <MenuItem value={'Office'}>Office</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                      </TextField>
                  </Grid>
              </React.Fragment>
              ) : null
        }
    


            <Grid item lg={12} md={12} sm={12} xs={12}>
              <AssignedEntityComponent entity={activeEntity} removeOutline={true} title={'Assigned Entity'} disabled={loading} user={props.user} updateParent={handleUpdateAssignedEntity} />
            </Grid>
            <Grid item lg={12} sm={12}>
              <SupplierComponent label="Vendor Name" vendors={props.vendors} vendor={state.vendor} placeholder="Vendor Name" helperText="Which Vendor Is This From/For?" single={true} closeModal={closeSupplierModal} updateParent={handleChosenSupplier} />
            </Grid>
            {/* {
              docTypeDetails['vendor'] ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id={`vendorname`}
                    options={props.vendors || []}
                    getOptionLabel={(option) => {
                      // console.log('Options: ', option)
                      return option.name || ''
                    }}
                    fullWidth
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateCustomer}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {client}
                    // ref={inputRef.client}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Vendor" helperText="Who Is The Vendor?" onKeyDown={handleTabInteraction} />}
                  />
                </Grid>
              ) : null
            } */}
            {
              (state.uploadType === 'Vendor Invoice' && (state.vendor && state.vendor._id)) ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SupplierPriceUpdateComponent  vendors={props.vendors} vendor={state.vendor} sizes={props.sizes} />
                </Grid>
              ) : null 
            }
              <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id={`customername`}
                    options={props.clients || []}
                    getOptionLabel={(option) => {
                      // console.log('Options: ', option)
                      return option.displayName || ''
                    }}
                    fullWidth
                    // getOptionSelected={React.useCallback((option, value) => option.value === value.value)}
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateCustomer}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {client}
                    // ref={inputRef.client}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} />}
                  />
                </Grid>
            {/* {
              (state.invoiceType === 'Client Project/Job' || state.uploadType === 'Photos' || state.uploadType === 'Vendor Quote' || state.uploadType === 'Other' || state.uploadType === 'Estimates' || state.uploadType === 'Plans' ) ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id={`customername`}
                    options={props.clients || []}
                    getOptionLabel={(option) => {
                      // console.log('Options: ', option)
                      return option.displayName || ''
                    }}
                    fullWidth
                    // getOptionSelected={React.useCallback((option, value) => option.value === value.value)}
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateCustomer}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {client}
                    // ref={inputRef.client}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} />}
                  />
                </Grid>
              ) : null
            } */}
            
        {
          loading ? (
            <CircularProgress color="primary" size={ 24 } />
          ) : (client && projects && projects.length) ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                id={`projectname`}
                options={projects || []}
                getOptionLabel={(option) => {
                  // console.log('Options: ', option)
                  return `${option.name}` || ''
                }}
                fullWidth
                // style={{ width: 200 }}
                // style={{marginBottom: '20px'}}
                onChange={updateProject}
                disabled={loading}
                // onBlur={handleUpdateParent}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.project}
                // ref={inputRef.client}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Project" helperText="Which Project?" onKeyDown={handleTabInteraction} />}
              />
            </Grid>
            ) : null
          }
          {
            (client && jobs && jobs.length) ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                id={`job`}
                options={jobs || []}
                getOptionLabel={(option) => {
                  // console.log('Options: ', option)
                  return `${option.jobName ? option.jobName : ''} ${option.number ? ` (${option.number})` : ''} ${option.scheduledDate ? ` - ${moment(option.scheduledDate).format('MM/DD/YY [at] hh:mma')}` : ''}` || ''
                }}
                fullWidth
                // style={{ width: 200 }}
                // style={{marginBottom: '20px'}}
                onChange={updateJob}
                disabled={loading}
                // onBlur={handleUpdateParent}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {job}
                // ref={inputRef.client}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Workorder" helperText="Which Workorder?" onKeyDown={handleTabInteraction} />}
              />
            </Grid>
            ) : null
          }
        <Grid item lg={12} md={12} sm={12} xs={12}>
        {
            docTypeDetails['number'] ? (
            <TextField
                    id="number"
                    label={docTypeDetails['number']['name']}
                    // style={{ margin: 8 }}
                    // value={inputRef.projectName}
                    onChange={updateRef}
                    // onChange={e=> setState({...state, projectName: e.target.value})}
                    // ref={inputRef['projectName']}
                    placeholder={docTypeDetails['number']['placeholder']}
                    helperText={docTypeDetails['number']['helperText']}
                    fullWidth
                    // onBlur={handleUpdateParent}
                    value={state.number}
                    // value={inputRef.current['projectName']}
                    margin="normal"
                    // variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            ) : null
          }
                </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            docTypeDetails['amount'] ? (
              <TextField
                    id="amount"
                    label={docTypeDetails['amount']['name']}
                    // style={{ margin: 8 }}
                    // value={inputRef.projectName}
                    onChange={updateRef}
                    // onChange={e=> setState({...state, projectName: e.target.value})}
                    // ref={inputRef['projectName']}
                    placeholder={docTypeDetails['amount']['placeholder']}
                    helperText={docTypeDetails['amount']['helperText']}
                    fullWidth
                    value={state.amount}
                    // onBlur={handleUpdateParent}
                    // value={inputRef.current['projectName']}
                    margin="normal"
                    // variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            ) : null
          }
        </Grid>
        {
          state.uploadType === 'Vendor Invoice' ? (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth >
                <InputLabel >Invoice Status</InputLabel>
                <Select
                  id="status"
                  // onBlur={handleUpdateParent}
                  value={state.invoiceStatus}
                  // onChange={e => setState({...state, invoiceStatus: e.target.value })}
                  onChange={updateStatus}
                  // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                >
                  <MenuItem value={'Due'}>Due</MenuItem>
                  <MenuItem value={'Paid'}>Paid</MenuItem>
                </Select>
                <FormHelperText>Has The Invoice Been Paid?</FormHelperText>
              </FormControl>
            </Grid>
          ) : null
        }
        <Grid item lg={12} md={12} sm={12} xs={12}>

          <TextField
                id="notes"
                label={'Notes'}
                // style={{ margin: 8 }}
                // value={inputRef.projectName}
                onChange={updateRef}
                // onBlur={handleUpdateParent}
                // onChange={e=> setState({...state, projectName: e.target.value})}
                // ref={inputRef['projectName']}
                placeholder={'Notes About This File'}
                helperText={'What Is This File?'}
                fullWidth
                value={state.notes}
                // value={inputRef.current['projectName']}
                margin="normal"
                // variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />

        </Grid>

        
      </Grid>
    )
  
}

const ChooseWhichImageToKeep = (props) => {
  const [message, setMessage] = React.useState('')
  const messageBeginning = 'Keeping the File on the ' 
  const handleLeft = () => {
    // props.chooseFile('left')
    setMessage('Left')
  }
  const handleClose = () => {
    props.closeModal()
  }
  const handleRight = () => {
    // props.chooseFile('right')
    setMessage('Right')
  }

  const handleIt = () => {
    props.chooseFile(message)
  }
  return (
    <Dialog
      size="md"
      onClose={handleClose}
      open={props.open}
      fullWidth
    >
      <DialogTitle style={{ textAlign: 'center' }}>Choose File to Keep</DialogTitle>
      {
        (message && message.length) ? (
          <div style={{ textAlign: 'center' }}>
            {`${messageBeginning}${message}`}
            <div>
              <Button variant="contained" color="primary" onClick={handleIt}>CONFIRM</Button>
            </div>
          </div>
        ) : null
      }
     
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 15, margin: 20 }}>
        <div>
          <IconButton onClick={handleLeft}><ThumbUpIcon size={128} /></IconButton>
        </div>
        <div>
        <IconButton onClick={handleRight}><ThumbUpIcon size={128} /></IconButton>
        </div>
      </div>
    </Dialog>
  )
}

// const OtherUploadsImage = (props) => {
//   const pdf = props.pdf
//   const upload = props.upload

//   // if (!pdf && theURL) fetchPDF(theURL)
//   return (
//     <div>
//       Uploaded: {moment(upload.created_at).format('MM/DD/YY hh:mma')}
//         {
//             (upload && upload.type && upload.type.includes('pdf')) ? 
//               <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="900px" /> : 
//                 (upload && upload.type && upload.type.includes('image')) ?  <img src={theURL} style={{ width: '100%' }} /> : null
                
//           }
//           {
//             (upload && upload.name && (upload.name.toLowerCase().includes('heic'))) ?  <img src={pdf} style={{ width: '100%' }} /> : null
//           }
//           {
//             (upload && upload.type && (upload.type.includes('csv') || upload.type.includes('office'))) ? <iframe width="100%" height="900px" src={`https://docs.google.com/gview?url=${upload.url}&embedded=true`}></iframe> : null
//           }
//     </div>
//   )
// }


  

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    entities: state.entities
  }
}

export default connect(mapStateToProps)(UploadDetailsInputArea)