import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Permissions from './Permissions'
import { Button, CircularProgress } from '@material-ui/core';
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  width: 100%;
  margin-top 20px;
`;

const DefaultButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items; center;
  justify-content: center;
  margin: 10;
  padding: 10;
`;

const DefaultButton = styled(Box)`
  display: flex;
  alignSelf: stretch;
  margin: 10;
  padding: 10;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: '100%',
    height: 250,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const AssignedEntityComponent = (props) => {
// console.log('USER ASSIGNED ENTITY', props)
  // let entityName = ''
  // if (props.userProfile && props.userProfile.defaultEntity && props.userProfile.defaultEntity.internalName) entityName = props.userProfile.defaultEntity.internalName
  let assignedEntities = []
  if (props.userProfile && props.userProfile.entities) assignedEntities = props.userProfile.entities
  const classes = useStyles();
  const [state, setState] = React.useState({
    counter: 0
  })
  const [profile, setProfile] = React.useState({})
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [entities, setEntities] = React.useState(assignedEntities)
  const [availableEntities, setAvailableEntities] = React.useState([])
  const [leftChecked, setLeftChecked] = React.useState([])
  const [rightChecked, setRightChecked] = React.useState([])

  React.useEffect(() => {
  // console.log('USER PROFILE CHANGED ASSIGNED ENTITY')
    if (props.userProfile && props.userProfile.entities && props.userProfile.entities.length) {
      setEntities(props.userProfile.entities)
      setProfile(props.userProfile)
    }
    if (props.entities) {
      // setAvailableEntities(props.entities)
      updateAvailableEntities(props.entities)
    } else {
      // setAvailableEntities([])
      updateAvailableEntities([])
    }
  }, [props.userProfile])

  // React.useEffect(() => {
  // // console.log('PROPS.entities changed')
  //   if (props.entities) {
  //     setAvailableEntities(props.entities)
  //     updateAvailableEntities(props.entities)
  //   }
  // }, [props.entities])

  // React.useEffect(() => {
  //   setAvailableEntities(props.entities)
  // }, [props])

 const updateAvailableEntities = (availEntities) => {
  // console.log('Entities has changed!!!', availEntities)
    let userEntities = assignedEntities
  // console.log('User Entities', userEntities)
    let entitiesToShowAsAvailable = availEntities
    for (const item of userEntities) {
      const index = entitiesToShowAsAvailable.findIndex(entity => {
        if (item._id === entity._id) {
          return entity
        }
      })
    // console.log('Entity Index: ', index)
    // console.log(item)
      if (index > -1) {
        entitiesToShowAsAvailable.splice(index, 1)
      }
    }
    let ids = entitiesToShowAsAvailable.filter(item => { return item._id })
  // console.log('Set LEft', ids)
    setLeft(ids)
    let assignedIds = entities.filter(item => { return item._id })
  // console.log('Set Right', assignedIds)
    setRight(assignedIds)
  // console.log('Available:', entitiesToShowAsAvailable)
    setAvailableEntities(entitiesToShowAsAvailable)
    setEntities(userEntities)
    setState({...state, counter: state.counter++})
  }

  // let leftChecked = intersection(checked, left);
  // let rightChecked = intersection(checked, right);

  // console.log('Left Checked: ', leftChecked)
  // console.log('Right Checked: ', rightChecked)
  // console.log('Left: ', left)
  // console.log("Right: ", right)
  // console.log('Checked: ', checked)

  const handleToggle = (value) => () => {
  // console.log('Toggle: ', value)
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
  // console.log('New Checked: ', newChecked)

    let ids = availableEntities.map(item => { return item._id })
    let idsOfAssignedEntities = entities.map(item => { return item._id })
    // Set Right and Left
  // console.log('ids', ids)
    setLeftChecked(intersection(newChecked, ids))
    setRightChecked(intersection(newChecked, idsOfAssignedEntities))
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    // setRight(right.concat(left));
    let newEnts = entities.concat(availableEntities)
    setEntities(newEnts)
    setAvailableEntities([]);
    saveEntities(newEnts)
  };

  const handleCheckedRight = () => {
  // console.log('Handle CHeck Right: ', entities)
  // console.log('Left Checked: ', leftChecked)
    let newLeft = availableEntities
    let newRight = entities
    // Set our Left Box
    for (const item of leftChecked) {
    // console.log(item)
      let index = newLeft.findIndex(ent =>  (ent['_id'] === item));
      // let index = -1
      // availableEntities.findIndex((ent, index) => { 
      //   // console.log(ent._id)
      //   // if (ent._id === item) {
      //   // // console.log('match')
      //   //   return ent
      //   // }
      //   (parseInt(ent._id.toString() === item.toString()))
      // })
    // console.log("INDEX", index)
      if (index > -1) {
        let itemToMove = availableEntities[index]
        newRight.push(itemToMove)
        newLeft.splice(index, 1)
      // console.log('Updated Left: ', newLeft)
        setAvailableEntities(newLeft)
      }
    }
    setLeftChecked([])
  // console.log('New Right: ', newRight)
    setEntities(newRight)
    // setRight(right.concat(leftChecked));
    // setLeft(not(left, leftChecked));
    setChecked([]);
    saveEntities(newRight)
    // updateAvailableEntities(availableEntities)
  };

  const handleCheckedLeft = () => {
  // console.log('CHeck Left')
  // console.log('RIGHT CHECKED', rightChecked)
    let newLeft = availableEntities
    let newRight = entities
    // Set our Left Box
    for (const item of rightChecked) {
    // console.log(item)
      let index = newRight.findIndex(ent =>  (ent['_id'] === item));
      // let index = -1
      // availableEntities.findIndex((ent, index) => { 
      //   // console.log(ent._id)
      //   // if (ent._id === item) {
      //   // // console.log('match')
      //   //   return ent
      //   // }
      //   (parseInt(ent._id.toString() === item.toString()))
      // })
    // console.log("INDEX", index)
      if (index > -1) {
        let itemToMove = newRight[index]
        newLeft.push(itemToMove)
        newRight.splice(index, 1)
      // console.log('Updated Left: ', newLeft)
        setAvailableEntities(newLeft)
      }
    }
    // setLeftChecked([])
    setRightChecked([])
  // console.log('New Right: ', newRight)
    setEntities(newRight)
    // setRight(right.concat(leftChecked));
    // setLeft(not(left, leftChecked));
    setChecked([]);
    saveEntities(newRight)
    // setEntities(entities.concat(rightChecked));
    // setRight(not(right, rightChecked));
    // setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    // console.log('entities', entities)
    // console.log('Available: ', availableEntities)
    let newAvail = availableEntities.concat(entities)
    // console.log('New Avail: ', newAvail)
    setEntities([]);
    setAvailableEntities(newAvail);
    saveEntities([])
  };

  const saveEntities = (ents) => {
    // console.log('Save this shit')
    // setTimeout(() => {
    props.saveEntities(ents)
    // }, 2500)
  }

  const CustomList = ({ list, name }) => {
  // console.log('Custom List: ', name)
  // console.log('list', list)
    return (
    <Paper className={classes.paper}>
      <List component="div" role="list">
        {list.map((item, index) => {
          const labelId = `transfer-list-item-${index}-label`;
          
          // console.log('To Include:', toInclude)
          // console.log('Admin... entitiy:', admin.entities)
          // let entityList = []
          // // admin.entities.map(item => {
          // //   entityList.push(item._id)
          // // })
          // // console.log('Entitiy List:', entityList)
          // // console.log('# of entities on admin', admin.entities.length)
          // // console.log('List of items...', list)
          // const toInclude = entityList.includes(item._id)
          return (
            <div key={index}>
            {
              // toInclude ? (
                <ListItem key={index} role="listitem" button onClick={handleToggle(item._id)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(item._id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={item.internalName ? item.internalName : item.name} />
                  <img alt="Logo" src={`${process.env.PUBLIC_URL}/${item._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 160, maxHeight: 80}} />
                </ListItem>

              // ) : <></>
            }
            </div>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  )};

  const handleUpdateDefaultEntity = () => {
  // console.log('UPdate Default Entity!')
    let index = entities.findIndex(ent =>  (ent['_id'] === rightChecked[0]))
    if (index > -1) {
      const theEntity = entities[index]
    // console.log(theEntity)
      props.updateDefaultEntity(theEntity, entities)
    }
  }

  return (
    <Container>
      Please Choose the Entities Assigned to {props.userProfile.name}
    <Grid
    container
    spacing={2}
    alignItems="center"
    className={classes.root}
  >
    <Grid item style={{width: '25%'}}>
      <CustomList list={availableEntities} name='left' />
    </Grid>
    <Grid item>
      <Grid container direction="column" alignItems="center">
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAllRight}
          disabled={availableEntities.length === 0}
          aria-label="move all right"
        >
          ≫
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAllLeft}
          disabled={entities.length === 0}
          aria-label="move all left"
        >
          ≪
        </Button>
      </Grid>
    </Grid>
    <Grid item style={{width: '25%'}}>
      {/* {customList(entities)} */}
      <CustomList list={entities} name={'right'}/>
      {
        (rightChecked && rightChecked.length === 1) ?
          (
            <DefaultButtonContainer >
            <DefaultButton>
              <Button
                style={{margin: 10}}
                variant="contained"
                color="primary"
                onClick={handleUpdateDefaultEntity}
              >Make Default</Button>
            </DefaultButton>
          </DefaultButtonContainer>
          ) : <></>

      }
    </Grid>
  </Grid>
  
  </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    entities: state.entities
  }
}

export default connect(mapStateToProps)(AssignedEntityComponent)