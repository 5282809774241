import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import { fade, makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import NewProjectModal from './NewProjectModal'
import ProjectList from './ProjectList'
import VendorInvoiceInput from './VendorInvoiceInput'
import LaborTrackerModal from './LaborTrackerModal'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import ProjectDashboardDetails from './ProjectDashboardDetails'
import { Link } from 'react-router-dom'
import { socket } from '../SocketIo/Socket'
import TrackLaborModal from './TrackLaborModal'

// const useStyles = makeStyles(theme => ({
const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  }
  })
);


const TopRow = (props) => {
  const classes = styles()
  return (
    <Grid container className={classes.root}>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          onClick={props.handleOpenNewProjectModal}
          startIcon={<FiberNewIcon  color="primary" />}
        >New Project</Button>
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        {/* <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          style={{ color: '#bf5c1f', borderColor: '#bf5c1f'}}
          startIcon={<PlusIcon  color="#bf5c1f" />}
        >PO Request</Button> */}
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
      <ProposalsNotAccepted history={props.history} />
        {/* <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          style={{ color: '#1fbfac', borderColor: '#1fbfac'}}
          startIcon={<AddIcon  color="#1fbfac" />}
          onClick={e => props.setVendorInvoiceInputModalOpen(true)}
        >Vendor Invoice</Button> */}
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          style={{ color: '#bf3253', borderColor: '#bf3253'}}
          startIcon={<CreateIcon  color="#bf3253" />}
          onClick={props.handleOpenLaborTrackerModal}
        >Track Labor</Button>
      </Grid>
    </Grid>
  )
}

// const ProjectList = (props) => {
//   if (props.projects && props.projects.length) {
//     return (
//       JSON.stringify(props.projects)
//     )
//   } else {
//     return null
//   }
// }

const ProjectDashbaord = (props) => {
  const [newProjectModalOpen, setNewProjectModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [clients, setClientList] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [employees, setEmployees] = React.useState([])
  const [salesList, setSalesList] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [vendors, setVendors] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0,
    projects: []
  })
  const [vendorInvoiceInputModalOpen, setVendorInvoiceInputModalOpen] = React.useState(false)
  const [showLaborTrackerModal, setShowLaborTrackerModal] = React.useState(false)
  const [activeEntity, setActiveEntity] = React.useState({})
  const [laborLineToEdit, setLaborLineToEdit] = React.useState({})
  const [jobSites, setJobSites] = React.useState([])

  React.useEffect(() => {
    let isMounted = true

    socket.on('project-list', (data) => {
      // console.log('Project list updated!', data)
      fetchProjects()
    })
    const fetchClients = async () => {
      setLoading(true)
      // ?byEntity=610a290f45ef7427f94b2e78
      let url = `${props.url}/api/customer/clients?active=true`
      axios.get(url).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setClientList(response.data)
        }
      })
    }
    // const fetchCrews = async () => {
    //   setLoading(true)
    //   axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
    //     setLoading(false)
    //     if (response && response.data) {
    //       if (isMounted) setCrews(response.data.crews)
    //     }
    //   })
    // }

    const fetchVendors = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/getVendors`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setVendors(response.data)
        }
      })
    }

    const fetchSalesReps = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/employees/getSalesReps`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setSalesList(response.data)
        }
      })
    }

    const fetchProjects = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/projects/listProjects?includeAllSites=1`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data && response.data.projects && response.data.projects.length) {
          if (isMounted) {
            setProjects(response.data.projects)
            setState({...state, projects: response.data.projects})
          }
        }
        if (response.data && response.data.jobSites && response.data.jobSites.length) setJobSites(response.data.jobSites)
      })
    }
    // const fetchCrews = async () => {
    //   setLoading(true)
    //   axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
    //     setLoading(false)
    //     if (response && response.data) {
    //       if (isMounted) setCrews(response.data.crews)
    //     }
    //   })
    // }

    const fetchEmployees = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/employees/listEmployees`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) {
            setEmployees(response.data.employees)
            setCrews(response.data.crews)
            if (response.data.jobSites) setJobSites(response.data.jobSites)
          }
        }
      })
    }

    fetchClients()
    // fetchSalesReps()
    fetchProjects()
    fetchVendors()
    // fetchCrews()
    fetchEmployees()
    // fetchSupplies()
    return () => {
      isMounted = false
      socket.off('project-list')
    }
  }, [])

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    setState({...state, projets: newArr })
  }



  const handleOpenNewProjectModal = () => {
    setNewProjectModalOpen(true)
  }

  const handleAddProject = (d) => {
    // console.log('New Project to save!', d)
    if (d && d._id) {
      let projs = state.projects
      projs.unshift(d)
      setProjects(projs)
      // console.log('New Projects', projs)
      setNewProjectModalOpen(false)
      setState({...state, projects: projs, counter: state.counter++ })
    }

  }

  const handleOpenLaborTrackerModal = () => {
    setShowLaborTrackerModal(true)
  }

  const handleCloseLaborTrackerModal = () => {
    setShowLaborTrackerModal(false)
  }

  const handleUpdateVisibleEntity = (entityChosen) => {
  // console.log('UPdate Visibile Projects::: ', entityChosen)
  // console.log('Current Active Entity', activeEntity)
    let projectList = projects
    if (entityChosen.name === 'All Assigned') {
      // console.log('Show all projects')
      // Show all available Customers
      // console.log('We have a bunch!! %d', rows.length)
      // filterOut(projectList, searchValue)
      // setRows(projectList)
      
    } else {
    // console.log('Only show %s projects', entityChosen.name)
      // Filter by the active Entity
      let filteredProjects = projectList.filter(item => { 
        // console.log('Entity: ', item.entity)
        
        return item.entity.toString() === entityChosen._id.toString()
      })

    // console.log('New Projects: ', filteredProjects)
      projectList = filteredProjects
      // filterOut(filteredProjects, searchValue)
      // setRows(filteredProjects)
    }
  // console.log('SEt Entity', entityChosen)
  // console.log('Project list', projectList)
    setActiveEntity(entityChosen)
    setState({...state, projects: projectList})
  }

  const handleSaveLabor = (type, data) => {
    console.log('Save this shit', data)
    setShowLaborTrackerModal(false)
    setLoading(true)    
    if (data && data.date && data.manHours) {
      let stuff = {
        manHours: data.manHours,
        date: data.date,
        project: data.project._id
      }
      if (data.crew && data.crew._id) {
        stuff.crew = data.crew._id
        stuff.crewName = data.crew.name
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/projects/addManHours`,
        data: stuff
      }).then(response => {
        setLoading(false)
      // console.log('Add Man hours', response.data)
        if (response && response.data && response.data._id) {
          // recalculateLaborShit(response.data)
          // setState({...state, totalManHours: response.data.manHoursWorked, manHoursByDay: response.data.manHoursByDay,  counter: state.counter++ })
        }
        props.notification({
          type: 'success',
          title: 'Man Hours Saved',
          message: 'Saved Labor Hours'
        })
      })
    }
  }

  return (
    <Paper style={{  margin: 10, padding: 10 , border: '2px solid #ddd', borderRadius: 5}}>
      <Grid container style={{margin: 5}} spacing={2}>
      <ProjectDashboardDetails />
      
        <Grid item lg={6} xs={12} sm={12} md={6}>
          <h1>Projects</h1>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
         
            {
              (props.user.entities && props.user.entities.length > 1) ? <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} /> : null
            }
            <TopRow history={props.history} handleOpenNewProjectModal={handleOpenNewProjectModal} setVendorInvoiceInputModalOpen={setVendorInvoiceInputModalOpen} handleOpenLaborTrackerModal={handleOpenLaborTrackerModal} />
            
            <ProjectList projects={state.projects} statePreojc={state} />
         
        </Grid>
        <TrackLaborModal open={showLaborTrackerModal} jobSites={jobSites} projects={projects} crews={crews} employees={employees} closeModal={handleCloseLaborTrackerModal} updateParent={handleSaveLabor} />
        <NewProjectModal open={newProjectModalOpen} clients={clients} updateParent={handleAddProject} closeModal={e => setNewProjectModalOpen(false) } />
        <VendorInvoiceInput clients={clients} open={vendorInvoiceInputModalOpen} vendors={vendors} closeModal={e => setVendorInvoiceInputModalOpen(false)} />
      </Grid>
      </Paper>
  )
}

const ProposalsNotAccepted = (props) => {
  const goToOpenEstimates = () => {
    props.history.push('/openEstimates')
  }
  return (
    <Button onClick={goToOpenEstimates} variant="contained" color="primary" size="small" >Open Estimates</Button>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ProjectDashbaord))