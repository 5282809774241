import React from 'react'
import { FileUploader } from "react-drag-drop-files";
import { fade, makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { connect } from 'react-redux'
import { Button, CircularProgress, MenuItem }  from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#eee',
    margin: 5,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '50vh',
      maxWidth: 1360,
    },
    // margin: 20
  },
  headerText: {
    fontSize: 32,
    color: '#aaa',
    // color: 'rgb(119,156,164)',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 18,
      // color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 14,
      // color: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '50vh',
      // maxWidth: 1360,
      textAlign: 'center',
      fontSize: 24,
      // color: 'green'
    }
  },
  giantText: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
    marginBottom: -15
  },
  subText: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
  }
}));

const FileUploadComponent = (props) => {
  const classes = useStyles()
  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLSX", "CSV", "TXT", "JPEG"];
  const [files, setFiles] = React.useState([])
  const [state, setState] = React.useState({
    filesToUpload: []
  })
  const [showUploadStatusModal, setShowUploadStatusModal] = React.useState(false)

// console.log('FIle Upload...!!', props)

  const uploadFilesNow = (fileList) => {
    // console.log('Time to upload this shit!!', fileList)
    let formData = new FormData()
    fileList.forEach(file => {
      // console.log('Append to formdata:', file)
      formData.append('theFileList', file)
    })
    // ?po=${props.po._id}
    // if (props.po && props.po._id) formData.append('metadata', JSON.stringify(props.po))
    const isWarranty = (props.warranty === true)
  // console.log('Form Data to send: ', formData)
    let url = `${props.url}/api/projects/uploadProjectFilesMultiple?onPortal=true`
    if (props.po) {
      url = `${props.url}/api/projects/uploadProjectFilesMultiple?po=${props.po._id}&warranty=${isWarranty}`
    }
    if (props.employee) {
      url = `${props.url}/api/projects/uploadProjectFilesMultiple?employee=${props.employee._id}`
    }
    if (props.hmiMtcAgreement) {
      url = `${props.url}/api/projects/uploadProjectFilesMultiple?hmiMtcAgreement=${props.hmiMtcAgreement._id}`
    }
    if (props.clientId) {
      url = `${url}&clientId=${props.clientId}`
    }
    if (props.punchlistId) {
      url = `${url}&punchlistId=${props.punchlistId}`
    }
    if (props.workorderId) {
      url = `${url}&workorderId=${props.workorderId}`
    }
    // console.log(url)
    setShowUploadStatusModal(true)
    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        // console.log('Upload Progress', progressEvent)
      }
    }).then(resp => {
      if (resp && resp.data) {
    // console.log('Response from axios upload::', resp.data)
      if (resp.data && resp.data.files && resp.data.files.length) setFiles(resp.data.files)
      props.updateParent(resp.data)
      // duplicateFiles
      // errorFiles
      // filesUploaded
      }
    }).catch(e => {
    // console.log('Error uploading file:', e)
      props.notification({
        type: 'error',
        title: 'Error Uploading File',
        message: 'Error Uploading File - 125'
      })
    })
  }

  const handleChange = (fileList) => {
  // console.log('FILES TO UPLOAD: ', fileList)
    let ourFileList = Array.from(fileList)
    // console.log('files', ourFileList)
    // ourFileList.map(item => (ourFileList.push(item)))
    // console.log('files: ', ourFileList)
    let newFiles = files.concat(ourFileList)
    setFiles(newFiles)
    setState({...state, filesToUpload: newFiles})
  // console.log('New Files to upload:', newFiles)
    // UPload the files now
    uploadFilesNow(newFiles)
  };

  const handleDragChange= (e, d) => {
    // console.log('Drag change', e)
    // console.log(d)
  }

  const handleSelect = (e, d) => {
    // console.log('Select: ', e)
    // console.log(d)
  }

  let message = 'Click Here to Upload or Drag and Drop Files Here'
  if (props.message) message = props.message
  return (
    <div style={{ margin: 10 }}>
      <FileUploader
        handleChange={handleChange}
        name="file" 
        multiple
        types={fileTypes}
        fileOrFiles='Array'
        onSelect={handleSelect}
        onDraggingStateChange={handleDragChange}
        >
          <div style={{ textAlign: 'center', border: '3px dashed #ccc', borderRadius: '10px', padding: '15px', cursor: 'pointer' }}>
            {message}
          </div>
      </FileUploader>
      <FileList files={files} />
    </div>
  )
}

const FileList = ({ files }) => {
  return (
    <ul>
      {
        files.map((item, index) => {
          return <li key={index}>{item.name} - {item.status ? item.status : 'Pending Upload'} </li>
        })
      }
    </ul>
  )
  // let html = `<ul>`
  // files.map(item => {
  //   html += `<li>{item.name}</li>`
  // })
  // html += `</ul>`
  // return html
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(FileUploadComponent)
