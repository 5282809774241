import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components'

const Container = styled(Paper)`
  min-height: 85vh;
  margin: 10px;
  font-size: 2em;
  alignContent: center;
`;

const Timeclock = (props) => {
// console.log('Time Clock Props', props)
  return (
    <div>
      <Container>
        Login
        
      </Container>

    </div>
  )
}

const mapStateToProps = (state) => {
// console.log('State Available: ', state)
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    position: state.position
  }
}

export default connect(mapStateToProps)(Timeclock)