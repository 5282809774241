import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { currencyFormat } from '../../../services/common'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import StatusModal from './StatusModal'
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 15,
    height: '95vh'
  },
  paper: {
    width: '100%',
    margin: 5,
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'scroll',
    minHeight: '40vh',
    maxHeight: '65vh'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    fontSize: '1.2em'
  },
  rowContainer: {
    // border: '3px solid red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowItem: {
    // border: '2px solid blue',
    fontSize: '1.25em',
    padding: 5,
    margin: 5
    // marginRight: 15
  },
  rowImage: {
    // border: '1px solid green',
    display: 'flex',
    alignSelf: 'stretch'
    // margin: -10,
    // padding: -5,
  }
}));

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Estimate Name' },
  { id: 'entity', numeric: false, disablePadding: false, label: 'Entity' },
  // { id: 'number', numeric: false, disablePadding: true, label: 'Estimate #' },
  { id: 'contractor', center: false, disablePadding: true, label: 'Contractor' },
  { id: 'clientName', numeric: false, disablePadding: true, label: 'Customer Name' },
  { id: 'amount', numeric: false, disablePadding: true, label: 'Amount' },
  { id: 'created_at', numeric: false, disablePadding: true, label: 'Created' },
  // { id: 'created_by', numeric: false, disablePadding: true, label: 'Created By' },
  { id: 'salesRep', numeric: false, disablePadding: true, label: 'Sales Rep' },
  { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
  { id: 'updated_at', numeric: false, disablePadding: true, label: 'Last Update' },
  // { id: 'updated_by', numeric: false, disablePadding: true, label: 'Updated By' },
  { id: 'action', numeric: false, disablePadding: true, label: 'Action' },
];


function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const ListOfOpenEstimates = (props) => {
  const classes = useStyles()
  // console.log("List of OPen EstimateS", props)
  const [filteredRows, setFilteredRows] = React.useState([])
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [rows, setRows] = React.useState([])
  const [searchValue, setSearchValue] = React.useState('')
  // const [selected, setSelected] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
  // console.log('Props changed List of open estimates', props)
    let isMounted = true
    if (props.list && props.list.length && isMounted) {
    // console.log('LIst changed:', props.list)
      if (isMounted) modifyOurList(props.list)
    }

    return () => {
      isMounted = false
    }
  }, [props])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
  // console.log('Selected ALL!!')
    if (event.target.checked) {
    // console.log('Select ALL')
      // Get the page only
      const newSelecteds = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => n._id);
      if (selected.length === newSelecteds.length) {
      // console.log('Deselect ALL??')
        setSelected([]);
      } else {
        setSelected(newSelecteds);
        return;
      }

    }
  // console.log('Deselect ALL')
    setSelected([]);
  };

  const handleClickCheckbox = (event, _id) => {
  // console.log('Click this one:', _id)
  // console.log('Currently selected:', selected)
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
  // console.log('Selected index', selectedIndex)
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
  // console.log('New selected', newSelected)
    setSelected(newSelected);
  };

    
  const modifyOurList = (newList) => {
  // console.log('LIst has updated', newList)
    setRows(newList)
    if (searchValue) {
      filterOut(rows, searchValue)
    } else {
      setFilteredRows(newList)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 

  const handleChange = (name) => (event) => {
  // console.log('Searching: ', event.target.value)
    setSearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }
  
  const filterOut = (r, searchText) => {
    let string = searchText
    let newArr = []
    r.filter(o => {
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
  // console.log('Search results:', newArr)
    setFilteredRows(newArr)

  }



  const handleModificationResponse = (data) => {
  // console.log('Modified:', data)
    setSelected([])
    props.updateParent(data)
  }

  const totalRevenue = filteredRows.reduce(function (acc, obj) { return acc + (obj.amount ? parseFloat(obj.amount) : 0); }, 0);
  
  return (
    <div >
      {/* <Paper className={classes.paper}> */}
        <h3>Open Estimates</h3>
        <Grid container padding={2}>
          <Grid item lg={3} sm={6} xs={6} md={6}>
            <TextField
              name="search" 
              value={ searchValue } 
              onChange={ handleChange('search') }
              label="Search Proposals"
              type="search"
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
          />
          <div style={{ marginLeft: '16px' }} >
            {filteredRows.length} / {props.list.length} Estimates
          </div>
          <div style={{ marginLeft: '16px' }} >
            Revenue of These {filteredRows.length} Estimates: {currencyFormat(totalRevenue)}
          </div>
          </Grid>
          <Grid item lg={3} sm={6} xs={6} md={6}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', marginLeft: '20px' }}>
              {selected.length > 0 ? (
        
                <div style={{ display: 'flex', verticalAlign: 'bottom' }}>{selected.length} Proposals Selected</div>
                
                ) : null
              }
              {selected.length > 0 ? (
                <div style={{ display: 'flex', verticalAlign: 'middle' }}><EstimateAction item={selected} url={props.url} setLoading={setLoading} updateParent={handleModificationResponse} /></div>
          
              ) : null
              }
            </div>
          </Grid>
          
        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table className={classes.table}>
              
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
              />
                <TableBody>
                  {
                    loading ? <CircularProgress size={95} color="primary" /> : (

                  <React.Fragment>
                    { stableSort(filteredRows, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                        // const _id = item._id
                        let isItemSelected = false
                        let items = selected
                        // console.log('Currently selected:', items)
                        const indexSel = items.indexOf(item._id)
                        // console.log('Index sel:', indexSel)
                        if (indexSel > -1) isItemSelected = true
                        // console.log('Item:', _id)
                        // console.log('is selected:', isItemSelected)
                        return (
                      <TableRow 
                        className={ classes.tableRow}
                        key={index}
                        hover
                        
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                    
                        <TableCell padding="checkbox">
                        <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClickCheckbox(event, item._id)}
                            inputProps={{ 'aria-labelledby': `${item.name}` }}
                          />
                        </TableCell>
                        
                        <TableCell className={classes.tableCell}>
                          {
                          `${item.name}   #${item.number}`
                          }
                        </TableCell>
                        <TableCell component="td" scope="row" padding="none" >
                        <div className={classes.rowContainer} >
                          <div className={classes.rowImage}>
                            <img alt="Logo" src={`${process.env.PUBLIC_URL}/${item.entity}_Logo.png`} style={{margin: '5px', maxWidth: 90, maxHeight: 35}} />
                          </div>
                        </div>
                      </TableCell>
                        {/* <TableCell className={classes.tableCell}>
                            {
                              item.number
                                ?
                                <span >
                                  #{item.number}
                                </span>
                                : null
                            }
                        </TableCell> */}
                        <TableCell className={classes.tableCell}>{item.contractor ? item.clientName : '' }</TableCell>
                        <TableCell className={classes.tableCell}>{item.clientName }</TableCell>
                        <TableCell className={classes.tableCell}>{currencyFormat(item.amount) }</TableCell>
                        <TableCell className={classes.tableCell}>{moment(item.created_at).format('MM/DD/YY') } by {item.created_by }</TableCell>
                   
                        <TableCell className={classes.tableCell}>{item.salesRep }</TableCell>
                        <TableCell className={classes.tableCell}>{item.status }</TableCell>
                        <TableCell className={classes.tableCell}>{moment(item.last_update).format('MM/DD/YY') } by {item.updated_by }</TableCell>
                 
                        <TableCell className={classes.tableCell}><EstimateAction setLoading={setLoading} disabled={(selected && selected.length) ? true : false} item={item} url={props.url} updateParent={handleModificationResponse} /></TableCell>
                      </TableRow>
                    )
                  })
                    }
                    </React.Fragment>

                    )}            
                </TableBody>
              </Table>
             
              
        </div>
          <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100, 500]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                // onChangePage={handleChangePage}
                // onPageChange={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />  
      {/* </Paper> */}
    </div>
  )
}

const EstimateAction = ({ setLoading, item, url, updateParent, disabled }) => {
  // console.log('Estimate Action:', props)
  const options = ['Change Status', 'Duplicate', 'Archive', 'Lost', 'Accept'];
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [showStatusModal, setShowStatusModal] = React.useState(false)
  // const [loading, setLoading] = React.useState(false)

  const updateEstimate = (type) => {
    // console.log('Update the Estimate', type)
    setLoading(true)
    // setClient({})
    // setState({...state, note})
    const data = {
      type: type,
      estimate: item
    }
    axios({
      method: 'post',
      url:  `${url}/api/proposal/modifyOpenEstimates`,
      data: data
    }).then(response => {
      setLoading(false)
      // console.log('UPdate estimate:', response.data)
      updateParent(response.data)
    }).catch(e => {
    // console.log('Error:', e)
    })
}

  const handleClick = () => {
    // console.info(`You clicked ${options[selectedIndex]}`);
    if (options[selectedIndex] === 'Change Status') {
      setShowStatusModal(true)
    } else {
      updateEstimate(options[selectedIndex])
    }
    // if (options[selectedIndex] === 'Archive') {
    //   updateEstimate('archive')
    // }
    // if (options[selectedIndex] === 'Closed Lost') {
    //   updateEstimate('lost')
    // }
    // if (options[selectedIndex] === 'Accept') {
    //   updateEstimate('accept')
    // }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseStatusModal = () => {
    setShowStatusModal(false)
  }

  const handleUpdateStatus = (newStatus) => {
    setShowStatusModal(false)
    updateEstimate(newStatus)
  }
  return (
    <div>
      <StatusModal open={showStatusModal} item={item} closeModal={handleCloseStatusModal} updateParent={handleUpdateStatus} />
    <ButtonGroup style={{ maxHeight: 40, fontSize: '0.6em' }} disabled={disabled} variant="contained" color="primary" ref={anchorRef} aria-label="split button">
      <Button onClick={handleClick} >{options[selectedIndex]}</Button>
      <Button
        color="primary"
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <ArrowDropDownIcon />
      </Button>
    </ButtonGroup>
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {options.map((option, index) => (
                  <MenuItem
                    key={option}
                    // disabled={index === 2}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ListOfOpenEstimates)
