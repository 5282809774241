import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import EmailTheInvoiceModal from '../Invoices/EmailTheInvoiceModal'

const InvoiceModal = (props) => {
  // console.log('INvoice Modal: ', props)
  const [pdf, setPDF] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [workorder, setWorkorder] = React.useState({})
  const [showEmailInvoiceModal, setShowEmailInvoiceModal] = React.useState(false)
  const [state, setState] = React.useState({
    workorder: {},
    display: 'inline',
    isApproved: false
  })

  React.useEffect(() => {
    // console.log('Use Effect INVOICE MODAL', props)
    setPDF(props.pdf)
    // if (props.invoice && props.invoice._id) setState({...state, invoice: props.invoice })
    if (props.workorder && props.workorder._id && props.workorder.invoiceDate && props.workorder.isInvoiced) {
      setState({...state, workorder: props.workorder, isApproved: true})
    } else if (props.workorder && props.workorder._id) {
       setWorkorder(props.workorder)
    }
    // if (props.workorder && props.workorder.invoiceDate) setState({...state, isApproved: true, display: 'none'})
    if (props.invoice && props.invoice.officeApproved) {
      setState({...state, isApproved: true })
    } else if (props.invoice && props.invoice.officeApproved === false) {
      setState({...state, isApproved: false })
    }
  }, [props])

  // React.useEffect(() => {
  //   // console.log('Workorder changed on us!!!', props)
  //   // setPDF(props.pdf)
  //   if (props.workorder && props.workorder._id) setWorkorder(props.workorder)
  //   if (props.workorder && props.workorder.invoiceDate) setState({...state, isApproved: true, display: 'none'})
  // }, [props.workorder])

  const handleClose = () => {
    // console.log('Closing invoice modal')
    setLoading(false)
    setState({...state,
      workorder: {},
      display: 'inline',
      isApproved: false
    })
    props.jobDetailsResponse('Close')
  }

  const handleEmailInvoice = () => {
    // console.log('Email Invoice')
    setLoading(true)
    let updateObject = {
      client: props.client,
      pdf: pdf,
      invoice: props.invoice,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/emailInvoice`,
      data: updateObject,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      setLoading(false)
      // console.log('EMAIL INVOICE RSPONSE: ', response)
      props.notification({
        type: 'success',
        title: 'Invoice Emailed',
        message: 'Email Sent. Grab a cup of coffee, you did great!'
      })
    })
  }

  const handleApproveInvoice = (doNotEmail) => {
    // console.log('Approve Invoice')
    setState({...state, isApproved: true})
    setLoading(true)
    let updateObject = {
      client: props.client,
      workorder: props.workorder,
      invoice: props.invoice,
      pdf: pdf
    }
    // console.log('Approval Object: ', updateObject)
    axios({
      method: 'post',
      url:  `${props.url}/api/invoice/approveInvoice?noEmail=${doNotEmail}`,
      data: updateObject,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      setLoading(false)

      // console.log('Approve INVOICE RSPONSE: ', response)
      props.updateInvoiceStatus('Approved')
      setState({...state, isApproved: true})
      if (response && response.data && response.data.noEmail) {
        // props.jobDetailsResponse('approved', response.data)
        props.notification({
          type: 'success',
          title: 'Please Print This Invoice',
          message: 'This Customer Is A Non-Email Customer - Please Print and Mail!'
        })
      } else {
        let numSent = null
        let message = `The Invoice was approved and emailed to the client!`
        if (response.data.sent && response.data.sent.length) {
          numSent = response.data.sent.length
          if (numSent === 1) message = `The Invoice was approved and emailed to the client at ${response.data.sent[0]['email']}!`
          if (numSent > 1) message = `The Invoice was approved and emailed to the client at ${numSent} email addresses.`
        }
        props.notification({
          type: 'success',
          title: 'Invoice Approved',
          message: message
        })
      }

    })
  }

  const handleOpenEmailInvoiceModal = () => {
  // console.log('Open the modal to fill out the email content')
    setShowEmailInvoiceModal(true)
  }
  // const handleDeclineInvoice = () => {
  //   // console.log('Decline Invoice')
  // }
  const OptionDetails = (data) => {
    // console.log('Invoice Approval options: ', data)
    if (data.isApproved) {
      // console.log('Is Approved - no Thumbs Up needed')
      return (
        <Grid container style={{textAlign: 'center'}}>
         <Grid item lg={12}>
          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            size="small"
            startIcon={<EmailIcon />}
            onClick={handleOpenEmailInvoiceModal}
          >
            { loading ? <CircularProgress color="primary" size={ 12 } /> : 'Email Invoice'} 
          </Button> 
          </Grid>
        </Grid>
      )
    } else {
      // console.log('Needs Approval')
      return (        
        <Grid container style={{textAlign: 'center'}}>
      <Grid item lg={12}>
        Please review this invoice and if everything looks good, please approve it by clicking the Thumbs Up button below. If not, please decline it by clicking the Thumbs Down button. 
      </Grid>
      <Grid item lg={12}>
        <Button
          variant="contained"
          disabled={loading}
          color="primary"
          size="small"
          startIcon={<ThumbUpIcon />}
          onClick={e => handleApproveInvoice(0)}
        >
          { loading ? <CircularProgress color="primary" size={ 12 } /> : 'Approve'} 
        </Button>  
        <Button
          variant="contained"
          disabled={loading}
          color="secondary"
          size="small"
          style={{ marginLeft: 20 }}
          startIcon={<ThumbUpIcon />}
          onClick={e => handleApproveInvoice(1)}
        >
          { loading ? <CircularProgress color="primary" size={ 12 } /> : 'Approve (No Email)'} 
        </Button>  
      </Grid>
    </Grid>)
    }
  }
  const closeEmailInvoiceModal = () => {
    setShowEmailInvoiceModal(false)
  }
  // console.log("Invoice Options Modal 209", props)
  return (
    <div>
      <EmailTheInvoiceModal open={showEmailInvoiceModal} pdf={props.pdf} entity={props.entity} client={props.client} invoice={props.invoice} contacts={props.contacts} handleCloseModal={closeEmailInvoiceModal} />
       <Dialog
        // fullScreen={true}
        maxWidth='sm'
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Invoice Options</DialogTitle>
        <DialogContent>
          <OptionDetails isApproved={state.isApproved} />
          <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="800px" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(InvoiceModal))