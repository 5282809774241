import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import ProjectNotes from './ProjectNotes'
import FileUpload from './FileUpload'
import ProjectUploadsList from './ProjectUploadsList'
import NewNoteModal  from './NewNoteModal'
import EditIcon from '@material-ui/icons/Edit';
import ProposalList from '../Proposals/ProposalList'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import EditProjectModal from './EditProjectModal'
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';
import AssociateWorkordersModal from './AssociateWorkordersModal'
import VendorInvoiceList from './VendorInvoiceList'
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import TaskListTable from '../Tasks/TaskListTable'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EditProgressPaymentModal from './EditProgressPaymentModal'
import ProjectTimeline from './ProjectTimeline'
import ProjectJobList from './ProjectJobList'
import JobList from '../Customer/JobList'
import { RefreshTwoTone } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import ContactList from './Contacts'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { formatPhoneNumber } from '../../services/common'
import LaborByDayList from './ProjectManHoursByDayList'
import ActivityDrawer from '../ActivityComponent/ActivityDrawer'
import ActivityDetailsContainer from '../ActivityComponent/ActivityDetails'
import ProjectMilestonesList from './ProjectMilestonesList'
import { socket } from '../SocketIo/Socket'
import MilestoneDetailsDrawer from './MileStoneDetailsDrawer'
import { setProjects, setContacts, setClient } from '../../actions/connectionActions'
import DeleteComponent from '../Common/DeleteComponent'
import ProjectUploadComponent from './ProjectUploadComponent'
import PaymentList from '../Payments/PaymentList'
import POListComponent from '../PurchaseOrders/PurchaseOrderList'
import AddLotInventory from './AddLotInventoryModal'
import LotInventoryList from './LotInventoryDetailsList'
import TrackLaborModal from './TrackLaborModal'
import AssessmentIcon from '@material-ui/icons/Assessment';
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'
import PercentageSlider from '../Common/PercentageSlider'
import CompletionPercentage from './ProjectCompletionPercentage'
import { Link, useLocation, useHistory  } from 'react-router-dom'
import InvoiceList from '../Invoices/InvoiceList'
import ProjectInvoicingDetails from './ProjectInvoicingDetails'

// import {useHistory, useLocation  } from 'react-router-dom'
// import InvoicedVsEstimated from './InvoicedVsEstimated'

const Labor_Rate = 33

const Fragment = React.Fragment

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  modal: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center'
      margin: 0
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
      margin: '5%'
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
      // margin: '5%'
    },
  },
  updateInfo: {
    color: '#aaa',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center'
      fontSize: 12,
      textAlign: 'center'
      // width: '100%',
      // textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 16
      // textAlign: 'right'
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
      fontSize: 17,
      textAlign: 'right'
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
      fontSize: 18,
      // backgroundColor: '#ccc',
      // margin: '5%'
    },
  },
  titleText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
      textAlign: 'left'
    }
  },
  subTitleText: {
      color: '#bbb',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 17,
      textAlign: 'left'
    }
  },
  notesBox: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '10vh',
    },
    border: '3px solid #bbb',
    borderRadius: 15,
    overflowY: 'scroll',
    maxHeight: 270,
    padding: 10,
    marginTop: 10
    // backgroundColor: '#eee'
  },
  wrapper: {
    maxWidth: '260px',
    minWidth: '100px',
    whiteSpace: 'nowrap',
    display: 'inline'
  },
  box: {
    borderLeft: '7px solid #333',
    padding: '7px',
    backgroundColor: '#234543',
    width: '100%',
    color: '#aaa',
    fontSize: '10px'
  },
  bigText: {
   color: '#777',
   fontSize: '16px'
   },
   estimateTotal: {
    borderLeft: '7px solid #56b349',
    padding: '7px',
    backgroundColor: '#eef8ed',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  estimatedCost: {
    borderLeft: '7px solid #ad8409',
    padding: '7px',
    backgroundColor: '#fdf7e7',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  invoicesPaid: {
    borderLeft: '7px solid #1eabd6',
    padding: '7px',
    backgroundColor: '#82d4ed',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
  inventoryAndShit: {
    backgroundColor: '#f5e0f9',
    borderLeft: '7px solid #763a82',
    padding: '7px',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
  invoicesDue: {
    borderLeft: '7px solid #ad8409',
    padding: '7px',
    backgroundColor: '#fdf7e7',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  paymentsCollected: {
    borderLeft: '7px solid #56b349',
    padding: '7px',
    backgroundColor: '#eef9ed',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  projectBalance: {
    borderLeft: '7px solid #d7393f',
    padding: '7px',
    backgroundColor: '#f6d5d6',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
   dueDate: {
    borderLeft: '7px solid #ad8409',
    padding: '7px',
    backgroundColor: '#fdf7e7',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  yardPics: {
    height: 280,
    minWidth: '300px',
    display: 'flex',
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    border: '3px solid #ccc',
    borderRadius: '15px',
    padding: 5,
    margin: 5
  },
  subHeaderText: {
    fontWeight: 400,
    color: '#999'
  }
}));

const useStyles2 = makeStyles(theme => ({
  root: {
    // backgroundColor: 'pink',
    // border: '3px solid #bbb',
    borderRadius: 15,
    // backgroundColor: '#eee',
    // minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
    },
  },
  titleText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
      textAlign: 'left'
    }
  },
  notesBox: {
    height: '100vh',
    border: '3px solid #bbb',
    borderRadius: 15,
    backgroundColor: '#eee'
  },
  dangerAlert: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 8
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 8
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22
    },
    backgroundColor: 'red',
    borderRadius: 25,
    border: '1px solid #eee',
    color: '#fff',
    height: 50,
    padding: 10,
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden'
    // [theme.breakpoints.up('md')]: {
    //   width: '80%'
    // },
    // [theme.breakpoints.up('lg')]: {
    //   width: '60%'
    // }
  },
  warningAlert: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 8
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 8
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22
    },
    backgroundColor: 'orange',
    borderRadius: 25,
    border: '1px solid #eee',
    color: '#fff',
    height: 50,
    padding: 10,
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    
  },
  infoAlert: {
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '12px !important'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '12px !important'
    // },
    // [theme.breakpoints.up('sm')]: {
    //   fontSize: '14px !important'
    // },
    // [theme.breakpoints.down('md')]: {
    //   fontSize: '18px !important'
    // },
    // [theme.breakpoints.up('md')]: {
    //   fontSize: '20px !important'
    // },
    // [theme.breakpoints.up('lg')]: {
    //   fontSize: '22px !important'
    // },
    backgroundColor: '#999',
    borderRadius: 25,
    fontSize: 16,
    border: '1px solid #eee',
    color: '#fff',
    height: 50,
    padding: 10,
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden'
  },
  importantArea: {
    minHeight: '200px',
    border: '3px solid #bbb',
    borderRadius: 15,
    padding: 10,
    marginBottom: 10
  }
}));

// const LaborList = (props) => {
// // console.log('Labor List', props)
//   return (
//     <div>
//       <Table></Table>
//     </div>
//   )
// }

// const statusOptions = [
//   'Archived',
//   'Lost',
//   'New',
//   'Pending Meeting',
//   'Pending Estimate',
//   'Estimate Sent',
//   'Pending Revisions',
//   'Revisions Sent',
//   'Pending Plans',
//   'Pending Deposit',
//   'Pending HOA Sub.',
//   'In Progress',
//   'Pending Progress Payment',
//   'Pending Final Walk',
//   'Pending Final Payment',
//   'Pending 3 Month',
//   'Complete'
// ]

const statusOptions = [
  'Archived',
  'Lost',
  'New',
  'Pending Meeting',
  'Pending Estimate',
  'Estimate Created',
  'Estimate Sent',
  'Pending Revisions',
  'Revisions Sent',
  'Pending Plans',
  'Tentative Start Date',
  'Pending Deposit',
  'Deposit Paid',
  'Pending HOA Sub.',
  'HOA Submittal',
  'In Progress',
  'Pending Progress Payment',
  'Pending Final Walk',
  'Final Walk',
  'Pending Final Payment',
  'Final Bill',
  'Final Paid',
  'Begin 90 Days Free Mtc',
  'Pending 3 Month',
  'Warranty Work Done',
  'Complete'
]

const defaultValues = { uploads: [], notes: [], counter: 0, workorders: [], manHoursByDay: [], manHoursWorked: 0, activities: [], invoices: [] }

const ProjectDetailArea = (props) => {
// console.log('PROJECT DETAIL AREA:: ', props)
  const classes = useStyles2();
  const [loading, setLoading] = React.useState(false)
  const [expanded, setExpanded] = React.useState('panel1')
  const [uploads, setUploads] = React.useState([])
  const [workorders, setWorkorders] = React.useState([])
  const [state, setState] = React.useState(defaultValues)
  const [vendorNotes, setVendorNotes] = React.useState('')


  React.useEffect(() => {
  // console.log('Props change on PROJECT DETAILS: 564', props)
    let newStateObj = {}
    if (props.uploads && props.uploads.length) {
      setUploads(props.uploads)
      newStateObj.uploads = props.uploads
      // console.log('Set our uploads details...', props.uploads)
      // setState({...state, uploads: props.uploads, counter: state.counter++})
    }
    if (props.workorders && props.workorders.length) {
      setWorkorders(props.workorders)
      newStateObj.workorders = props.workorders
      // console.log('Set Workorders: ', props.workorders)
      // setState({...state, workorders: workorders, counter: state.counter++})
      // setState({...state, workorders: props.workorders, counter: state.counter++ })
    }
    setState({...state, ...newStateObj})
    // if (props.project && props.project.vendorNotes) {
    // // console.log('Vendor notes; ', props.project.vendorNotes)
    //   setVendorNotes(props.project.vendorNotes)
    // }
    // if (props.notes && props.notes.length) {
    //   setState({...state, notes: props.notes})
    // }
  }, [props])

  // const getProjectDetails = async (id) => {
  //   axios.get(`${props.url}/api/projects/getDetails?client=${id}`).then(resp => {
  //     if (resp && resp.data) {
  //     // console.log('Axios Response with Project Details: ', resp.data)
  //     }
  //   })
  // }

  React.useEffect(() => {
    let isMounted = true
  // console.log('Props change on PROJECT DETAILS: 595', props)
    // if (props.uploads && props.uploads.length) {
    //   setUploads(props.uploads)
    // // console.log('Set our uploads details...', props.uploads)
    //   setState({...state, uploads: props.uploads})
    // }
    if (props.project && props.project.vendorNotes) {
      // console.log('Vendor notes; ', props.project.vendorNotes)
      setVendorNotes(props.project.vendorNotes)
    }
    if (props.project && props.project._id) {
    // console.log('Set Our status:::', props.project)
      // let manHoursByDay = []
      // if (props.project.manHoursByDay && props.project.manHoursByDay.length) {
      // // console.log('We have some entries ', manHoursByDay.length)
      //   manHoursByDay = props.project.manHoursByDay.length
      // }
      let status = props.project.status
      let index = statusOptions.findIndex(item => (item === status))
      if (index > -1) {
        status = statusOptions[index]
      }
      let statusObj = {
        key: status,
        value: status
      }
      
      let newState = { status: statusObj }
    // console.log('State to set:', newState)
      setState({...state, ...newState })
    }
    // if (props.notes && props.notes.length) {
    //   setState({...state, notes: props.notes})
    // }
    // if (props.project && props.project.client && props.project.client._id) {
    //   getProjectDetails(props.project.client._id)
    // }
    return () => {
      isMounted = false
    }
  }, [props.project])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }
  
  const updateVendorNotes = (e) => {
    // console.log('Update Vendor Notes', e.target.value)
    // console.log(vendorNotesRef)
    setVendorNotes(e.target.value)
    // props.updateVendorNotes(e.target.value)
  }
  const updateVendorNotesToParent = (e) => {
    // console.log('UPdate Vendor parent notes; ', e.target.value)
  // console.log(vendorNotes)
    props.updateVendorNotes(e.target.value)
  }

  const updateVendorInvoiceTotals = (data) => {
  // console.log('Update Vendor Invoice Totals', data)
    props.updateVendorInvoiceTotals(data)
  }

  const updateFromJobList = (data) => {
  // console.log('Update From job list: ', data)
  }

  const handleDeleteManHoursLine = (line) => {
  // console.log('Delete this ', line)
    props.handleModifyManHours({
      type: 'delete',
      item: line
    })
  }

  const handleEditProjectManHours = (line) => {
  // console.log('Edit this line', line)
    // setLaborLineToEdit(line)
    // props.handleEditProjectManHours(line)
    props.handleModifyManHours({
      type: 'edit',
      item: line
    })
  }


  const updatePOsIncluded = (includeOrNot, entityToUse) => {
    // console.log('Update POS: Include REconciled: ', includeOrNot)
    let query = `${entityToUse || 'All'}`
    if (includeOrNot) query = '&reconciledToInvoice=true'
    axios({
      method: 'get',
      url: `${props.url}/api/purchaseOrders/list?${query}`
    }).then(response => {
      if (response && response.data && response.data.length) {
        let numReconciled = 0
        response.data.map(item => {
          if (item && item.reconciledToInvoice) numReconciled++
        })
        // console.log('Num Reconciled: ', numReconciled)
        let percentReconciled = numReconciled / response.data.length

        // console.log(percentReconciled)
        percentReconciled = parseInt(percentReconciled * 100)
        setState({...state, purchaseOrders: response.data, percentReconciled })
      }
    })
  }

  const handleDeleteItem = async (itemToDelete) => {
    // let purchaseOrders = state.purchaseOrders
    // let index = purchaseOrders.findIndex(item => (item._id.toString() === itemToDelete._id.toString() ))
    // if (index > -1) {
    //   purchaseOrders.splice(index, 1)
    axios({
      method: 'post',
      data: itemToDelete,
      url: `${props.url}/api/purchaseOrders/delete`
    }).then(response => {
      if (response.data && response.data.message) {
        props.notification({
          type: 'success',
          message: response.data.message,
          title: 'Deletion Complete'
        })
      }
    })
      // setState({...state, purchaseOrders: purchaseOrders})
    // }
  }
  const photos = uploads.filter(upload => {
    // console.log('is this a  photo', upload)
    if (upload.type.includes('image')) return upload
  })
  // console.log('Photos', photos)
  // console.log('(816....', state)
  // console.log('props', props)
  return (
   
      <Grid container style={{ width: '100%' }}>

        <Grid item xs={12} sm={12} md={12} lg={12}>
        <Accordion square expanded={expanded === 'paneltimeline'} onChange={handleChange('paneltimeline')}>
            <AccordionSummary aria-controls="paneltimeline-content"  expandIcon={<ExpandMoreIcon />} id="paneltimeline-header">
              <Typography>Project Timeline</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectTimeline project={props.project} workorders={props.workorders} woNum={props.workorders.length} counter={state.counter} />
            </AccordionDetails>
          </Accordion>
        <Accordion square expanded={expanded === 'panel-contacts'} onChange={handleChange('panel-contacts')}>
          <AccordionSummary aria-controls="panel-contacts-content"  expandIcon={<ExpandMoreIcon />} id="panel-contacts-header">
            <Typography>Project Contacts</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContactList project={props.project} />
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'panelActivities'} onChange={handleChange('panelActivities')}>
            <AccordionSummary aria-controls="panelActivities-content"  expandIcon={<ExpandMoreIcon />} id="panelActivities-header">
              <Typography>Activities (Phone Calls, Text Messages, Emails, Etc)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ActivityDetailsContainer activities={props.activities} />
            </AccordionDetails>
          </Accordion>
        <Accordion square expanded={expanded === 'vendorInvoices'} onChange={handleChange('vendorInvoices')}>
            <AccordionSummary aria-controls="vendorInvoices-content"  expandIcon={<ExpandMoreIcon />} id="vendorInvoices-header">
              <Typography>Vendor Invoices ({`${props.vendorInvoices.length}`})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <VendorInvoiceList uploads={props.vendorInvoices} state={state} project={props.project} updateVendorInvoiceTotals={updateVendorInvoiceTotals} />
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'lotInventoryDetails'} onChange={handleChange('lotInventoryDetails')}>
            <AccordionSummary aria-controls="lotInventoryDetails-content"  expandIcon={<ExpandMoreIcon />} id="lotInventoryDetails-header">
              <Typography>Lot Inventory Used Details ({`${props.lotInventoryDetails.length}`})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LotInventoryList list={props.lotInventoryDetails} state={state} project={props.project} deleteLine={props.handleDeleteLotInventoryItem } editLine={props.handleEditLotInventoryItem} />
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'vendorQuotes'} onChange={handleChange('vendorQuotes')}>
            <AccordionSummary aria-controls="vendorQuotes-content"  expandIcon={<ExpandMoreIcon />} id="vendorQuotes-header">
              <Typography>Vendor Quotes </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <ProjectUploadsList uploads={uploads} state={state} project={props.project}  /> */}
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'purchaseOrders'} onChange={handleChange('purchaseOrders')}>
            <AccordionSummary aria-controls="purchaseOrders-content"  expandIcon={<ExpandMoreIcon />} id="purchaseOrders-header">
              <Typography>Purchase Orders </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <POListComponent history={props.history} ourList={props.purchaseOrders} deleteItem={handleDeleteItem} updatePOsIncluded={updatePOsIncluded} activePO={null} />
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'photos'} onChange={handleChange('photos')}>
            <AccordionSummary aria-controls="photos-content"  expandIcon={<ExpandMoreIcon />} id="photos-header">
              <Typography>Photos </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectUploadsList uploads={photos} state={state} project={props.project}  />
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'plans'} onChange={handleChange('plans')}>
            <AccordionSummary aria-controls="plans-content"  expandIcon={<ExpandMoreIcon />} id="plans-header">
              <Typography>Plans </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <ProjectUploadsList uploads={uploads} state={state} project={props.project}  /> */}
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary aria-controls="panel2d-content"  expandIcon={<ExpandMoreIcon />} id="panel2d-header">
              <Typography>Estimates ({`${props.estimates.length} Associated`})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProposalList estimates={props.estimates} project={props.project} client={ props.client } entity={props.entity} contacts={props.contacts} jobSites={props.jobSites} postConversionProcess={null} updateProposalList={props.updateProposalList} />
            </AccordionDetails>
          </Accordion>
          <Accordion square expanded={expanded === 'vendorQuotes'} onChange={handleChange('vendorQuotes')}>
            <AccordionSummary aria-controls="vendorQuotes-content"  expandIcon={<ExpandMoreIcon />} id="vendorQuotes-header">
              <Typography>Workorders </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <JobList project={props.project} workorders={props.workorders} salesReps={props.salesReps} jobSites={props.jobSites} taxLocales={props.taxLocales} updateParent={updateFromJobList} />
                {/* <ProjectJobList client={ props.client } project={props.project} workorders={props.workorders} jobSites={props.jobSites} taxLocales={props.taxLocales} /> */}
            </AccordionDetails>
        </Accordion>
          <Accordion square expanded={expanded === 'customerInvoices'} onChange={handleChange('customerInvoices')}>
            <AccordionSummary aria-controls="customerInvoices-content"  expandIcon={<ExpandMoreIcon />} id="customerInvoices-header">
              <Typography>Customer Invoices ({props.invoices.length}) </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvoiceList stateData={ state } invoices={props.invoices} contacts={props.contacts} paymentProfiles={[]} client={ props.client } deposits={[]} credits={[]} entity={props.entity} invoicesProvided={true} />
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'customerPayments'} onChange={handleChange('customerPayments')}>
            <AccordionSummary aria-controls="customerPayments-content"  expandIcon={<ExpandMoreIcon />} id="customerPayments-header">
              <Typography>Customer Payments ({props.clientPayments.length}) </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentList project={props.project} payments={props.clientPayments} client={props.client} />
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'manHoursByDay'} onChange={handleChange('manHoursByDay')}>
            <AccordionSummary aria-controls="manHoursByDay-content"  expandIcon={<ExpandMoreIcon />} id="serviceVisits-header">
              <Typography>Man Hours By Day      ({props.manHoursByDay.length || 0} - {`${props.totalManHours || 0} Man Hours`})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LaborByDayList list={props.manHoursByDay} state={state} project={props.project} deleteLine={handleDeleteManHoursLine } editLine={handleEditProjectManHours} />
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'serviceVisits'} onChange={handleChange('serviceVisits')}>
            <AccordionSummary aria-controls="serviceVisits-content"  expandIcon={<ExpandMoreIcon />} id="serviceVisits-header">
              <Typography>Service Visits ({props.serviceVisits.length || 0} - {`${props.totalManHours || 0} Man Hours`})</Typography>
            </AccordionSummary>
            <AccordionDetails>
            </AccordionDetails>
        </Accordion>
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content"  expandIcon={<ExpandMoreIcon />} id="panel1d-header">
              <Typography>Tasks ({`${props.tasks.length} Associated`})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TaskListTable tasks={props.tasks} />
            </AccordionDetails>
          </Accordion>
          <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls="panel5d-content"  expandIcon={<ExpandMoreIcon />} id="panel5d-header">
              <Typography>File Uploads {(props.uploads && uploads.length) ? `(${props.uploads.length} Files)` : '' } </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectUploadsList uploads={props.uploads} state={state} project={props.project}  />
            </AccordionDetails>
          </Accordion>
          

          
        </Grid>
      </Grid>

  )
}

const AlertBar = (props) => {
  const classes = useStyles2();
  let className = props.className
// console.log(className)
  let html = <div className={classes[props.className]}>{props.message}</div>
// console.log(html)
  return html
  // return (
    
  // )
}

const defaultStateValues = {
  name: '',
  uploads: [],
  notes: [],
  vendorInvoicesPaid: 0,
  vendorInvoicesDue: 0,
  totalManHours: 0,
  totalLaborCost: 0,
  manHoursByDay: [],
  milestones: [],
  totalLotInventoryCost: 0,
  activeLotInventoryItem: null,
  pctComplete: 0,
  pctInvoiced: 0,
  invoicedTotal: 0,
  nonEstimateInvoicedTotal: 0,
  estimateInvoicedTotal: 0,
  invoices: []
}

const ProjectDetailsModal = (props) => {
  // console.log('Project Details  Props: do we have history??? ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [project, setProject] = React.useState({})
  const [uploads, setUploads] = React.useState([])
  const [jobSites, setJobSites] = React.useState([])
  const [contacts, setContacts] = React.useState([])
  const [estimates, setEstimates] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [purchaseOrders, setPurchaseOrders] = React.useState([])
  const [client, setClient] = React.useState({})
  const [entity, setEntity] = React.useState({})
  const [notes, setNotes] = React.useState([])
  const [tasks, setTasks] = React.useState([])
  const [showAssociateWorkordersModal, setShowAssociateWorkordersModal] = React.useState(false)
  const [showNewNoteModal, setShowNewNoteModal] = React.useState(false)
  const [state, setState] = React.useState(defaultStateValues)
  const [editProjectModalOpen, setEditProjectModalOpen] = React.useState(false)
  const [estimateTotalAccepted, setEstimateTotalAccepted] = React.useState(0)
  const [vendorInvoicesDue, setVendorInvoicesDue] = React.useState(0)
  const [vendorInvoicesPaid, setVendorInvoicesPaid] = React.useState(0)
  const [vendorInvoices, setVendorInvoices] = React.useState([])
  const [estimatedEstimateCosts, setEstimatedEstimateCosts] = React.useState(0)
  const [workorders, setWorkorders] = React.useState([])
  const [jobs, setJobs] = React.useState([])
  const [serviceVisits, setServiceVisits] = React.useState([])
  const [showTrackLaborModal, setShowTrackLaborModal] = React.useState(false)
  const [showProgressPaymentModal, setShowProgressPaymentModal] = React.useState(false)
  const [taxLocales, setTaxLocales] = React.useState([])
  const [activities, setActivities] = React.useState([])
  const [clientInvoicesPaid, setClientInvoicesPaid] = React.useState(0)
  const [clientInvoicesDue, setClientInvoicesDue] = React.useState(0)
  const [clientInvoices, setClientInvoices] = React.useState([])
  const [projectBalance, setProjectBalance] = React.useState(0)
  const [milestones, setMilestones] = React.useState([])
  const [showMilestoneDrawer, setShowMilestoneDrawer] = React.useState(false)
  const uploadInputRef = React.useRef(null);
  const [laborLineToEdit, setLaborLineToEdit] = React.useState(null)
  const [clientPayments, setClientPayments] = React.useState([])
  const [activeMilestone, setActiveMilestone] = React.useState({})
  const [lotInventoryData, setLotInventoryData] = React.useState([])
  const [punchlistItems, setPunchlistItems] = React.useState([])
  const location = useLocation()
  const history = useHistory()
  // React.useEffect(() => {
  //   let isMounted = true
  //   const fetchProjectDetails= async (id) => {
  //     axios.get(`${props.url}/api/projects/details?id=${id}`).then(response => {
  //       if (response.data) {
  //       // console.log('Project details: ', response.data)
  //         if (response.data.uploads) {
  //         // console.log('Set Uploads: ', response.data.uploads)
  //           setUploads(response.data.uploads)
  //           setState({...state, uploads: response.data.uploads})
  //         }
  //         if (response.data.notes) {
  //           setState({...state, notes: response.data.notes})
  //         }
  //       }
  //     })
  //   }
  //   if (props.project && props.project._id) {
  //     setProject(props.project)
  //     fetchProjectDetails(props.project._id)
  //   }

  //   return () => {
  //     isMounted = false
  //   }
  // }, [props])

  // When Service Visits Change total up our Man Hours and Labor Costs
  // Turn off 3/21/23 -- going to use man hours tracked manually for now
  //  React.useEffect(() => {
  //   // console.log('Service Visits changed - total up our man hours')
  //   let manHours = 0
  //   let totalLabor = props.project.manHoursWorked || 0
  //   serviceVisits.map(item => {
  //     // console.log('Man Hours: %d   -- Total Labor Cost:   %d ', item.totalManHours, item.totalLaborCost)
  //   // console.log(item)
  //     manHours = parseFloat(manHours - 0 + item.totalManHours).toFixed(2)
  //     totalLabor = parseFloat(totalLabor - 0 + item.totalLaborCost).toFixed(2)
  //   })
  //   // console.log('Total Labor: ', totalLabor)
  //   // console.log('Total Man Hours: ', manHours)
  //   setState({...state, totalLaborCost: totalLabor, totalManHours: manHours })
  //  }, [serviceVisits])

  // React.useEffect(() => {
  //   let manHours = props.project.manHoursWorked || 0
  //   let laborEntries = props.project.manHoursByDay || []
  //   let totalLabor = 0
  //   laborEntries.map(item => {
  //     // console.log('Man Hours: %d   -- Total Labor Cost:   %d ', item.totalManHours, item.totalLaborCost)
  //   // console.log('LABOR: ', item)
  //     // manHours = parseFloat(manHours - 0 + item.totalManHours).toFixed(2)
  //     let hourlyRate = 23
  //     let hoursWorked = Number(item.hoursWorked)
  //     let itemTotal = Number(hourlyRate * hoursWorked).toFixed(2)
  //   // console.log('Item Total', itemTotal)
  //     totalLabor = Number(Number(totalLabor) - 0 + Number(itemTotal)).toFixed(2)
  //   })
  // // console.log('Total Labor: ', totalLabor)
  // // console.log('Total Man Hours: ', manHours)
  //   let newState = { totalLaborCost: totalLabor, totalManHours: manHours, manHoursByDay: laborEntries }
  // // console.log('NEw state to set', newState)
  //   setState({...state, ...newState})
  // }, [props.project])


  // When Workorders change get our accompanying service visits
  // React.useEffect(() => {
  //   let isMounted = true
  //   const fetchServiceVisits = async () => {
  //     let woList = []
  //     workorders.map(item => { woList.push(item._id) })
  //     setLoading(true)
  //     axios({
  //       method: 'post',
  //       url:  `${props.url}/api/projects/getAssociatedServiceVisits`,
  //       data: woList
  //     }).then(response => {
  //       setLoading(false)
  //     // console.log('Service visit', response.data)
  //       if (response.data && response.data.length && isMounted) {
  //         setServiceVisits(response.data)
  //       }
  //     })
  //   }

  //   if (workorders && workorders.length) {
  //   // console.log('Get assoicated service visits now', workorders)
  //     fetchServiceVisits()
  //   }
  // }, [workorders])

  const socketMessageUpdate = (data) => {
  // console.log('Socket update', data)
  console.log('Socket message for this project', data)
  // console.log('State right now', state)
  // console.log('props', props)
  // console.log('Current milestones', milestones)
    if (data && data.milestones && data.milestones.length) {
      // Add this milestone
      // let ourMilestones = milestones
      // console.log('Milestones thus far;', ourMilestones)
      // ourMilestones.unshift(data.milestone)
      console.log('Set Milestones ', data.milestones)
      setMilestones(data.milestones)
      // setState({...state, milestones: data.milestones})
    }
  }

  React.useEffect(() => {
  // console.log('Milestoens changes', milestones)
  }, [milestones])

  let customerInvoices = []
  
  React.useEffect(() => {
    let isMounted = true
    let newState = { ...state }
    let projectId = null

    const fetchProjectDetails= async (id) => {
    // console.log('Fetch Project Details')
      const url = `${props.url}/api/projects/details?id=${id}`
    // console.log('URL: ', url)
      axios.get(url).then(response => {
        console.log('Project Details --- 1169?? GOT ALL THE DETAILS???', response.data)
        if (response.data && isMounted) {
          let totalVendorInvoices = 0
          if (response.data && response.data.purchaseOrders) setPurchaseOrders(response.data.purchaseOrders)
          console.log('All the stuff the project needs ', response.data)
          setJobSites(response.data.jobSites)
          setProject(response.data.project)
          setContacts(response.data.contacts)
          props.setContacts(response.data.contacts)
          setActivities(response.data.activities)
          setTaxLocales(response.data.taxLocales)
          setMilestones(response.data.milestones)
          newState.milestones = response.data.milestones
          // let totalManHours = response.data.
          setClient(response.data.client)
          if (response.data.client && response.data.client._id) props.setClient(response.data.client)
          setEntity(response.data.entity)
          setEstimates(response.data.estimates)
          setProjects(response.data.projects)
        // console.log('Set Projects', response.data.projects)
          props.setProjects(response.data.projects)
          setTasks(response.data.tasks)
          setWorkorders(response.data.workorders)
          setJobs(response.data.jobs)
          setPunchlistItems(response.data.punchlistItems)
          if (response.data.payments) {
          // console.log('Client Payments!!', response.data.payments)
            setClientPayments(response.data.payments)
          }
          if (response.data.lotInventoryUsed) {

            setLotInventoryData(response.data.lotInventoryUsed)
            let totalLotInventoryCost = 0
          // console.log('Get our lot inventory costs')
            response.data.lotInventoryUsed.map(item => {
            // console.log('Cost:', item)
              totalLotInventoryCost += Number(item.amount)
            // console.log(totalLotInventoryCost)
            })
            newState.totalLotInventoryCost = totalLotInventoryCost
          }
          if (response.data.estimates) {
            // Get accepted ttotal
            
            let estimatesAccepted = []
            let totalCost = 0
            let totalPrice = 0
            response.data.estimates.map(item => {
              if (item.accepted) {
                estimatesAccepted.push(item)
                totalCost += item.totalCost
                totalPrice += item.totalPrice
              }
            })
            // console.log('Accepted Estimates;', estimatesAccepted)
            // let total = estimatesAccepted.sum('totalPrice')
            // console.log('Totals: %d -- %d ', totalCost, totalPrice)
            newState.estimatesTotalRevenue = totalPrice
            newState.estimatesTotalCost = totalCost
            setEstimateTotalAccepted(totalPrice)
            setEstimatedEstimateCosts(totalCost)
          }
          // setState({
          //   ...state,
          //   contacts: response.data.contacts,
          //   jobSites: response.data.jobSites,
          //   client: response.data.client,
          //   estimates: response.data.estimates,
          //   entity: response.data.entity,
          //   uploads: response.data.uploads,
          //   notes: response.data.notes,
          //   status: 'props.project.status'
          // })
          // if (response.data.contacts) {
          //   setState({...state, contacts: response.data.contacts})
          // }
          // if (response.data.jobSites) {
          //   setState({...state, jobSites: response.data.jobSites})
          // }
          // if (response.data.client) {
          //   setState({...state, client: response.data.client})
          // }
          // if (response.data.estimates) {
          //   setState({...state, estimates: response.data.estimates})
          // }
          // if (response.data.entity) {
          //   setState({...state, entity: response.data.entity})
          // }
          if (response.data.uploads) {
          // console.log('Set Uploads: ', response.data.uploads)
            setUploads(response.data.uploads)
            let totalDue = 0
            let totalPaid = 0
            // let totalVendorInvoices = 0
            let vendorInvoicesArray = []
            let vendorQuotes = []
            let photos = []
            
            response.data.uploads.map(async item => {
            // console.log('UPload:1174:: ', item)
              if (item.documentType === 'Vendor Invoice' || item.category === 'Vendor Invoice') {
              // console.log('INCLUDE: 1176', item)
                if (item.status === 'Paid') totalPaid += Number(item.amount)
                if (item.status === 'Due') totalDue += Number(item.amount)
                totalVendorInvoices += Number(item.amount)
                  vendorInvoicesArray.push(item)
          
                
              }
              if (item.documentType === 'Photos') {
                photos.push(item)
              }
            })
            // console.log('Total Due: ', totalDue)
            // console.log('Total Paid: ', totalPaid)
            totalDue = totalDue
            totalPaid = totalPaid
            setVendorInvoicesPaid(totalVendorInvoices)
          // console.log('tOTAL vENDOR iNVOICES pAID:', totalVendorInvoices)
            setVendorInvoicesDue(totalDue)
            setVendorInvoices(vendorInvoicesArray)
            newState.vendorInvoices = vendorInvoicesArray
            // newState.customerInvoices = customerInvoices
            newState.vendorInvoicesDue = totalDue
            newState.vendorInvoicesPaid = totalPaid
            newState.uploads = response.data.uploads
            // newState = { vendorInvoicesDue: totalDue, vendorInvoicesPaid: totalPaid, uploads: response.data.uploads, status: props.project.status, totalManHours: props.project.manHoursWorked }
            // setState({...state, vendorInvoicesDue: totalDue, vendorInvoicesPaid: totalPaid, uploads: response.data.uploads, status: props.project.status, totalManHours: props.project.manHoursWorked })
          } else {
            newState.uploads = []
            // = { uploads: [], status: props.project.status, totalManHours: props.project.manHoursWorked}
            // setState({...state, uploads: [], status: props.project.status, totalManHours: props.project.manHoursWorked})
          }
          // if (response.data.customerInvoices && response.data.customerInvoices.length) {
          // // console.log('Project invoices: ', response.data.customerInvoices)
          //   setClientInvoices(response.data.customerInvoices)
          //   let totalInvoiced = 0
          //   let totalPaid = 0
          //   response.data.customerInvoices.map(invoice => {
          //     totalInvoiced += parseFloat(invoice.amount)
          //     if (invoice.status === 'Paid') {
          //     // console.log('Paid', invoice)
          //       totalPaid += parseFloat(invoice.amount)
          //     }
          //   })
          // // console.log('Total Paid', totalPaid)
          // // console.log('Total Invoiced', totalInvoiced)
          //   let totalDue = totalInvoiced - totalPaid
          //   newState.clientInvoicesDue = totalDue
          //   newState.clientInvoicesPaid = totalPaid
          //   setClientInvoicesPaid(totalPaid)
          //   setClientInvoicesDue(totalDue)
          //   newState.customerInvoices = response.data.customerInvoices
          // }

          // Get our project balance
        // console.log('Get project balance...', state)
        // console.log('State about to update', newState)
          // Accepted Estiamtes total - clientInvoicesPaid
          let estimateTotal = newState.estimatesTotalRevenue || 0
          // let totalPaid = newState.clientInvoicesPaid || 0
          // console.log('Total Paid payments', totalPaid)
          // console.log('We have total estimate of', estimateTotal)
         
          // console.log('Balance before labor', balance)
          // Add labor
          // console.log('Labo totals', newState.totalLaborCost)
          let totalLaborCost = newState.totalLaborCost
          // balance = parseFloat(balance - totalLaborCost).toFixed(2)
          // console.log('Balance w/ labor', balance)

          // Estimted Profit
          let estimatedProfit = 0
          // let vendorInvoicesPaid = newState.vendorInvoicesPaid 
          // let vendorInvoicesDue = newState.vendorInvoicesDue
        // console.log('Vendor INvoices  %d, and Total Labor: %d ', totalVendorInvoices, totalLaborCost)
          let totalCostsSoFar = Number(totalVendorInvoices) + Number(totalLaborCost) + Number(newState.totalLotInventoryCost)
        // console.log('Total PRoject Costs', totalCostsSoFar)
          let balance = estimateTotal - totalCostsSoFar
        // console.log('Lot Inventory:', newState.totalLotInventoryCost)
          newState.projectBalance = balance
          setProjectBalance(balance)
          // Deal w/ Invoiced Amount vs Accepted Estimates
          if (response.data?.invoices?.length) {
            newState.invoices = response.data.invoices
            console.log('We have invoices to address', response.data.invoices)
            let totalAmount = 0
            let nonEstimateInvoicedTotal = 0
            let estimateInvoicedTotal = 0
            response.data.invoices.forEach(item => {
              totalAmount += Number(item.amount)
              if (!item.estimate) {
                nonEstimateInvoicedTotal += Number(item.amount)
              } else {
                estimateInvoicedTotal += Number(item.amount)
              }
            })
            console.log(totalAmount)
            newState.invoicedTotal = totalAmount
            newState.nonEstimateInvoicedTotal = nonEstimateInvoicedTotal
            newState.estimateInvoicedTotal = estimateInvoicedTotal
          }
        // console.log('Set Project balance', balance)
          newState.pctComplete = props?.project?.pctComplete
          if (response.data.notes) {
            newState.notes = response.data.notes
            // setState({...state, notes: response.data.notes, status: props.project.status, totalManHours: props.project.manHoursWorked})
            setNotes(response.data.notes)
          } else {
            newState.notes = []
            // setState({...state, notes: [], status: props.project.status, totalManHours: props.project.manHoursWorked})
            setNotes([])
          }
          setState({...state, ...newState})
        }
      })
    }

    if (props.project && props.project._id) {
      projectId = props.project._id
      socket.on(`${projectId}_project`, function (msg) {
        msg.props = props
        msg.state = state
        socketMessageUpdate(msg)
      })
      console.log('Set the project', props.project)
      setProject(props.project)
      // if (props.project && props.project._id) {
      // // console.log('Set Our status:::', props.project)
      //   setState({...state, status: props.project.status})
      // }
      let manHours = props.project.manHoursWorked || 0
      let laborEntries = props.project.manHoursByDay || []
      let totalLabor = 0
      laborEntries.map(item => {
        // console.log('Man Hours: %d   -- Total Labor Cost:   %d ', item.totalManHours, item.totalLaborCost)
      // console.log('LABOR: ', item)
        // manHours = parseFloat(manHours - 0 + item.totalManHours).toFixed(2)
        let hourlyRate = Labor_Rate
        let hoursWorked = Number(item.hoursWorked)
        let itemTotal = Number(hourlyRate * hoursWorked).toFixed(2)
      // console.log('Item Total', itemTotal)
        totalLabor = Number(Number(totalLabor) - 0 + Number(itemTotal)).toFixed(2)
      })
    // console.log('Total Labor: ', totalLabor)
    // console.log('Total Man Hours: ', manHours)
      newState.totalLaborCost = totalLabor
      newState.totalManHours = manHours
      newState.manHoursByDay = laborEntries
      newState.vendorNotes = props.project.vendorNotes
    // console.log('NEw state to set', newState)
      
      // setState({...state, vendorNotes: props.project.vendorNotes, status: props.project.status, totalManHours: props.project.manHoursWorked })
      fetchProjectDetails(props.project._id)
      let status = null
      let index = statusOptions.findIndex(item => (item === props.project.status))
      if (index > -1) {
        status = statusOptions[index]
      }
      let statusObj = {
        key: status,
        value: status
      }
       newState.status = status
    }

    console.log('SEt the state now --- ', newState)
    setState({...state, ...newState})
    return () => {
      isMounted = false
      socket.off(`${projectId}_project`)
    }
  }, [props.project])

  const handleSave = () => {
  // console.log(state)
    // console
    if (props.project.vendorNotes !== state.vendorNotes) {
      let data = {
        _id: props.project._id,
        vendorNotes: state.vendorNotes
      }
      setLoading(true)
      // setClient({})
      // setState({...state, note})
      axios({
        method: 'post',
        url:  `${props.url}/api/projects/addVendorNotes`,
        data: data
      }).then(response => {
        setLoading(false)
        if (response.data && response.data._id) {
          props.notification({
            type: 'success',
            title: 'Project Saved',
            message: 'That Project Has Been Saved. Great Job!'
          })
          // setProject.response.data)
          props.updateParent(response.data, 'saved')
          // props.closeModal()
        }
      })
    }
  // console.log('Save Status Update now too')
    setState({...state, uploads: [], notes: [], vendorNotes: ''})
    setProject({})
    setUploads()
    // props.closeModal()
    handleClose()
  }

  const onChange = (e) => {
    // console.log('File upload', e.target.files)
  }

  const handleUpdateFiles = (d) => {
    // console.log('Update oru files!!', d)
    if (d && d.length) {
      let ourUploads = uploads
      for (const item of d) {
        ourUploads.push(item)
      }
      // console.log('Our Uploads: ', ourUploads)
      setUploads(ourUploads)
      setState({...state, uploads: ourUploads})
      // console.log('Project: ', project)
    }
  }

  const handleClose = () => {
    // setProject({})
    // setLoading(true)
    setUploads([])
    // setProject({})
    setEstimates([])
    setNotes([])
    setServiceVisits([])
    setState(defaultStateValues)
    // setState({...state, uploads: [], notes: [], vendorNotes: ''})
    // setTimeout(() => {
    //   setLoading(false)
    props.closeModal()
    // }, 500)
  }

  const handleNewNote = () => {
    setShowNewNoteModal(true)
  }
  const saveNote = (updatedProject) => {
  // console.log('Save note', updatedProject)
    // setShowNewNoteModal(false)
    let newNotes = updatedProject.projectNotes
    // newNotes.unshift(d)
  // console.log('Notes: 1180', newNotes)

    // setNotes(newNotes)
    setShowNewNoteModal(false)
    setProject(updatedProject)
    // setState({...state, notes: newNotes})
  }

  const updateVendorNotes = (d) => {
    // console.log('Update the vendor notes:', updateVendorNotes)
    setState({...state, vendorNotes: d})
  }

  const handleDelete = () => {
    // if (window.confirm('Are You Sure You Wish To Delete This Project?')) {
      // console.log('Delete', project)
      setLoading(true)
      axios({
        method: 'post',
        url:  `${props.url}/api/projects/delete`,
        data: project
      }).then(response => {
        setLoading(false)
  
        if (response.data && response.data.n) {
          props.notification({
            type: 'success',
            title: 'Project Deleted',
            message: 'That Project Has Been Deleted. Great Job!'
          })
          // setProject.response.data)
          
          setState({...state, uploads: [], notes: [], vendorNotes: ''})
          setProject({})
          setUploads([])
          props.updateParent(response.data, 'deleted')
          // props.closeModal()
          handleClose()
          // props.closeModal()
        }
      })
  }

  const handleEdit = () => {
    setEditProjectModalOpen(true)
  }

  const handleChangeStatus = (e) => {
    // console.log('Change Status: ', e.target.value)
    setLoading(true)
    let status = null
    let index = statusOptions.findIndex(item => (item === e.target.value))
    if (index > -1) {
      status = statusOptions[index]
    }
    let statusObj = {
      key: status,
      value: status
    }
    setState({...state, status: status })
    let data = {
      _id: props.project._id,
      status: e.target.value
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/changeStatus`,
      data: data
    }).then(response => {
      setLoading(false)
      let ourProject = {...project}
      ourProject.status = status
      props.updateParent(ourProject, 'saved')
    })
  }

  const responseFromEdit = (editedProject) => {
    // console.log('Project After Edit', editedProject)
    // let newProject = project
    // console.log(newProject)
    // newProject.name = editedProject.name
    // newProject.salesRep = editedProject.salesRep
    setProject(editedProject)
    setEditProjectModalOpen(false)
  }

  const updateProposalList = (data) => {
    // console.log('Update PRoposal List: ', data)
    let estimatesAccepted = []
    let totalCost = 0
    let totalPrice = 0
    data.map(item => {
      if (item.accepted) {
        estimatesAccepted.push(item)
        totalCost += item.totalCost
        totalPrice += item.totalPrice
      }
    })
    // console.log('Accepted Estimates;', estimatesAccepted)
    // let total = estimatesAccepted.sum('totalPrice')
    // console.log('Totals: %d -- %d ', totalCost, totalPrice)
    setEstimateTotalAccepted(totalPrice)
    setEstimatedEstimateCosts(totalCost)
  }

  const CloseButton = () => {
    return (
      <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '0px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
        <CloseIcon />
      </IconButton>
    )
  }

  const handleAssociateWOs = () => {
    setShowAssociateWorkordersModal(true)
  }

  const updateFromAssociatedWos = (type, d) => {
    setShowAssociateWorkordersModal(false)
    if (type === 'saved') {
      // console.log('Added workorders?? ', d)
      let wos = workorders
      for (const item of d) {
        wos.unshift(item)
      }
    // console.log('Wos: ', wos)
      const sortedWorkorders = wos.sort((a, b) => new Date(b.scheduledDate) - new Date(a.scheduledDate))
      // .sort((a, b) => a.scheduledDate - b.scheduledDate)
      // console.log('SORTED: ', sortedWorkorders)
      setWorkorders(sortedWorkorders)
      setState({...state, counter: state.counter })

    }

  }

  const updateVendorInvoiceTotals = (data) => {
    // console.log('update our uploads: ', data)
    // setVendorInvoices(data)
    let totalDue = 0
    let totalPaid = 0
    data.map(async item => {
      // console.log(item)
      if (item.documentType === 'Vendor Invoice') {
        // console.log('INCLUDE: ', item)
        if (item.status === 'Paid') totalPaid += Number(item.amount)
        if (item.status === 'Due') totalDue += Number(item.amount)

          // vendorInvoicesArray.push(item)
  
        
      }
    })
    // console.log('Total Due: ', totalDue)
    // console.log('Total Paid: ', totalPaid)
    totalDue = currencyFormat(totalDue)
    totalPaid = currencyFormat(totalPaid)
    setVendorInvoicesPaid(totalPaid)
    setVendorInvoicesDue(totalDue)
    // setVendorInvoices(data)
    setState({...state, vendorInvoicesDue: totalDue, vendorInvoicesPaid: totalPaid })
  }

  const handleAddLabor = () => {
    setLaborLineToEdit(null)
    setShowTrackLaborModal(true)
  }
  
  const saveLabor = async (type, data) => {
  console.log('Save Labor: ', data)
    setShowTrackLaborModal(false)
    setLoading(true)
    if (laborLineToEdit) {
    // console.log('Editing something!!', data)
      let ourData = {
        updated: data,
        old: laborLineToEdit
      }
      modifyLabor('edit', ourData)
      // let stuff = {
      //   manHours: data.manHours,
      //   date: data.date,
      //   project: props.project._id,
      //   old: laborLineToEdit
      // }
      // axios({
      //   method: 'post',
      //   url:  `${props.url}/api/projects/editManHours`,
      //   data: stuff
      // }).then(response => {
      //   setLoading(false)
      // // console.log('Edited Man hours', response.data)
      //   if (response && response.data && response.data._id) {
      //     setState({...state, totalManHours: response.data.manHoursWorked, manHoursByDay: response.data.manHoursByDay, counter: state.counter++ })
      //   }
      //   props.notification({
      //     type: 'success',
      //     title: 'Man Hours Updated',
      //     message: 'Updated Labor Hours'
      //   })
      // })
    } else {
      
      if (data && data.date && data.manHours) {
        let stuff = {
          ...data,
          project: props.project._id
        }
        if (data.crew && data.crew._id) {
          stuff.crew = data.crew._id
          stuff.crewName = data.crew.name
        }
        axios({
          method: 'post',
          url:  `${props.url}/api/projects/addManHours`,
          data: stuff
        }).then(response => {
          setLoading(false)
        // console.log('Add Man hours', response.data)
          if (response && response.data && response.data._id) {
            recalculateLaborShit(response.data)
            // setState({...state, totalManHours: response.data.manHoursWorked, manHoursByDay: response.data.manHoursByDay,  counter: state.counter++ })
          }
          props.notification({
            type: 'success',
            title: 'Man Hours Saved',
            message: 'Saved Labor Hours'
          })
        })
      }
    }
    setLaborLineToEdit(null)
  }

  const modifyLabor = async (type, data) => {
  // console.log('Modiication Type: ', type)
  // console.log('Modify Labor: ', data)
    setShowTrackLaborModal(false)
    setLoading(true)
    let stuff = {
      data: data,
      type: type,
      project: props.project._id
    }
    if (data.crew && data.crew._id) {
      stuff.crew = data.crew._id
      stuff.crewName = data.crew.name
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/modifyManHours`,
      data: stuff
    }).then(response => {
      setLoading(false)
    // console.log('Updated Man hours', response.data)
      if (response && response.data && response.data._id) {
        recalculateLaborShit(response.data)
      // console.log('Our updated project...')
      //   let newState = {}
      //   let manHours = response.data.manHoursWorked || 0
      //   let laborEntries = response.data.manHoursByDay || []
      //   let totalLabor = 0
      //   laborEntries.map(item => {
      //     // console.log('Man Hours: %d   -- Total Labor Cost:   %d ', item.totalManHours, item.totalLaborCost)
      //   // console.log('LABOR: ', item)
      //     // manHours = parseFloat(manHours - 0 + item.totalManHours).toFixed(2)
      //     let hourlyRate = 23
      //     let hoursWorked = Number(item.hoursWorked)
      //     let itemTotal = Number(hourlyRate * hoursWorked).toFixed(2)
      //   // console.log('Item Total', itemTotal)
      //     totalLabor = Number(Number(totalLabor) - 0 + Number(itemTotal)).toFixed(2)
      //   })
      // // console.log('Total Labor: ', totalLabor)
      // // console.log('Total Man Hours: ', manHours)
      //   newState.totalLaborCost = totalLabor
      //   newState.totalManHours = manHours
      //   newState.manHoursByDay = laborEntries
      //   setState({...state, ...newState})
      }
      props.notification({
        type: 'success',
        title: 'Man Hours Updated',
        message: 'Updated Labor Hours'
      })
    })
  }

  const recalculateLaborShit = (data) => {
    console.log('Recalculate labor!', data)
    let newState = {}
        let manHours = data.manHoursWorked || 0
        let laborEntries = data.manHoursByDay || []
        let totalLabor = 0
        laborEntries.map(item => {
          // console.log('Man Hours: %d   -- Total Labor Cost:   %d ', item.totalManHours, item.totalLaborCost)
        // console.log('LABOR: ', item)
          // manHours = parseFloat(manHours - 0 + item.totalManHours).toFixed(2)
          let hourlyRate = 33
          let hoursWorked = Number(item.hoursWorked)
          let itemTotal = Number(hourlyRate * hoursWorked).toFixed(2)
        // console.log('Item Total', itemTotal)
          totalLabor = Number(Number(totalLabor) - 0 + Number(itemTotal)).toFixed(2)
        })
      // console.log('Total Labor: ', totalLabor)
      // console.log('Total Man Hours: ', manHours)
        newState.totalLaborCost = totalLabor
        newState.totalManHours = manHours
        newState.manHoursByDay = laborEntries
        console.log('Set it', newState)
        setState({...state, ...newState})
  }

  const handleAddProgressPayment = () => {
    setShowProgressPaymentModal(true)
  }
  const saveProgressPayments = (d) => {
  // console.log('Save this: ', d)
  }
  const closeProgressPaymentModal = () => {
    setShowProgressPaymentModal(false)
  }

  const updateProjectNotes = (updatedProject)=> {
  // console.log('Update prjoect notes', updatedProject)
    setProject(updatedProject)
    // setNotes(updatedProject.ProjectNotes)
  }

  const updateParentWithNewActivity = (newActivity) => {
  // console.log('New Activity: ', newActivity)
    let currentActivities = activities
    if (currentActivities && currentActivities.length) {
      currentActivities.unshift(newActivity)
    } else {
      currentActivities = [newActivity]
    }
  // console.log('New Activities', currentActivities)
    setActivities(currentActivities)
    setState({ ...state, activities: currentActivities })
  }
  
  const handleModifyManHours = (updateData) => {
  // console.log('Modify Man Hours', updateData)
    if (updateData && updateData.type) {
    // console.log('Type: ', updateData.type)
      if (updateData.type === 'delete') {
        modifyLabor('delete', updateData.item)
      }
      if (updateData.type === 'edit') {
      // console.log('Edit this bitch', updateData.item)
        setLaborLineToEdit(updateData.item)
        setShowTrackLaborModal(true)
      }
    }
  }

  const handleViewMilestoneDetails = (data) => {
  // console.log('View details', data)
    setActiveMilestone(data)
    setShowMilestoneDrawer(true)
  }

  const handleClosemilestoneDrawer = () => {
    setShowMilestoneDrawer(false)
  }

  const handleDeleteMilestone = (itemToDelete) => {
  // console.log('Delete this:', itemToDelete)
    let ourList = milestones
    let index = ourList.findIndex(item => (item._id === itemToDelete._id))
    if (index > -1) {
      ourList.splice(index, 1)
      setMilestones(ourList)
      setState({...state, counter: state.counter++})
    }
  }

  const handleMilestoneUpdate = (updatedItem) => {
    console.log('Milestone update...')
    let ourList = milestones
    let index = ourList.findIndex(item => (item._id === updatedItem._id))
    if (index > -1) {
      ourList[index] = updatedItem
      setMilestones(ourList)
      setState({...state, counter: state.counter++})
    }
  }

  const handleReOrderMilestones = (reorderedList) => {
    // console.log('State:', state)
  // console.log('props;', props)
    setMilestones(reorderedList)
    // setLoading(true)
    setState({...state, counter: state.counter++})
    let data = { list: reorderedList, projectId: props.project._id}
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/reorderMilestones`,
      data: data
    }).then(response => {
      // setLoading(false)
    })
  }

  const handleUploadComplete = (data) => {
  // console.log('Finished w/ an upload...', data)
    if (data && data.filesUploaded && data.filesUploaded.length) {
    // console.log('We hae some uploaded files!!!')
      let msg = `Uploaded ${data.filesUploaded.length} to the Project`
      if (data.filesUploaded.length < 2) msg = `Uploaded a new file to the Project`
      props.notification({
        type: 'success',
        message: msg,
        title: 'Upload Complete'
      })
      let existingUploads = uploads
    // console.log('We have %d uploads right nwo', uploads.length)
      existingUploads = data.filesUploaded.concat(existingUploads)
    // console.log('Updated', existingUploads)
      setUploads(existingUploads)
    }
    if (data && data.duplicateFiles && data.duplicateFiles.length) {
      let msg = `We detected ${data.duplicateFiles.length} Duplicate Files`
      props.notification({
        type: 'warning',
        message: msg,
        title: 'Duplicate Files Detected'
      })
    }
    if (data && data.errorFiles && data.errorFiles.length) {
      let msg = `We detected ${data.errorFiles.length} Files That Were NOT Uploaded`
      props.notification({
        type: 'warning',
        message: msg,
        title: 'Error Detected'
      })
    }

  }

  const handleAddNewLotInventoryItem = (item) => {
  // console.log('add this one', item)
    let existingItems = lotInventoryData
    existingItems.unshift(item)
    setLotInventoryData(existingItems)
    let newTot = state.totalLotInventoryCost + Number(item.amount)
    let newBalance = projectBalance - Number(item.amount)
    setProjectBalance(newBalance)
    setState({...state, totalLotInventoryCost: newTot, counter: state.counter++ })
  }

  const handleEditLotInventoryItem = (data) => {
  // console.log('Edit this one:', data)
    setState({...state, activeLotInventoryItem: data })
  }

  const handleModifyLotInventoryDetails = (data) => {
  // console.log('Update this guy:', data)
    let lotInventory = lotInventoryData
    let index = lotInventory.findIndex(item => (item._id === data._id))
    if (index > -1) {
      let newTot = state.totalLotInventoryCost - Number(lotInventory[index].amount) + Number(data.amount)
      let newBalance = projectBalance + Number(lotInventory[index].amount) - Number(data.amount)
      lotInventory[index] = data
      setLotInventoryData(lotInventory)
      setProjectBalance(newBalance)
      setState({...state, activeLotInventoryItem: null, totalLotInventoryCost: newTot, counter: state.counter++ })
    }
    // axios({
    //   method: 'post',
    //   data: data,
    //   url: `${props.url}/api/projects/modifyLotInventoryUsed`
    // }).then(response => {
    //   if (response.data && response.data.error) {
    //     props.notification({
    //       type: 'danger',
    //       message: response.data.message,
    //       title: 'Error'
    //     })
    //   } else if (response.data && response.data.message) {
    //     let lotInventory = lotInventoryData
    //     let index = lotInventory.findIndex(item => (item._id === data._id))
    //     if (index > -1) {
    //       let newTot = state.totalLotInventoryCost - Number(lotInventory[index].amount) + Number(data.amount)
    //       let newBalance = projectBalance + Number(lotInventory[index].amount) - Number(data.amount)
    //       lotInventory[index] = data
    //       setLotInventoryData(lotInventory)
    //       setProjectBalance(newBalance)
    //       setState({...state, activeLotInventoryItem: null, totalLotInventoryCost: newTot, counter: state.counter++ })
    //     }
    //     props.notification({
    //       type: 'success',
    //       message: response.data.message,
    //       title: 'Modification Complete'
    //     })
    //   }

    // })
  }

  const handleDeleteLotInventoryItem = (data) => {
  // console.log('Delete this one:', data)
    axios({
      method: 'post',
      data: data,
      url: `${props.url}/api/projects/deleteLotInventoryUsed`
    }).then(response => {
      if (response.data && response.data.error) {
        props.notification({
          type: 'danger',
          message: response.data.message,
          title: 'Error'
        })
      } else if (response.data && response.data.message) {
        props.notification({
          type: 'success',
          message: response.data.message,
          title: 'Deletion Complete'
        })
        let lotInventory = lotInventoryData
        let index = lotInventory.findIndex(item => (item._id === data._id))
        if (index > -1) {
          let newTot = state.totalLotInventoryCost - Number(lotInventory[index].amount)
          let newBalance = projectBalance + Number(lotInventory[index].amount)
          lotInventory.splice(index, 1)
          setLotInventoryData(lotInventory)
          setProjectBalance(newBalance)
          setState({...state, totalLotInventoryCost: newTot, counter: state.counter++ })
        }
      }
    })
  }

  const handleCloseTheLotInventoryModal = () => {
    setState({...state, activeLotInventoryItem: null })
  }

  const handleUpdatePct = (e, newPct) => {
    setState({...state, pctComplete: newPct })
    let data = {
      _id: props.project._id,
      pctComplete: newPct
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/changeStatus?pctComplete=1`,
      data: data
    }).then(response => {
      console.log('Saved status', response.data)
      // setLoading(false)
      // let ourProject = {...project}
      // ourProject.status = status
      // props.updateParent(ourProject, 'saved')
    })
  }
  const handleUpdatePctInvoiced = (e, newPct) => {
    // setState({...state, pctInvoiced: newPct })
    // let data = {
    //   _id: props.project._id,
    //   pctInvoiced: newPct
    // }
    // axios({
    //   method: 'post',
    //   url:  `${props.url}/api/projects/changeStatus?pctInvoiced=1`,
    //   data: data
    // }).then(response => {
    //   // console.log('Saved status', response.data)
    //   // setLoading(false)
    //   // let ourProject = {...project}
    //   // ourProject.status = status
    //   // props.updateParent(ourProject, 'saved')
    // })
  }

  const handleAssignedToChange = (d) => {
    console.log('Change assigned to data for milestone', d)
  }

  let jobSite = {
    name: 'Jobsite'
  }
  if (project.jobSite) {
    let index = jobSites.findIndex(item => (item._id === project.jobSite))
    if (index > -1) {
      jobSite = jobSites[index]
    }
  }
  // console.log('2058', jobSite)
  let invoicedTotal = state?.invoicedTotal ?? 0;
  let estimateTotalAcceptedNum = estimateTotalAccepted || 0; // Fallback to 0 if it's undefined or falsy
  if (state?.nonEstimateInvoicedTotal) estimateTotalAcceptedNum += Number(state.nonEstimateInvoicedTotal)
  // if (state?.estimateInvoicedTotal) estimateInvoicedTotal 
  // Calculate percentage if estimateTotalAccepted is not zero, otherwise set to 0.0
  let invoicedPercentage = estimateTotalAcceptedNum > 0 
      ? (100 * (invoicedTotal / estimateTotalAcceptedNum)).toFixed(1) 
      : "0.0";

  // Check if the result is NaN (this will happen if invoicedTotal is not a number)
  invoicedPercentage = isNaN(parseFloat(invoicedPercentage)) ? "0.0" : invoicedPercentage;

  // let invoicedPercentage = 100 * (state?.invoicedTotal / estimateTotalAccepted)
  // console.log('Invoiced %', invoicedPercentage)
  // console.log(state)
  // console.log('Project', project)


  return (
    <div>
      <Dialog open={props.open} fullScreen onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title">
                  <div style={{ paddingTop: '0.5em', paddingBottom: '1.25em', backgroundColor: '#eee' }}>
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={handleEdit}
                    style={{marginLeft: 25}}
                    >
                    <EditIcon />
                  </IconButton>
                  <Tooltip title="Associate Workorders">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={handleAssociateWOs}
                      style={{marginLeft: 25}}
                      >
                      <BuildIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add Labor Hours">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={handleAddLabor}
                      style={{marginLeft: 25}}
                      >
                      <AddAlarmIcon />
                    </IconButton>
                  </Tooltip>
                  <AddLotInventory activeLotInventoryItem={state.activeLotInventoryItem} closeModalOnly={handleCloseTheLotInventoryModal} modifyItem={handleModifyLotInventoryDetails} project={project} client={props.client} counter={state.counter} addNewItem={handleAddNewLotInventoryItem} />
                  <Tooltip title="Add Progress Payment">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={handleAddProgressPayment}
                      style={{marginLeft: 25}}
                      >
                      <AttachMoneyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Breakdown Report">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={props.handleReport}
                      style={{marginLeft: 50}}
                      >
                      <AssessmentIcon />
                    </IconButton>
                  </Tooltip>
                  <span style={{marginLeft: 50 }} >
                   
                      <DeleteComponent
                        type="Projects"                        
                        item={project}
                        verificationText={`Are you sure you wish to Delete this project?`}
                        confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
                        confirmationButton="DELETE"
                        denyText="NO"
                        ifApproved={handleDelete}
                        deleteFunction={'project'}
                      />
                  </span>
                  <span style={{marginLeft: 50 }} >
                    <ActivityDrawer project={project} contacts={contacts} updateParentWithNewActivity={updateParentWithNewActivity} />
                  </span>
                  <span
                    style={{ marginLeft: '4em', fontSize: '1.5em', color: '#555' }}>
                      {project.name ? `${project.name}` : 'Un-Named Project' } {project.salesRep ? `(${project.salesRep.displayName})` : '' }
                  </span>
                  <span style={{ marginLeft: '4em', color: '#555' }}>
                    <Tooltip title="Upload Files">
                     <ProjectUploadComponent project={project} onUploadComplete={handleUploadComplete} />
                    </Tooltip>
                  </span>
                  <CloseButton />
                  </div>
                
                  
                  
          
          <DialogContent >
         
          
          <Grid container >
              <Grid item lg={2} md={3} sm={6} xs={12}>
                    
                    <div className={classes.wrapper} >
                      <div className={classes.estimateTotal}>
                        <div className={classes.bigText}>
                          Accepted Estimates Total
                        </div>
                        <div className={classes.actuaLItem}>
                           Amount: <span className={classes.bigText}>{ currencyFormat(estimateTotalAccepted)} </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    
                    <div className={classes.wrapper} >
                      <div className={classes.estimatedCost}>
                        <div className={classes.bigText}>
                        Total Labor Cost
                        </div>
                        <div className={classes.actuaLItem}>
                           Amount: <span className={classes.bigText}>{ currencyFormat(state.totalLaborCost) } </span>
                           <span style={{ marginLeft: 10}} >
                              Man Hours: <span className={classes.bigText}>{ currencyFormatWithOutFlair(state.totalManHours) } </span>
                            </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                   
                    <div className={classes.wrapper} >
                      <div className={classes.invoicesPaid}>
                        <div className={classes.bigText}>
                        Vendor Invoices
                        </div>
                        <div className={classes.actuaLItem}>
                           Amount: <span className={classes.bigText}>{ currencyFormat(vendorInvoicesPaid)} </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid>
                <Grid item lg={2} md={3} sm={6} xs={12}>
                   
                   <div className={classes.wrapper} >
                     <div className={classes.inventoryAndShit}>
                       <div className={classes.bigText}>
                       Inventory & Other
                       </div>
                       <div className={classes.actuaLItem}>
                          Amount: <span className={classes.bigText}>{ currencyFormat(state.totalLotInventoryCost)} </span>
                       </div>
                       
                     </div>
                   </div>
               </Grid>
                {/* <Grid item lg={2} md={3} sm={6} xs={12}>
                   
                   <div className={classes.wrapper} >
                     <div className={classes.invoicesDue}>
                       <div className={classes.bigText}>
                     
                   Invoices Due
                       </div>
                       <div className={classes.actuaLItem}>
                          Amount: <span className={classes.bigText}>{currencyFormat(vendorInvoicesDue)} </span>
                       </div>
                       
                     </div>
                   </div>
               </Grid> */}
               {/* <Grid item lg={2} md={3} sm={6} xs={12}>
                    
                    <div className={classes.wrapper} >
                      <div className={classes.paymentsCollected}>
                        <div className={classes.bigText}>
                        Payment Collected
                        </div>
                        <div className={classes.actuaLItem}>
                        Amount: <span className={classes.bigText}>{currencyFormat(clientInvoicesPaid)} </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid> */}
                <Grid item lg={2} md={3} sm={6} xs={12}>
                    
                    <div className={classes.wrapper} >
                      <div className={classes.projectBalance}>
                        <div className={classes.bigText}>
                          Projected GP
                        </div>
                        <div className={classes.actuaLItem}>
                           Amount: <span className={classes.bigText}>{ currencyFormat(projectBalance)} </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid>

              <Grid item lg={12} xs={12}>
                <span style={{margin: 10}}>&nbsp;</span>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.titleText}>
                  {project.name ? `${project.name}` : 'Un-Named Project' } {project.salesRep ? `(${project.salesRep.displayName})` : '' }
                  { (client && client._id) ? (
                    <>
                     <br /> 
                    <Link 
                      style={{pointer: 'cursor', color: 'blue', fontSize: 16 }}
                      target="_blank"
                      to={{
                            pathname: '/clients/' + client?._id,
                            // search: '?sort=name',
                            // hash: '#the-hash',
                            state: { client: client }
                          }}> 
                          {(client && client._id) ? client.displayName : '' }
                    </Link>                 
                    </>
                  ) : null
                }
  
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.titleText}>
                {
                  jobSite ? `${jobSite?.name} ${jobSite?.address || ''} ${jobSite?.city || ''} ${jobSite?.zip || ''}` : `JobSite`
                }
              </Grid>
             
              
              <Grid item lg={12} md={12} sm={12} xs={12} >
                
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12} className={classes.subTitleText}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.status}
                  style={{ width: '50%', maxWidth: 350 }}
                  variant="outlined"
                  onChange={handleChangeStatus}
                  label="Project Status"
                >
                  {
                    statusOptions.map(unit => {
                      return (
                      <MenuItem key={unit} value={unit} >{unit}</MenuItem>
                    )})
                  }
                </Select>
              </Grid>
              
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <ProjectInvoicingDetails 
                    state={state}
                    estimateTotalAccepted={estimateTotalAccepted}
                    currencyFormat={currencyFormat}
                    client={client}
                  />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                      <PercentageSlider title={'Invoiced Percentage'} percentage={invoicedPercentage} setPercentage={handleUpdatePctInvoiced} />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                      <PercentageSlider title='Percent Complete' percentage={state.pctComplete} setPercentage={handleUpdatePct} />
                  </Grid>
                </Grid>
                 
              </Grid>
            </Grid>          
          <Grid container>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{border: '2px #999 solid', borderRadius: 10 }}>
                   <ProjectContactsTable project={project} />
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}  className={classes.notesBox} >
                    <ProjectNotes project={project} saveNote={saveNote} updateProjectNotes={updateProjectNotes} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {/* <AlertBar className="dangerAlert" message="MILESTONE DATE PAST DUE - Concrete Pour Was Scheduled For 5 Days Ago" />
                    <AlertBar className="warningAlert" message="Rain Forecasted In The Area This Week"/>
                    <AlertBar className="infoAlert" message="Project Paused - Pending Deposit"/> */}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} >
                    <ProjectDetailArea 
                      updateVendorInvoiceTotals={updateVendorInvoiceTotals} 
                      salesReps={props.salesReps} 
                      workorders={workorders} 
                      totalManHours={state.totalManHours} 
                      manHoursByDay={state.manHoursByDay} 
                      serviceVisits={serviceVisits} 
                      project={project} 
                      tasks={tasks} 
                      vendorInvoices={vendorInvoices} 
                      customerInvoices={clientInvoices} 
                      clientPayments={clientPayments}
                      activities={activities}
                      uploads={uploads} 
                      notes={state.notes} 
                      estimates={estimates} 
                      jobSites={jobSites}
                      jobSite={jobSite}
                      contacts={contacts} 
                      client={client} 
                      taxLocales={taxLocales} 
                      entity={entity} 
                      updateVendorNotes={updateVendorNotes} 
                      updateProposalList={updateProposalList}
                      handleModifyManHours={handleModifyManHours}
                      purchaseOrders={purchaseOrders}
                      history={props.history}
                      lotInventoryDetails={lotInventoryData}
                      handleDeleteLotInventoryItem={handleDeleteLotInventoryItem}
                      handleEditLotInventoryItem={handleEditLotInventoryItem}
                      invoices={state.invoices}
                      punchlistItems={punchlistItems}
                    />
                    </Grid>         
                  </Grid>
              
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <ProjectMilestonesList punchlistItems={punchlistItems} project={project} milestones={milestones} contacts={contacts} viewMilestoneDetails={handleViewMilestoneDetails} deleteMilestoneFromProject={handleDeleteMilestone} updateMilestone={handleMilestoneUpdate} reorderMilestones={handleReOrderMilestones} />
            </Grid>
                   
              </Grid>


            {/* <ProjectTimeline project={project} /> */}
          </DialogContent>
       

        </Dialog>
         <MilestoneDetailsDrawer punchlistItems={punchlistItems} open={showMilestoneDrawer} onClose={handleClosemilestoneDrawer} milestone={activeMilestone} handleAssignedToChange={handleAssignedToChange} />
         <TrackLaborModal projectId={project?._id || null} crews={props.crews} open={showTrackLaborModal} updateParent={saveLabor} lineToEdit={laborLineToEdit} />
         <EditProgressPaymentModal open={showProgressPaymentModal} jobs={jobs} workorders={workorders} project={project} updateParent={saveProgressPayments} closeModal={closeProgressPaymentModal} />
         <NewNoteModal project={project} open={showNewNoteModal} updateParent={saveNote} closeModal={e => setShowNewNoteModal(false)} />
         <EditProjectModal open={editProjectModalOpen} project={project} jobSites={jobSites} clients={[]} salesList={[]} updateParent={responseFromEdit} closeModal={e => setEditProjectModalOpen(false) } />
         <AssociateWorkordersModal open={showAssociateWorkordersModal} workorders={workorders} client={client} project={project} updateParent={updateFromAssociatedWos} />
      
        </div>
  )
}

const InvoicedVsEstimateChart = ({ invoicedTotal, estimatesTotal }) => {

  return (
    <div>
      {/* <InvoicedVsEstimated /> */}

    </div>
  )
}
const ProjectDetailsContainer = (props) => {
// console.log('Project Details Cotnainer - get our shit here to serve the modal', props)
  const [showProjectDetailsModal, setShowProjectDetailsModal] = React.useState(false)
  const [project, setProject] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const location = useLocation()
  React.useEffect(() => {
    let isMounted = true
    const fetchProject = async (id) => {
    // console.log('Fetch Project')
      setLoading(true)
      axios.get(`${props.url}/api/projects/details?id=${id}&quick=true`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data && response.data.project) {
        console.log('Project Details Container -1840 Redundant???', response.data)
          if (isMounted) {
            // client,
            // jobSites,
            // entity,
            // estimates,
            // contacts,
            // notes,
            // uploads,
            // tasks,
            // events: [],
            // milestones: [],
            // estimateUploads,
            // workorders,
            // jobs,
            // taxLocales
            setProject(response.data.project)
            setShowProjectDetailsModal(true)
            // setState({...state, projects: response.data.projects})
          }
        }
      })
    }
    
    if (props.match && props.match.params && props.match.params.projectId) {
    // console.log('Get Project:', props.match.params.projectId)
      // getClient(props.match.params.clientId)
      fetchProject(props.match.params.projectId)

      // setState({...state, openItem: props.match.params.type, _id: props.match.params._id })
    }
    return () => {
      isMounted = false
    }
  }, [])
  // React.useEffect(() => {
  //   let isMounted = true

  //   const fetchProjects = async () => {
  //   // console.log('Fetch Projects')
  //     setLoading(true)
  //     axios.get(`${props.url}/api/projects/details?id=${id}`).then((response, status, data) => {
  //       setLoading(false)
  //       if (response && response.data && response.data.projects && response.data.projects.length) {
  //         if (isMounted) {
  //           setProjects(response.data.projects)
  //           setState({...state, projects: response.data.projects})
  //         }
  //       }
  //     })
  //   }

  //   fetchProjects()
  //   return () => {
  //     isMounted = false
  //   }
  // }, [])

  const handleCloseModal = () => {
    // setShowProjectDetailsModal(false)
    window.close()
  }

  const updateProjectDetails = (d, updateType) => {
  // console.log('modify pRoject: ', d)
  // console.log('type:', updateType)
    // if (updateType === 'deleted') {
    //   let projList = props.projects
    //   // console.log(projList)
    //   let index = projList.findIndex(item => item._id.toString() === d._id.toString())
    //   // console.log('INDEX:', index)
    //   // console.log('DELETE: ', projList[index])
    //   if (index > -1) {
    //     // console.log('Deleting: ', index)
    //     // projList[index] = d
    //     projList.splice(index, 1)
    //     // console.log('AFter Splice: ', projList)
    //     setRows(projList)
    //     setFilteredRows(projList)
    //     // setState({...state, project: d })
    //   }
    // }
    // if (updateType === 'saved') {
    //   let projList = props.projects
    //   let index = projList.findIndex(item => item._id === d._id)
    //   if (index > -1) {
    //     projList[index] = d
    //     setRows(projList)
    //     setFilteredRows(projList)
    //   }
    //   setState({...state, project: d })
    // }
  }

  // <ProjectDetailsModal open={showProjectDetailsModal} project={project} closeModal={handleCloseModal } updateParent={ updateProjectDetails } handleRefresh={handleRefresh} />

  const handleRefresh = () => {
  // console.log('Refresh Project')
  }

  const handleReport = () => {
    // console.log('Run our report!!', project)
    // console.log(props)
    const projectId = project?._id
    if (!projectId) return
    let msg = `Are you sure you wish to export a Monthly Breakdown Report for this project?`
    if (window.confirm(msg)) {
      axios({
        method: 'get',
        url: `${props.url}/api/projects/runReport?id=${projectId}`
      }).then(response => {
        if (response.data && response.data.success) {
          props.notification({
            type: 'success',
            message: `Report is running and will be emailed to you (${props?.user?.email || 'No Email'}) upon completion.`,
            title: 'Project Breakdown Report'
          })
        }
      }).catch(err => {
        props.notification({
          type: 'warning',
          message: 'Something Went Wrong',
          title: 'Uh Oh'
        })
      })
    }
  }

  return (
    <>
      {
        loading ? (
          <div style={{display: 'flex', justifyContent: 'center', marginTop: 150 }}>
            
            <CircularProgress size={148} />
          </div>
        ) : <ProjectDetailsModal {...props} open={showProjectDetailsModal} project={project} closeModal={handleCloseModal } updateParent={ updateProjectDetails } handleRefresh={handleRefresh} handleReport={handleReport} /> 
      }
    </>
  )
}

const ProjectContactsTable = ({ project }) => {
  const classes = useStyles()
  return (
    <Table >
    <TableHead>
      <TableRow>
        <TableCell className={classes.subHeaderText}>Contact Type</TableCell>
        <TableCell className={classes.subHeaderText}>Name</TableCell>
        <TableCell className={classes.subHeaderText}>Phone</TableCell>
        <TableCell className={classes.subHeaderText}>Email</TableCell>
      </TableRow>
    
    </TableHead>
    <TableBody>
      {
        (project.salesRep && project.salesRep._id) ? (
          <TableRow style={{ height: 33 }}>
            <TableCell className={classes.subHeaderText}>
              Salesperson
            </TableCell>
            <TableCell  className={classes.subHeaderText}>{project.salesRep.displayName}</TableCell>
            <TableCell  className={classes.subHeaderText}>{formatPhoneNumber(project.salesRep.primaryPhone)}</TableCell>
            <TableCell  className={classes.subHeaderText}>{project.salesRep.email}</TableCell>
          </TableRow>
        ) : null
      }
      {
        (project.projectManager && project.projectManager._id) ? (
          <TableRow style={{ height: 33 }}>
            <TableCell className={classes.subHeaderText}>
              Project Manager
            </TableCell>
            <TableCell  className={classes.subHeaderText}>{project.projectManager.displayName}</TableCell>
            <TableCell  className={classes.subHeaderText}>{formatPhoneNumber(project.projectManager.primaryPhone)}</TableCell>
            <TableCell  className={classes.subHeaderText}>{project.projectManager.email}</TableCell>
          </TableRow>
        ) : null
      }
      {
        (project.foreman && project.foreman._id) ? (
          <TableRow style={{ height: 33 }}>
          <TableCell className={classes.subHeaderText}>
              Foreman
            </TableCell>
            <TableCell  className={classes.subHeaderText}>{project.foreman.displayName}</TableCell>
            <TableCell  className={classes.subHeaderText}>{formatPhoneNumber(project.foreman.primaryPhone)}</TableCell>
            <TableCell  className={classes.subHeaderText}>{project.foreman.email}</TableCell>
          </TableRow>
        ) : null
      }
    </TableBody>
  </Table>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    salesReps: state.salesReps,
    crews: state.crews
  }
}

const mapDispatchToProps = (dispatch) => {
  // console.log('UPdate our contacts/client!!! dispatch', dispatch)
  return {
    setContacts: (contacts) => { dispatch(setContacts(contacts)) },
    setProjects: (projects) => { dispatch(setProjects(projects)) },
    setClient: (client) => { dispatch(setClient(client)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsContainer)
