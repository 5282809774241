import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const TaxLocaleDetails = ({ locs, updateTaxStuff }) => {
    // console.log('REnder TAX LOCALE DETAILS', locs)
    if (locs && locs.length) {
      return locs.map((item, index) => {
        // console.log(item)
        return (
          <FormControl fullWidth key={index} style={{marginBottom: '15px'}}>
            <Grid container spacing={4}>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  label="Taxable Area"
                  variant="outlined"
                  type="text"
                  fullWidth
                  disabled
                  value={`${item.name} - ${item.rate}`}
                  // onChange={e => setState({...state, taxAmount: e.target.value})}
                  // onFocus={handleFocus}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6}>
                <TextField
                  label="Taxable Amount"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={item.taxableAmount}
                  onChange={e => updateTaxStuff(e, 'taxableAmount', index, e.target.value, item)}
                  // onFocus={handleFocus}
                />
              </Grid>
              {/* <Grid item lg={3} sm={3} md={3}>
                <TextField
                  label="Tax Amount"
                  variant="outlined"
                  type="text"
                  fullWidth
                  disabled
                  value={item.taxAmount}
                  // onChange={e => setState({...state, taxAmount: e.target.value})}
                  // onFocus={handleFocus}
                />
              </Grid> */}
            </Grid>
              
          </FormControl>
        )
      })
    } else {
      return <div></div>
    }
  }

const EditTaxDetails = (props) => {
// console.log('EditTaxDetails  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [individual, setIndividual] = React.useState(true);
  // const [invoice, setInvoice] = React.useState(null)
  const [state, setState] = React.useState({
    equipment: {},
    chosenLocales: []
  })

  React.useEffect(() => {
    setState({...state, chosenLocales: props.wo.taxLocales })
  }, [props.wo])

const handleClose = () => {
  // console.log('Closing DepositModal Modal')
  props.closeModal()
}

const handleSave = () => {
  setLoading(true)
// console.log('Save the tax details!!!', state)
// console.log('PROPS:', props)
  state.workorderId = props.wo._id
  state.clientId = props.client._id
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/modifyTaxesOnWorkorder`,
      data: state
    }).then(response => {
      setLoading(false)
    // console.log('Axios response from Modify Taxes: ', response)
      if (response && response.data && response.data.workorder && response.data.workorder._id) {
        props.updateFromTaxes(response.data.workorder)
        props.notification({
          type: 'success',
          title: 'Tax Amounts Updated',
          message: 'We Have Modified Those Taxes'
        })
      }
    //     // console.log('We have a new equipment!!', response.data)
    //     props.notification({
    //       type: 'success',
    //       title: 'Equipment Created!',
    //       message: 'Equipment Was Created Successfully'
    //     })
    //     setState({...state,
    //       equipment: {}
    //     })
    //     // handleClose('success', response.data)
    //     props.updateParent({equipment: response.data})
    //   } else {
    //     props.notification({
    //       type: 'warning',
    //       title: 'Equipment Error',
    //       message: response.data.message
    //     })
        // props.notification({
        //   type: 'warning',
        //   title: 'Unknown Error',
        //   message: 'We Do Not Know Why This Shows Up...'
        // })
    //   }
  // }).catch(response => {
  //   // console.log('Error saving equipment')
  //   if (response && response.data && response.data.message) {
  //     // console.log('Got an error', response.data.message)
  //     props.notification({
  //       type: 'warning',
  //       title: 'Equipment Error',
  //       message: response.data.message
  //     })
  //   }
  })
}

const updateTaxStuff = (e, type, index, val, locale) => {
    // console.log('Update TaX stuff on TAX DETAILS: ', index)
    // console.log(val)
    let locs = state.chosenLocales
    let loc = locs[index]
    // loc[type] = val
    if (type === 'taxableAmount') {
      // Calculate Taxes
      // console.log(loc)
      loc.taxableAmount = parseFloat(val)
      let amt = parseFloat(parseFloat(val) * parseFloat(loc.rate || 0)).toFixed(2)
      loc.taxAmount = amt
      // console.log('AMount: ', amt)
    }
    let taxAmount = 0
    let taxableAmount = 0
    locs.map(item => {
      // console.log('loc: ', item)
      // console.log(item.taxableAmount)
      // console.log(item.rate)
      let ta = parseFloat(item.taxableAmount) * parseFloat(item.rate || 0)
      // console.log('TA: ', ta)
      taxAmount = parseFloat(parseFloat(taxAmount) + parseFloat(ta)).toFixed(2)
      // parseFloat(parseFloat(item.taxableAmount) * parseFloat(item.rate || 0)).toFixed(2)
      // console.log('Tax Amount: ', taxAmount)
      taxableAmount += parseFloat(item.taxableAmount)
    })
    // console.log(locs)
    // setChosenLocales(locs)
    // console.log('Taxable: ', taxableAmount)
    // console.log('Tax Amount: ', taxAmount)
    taxAmount = parseFloat(taxAmount).toFixed(2)
    taxableAmount = parseFloat(taxableAmount).toFixed(2)
    setState({...state, chosenLocales: locs, taxAmount: taxAmount, taxableAmount: taxableAmount })
  }

const handleUpateTaxLocs = (e, n) => {
    setState({...state, chosenLocales: n })
}
return (
  <div className={classes.root}>
    <Dialog open={props.open} fullWidth={false} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Tax Details</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Edit the Tax Details Below
          </DialogContentText>
          <Grid container>
              <Grid item lg={12} style={{marginBottom: '15px'}}>
              <FormControl fullWidth> 
                <Autocomplete
                    id={`taxLocale`}
                    fullWidth
                    options={props.taxLocales}
                    onChange={handleUpateTaxLocs}
                    // freeSolo
                    // autoSelect
                    multiple
                    value={state.chosenLocales}
                    disableCloseOnSelect
                    style={{marginTop: 25}}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.name}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Tax Locale(s)" placeholder="Taxable Area(s)" />
                    )}
                    />
                
                </FormControl>
              </Grid>
            <Grid item lg={12}>
                <TaxLocaleDetails locs={state.chosenLocales} updateTaxStuff={updateTaxStuff} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(EditTaxDetails))