import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CustomerDetailsModal from './CustomerDetail'
import AddCustomerModal from './AddCustomerModal'
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import FertilizationModal from './FertilizationModal'
import SnoutWeevilModal from './SnoutWeevilModal'
import WinterFrostProtectionModal from './WinterFrostProtectionModal'

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const parseOurConfigs = (entityId, configs) => {
  // console.log('Parse the configs', configs)
  if (configs && configs.length) {
    const activeConfigs = configs.find(item => (item.entity === entityId))
    return activeConfigs
  } else {
    return false
  }

}
// function createData(name, status, created, lastactivity, tourbooked) {
//   return { name, status, created, lastactivity, tourbooked };
// }

// const rows = [
//   createData('Tom Smith', 'New', '12/17/2019', null, null),
//   createData('Donut', 'Working', '12/18/2019', 51, 4.9),
//   createData('Eclair', 'New', '11/29/2019', 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'displayName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'defaultEntity', center: true, disablePadding: false, label: 'Entity' },
  { id: 'tags', numeric: false, disablePadding: false, label: 'Tags' },
  { id: 'defaultJobSiteAddress', numeric: false, disablePadding: false, label: 'Job Site' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  // { id: 'accountBalance', numeric: true, disablePadding: false, label: 'Balance' },
  { id: 'accountBalance', numeric: true, disablePadding: false, label: 'Balance' },
  { id: 'updated_at', numeric: false, disablePadding: false, label: 'Last Update' },

];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort, num } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.center ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'scroll',
    minHeight: '40vh',
    maxHeight: '55vh'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    fontSize: '1.2em'
  },
  rowContainer: {
    // border: '3px solid red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowItem: {
    // border: '2px solid blue',
    fontSize: '1.25em',
    padding: 5,
    margin: 5
    // marginRight: 15
  },
  rowImage: {
    // border: '1px solid green',
    display: 'flex',
    alignSelf: 'stretch'
    // margin: -10,
    // padding: -5,
  },
  alphabetNav: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    margin: 4,
    overflowX: 'auto'
  },
  alphabetButton: {
    margin: 2,
    padding: 1,
    cursor: 'pointer',
    border: '2px solid #ccc'
  },
  selectedAlphabetButton: {
    margin: 2,
    padding: 1,
    cursor: 'pointer',
    backgroundColor: '#accacd',
    border: '2px solid #516e76'
  }
}));

function EnhancedTable(props) {
  // console.log('Customer List Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [crewList, setCrewList] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [customerDetailModalOpen, setCustomerDetailModalOpen] = React.useState(false)
  const [snoutWeevilModalOpen, setSnoutWeevilModalOpen] = React.useState(false)
  const [fertilizationModalOpen, setFertilizationModalOpen] = React.useState(false)
  const [winterFrostProtectionModalOpen, setWinterFrostProtectionmodalOpen] = React.useState(false)
  const [client, setClient] = React.useState({});
  const [loading, setLoading] = React.useState(false)
  const [showAllCustomers, setShowAllCustomers] = React.useState(false)
  const [filteredRows, setFilteredRows] = React.useState([])
  const [state, setState] = React.useState({
    searchMatches: 0,
    openSnoutWeevil: false
  })
  const [showNewClientModal, setShowNewClientModal] = React.useState(false)
  const [doDeepSearch, setDoDeepSearch] = React.useState(false)
  // const [leads, setLeads] = React.useState([]);
  // setLeads(props.leads)
  // let clients  = []
  // if (clients && clients.length > 0) clients = props.clients
  // console.log('Clients on table: ', clients)
  // const rows = leads
  const [rows, setRows] = React.useState(props.clients || []);
  const [activeEntity, setActiveEntity] = React.useState({})
  const [selectedLetter, setSelectedLetter] = React.useState('all');
  // setRows(leads)
  // setRows(leads)
  // useEffect(() => {
  //   // console.log('Use effect LEADS', leads)
  //   axios
  //     .get(
  //       `${url}/api/tours/getAll`
  //       )
  //     .then(({ data }) => {
  //       // console.log(data)
  //       setLeads(data)
  //     }).catch(function (err) {
  //       // console.log('Error getting stuff', err)
  //     });
  // }, [leads]);

  //   async function fetchCrews() {
  //   // console.log('Fetch our crews so we have them for snout weevil')
  //   setLoading(true)
  //   axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
  //     // console.log(response)
  //     // console.log(status)
  //     // console.log(data)
  //     if (response && response.data && response.data.crews) {
  //       // console.log('Got Crews for snout weevil!!', response.data )
  //       setCrewList(response.data.crews)
  //     }
  //   }).catch((error, status) => {
  //     // console.log('Error', error)
  //     // console.log(status)
  //   })
  // }
  // React.useEffect(() => {
  //   // console.log('Get Crews for SNout Weevil')
  //   fetchCrews()
  // }, [props])

  React.useEffect(() => {
    // console.log('Use effectset rows: ', props.clients)
    
    let activeClients = props.clients.filter(item => { return item.active })
    // console.log('Got %d activeClients: ', activeClients.length)
    setFilteredRows(activeClients)
    setRows(activeClients)
    // fetchCrews()
    socket.on(props.stuff.user.username, function (msg) {
      // console.log('GOT A SOCKET MESSAGE: ', msg)
      if (msg === 'BulkStatementComplete') {
        props.notification({
            type: 'success',
            title: 'Bulk Statement Complete',
            message: 'The Bulk Statement is Ready to Download'
          })
      }
    })
    // console.log('PRops clients has changed...', props.clients)
    if (props.clients && props.clients.length) {
      // console.log('HistorY: ', props.stuff)
      // If we have t set to a customer ID we need to open that client
      if (props.stuff && props.stuff.match && props.stuff.match.params && props.stuff.match.params.clientId) {
        // console.log('Open: ', props.stuff.match.params.clientId)
        let index = props.clients.findIndex(item => (item._id === props.stuff.match.params.clientId))
        // console.log('index', index)
        if (index > -1) {
          setClient(props.clients[index])
          // console.log('Open Client', props.clients[index])
          setCustomerDetailModalOpen(true)
        }
      }
    }
    // socket.on('customerUpdate', function (client) {
    //   // console.log('UPDATE CUSTOMER: ', client)
    //   // let clients = props.clients
    //   // console.log('Got %d clients: ', clients.length)
    //   // let index = clients.findIndex(item => (item._id === client._id))
    //   // console.log('INDEX: ', index)
    //   // if (index > -1) {
    //   //   // console.log('Client already exists')
    //   //   // console.log('Client List: ', clients)
    //   //   clients[index] = client
    //   //   // Set Search Params
    //   //   // console.log('Update Client List: ', clients)
    //   //   setRows(clients)
    //   //   filterOut(clients, searchValue)
    //   // }
    // })
    return () => {
      socket.off(props.stuff.user.username)
      // socket.off('customerUpdate')
    }
  }, [props.clients, props.configs])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   // console.log('handle click ', name)
  //   // console.log('Index: ', selectedIndex)
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOutOld = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }

  const filterOut = (r, searchText, passedLetter) => {
    if (!searchText && !selectedLetter) return rows;
    let rowsToUse = r || rows
    const letter = passedLetter || selectedLetter
    // console.log('Search by this: %s and this letter: %s ', searchText, letter)
    // console.log('Rows to use', rowsToUse)
    let newRows = rowsToUse.filter(client => {
      // console.log('Client to search on', client)
      let startsWithLetter = false
      if (letter === 'all') {
        startsWithLetter = true
      } else {
        startsWithLetter = letter
        ? ['firstName', 'lastName', 'companyName', 'displayName'].some(key =>
            client[key]?.toLowerCase().startsWith(letter.toLowerCase())
          )
        : true;
      }

      // console.log('Starts w/ Letter??', startsWithLetter)
      let matchesSearch = searchText
        ? Object.values(client).some(value =>
            value?.toString().toLowerCase().includes(searchText.toLowerCase())
          )
        : true;
      // console.log('Matches Search', matchesSearch)
      return startsWithLetter && matchesSearch;
    });
    // console.log('Updated Client List', newRows)
    setFilteredRows(newRows)
  }

  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      filterOut(rows, null)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const handleLetterClick = (letter) => {
    // console.log('Select Letter', letter)
    setSelectedLetter(letter);
    let newFilteredRows = [...rows]
    if (letter === 'all') {

    } else {
      newFilteredRows = rows.filter(row => {
        return (
          row.firstName?.startsWith(letter) ||
          row.lastName?.startsWith(letter) ||
          row.companyName?.startsWith(letter) ||
          row.displayName?.startsWith(letter)
        );
      });
    }
    if (searchValue) {
      filterOut(newFilteredRows, searchValue, letter)
    } else {
      setFilteredRows(newFilteredRows);
    }
    setPage(0);
  };
  // async function fetchData() {
  //   // console.log('Update our clients')
  //   const response = await axios.get(`${props.url}/api/customer/clients`)
  //   // console.log(response)
  //   if (response.data && response.data.length > 0) {
  //     // console.log('Got Updated Clients!!')
  //     setFilteredRows(response.data)
  //     setRows(response.data)
  //   } else {
  //     // console.log('Fucked up getting Clients')
  //   }
  // }

  const openClientModal = (client) => {
    // console.log('Open client modal: ', client)
    // window.history.pushState('staasfd', 'TEest', `/clients/${client._id}`)
    setClient(client)
    setCustomerDetailModalOpen(true)
    // props.updateCustomerList(clients => {
    //   // console.log('Got updated client list!', clients)
    //   // console.log('SHOW AL: ', showAllCustomers)
    //   if (showAllCustomers) {
    //     // console.log('Show everyone')
    //     setRows(clients)
    //     filterOut(clients, searchValue)
    //   } else {
    //     // console.log('Gonna just show the active clients')
    //     setRows(clients)
    //     let activeClients = clients.filter(item => { return item.active })
    //     filterOut(activeClients, searchValue)
    //   }
    // })
  }
  
  const getCustomers = async (deep) => {
    // console.log('Fetch our clients but keep our search value', searchValue)
    setLoading(true)
    axios.get(`${props.url}/api/customer/clients?deep=${deep}`).then((response, status, data) => {
      // console.log(response)
      // console.log(status)
      // console.log(data)
      if (response && response.data && response.data.length > 0) {
        // console.log('Got Clients!!')
        setLoading(false)
        let customerList = []
        if (showAllCustomers) {
          // console.log('Show everyone')
          // setRows(response.data)
          // filterOut(response.data, searchValue)
          customerList = response.data
        } else {
          // console.log('Gonna just show the active clients')
          let activeClients = response.data.filter(item => { return item.active })
          // console.log('Active: ', activeClients)
          // filterOut(activeClients, searchValue)
          // setRows(activeClients)
          customerList = activeClients
        }
        if (!activeEntity.name || activeEntity.name === 'All Assigned') {
          // Show all available Customers
          filterOut(customerList, searchValue)
          setRows(customerList)
        } else {
          // Filter by the active Entity
          let filteredClients = customerList.filter(item => { return item.entity === activeEntity._id })
          // console.log('Active: ', activeClients)
          filterOut(filteredClients, searchValue)
          setRows(filteredClients)
        }
        // setRows(response.data)
        // filterOut(response.data, searchValue)
      } else {
        // console.log('Fucked up', response)
        if (response === 'Unauthorized') props.history.push('/restricted')
      }
    }).catch((error, status) => {
      // console.log('Error', error)
      // console.log(status)
    })
  }
  
  const onModalClose = (toClose) => {
    // console.log('Modal close...', toClose)
    // setClient(null)
    setClient({})
    setCustomerDetailModalOpen(false)
    setLoading(false)
    // console.log('history??', props)
    // props.stuff.history.push('/clients')
    getCustomers()
    // props.updateCustomerList(searchValue)
    //  => {
    //     // console.log('Got updated client list!', clients)
    //     setLoading(false)
      //   // console.log('SHOW AL: ', showAllCustomers)
      //   if (showAllCustomers) {
      //     // console.log('Show everyone')
      //     setRows(clients)
      //     filterOut(clients, searchValue)
      //   } else {
      //     // console.log('Gonna just show the active clients')
      //     setRows(clients)
      //     let activeClients = clients.filter(item => { return item.active })
      //     filterOut(activeClients, searchValue)
      //   }
      // })
  }

  const handleSnoutWeevilOrFertilization = (client, type) => {
    // console.log('Open Snout Weevil Modal')
    setClient(client)
    // setState({...state, openSnoutWeevil: true})
    // setSnoutWeevilModalOpen(true)
    // setCustomerDetailModalOpen(true)
    if (type === 'fertilization') {
      // window.alert('Fert')
      setFertilizationModalOpen(true)
    }
    if (type === 'snoutWeevil') {
      // openSnoutWeevilModal
      setSnoutWeevilModalOpen(true)
      // window.alert('Snout Weevil')
    }
    if (type === 'frostProtection') {
      setWinterFrostProtectionmodalOpen(true)
    }
  }

  const onSWModalClose = (d) => {
    // console.log('Close snout weevil modal', d)
    setState({...state, openSnoutWeevil: false})
    setSnoutWeevilModalOpen(false)
    setCustomerDetailModalOpen(false)
  }
  const updateParent = (d) => {
    // console.log('Update from child ', d)
    // console.log('Search Value: ', searchValue)
    if (d && d === 'onSWModalClose') onSWModalClose(d)
    if (d && d.client) {
      setShowNewClientModal(false)
      openClientModal(d.client)
    }
  }

const handleAddClient = () => {
  // console.log('Add new client')
  setShowNewClientModal(true)
}

function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const setShowAllOrNot = () => {
  // console.log('To Shor all or not', state)
  setsearchValue('')
  if (showAllCustomers) {
    // console.log('Gonna just show the active now')
    setShowAllCustomers(false)
    let activeClients = props.clients.filter(item => { return item.active })
    // console.log('Got %d activeClients: ', activeClients.length)
    setFilteredRows(activeClients)
    setRows(activeClients)
  } else {
    // console.log('Show everyone')
    setShowAllCustomers(true)
    setFilteredRows(props.clients)
    setRows(props.clients)
  }
  setPage(0)
}

const handleUpdateVisibleEntity = (activeEntity) => {
  // console.log('Modify the entity...', activeEntity)
  let customerList = null
  if (showAllCustomers) {
    customerList = props.clients
  } else {
    customerList = props.clients.filter(item => { return item.active })
  }
  if (activeEntity.name === 'All Assigned') {
    // Show all available Customers
    filterOut(customerList, searchValue)
    setRows(customerList)
  } else {
    // Filter by the active Entity
    let filteredClients = customerList.filter(item => { return item.entity === activeEntity._id })
    // console.log('Active: ', activeClients)
    filterOut(filteredClients, searchValue)
    setRows(filteredClients)
  }
  setPage(0)
  setActiveEntity(activeEntity)
}

const toggleDeepSearch = () => {
  // console.log('Toggle', doDeepSearch)
  let newState = !doDeepSearch
  if (newState) {
    // console.log('Deep search is on!')
    // getCustomers(true)
  }
  setDoDeepSearch(newState)
}

const closeFertModal = () => {
  setFertilizationModalOpen(false)
}
const closeSnoutWeevilModal= () => {
  setSnoutWeevilModalOpen(false)
}

const closeWinterFrostProtectionModal = () => {
  setWinterFrostProtectionmodalOpen(false)
}

  return (
    <div className={classes.root}>
      <FertilizationModal closeModal={closeFertModal} open={fertilizationModalOpen} client={client} />
      <SnoutWeevilModal closeModal={closeSnoutWeevilModal} open={snoutWeevilModalOpen} client={client} /> 
      <WinterFrostProtectionModal closeModal={closeWinterFrostProtectionModal} open={winterFrostProtectionModalOpen} client={client} /> 
      <AddCustomerModal updateParent={updateParent} closeModal={() => setShowNewClientModal(false)} open={showNewClientModal} />
      <CustomerDetailsModal updateParent={updateParent} openSnoutWeevil={state.openSnoutWeevil} open={customerDetailModalOpen} customerDetailModalOpen={customerDetailModalOpen} closeModal={onModalClose} client={client} />
      <Paper className={classes.paper}>
        {
          (props.user && props.user.entities && props.user.entities.length > 1) ? (
            <Grid container style={{padding: '10px'}}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} />
            </Grid>
          </Grid>
          ) : ''
        }
        
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={3} xs={6}>
          Total {showAllCustomers ? '' : 'Active'} Customers: {rows.length}
          </Grid>
          <Grid item lg={3} xs={6}>
          <Button
          variant="contained"
          // style="secondary"
          style={{ color: '#222'}}
          // className={classes.button}
          size="small"
          startIcon={<AddIcon  color="primary" />}
          disabled={loading}
          // onClick={handleSnoutWeevilOrFertilization }
          onClick={handleAddClient}
          // onClick={handleDeleteService}
          // onClick={offerDeleteOption}
          // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
        >
          Add Client
        </Button> 
            {/* <IconButton onClick={handleAddClient} label="Add Client" edge='end' alt="Add New Client" aria-label="Add Client">
              <AddIcon color="primary" />
            </IconButton> */}
          </Grid>
        </Grid>
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={4} xs={6}>

            <div className={classes.search}>
                {/* <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div> */}
                {/* <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input> */}
                <TextField
                    name="search" 
                    value={ searchValue } 
                    onChange={ handleChange('search') }
                    label="Search"
                    type="search"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                />
                {/* <div className={classes.fakeLink} onClick={toggleDeepSearch}>Go Deeper</div> */}
              </div>
              
          </Grid>
          {/* <Grid item lg={3} xs={6}>
            <FormControlLabel
                    control={<Checkbox checked={doDeepSearch} onChange={toggleDeepSearch} name="DeepSearch" />}
                    label="Deep Search"
                  />
          </Grid> */}
          <Grid item lg={3} xs={6} style={{marginTop: 25}}>
            <FormControlLabel
                    control={<Checkbox checked={showAllCustomers} onChange={setShowAllOrNot} name="showAllCustomers" />}
                    label="Show All Customers"
                  />
          </Grid>
        </Grid>
        <div className={classes.alphabetNav}>
          {alphabet.map((letter) => (
            <Button
              key={letter}
              // className={classes.alphabetButton}
              className={selectedLetter === letter ? classes.selectedAlphabetButton : classes.alphabetButton}
              variant={selectedLetter === letter ? 'contained' : 'outlined'}
              onClick={() => handleLetterClick(letter)}
            >
              {letter}
            </Button>
          ))}
           <Button
              key={'all'}
              className={selectedLetter === 'all' ? classes.selectedAlphabetButton : classes.alphabetButton}
              variant={selectedLetter === 'all' ? 'contained' : 'outlined'}
              onClick={() => handleLetterClick('all')}
            >
             All
            </Button>
        </div>
        <div>
          Total Matching Clients: {filteredRows.length}
        </div>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`
                   const {
                     fertilizationButtonActive,
                     snoutWeevilButtonActive,
                     winterFrostProtectionButtonActive
                  } = parseOurConfigs(row?.entity, props?.configs)
                  return (
                    
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      style={row.pastDue ? { backgroundColor: 'rgba(233, 103, 103, .5)', } : null}
                    >
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <span className={classes.fakeLink} onClick={() => openClientModal(row)}>{row.displayName} ({row.agId})</span>
                        &nbsp;&nbsp;&nbsp;
                        {
                          (fertilizationButtonActive) ? (
                            <Button
                              variant="contained"
                              style={{backgroundColor: 'rgb(81 110 118)', color: '#fff', fontSize: '.5em', height: '25px'}}
                              className={classes.button}
                              size="small"
                              // startIcon={<AdbIcon />}
                              // onClick={handleSnoutWeevilOrFertilization }
                              onClick={() => handleSnoutWeevilOrFertilization(row, 'fertilization')}
                              // onClick={handleDeleteService}
                              // onClick={offerDeleteOption}
                              // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
                            >
                              Fertilization
                            </Button>
                          ) : null
                        }
                                                {
                          (snoutWeevilButtonActive) ? (
                            <Button
                              variant="contained"
                              style={{backgroundColor: 'rgb(81 110 118)', color: '#fff', fontSize: '.5em', height: '25px'}}
                              className={classes.button}
                              size="small"
                              // startIcon={<AdbIcon />}
                              // onClick={handleSnoutWeevilOrFertilization }
                              onClick={() => handleSnoutWeevilOrFertilization(row, 'snoutWeevil')}
                              // onClick={handleDeleteService}
                              // onClick={offerDeleteOption}
                              // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
                            >
                              Snout Weevil
                            </Button>
                          ) : null
                        }
                        
                        {
                          (winterFrostProtectionButtonActive) ? (
                            <Button
                              variant="contained"
                              style={{backgroundColor: '#B3DAF1', color: '#fff', fontSize: '.5em', height: '25px'}}
                              className={classes.button}
                              size="small"
                              // startIcon={<AdbIcon />}
                              // onClick={handleSnoutWeevilOrFertilization }
                              onClick={() => handleSnoutWeevilOrFertilization(row, 'frostProtection')}
                              // onClick={handleDeleteService}
                              // onClick={offerDeleteOption}
                              // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
                            >
                             Frost Protection
                            </Button>
                          ) : null
                        }
                          
                        {/* <div className={classes.rowContainer} >
                          <div className={classes.rowItem}>
                            <span className={classes.fakeLink} onClick={() => openClientModal(row)}>{row.displayName}</span>
                          </div>
                          <div className={classes.rowImage}>

                          <img alt="Logo" src={`../${row.entity}_Logo.png`} style={{margin: '5px', maxWidth: 170, maxHeight: 40}} />
                          </div> */}
                          
                          {/* <div className={classes.rowItem}>
                            <img alt="Logo" src={`../${row.entity}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 50, maxHeight: 40}} />
                          </div> */}
                        {/* </div> */}
                        {/* <img alt="Logo" src={`../${row.entity}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 50, maxHeight: 40}} /> */}
                        
                        
                      {/* <Link to={{
                          pathname: '/clients/' + row._id,
                          // search: '?sort=name',
                          // hash: '#the-hash',
                          state: {customer: row, stuff: 'Customer List'}
                        }}> { row.displayName + '' }
                        
                      </Link> */}
                      </TableCell>
                      <TableCell component="td" scope="row" padding="none" >
                        <div className={classes.rowContainer} >
                          <div className={classes.rowImage}>
                            <img alt="Logo" src={`${process.env.PUBLIC_URL}/${row.entity}_Logo.png`} style={{margin: '5px', maxWidth: 170, maxHeight: 40}} />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                      {row.HOA ? <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="HOA" /> : ''}
                      {row.contractor ? <Chip style={{color: '#fff', background: '#f7d509' }} size="small" label="Contractor" /> : ''}
                      {row.paperInvoice ? <Chip style={{color: '#fff', background: '#013466' }} size="small" label="Paper Invoice" /> : ''}
                      {row.doNotEmail ? <Chip style={{color: '#fff', background: '#ef0000' }} size="small" label="Do NOT Email" /> : ''}
                      {row.autopayMTC ? <Chip style={{color: '#fff', background: '#4cb929' }} size="small" label="Autopay (Maintenance)" /> : ''}
                      {row.autopayAll ? <Chip style={{color: '#fff', background: '#33a330' }} size="small" label="Autopay (All)" /> : ''}
                      {row.active ? '' : <Chip key={'deactive'} style={{background: `rgba(223,192,111, .3)` }} size="small" label="Inactive" /> }
                      {row.activeMtc ? <Chip style={{color: '#fff', background: '#2ca932' }} size="small" label="Active MTC" /> : ''}
                      {!row.collectionNotifications ? <Chip style={{color: '#fff', background: '#a86032' }} size="small" label="No Collections" /> : ''}
                        &nbsp;&nbsp;&nbsp;
                         {/* <Button
                          variant="contained"
                          style={{backgroundColor: '#ccb056', fontSize: '.5em', width: '50px', height: '25px'}}
                          className={classes.button}
                          size="small"
                          startIcon={<AdbIcon />}
                          // onClick={handleSnoutWeevilOrFertilization }
                          onClick={() => handleSnoutWeevilOrFertilization(row)}
                          // onClick={handleDeleteService}
                          // onClick={offerDeleteOption}
                          // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
                        >
                          Snout Weevil
                        </Button>  */}
                        
                      </TableCell>
                      <TableCell align="left">
                        {/* { moment(row.created_at).format('MM/DD/YYYY - hh:mmA') } */}
                        { row.defaultJobSiteAddress }
                      </TableCell>
                      <TableCell align="left">{ row.email } </TableCell>
                      {/* <TableCell align="right">{ parseFloat(row.accountBalance) < 0 ?  `(${currencyFormat(Math.abs(row.accountBalance))})` : `${currencyFormat(parseFloat(row.accountBalance))}` } </TableCell> */}
                      <TableCell align="right">{ parseFloat(row.accountBalance) < 0 ?  `(${currencyFormat(Math.abs(row.accountBalance))})` : `${currencyFormat(parseFloat(row.accountBalance))}` } </TableCell>
                      <TableCell align="left">
                        { moment(row.updated_at).fromNow() }
                        {/* <Chip style={{background: '#eee'}} size="small" label={ moment(row.updated_at).fromNow() } /> */}
                      </TableCell>
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 2000]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  // console.log('Map to state customerlist', state)
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    user: state.user,
    // fertilizationButtonActive: state.fertilizationButtonActive,
    // snoutWeevilButtonActive: state.snoutWeevilButtonActive,
    // winterFrostProtectionButtonActive: state.winterFrostProtectionButtonActive,
  }
}

export default React.memo(connect(mapStateToProps)(EnhancedTable))
