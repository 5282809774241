import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Fade from '@material-ui/core/Fade';
import CheckInButton from './CheckInButton'
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DriverLogo from '@material-ui/icons/DirectionsCar';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClockOutIcon from '@material-ui/icons/TimerOff';
import Tooltip from '@material-ui/core/Tooltip';
import CrewModal from '../Crews/CrewModal'
import EmployeeModal from './EmployeeDetailsModal'
// import EcoIcon from '@material-ui/icons/Eco';
import TempIcon from '@material-ui/icons/Title';
import SickIcon from '@material-ui/icons/Hotel';
import PTOIcon from '@material-ui/icons/InsertEmoticon';
import NoCallIcon from '@material-ui/icons/Block';
import VacationIcon from '@material-ui/icons/Flight';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TestComponent from './TestComponent'
import EventNoteIcon from '@material-ui/icons/EventNote';
// import ForemanIcon from '@material-ui/icons/Facebook';
// import ForemanIcon from '@material-ui/icons/SupervisedUserCircle';
// import ForemanIcon from '@material-ui/icons/SupervisorAccount';
import ForemanIcon from '@material-ui/icons/RecordVoiceOver';
import MultiDriversModal from './MultiDriversModal'
import { setCrew } from '../../actions/connectionActions'
import Schedule from '../Schedule/Schedule'
import { socket } from '../SocketIo/Socket'
import MapIcon from '@material-ui/icons/Map';
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import EventNote from '@material-ui/icons/EventNote'
import ExploreIcon from '@material-ui/icons/Explore';
import EmployeeSearch from './EmployeeSearchComponent'
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
// const Container = styled.div`
//   margin: 2px;
//   width: 100%;
//   height: 100%;
// `;

const Container = styled(Paper)`
  min-height: 85vh;
  && {
    color: grey;
    background-color: white;
  }`

const HeaderBar = styled.div`
  display: flex;
  justify-content: justify;
  align-items: center;
  margin: 5px;
  align-self: stretch;
  `;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px;
  flex-wrap: wrap;
`;

const CrewBoxFooter = styled(Box)`
display: flex;
flex-grow: 1;
justify-content: space-between;
`;

const FooterLeftSide = styled(Box)`
display: flex;
justify-content: flex-start;
align-items: flex-start;
margin: 5px;
`;

const FooterRightSide = styled(Box)`
display: flex;
justify-content: flex-end;
align-items: flex-end;
margin: 5px;
`;

const ClockIn = styled.div`
  margin-left: auto;
  margin-bottom: -21px;
`;

const CrewBox = styled.div`
  background-color: ${props => (props.isDraggingOver ? '#30ec75' : 'white')};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 100px;
  width: ${props => (props.isWideView ? '100%' : '250px')};
  max-width: ${props => (props.isWideView ? '100%' : '250px')};
  display: ${props => (props.isWideView ? 'flex' : 'block')};
  flex-wrap: ${props => (props.isWideView ? 'wrap' : 'nowrap')};
`;

const CrewContainer = styled.div`
  border: 3px solid darkgrey;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  margin-bottom: 15px;
  background-color: ${props => (props.isDraggingOver ? '#34eb80' : 'white')};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: ${props => (props.isWideView ? 'flex' : 'block')};
  flex-wrap: ${props => (props.isWideView ? 'wrap' : 'nowrap')};
`;

const CrewBoxOld = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid darkgrey;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  min-height: 200px;
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const EmpBox = styled.div`
  border: ${props => ((props.isClockedIn || props.isDragging) ? '3px solid #444' : '2px solid lightgrey')};
  color: ${props => ((props.absenceType || props.isCheckedIn || props.isClockedIn)  ? '#ddd;' : '#444;')};
  border-radius: 5px;
  padding: 4px;
  margin: 2px;
  width: ${props => (props.isWideView ? '200px' : '100%')};
  background-color: ${props => (
    props.isDragging ? '#34eb80': (
        props.absenceType ? (
          props.absenceType === 'off' ? 'rgba(149,49,188,1)' : props.absenceType === 'nocall' ? 'rgba(90,46,8,1)' : props.absenceType === 'approved' ? 'rgba(73,128,36,1)' : 'blue'
        ) : props.isCheckedIn ? (props.isClockedIn ? '#a63e06' : '#c4c428') : 'inherit'))};
`;

const EmployeeStuff = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const EmpDetails = styled.div`
  margin-left: auto;
  padding-left: 10px;
`;

const CrewName = styled.div`
  font-size: 0.7em;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckedInBox = styled.div`
  font-size: 2em;
  color: darkgrey;
`;


// const options = [
//   'Maintenance',
//   'Landscape Install',
//   'Hardscape',
//   'Specialty',
//   'Irrigation and Lighting',
//   'Yard'
// ]

const DailyRoster = (props) => {
  console.log('Roster Component!!', props)
  // const [crews, setCrews] = React.useState([])
  // const [employees, setEmployees] = React.useState([])
  // const [crewsWithoutDrivers, setCrewsWithoutDrivers] = React.useState([])
  // const [fullCrews, setFullCrews] = React.useState([])
  // const [shortCrews, setShortCrews] = React.useState([])
  // const [open, setOpen] = React.useState(false)
  const [state, setState] = React.useState({
    crews: [],
    loading: false,
    employee: {},
    empModalOpen: false,
    employees: [],
    checkedInEmployees: [],
    clockedInEmployees: [],
    absentEmployees: [],
    activeCrew: {},
    division: 'Maintenance',
    workorders: [],
    crewLess: {}
  })
  const [division, setDivision] = React.useState('Maintenance')
  const [crewModalOpen, setCrewModalOpen] = React.useState(false)
  const [activeCrew, setActiveCrew] = React.useState({})
  const [crewScheduleOpen, setCrewScheduleOpen] = React.useState(false)
  const [empModalOpen, setEmpModalOpen] = React.useState(false)
  const [multiDriverModalOpen, setMultiDriverModalOpen] = React.useState(false)
  const [lastUpdated, setLastUpdated] = React.useState(null)
  const [activeEntity, setEntity] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  // const [activeEmp, setActiveEmp] = React.useState({})
  // const [checkedInEmployees, setCheckedInEmployees] = React.useState([])
  // const [clockedInEmployees, setClockedInEmployees] = React.useState([])
  // const [crewsWithoutDrivers, setCrewsWithoutDrivers] = React.useState([])

  const getCrewsAndShit = async (divisionPassed, entitiyPassed) => {
    // console.log('Get Crews and shit:', divisionPassed)
    // let divisionToChoose = divisionPassed
    // if (divisionPassed === 'autoupdate') divisionToChoose = division
    // console.log('Get Crews: ', division, divisionToChoose, divisionPassed, state.division)
    let entityToUse = 'All'
    let divisionToSearchFor = 'Maintenance'
    if (entitiyPassed && entitiyPassed._id) entityToUse = entitiyPassed._id
    let t = await localStorage.getItem('division')
  // console.log('Local Storage Division: ', t)
    // setLoading(true)
    if (t) {
      divisionToSearchFor = t
    }
    if (divisionPassed) {
      divisionToSearchFor = divisionPassed
    }
    setLoading(true)
      axios
      .get(
        `${props.url}/api/roster/getAll?division=${divisionToSearchFor}&entity=${entityToUse}`
        )
      .then(({ data }) => {
        // setLoading(false)
        // console.log('Got Crew Data', data)
        let empsCheckedIn = data.employees.filter(item => { return item.isCheckedIn })
        // setCheckedInEmployees(empsCheckedIn)
        let empsClockedIn = data.employees.filter(item => { return item.isClockedIn })

        let empsAbsent = data.employees.filter(item => { return item.absent })
        // setClockedInEmployees(empsClockedIn)
        // setCrews(data.crews)
        // Add in the crewless as their own crew
        let crewlessCrew = {
          _id: 'crewless',
          name: 'Unassigned',
          assignedEmployees: data.crewLess || []
        }
        setState({...state, crews: data.crews, crewLess: crewlessCrew, employees: data.employees, absentEmployees: empsAbsent, checkedInEmployees: empsCheckedIn, clockedInEmployees: empsClockedIn})
      setLastUpdated(Date.now())//   let noDrivers = []
        setLoading(false)
      }).catch(function (err) {
        // console.log('Error getting stuff', err)
        props.notification({
          type: 'warning',
          title: 'Error Loading Data',
          message: err.message
        })
      });
  }


  React.useEffect(() => {
    const preferredDivision = localStorage.getItem('division')
  // console.log('Preferred Division: ', preferredDivision)

    if (preferredDivision) {
      setDivision(preferredDivision)
      setState({...state, division: preferredDivision})
      getCrewsAndShit(preferredDivision, 'All')
    } else {
      // setState({...state, division: 'Maintenance'})
      getCrewsAndShit('Maintenance', 'All')
      setDivision('Maintenance')
    }

    // const autoUpdate = setInterval(() => {
    // // console.log('UPdate: ', division)
    // // console.log('state.division', state.division)
    // // console.log('Preferred DIvision', preferredDivision)
    //   getCrewsAndShit(preferredDivision)
    //   // alert('Auto Updated')
    // }, 120000)

  socket.on('dailyRoster', async function (data) {
  // console.log('GOT A SOCKET MESSAGE: ', data)
    // updatedCrew ---> store this as a crew
    if (data === 'updatedCrew') {
      // console.log('UPdate the crews now - our division', division)
      // console.log('PReferred Division? ', preferredDivision)
      // console.log()
      const savedDivision = await localStorage.getItem('division')
    // console.log('Saved Division: ', savedDivision)
      getCrewsAndShit(savedDivision, activeEntity)
    }
    if (data.type === 'updatedCrew') {
      // console.log('UPdate the crews now - our division', division)
      // console.log('PReferred Division? ', preferredDivision)
      // console.log()
      const savedDivision = await localStorage.getItem('division')
    // console.log('Saved Division: ', savedDivision)
      getCrewsAndShit(savedDivision, activeEntity)
    }
  })
  return () => {
    socket.off('dailyRoster')
    // clearInterval(autoUpdate)
  }
}, [])

// console.log('Roster 331')
  const crewTypes = [...new Set(props.crews.map(item => item.division ? item.division : 'Other' ))]
  console.log('Crew Types 333;', crewTypes)
  // React.useEffect(() => {
  //   // console.log('Get the crews and shit')
  //   const preferredDivision = localStorage.getItem('division')
  // // console.log('Preferred Division: ', preferredDivision)
  //   if (preferredDivision) {
  //     setDivision(preferredDivision)
  //     // setState({...state, division: preferredDivision})
  //     getCrewsAndShit(preferredDivision)
  //   } else {
  //     // setState({...state, division: 'Maintenance'})
  //     getCrewsAndShit('Maintenance')
  //   }
  // }, [])

  // React.useEffect(() => {
  //   // console.log('Get the crews and shit')
  //   const preferredDivision = localStorage.getItem('division')
  // // console.log('Preferred Division: ', preferredDivision)
  //   if (preferredDivision) {
  //     // setState({...state, division: preferredDivision})
  //     // getCrewsAndShit(preferredDivision)
  //     setDivision(preferredDivision)
  //   }
  // }, )

  const onDragStart = () => {
    // // console.log()
    document.body.style.color = 'orange';
    document.body.style.transition = 'background-color 0.2s ease'
  }

  const onDragUpdate = update => {
    const { destination } = update
    const opacity = destination ? destination.index / Object.keys(state.crews).length : 0;
    document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`
  }

  const onDragEnd = result => {
  // console.log('DRAG END', result)
    document.body.style.color = 'inherit';
    document.body.style.backgroundColor = 'inherit'
    // console.log('on Drag End', result)
    const { destination, source, draggableId } = result;
    // console.log('Destination: ', destination)
    // console.log('Source: ', source)
    // console.log('ID: ', draggableId)
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    // Crew Start
    let allCrews = state.crews
    let startIndex = allCrews.findIndex(crewItem =>  (crewItem['_id'].toString() === source.droppableId.toString()));
    const start = allCrews[startIndex]
    // console.log('Crew Start: ', start)
    // Crew Finish
    let finishIndex = allCrews.findIndex(crewItem =>  (crewItem['_id'].toString() === destination.droppableId.toString()));
    const finish = allCrews[finishIndex]
  // console.log('Crew Finish', finish)

    // Update FROM Crew
    // const fromCrew = Array.from(start.assignedEmployees)
    let employeeBeingDragged = start.assignedEmployees[source.index]
    // console.log('Employee Being Dragged: ', employeeBeingDragged)
    start.assignedEmployees.splice(source.index, 1)
    // const newFromCrew = {
    //   ...start,
    //   assignedEmployees: fromCrew
    // }
  // console.log('Finish Emps: ', finish.assignedEmployees)
    if (finish && finish.assignedEmployees) {
      finish.assignedEmployees.splice(destination.index, 0, employeeBeingDragged)
      // setCrews(allCrews)
      assignEmployeeToCurrentCrew(employeeBeingDragged, finish)
    } else {
      finish.assignedEmployees = []
      finish.assignedEmployees.splice(destination.index, 0, employeeBeingDragged)
      // setCrews(allCrews)
      assignEmployeeToCurrentCrew(employeeBeingDragged, finish)
    }
    setState({...state, crews: allCrews})
  }

  const assignEmployeeToCurrentCrew = (employee, crew) => {
    // console.log('Assign this guy to this crew: ', employee.firstName)
    // console.log('Crew: ', crew.name)
    axios({
      method: 'post',
      url:  `${props.url}/api/roster/moveEmployee`,
      data: {
        employeeId: employee._id,
        crewId: crew._id
      }
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Checked In Employee: ', response.data)
    })
  }

  const handleOpenCheckInModal = () => {
    // setOpen(true)
  }
  const closeCheckInModal = () => {
    // setOpen(false)
  }

 
  const openEmployeeModal = (emp) => {
    // console.log('Open employee modal: ', emp)
    setState({...state, employee: emp, empModalOpen: true})
    // setActiveEmp(emp)
    setEmpModalOpen(true)
    // axios({
    //   method: 'get',
    //   url:  `${props.url}/api/employees/getRecentTimeClockData?id=${emp._id}&limit=8`,
    // }).then((response) => {
    //   // console.log('Time clock data: ', response.data)
    //   // setTimeClockData(response.data)
    //   emp.timeClockData = response.data
    //   setActiveEmp(emp)
    //   setEmpModalOpen(true)
    // }).catch((err) => {
    //   // console.log('Time Clock Error: ', err)
    // })

  }

  const openCrewModal = (e, crew) => {
    e.preventDefault();
    // console.log(e.currentTarget);
    // console.log('Open Crew Modal', crew)
    setCrewModalOpen(true)
    setActiveCrew(crew)
  }

  const updateFromCrewModal = (d) => {
    // console.log('Repsonse from Crew Modal', d)
    if (d === 'closeModal') setCrewModalOpen(false)
    getCrewsAndShit(division, activeEntity)
  }

  const updateFromEmployeeModal = (d) => {
    // console.log('Update From Employee Modal', d)
    getCrewsAndShit(division, activeEntity)
    setState({...state, employee: {}})
    // setActiveEmp({})
    // if (d === 'closeModal') 
    setEmpModalOpen(false)
  }

  const handleCheckIn = (crew, index, empIndex, emp) => {
    // console.log('Check In: ', emp)
    // console.log('CrEW: ', crew.name)
    // console.log('Index: ', index)
    // console.log('Employee Index;', empIndex)
    let newCrews = state.crews
    let crewToFix = newCrews[index]
    let emps = crewToFix['assignedEmployees']
    let empToUpdate = emps[empIndex]
    empToUpdate.isCheckedIn = true
    empToUpdate.absent = null
    // console.log('Update Employess: ', newCrews)
    let empsCheckedIn = state.checkedInEmployees
    empsCheckedIn.push(emp)
    // setCheckedInEmployees(empsCheckedIn)
    setState({...state, crews: newCrews, checkedInEmployees: empsCheckedIn})
    axios({
      method: 'post',
      url:  `${props.url}/api/roster/checkInEmployee`,
      data: emp,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Checked In Employee: ', response.data)
      props.notification({
        type: 'success',
        title: 'Checked-In!',
        message: 'They Are Here and Accounted For!!'
      })
      // getCrewsAndShit()
    })
  }

  const handleClockIn = (crew, index, empIndex, emp) => {
  // // console.log('Clock In: ', emp)
  // // console.log('CrEW: ', crew.name)
  // // console.log('Index: ', index)
  // // console.log('Employee Index;', empIndex)
  let newCrews = state.crews
  let crewToFix = newCrews[index]
  let emps = crewToFix['assignedEmployees']
  let empToUpdate = emps[empIndex]
  empToUpdate.isClockedIn = true
  // // console.log('Update Employess: ', newCrews)
  let empsClockedIn = state.clockedInEmployees
  empsClockedIn.push(emp)
  // setClockedInEmployees(empsClockedIn)
  setState({...state, crews: newCrews, clockedInEmployees: empsClockedIn})
  axios({
    method: 'post',
    url:  `${props.url}/api/roster/clockInEmployee`,
    data: emp,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Checked In Employee: ', response.data)
    props.notification({
      type: 'success',
      title: 'Employee Clocked-In!',
      message: 'They Are On The Clock!!'
    })
    // getCrewsAndShit()
  })
}

const handleApproveOT = (crew, index) => {
  console.log('Approve ot for this crew', crew)
}

const handleClockInCrew = (crew, index) => {
  // console.log('Clock In Crew: ', crew.name)
  let empsClockedIn = state.clockedInEmployees
  let newCrews = state.crews
  let crewToFix = newCrews[index]
  let emps = crewToFix['assignedEmployees']
  for (const emp of emps) {
    if (emp && emp.isCheckedIn) {
      emp.isClockedIn = true
      empsClockedIn.push(emp)
    }
  }
  setState({...state, crews: newCrews, clockedInEmployees: empsClockedIn})
  axios({
    method: 'post',
    url:  `${props.url}/api/roster/clockInCrew`,
    data: {
      userId: props.user._id,
      crewId: crew._id
    },
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setState({...state, loading: false})
    // console.log('Clocked In Crew: ', response.data)
    getCrewsAndShit(division, activeEntity)
    props.notification({
      type: 'success',
      title: 'Crew Clocked-In!',
      message: 'They Are On The Clock!!'
    })
  })
}

const updateFromMultiDrivers = (kind, d) => {
  // if (kind==='closeModal') {
    setMultiDriverModalOpen(false)
  // }
  if (kind === 'save') {
  // console.log('Multi Drivers - who is our driver?', d)
    axios({
      method: 'post',
      url:  `${props.url}/api/roster/clockOutCrew`,
      data: {
        userId: props.user._id,
        crewId: state.activeCrew._id,
        driver: d
      },
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Clocked Out Crew: ', response.data)
      setState({...state, loading: false})
      // getCrewsAndShit()
      getCrewsAndShit(division, activeEntity)
      props.notification({
        type: 'success',
        title: 'Crew Has Been Clocked Out!',
        message: 'Well Done!!'
      })
      // setState({...state, crews: response.data.crews})
    })
  }
}

const clockOutTheCrew = (crew, driverId) => {
  axios({
    method: 'post',
    url:  `${props.url}/api/roster/clockOutCrew`,
    data: {
      userId: props.user._id,
      crewId: crew._id,
      driver: driverId
    },
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Clocked Out Crew: ', response.data)
    setState({...state, loading: false})
    // getCrewsAndShit()
    getCrewsAndShit(division, activeEntity)
    props.notification({
      type: 'success',
      title: 'Crew Has Been Clocked Out!',
      message: 'Well Done!!'
    })
    // setState({...state, crews: response.data.crews})
  })
}


const handleClockOutCrew = (crew, index) => {
  // console.log('Clock Out Crew', crew.name)
  // setState({...state, loading: true})
  // let empsClockedIn = state.clockedInEmployees
  // let newCrews = state.crews
  // let crewToFix = newCrews[index]
  // let emps = crewToFix['assignedEmployees']
  // for (const emp of emps) {
  //   if (emp && emp.isCheckedIn) {
  //     emp.isClockedIn = false
  //     // empsClockedIn.push(emp)
  //   }
  // }
  // setState({...state, crews: newCrews})
  const drivers = crew.assignedEmployees.filter(item => item.isDriver)
  let numberofClockedInDrivers = 0
  let activeDriver = null
  if (drivers && drivers.length) {
  // console.log('Drivers: ', drivers)
    if (drivers && drivers.length > 1) {
      for (const driver of drivers) {
        if (driver.isClockedIn) {
          numberofClockedInDrivers++
          activeDriver = driver
        }
      }
      let driverId = null
      if (activeDriver && activeDriver._id) {
        driverId =activeDriver._id
      }
      if (numberofClockedInDrivers > 1) {
        setMultiDriverModalOpen(true)
        setState({...state, activeCrew: crew})
      } else {
        clockOutTheCrew(crew, driverId)
      }

      // if (window.confirm('Yo - We Gots More than one driver homey')) {
      //   axios({
      //     method: 'post',
      //     url:  `${props.url}/api/roster/clockOutCrew`,
      //     data: {
      //       userId: props.user._id,
      //       crewId: crew._id,
      //       driver: drivers[0]['_id']
      //     },
      //     // validateStatus: (status) => {
      //     //   // console.log('Validate status: ', status)
      //     //   if (status && status === 500) {

      //     //   }
      //     // },
      //   }).then(response => {
      //     // console.log('Clocked Out Crew: ', response.data)
      //     setState({...state, loading: false})
      //     getCrewsAndShit()
      //     props.notification({
      //       type: 'success',
      //       title: 'Crew Has Been Clocked Out!',
      //       message: 'Well Done!!'
      //     })
      //     // setState({...state, crews: response.data.crews})
      //   })
      // }
    } else {
      clockOutTheCrew(crew, drivers[0]['_id'])
    }
  } else {
    clockOutTheCrew(crew, 'NoDriver')
  }

}

const handleClockInAll = () => {
// console.log('Clock in everyone whos checked in')
  let empsCheckedIn = state.checkedInEmployees
  let empsClockedIn = state.clockedInEmployees
  for (const emp of empsCheckedIn) {
    if (emp && emp.isCheckedIn) {
      emp.isClockedIn = true
      empsClockedIn.push(emp)
    }
  }
  setState({...state, clockedInEmployees: empsClockedIn})
  axios({
    method: 'post',
    url:  `${props.url}/api/roster/clockInAll`,
    data: {
      userId: props.user._id,
      employees: empsCheckedIn,
      division: division
    },
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    setState({...state, loading: false})
    // console.log('Clocked In Crew: ', response.data)
    // getCrewsAndShit()
    getCrewsAndShit(division, activeEntity)
    props.notification({
      type: 'success',
      title: 'All Clocked-In!',
      message: 'They Are On The Clock!!'
    })
  })
}

const handleClockOut = (crew, index, empIndex, emp) => {
  // // console.log('Clock In: ', emp)
  // // console.log('CrEW: ', crew.name)
  // // console.log('Index: ', index)
  // // console.log('Employee Index;', empIndex)
  let newCrews = state.crews
  let crewToFix = newCrews[index]
  let emps = crewToFix['assignedEmployees']
  let empToUpdate = emps[empIndex]
  empToUpdate.isClockedIn = false
  // // console.log('Update Employess: ', newCrews)
  let empsClockedIn = state.clockedInEmployees
  empsClockedIn.splice(empIndex, 1)
  // // console.log('Employees CLocked In', empsClockedIn)
  // setClockedInEmployees(empsClockedIn)
  setState({...state, crews: newCrews, clockedInEmployees: empsClockedIn})
  axios({
    method: 'post',
    url:  `${props.url}/api/roster/clockOutEmployee`,
    data: emp,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    props.notification({
      type: 'success',
      title: 'Employee Has Been Clocked Out!',
      message: 'Well Done!!'
    })
    // console.log('Clocked Out Employee: ', response.data)
    // getCrewsAndShit()
  })
}

const handleChangeDivisions = async (division) => {
// console.log('Change Division: ', division)
  if (division) {
  // console.log('Set Division: ', division)
    setDivision(division)
    setState({...state, division: division})
    await localStorage.setItem('division', division)
    // let t = await localStorage.getItem('division')
    // console.log('DIvision from local storage: ', t)
    getCrewsAndShit(division, activeEntity)
  }
}

  const handleOpenCheckOutAll = () => {
    if (window.confirm('Are You Sure? This Will Clock-Out/Check-Out Everyone and Reset Them To Their Default Crews?')) {
      const obj = {
        user: props.user
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/roster/checkOutAll?division=${division}`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
        // console.log('Got Updated Crews', response.data)
        // setCheckedInEmployees([])
        // setClockedInEmployees([])
        setState({...state, crews: response.data.crews, clockedInEmployees: [], checkedInEmployees: [] })
      })
  }
  }

  const handleViewCrewMap = async (crew, index) => {
  // console.log('PROPS.location: ', props)
    // props.location.push(`/birdseyeview?crew=${crew._id}`)
    window.open(`/birdseyeview?crew=${crew._id}`, "_blank")
  }

  const handleViewCrewLog = async (crew) => {
    window.open(`/crewDailyLog?crew=${crew._id}`, "_blank")
  }
  const handleViewCrewSchedule = async (crew, index) => {
    setLoading(true)
    console.log('open crew schedule ', crew)
    let isMtc = false
    if (crew && crew.division === 'Maintenance') isMtc = true
    // async function fetchData(id) {
    // console.log('Fetch our workorders for the crew', crew._id)
      // setLoading(true)
      const response = await axios.get(`${props.url}/api/crews/workorderSchedule?id=${crew._id}`)
    // console.log(response)
      setLoading(false)
      if (response.data && response.data.length > 0) {
      console.log('Got Workorders!!', response.data)
        props.setCrew(crew)
        setState({...state, workorders: response.data})
        setCrewScheduleOpen(true)
      } else {
      // console.log('Fucked up')
      }
    // }

  }

  function getCardinalDirection(angle) {
    if (typeof angle === 'string') angle = parseInt(angle);
    if (angle < 0 || angle > 360 || typeof angle === 'undefined') return '☈';
    const arrows = { north: '↑ N', north_east: '↗ NE', east: '→ E', south_east: '↘ SE', south: '↓ S', south_west: '↙ SW', west: '← W', north_west: '↖ NW' };
    const directions = Object.keys(arrows);
    const degree = 360 / directions.length;
    angle = angle + degree / 2;
    for (let i = 0; i < directions.length; i++) {
      if (angle >= (i * degree) && angle < (i + 1) * degree) return arrows[directions[i]];
    }
    return arrows['north'];
  }

  const updateFromCrewSchedule = () => {
    setCrewScheduleOpen(false)
    props.setCrew(null)
  }
  const AbsenceDisplay = ({ type }) => {
    if (type === 'vacation') {
      return <span style={{ marginLeft: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center' }} ><Tooltip title="Vacation"><VacationIcon style={{fontSize: '10px'}} /></Tooltip></span>
    }
    if (type === 'approved') {
      return <span style={{ marginLeft: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center' }} ><Tooltip title="PTO"><PTOIcon style={{fontSize: '10px'}} /></Tooltip></span>
    }
    if (type === 'off') {
      return <span style={{ marginLeft: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center' }} ><Tooltip title="Called/Off"><SickIcon style={{fontSize: '10px'}} /></Tooltip></span>
    }
    if (type === 'nocall') {
      return <span style={{ marginLeft: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center' }} ><Tooltip title="No-Call/No-Show"><NoCallIcon style={{fontSize: '10px'}} /></Tooltip></span>
    }
    return null
  }

  const CrewList = (props) => {
    const iconSize = 20
    const sortedCrews = props?.crewsToFilter.sort((a, b) => (a.name - b.name))

    return (
      <Grid container>
      <Schedule open={crewScheduleOpen} updateParent={updateFromCrewSchedule} workorders={state.workorders} />
        <CrewModal open={crewModalOpen} crew={activeCrew} employees={state.employees} updateParent={updateFromCrewModal} />
        {sortedCrews.map((crew, index) => {
        // console.log('CREW OBJECT:', crew)
        const isWideView = crew._id === 'crewless'
          return (
          <Grid 
            item
            key={index}
            lg={isWideView ? 12 : 2}
            md={isWideView ? 12 : 2}
            sm={isWideView ? 12 : 2}
            xs={isWideView ? 12 : 2}
          >
            <CrewContainer isWideView={isWideView}>
            <Grid container style={{ verticalAlign: 'top'}}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CrewName onDoubleClick={(e) => openCrewModal(e, crew)}>{crew.name} {crew.foremanName ? `(${crew.foremanName})` : ''}</CrewName>
              </Grid>
            </Grid>
            <Droppable droppableId={crew._id} key={index} >
              { (provided, snapshot) => (
                
              <CrewBox 
                {...provided.droppableProps}
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
                isWideView={isWideView}
              >
                  {crew.assignedEmployees && crew.assignedEmployees.map((emp, empIndex) => (
                    <React.Fragment key={empIndex}>
                        <Draggable draggableId={emp._id} index={empIndex} >
                        {(provided, snapshot) => (
                          <EmpBox isDriver={emp.isDriver}
                            isCheckedIn={emp.isCheckedIn}
                            isClockedIn={emp.isClockedIn}
                            absenceType={emp.absent}
                            isWideView={isWideView}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // innerRef={provided.innerRef}
                            ref={provided.innerRef}
                            isDragging={snapshot.isDragging}
                            onDoubleClick={() => openEmployeeModal(emp)}
                          >
                            <EmployeeStuff style={{flexWrap: 'wrap'}}>
                              {emp.type === 'Foreman' ? (
                                <Tooltip title="Foreman">
                                  <ForemanIcon style={{marginRight: '10px', fontSize: '10px' }} />
                                </Tooltip>
                               ) : ''
                              }
                              {emp.isDriver ? (
                                <Tooltip title="Driver">
                                  <DriverLogo style={{marginRight: '10px', fontSize: '10px' }} />
                                </Tooltip>
                               ) : ''
                              }
                              
                              
                              {(emp.isTemp || emp.type === 'Temp') ? (
                                <Tooltip title="Temp Worker">
                                  <TempIcon style={{marginRight: '10px', fontSize: '10px' }} />
                                </Tooltip>
                                )
                                : ''}
                              {emp.displayName}
                              {emp.absent ? <AbsenceDisplay type={emp.absent} /> : ''}
                              <EmpDetails>
                                {emp.isClockedIn ? (
                                    <Tooltip title="Clock-Out">
                                    <IconButton onClick={() => handleClockOut(crew, index, empIndex, emp) } size="small" align="edge" alt="Clock-Out" label="Clock-Out" aria-label="Clock-Out">
                                      <ClockOutIcon style={{fontSize: '10px'}}/>
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                emp.isCheckedIn ? (
                                  <Tooltip title="Clock-In">
                                  <IconButton onClick={() => handleClockIn(crew, index, empIndex, emp) } size="small" align="edge" alt="Clock-In" label="Clock-In" aria-label="Clock-In">
                                    <ScheduleIcon style={{fontSize: '10px'}} />
                                  </IconButton>
                                </Tooltip>
                                ) : (
                                  <Tooltip title="Check-In">
                                  <IconButton onClick={() => handleCheckIn(crew, index, empIndex, emp) } size="small" align="edge" alt="Check-In" label="Check-In" aria-label="Check-In">
                                    <CheckCircleOutlineIcon style={{fontSize: iconSize }} />
                                  </IconButton>
                                </Tooltip>
                                ))}
                                </EmpDetails>
                            </EmployeeStuff>
                            
                          
                          </EmpBox>
                        
                        )}
                        
                        </Draggable>
                        {provided.placeholder}
                      </React.Fragment>
                    ))}        
            
            
            </CrewBox>
            )}
            </Droppable>
            {

            isWideView ? null : (
              <React.Fragment>
        <CrewBoxFooter>
          {
            crew.isOnCampus ? (
              <Tooltip title="Is Crew On Campus">
                <span style={{ marginTop: '5px' }}>{ crew.isOnCampus ? crew.locationName : '' }</span>
              </Tooltip>
            ) : crew.isOnCustomerSite ? (
              <Tooltip title="Is Crew On Customer Site">
                <span style={{ marginTop: '5px' }}>{ (crew.isOnCustomerSite && crew.currentJobSite && crew.currentJobSite._id) ? `${crew.currentJobName} (${crew.currentJobSite.address})` : '' }</span>
              </Tooltip>
            ) : (
              crew.lastLocation ? (
                crew.lastLocation.is_moving ? (
                <Tooltip title="Traveling">
                  <span style={{ marginTop: '5px' }}>Traveling {(crew.lastLocation && crew.lastLocation.coords.heading) ? getCardinalDirection(crew.lastLocation.heading) : ''} at {(crew.lastLocation && crew.lastLocation.speed) ? `${parseInt(crew.lastLocation.speed)} mph` : ''}</span>
                </Tooltip>
                ) : (
                  <Tooltip title="Resting">
                    <span style={{ marginTop: '5px' }}> </span>
                  </Tooltip>
                )
              ) : null
            )
          }
          {crew.activityStatus}
            
        </CrewBoxFooter>
        <CrewBoxFooter>
            <Tooltip title="Last Update">
              <span style={{ marginTop: '5px' }}>{ (crew.lastLocation && crew.lastLocation.timestamp) ? `Last Update: ${moment(crew.lastLocation.timestamp).fromNow()}` : '' }</span>
            </Tooltip>
        </CrewBoxFooter>
        <CrewBoxFooter>
          <FooterLeftSide>
            <Tooltip title="View Crew Schedule">
              <IconButton onClick={() => handleViewCrewSchedule(crew, index) } size="small" align="edge" alt="View Schedule" label="View Schedule" aria-label="View Schedule">
                <ScheduleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Crew">
              <IconButton onClick={() => handleViewCrewMap(crew, index) } size="small" align="edge" alt="View Crew" label="View Crew" aria-label="View Crew">
                <ExploreIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Daily Log">
              <IconButton onClick={() => handleViewCrewLog(crew, index) } size="small" align="edge" alt="View Crew" label="View Crew" aria-label="View Daily Log">
                <EventNote />
              </IconButton>
            </Tooltip>
          </FooterLeftSide>
          <FooterRightSide>
            <Tooltip title="Approve Overtime">
              <IconButton onClick={() => handleApproveOT(crew, index) } size="small" align="edge" alt="Approve OT" label="Clock-In Crew" aria-label="Approve OT">
                {
                  crew.overtimeRequested ? crew.overtimeApproved ? <HourglassFullIcon /> : <HourglassEmptyIcon /> : null
                }
              </IconButton>
            </Tooltip>
            <Tooltip title="Clock-In Checked-In Crew Members">
            <IconButton onClick={() => handleClockInCrew(crew, index) } size="small" align="edge" alt="Clock-In Crew" label="Clock-In Crew" aria-label="Clock-In Crew">
              <ScheduleIcon />
            </IconButton>
          </Tooltip>
            <Tooltip title="Clock-Out Entire Crew">
            <IconButton onClick={() => handleClockOutCrew(crew, index) } size="small" align="edge" alt="Clock-Out Crew" label="Clock-Out Crew" aria-label="Clock-Out Crew">
              <ClockOutIcon />
            </IconButton>
          </Tooltip>
          </FooterRightSide>
        </CrewBoxFooter>
        </React.Fragment>  ) }                       
            </CrewContainer>
          </Grid>
        )})}
      </Grid>
    )
  }

  const handleUpdateVisibleEntity = (newEntity) => {
  // console.log('Show ', newEntity)
    setEntity(newEntity)
    getCrewsAndShit(division, newEntity)
  }

  const handleEmployeeSelection = (emp) => {
    console.log('Open Employee Modal', emp)
    setState({...state, employee: emp, empModalOpen: true})
    setEmpModalOpen(true)
  }

  return (
    <div style={{padding: '8px', margin: '5px'}}>
      <MultiDriversModal open={multiDriverModalOpen} crew={state.activeCrew} updateParent={updateFromMultiDrivers} />
      <EmployeeModal open={empModalOpen} employee={state.employee} crewTypes={crewTypes} updateParent={updateFromEmployeeModal} />
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
      >
      <Container>
      {
          (props.user && props.user.entities && props.user.entities.length > 1) ? (
            <Grid container style={{padding: '10px'}}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} />
            </Grid>
          </Grid>
          ) : ''
        }
        <Grid container spacing={2} >
          <Grid item lg={6} md={6} sm={12} xs={12}>

      <HeaderBar>
         
     
              {/* <CheckInButton employees={state.employees} open={open} closeModal={closeCheckInModal} refreshEmployeeList={refreshList} /> */}
              <Button
         variant="contained"
         color="secondary"
         onClick={handleClockInAll}
         >Clock-In All</Button>

      <Button
         variant="contained"
         color="secondary"
         onClick={handleOpenCheckOutAll}
         style={{margin: '30px'}}
         >Check-Out All</Button>
               </HeaderBar>
            </Grid>
          <Grid item lg={3} md={4} sm={12} xs={12}>
          <FormControl 
          fullWidth 
            >
                
                <TextField
                  id="demo-simple-select-helper"
                  value={division}
                  type="text"
                  style={{ margin: 20 }}
                  select
                  variant="outlined"
                  onChange={(e) => handleChangeDivisions(e.target.value)}
                  // onChange={(e) => setState({...state, division: e.target.value })}
                >
                 {
                   crewTypes.map((item, index) => {

                    return (
                      <MenuItem key={index} value={item}>{item}</MenuItem>
                    )
                   })
                 }
                </TextField>
                <FormHelperText>Crews To Display</FormHelperText>
              </FormControl>
              </Grid>
            </Grid>
            <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <EmployeeSearch selectTheEmployee={handleEmployeeSelection} />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>


                <RightSide>
                <span> Last Updated: {moment(lastUpdated).format('hh:mm:ss a')} </span>
<CheckedInBox>
       # Checked-In: {state.checkedInEmployees.length}

       
     </CheckedInBox>  
  
   <CheckedInBox>

   # Clocked-In: {state.clockedInEmployees.length}
   
   </CheckedInBox>

   <CheckedInBox>

   # Absent: {state.absentEmployees.length}
   
   </CheckedInBox>
    <CheckedInBox> <TestComponent /></CheckedInBox>
 </RightSide>
      </Grid>

   </Grid>

   <CrewList crewsToFilter={state.crews} />
               {/* {
                loading ? <div style={{textAlign: 'center'}} ><CircularProgress size={96} style={{color: '#769ba2'}} /></div> : (
                <React.Fragment>
                  <CrewList crewsToFilter={state.crews} />
                </React.Fragment>
                )
               }  */}

    
        
      </Container>
     
      </DragDropContext>
      
    </div>
  ) 
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    crews: state.crews
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCrew: (crew) => { dispatch(setCrew(crew)) }
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(DailyRoster))