import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import StopIcon from '@material-ui/icons/HighlightOffSharp';

const ApprovingModal = (props) => {
// console.log('Approving Modal: ', props)
  const [value, setValue] = React.useState(0)
  const [toCancel, setToCancel] = React.useState(false)
  const [state, setState] = React.useState({
    cancel: false,
    value: 0
  })
  const [ourTimeOut, setOurTimeout] = React.useState(null)
  const [shift, setShift] = React.useState({})

  const countdown = (v) => {
  // console.log('Coundown called: ', value)
    // console.log(value)

    
      if (state.cancel) {
        props.updateParent('cancel', props.shift)
        // clearTimeout(timer)
      } else {
        if (value === -20) {
          props.updateParent('approved', props.shift)
        } else {
          // let timer = setTimeout(() => {
          //   countdown(t)
          // }, 300)
          // if (!shift || state.cancel) clearTimeout(timer)
        }
      }
  }

  React.useEffect(() => {
  // console.log('Use Effect Value: ', value)
    if (value < -20) return;
    if (props.shift && props.shift._id) {
    // console.log('Start Interval')
      const timer = setInterval(() => {
      // console.log('Interval: ', value)
          setValue(value - 10)
          countdown()
          if (value === -20) clearInterval(timer)
        // } else {
        //   // clearInterval(timer)
        // }
      }, 200)
      
    
    return () => clearInterval(timer)
  }
  }, [value])

  React.useEffect(() => {
  // console.log('Use Effect Approval Modal', props.shift)
    if (props.shift && props.shift._id ) {
    // // console.log('Its the same')
    // } else {
      // if (props.shift && props.shift._id) {
      // console.log('New Shift!!')
        setState({...state, value: 100, cancel: false})

        setShift(props.shift)
        setValue(100)
        // countdown(100)


      // }

    }
  //   // if (props.open) {
  //     // setValue(100)
  //     // setState({...state, value: 100})
  //     let timer1 = setInterval(() => {
  //     // console.log('Coundown', state.value)
  //       if (props.open) countdown(state.value)
  //     }, 150)
  //     // countdown(100)
  //     // setOurTimeout(setTimeout(() => {
  //     //   countdown(value)
  //     // }, 200))
  //   // }
  //   return () => {
  //     setState({...state, cancel: true})
  //     setToCancel(true)
  //     clearInterval(timer1)
  //     // setOurTimeout(clearTimeout(ourTimeOut))
  //   }
  }, [props.shift])

  const handleStopThis = () => {
    // setToCancel(true)
    setState({...state, cancel: true, value: 100})
    setValue(0)
    setShift({})
    props.updateParent('cancel', props.shift)
  }

  function hoursRounded(d) {
    return (Math.round(d * 4) / 4).toFixed(2)
  }

  return (
    <div>
      <Dialog
        open={props.open}
        maxWidth={'sm'}
        PaperProps={{
          style: {
            backgroundColor: '#eee',
            boxShadow: 'none',
          },
        }}
        style={{textAlign: 'center', color: '#fff'}}
        >
          <DialogTitle>
            Approving Shift for {(props.shift && props.shift.employee && props.shift.employee.displayName) ? props.shift.employee.displayName : ''}
          </DialogTitle>
          <DialogTitle style={{fontSize: '42px', color: '#aaa'}}>
           {(props.shift && props.shift.hoursWorked ) ? `${hoursRounded(props.shift.hoursWorked)} Hours Worked` : ''} 
          </DialogTitle>
          <DialogContent  >
            { value > 0 ? (
              <div>
               <CircularProgress variant="static" size={96} value={value}  />
              <DialogTitle>
                If you wish to cancel, please do so now!
              </DialogTitle>
              <Button
                size={'large'}
                fullWidth
                style={{backgroundColor: '#bd2319'}}
                startIcon={<StopIcon />}
                onClick={handleStopThis}
                >Cancel</Button>
              </div>

            ) : '' }
          </DialogContent>
        
        </Dialog>
    </div>
  )
}

export default ApprovingModal