import React, { useEffect, useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';

const hmiColor = 'rgb(2 164 9)'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: hmiColor,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  photoItem: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  caption: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    padding: '5px',
    boxSizing: 'border-box',
    fontSize: '36px'
  }
}));


const PhotoSlideshow = ({ photos, fetchPhotos }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    }, 10000); // Change photo every 10 seconds

    return () => clearInterval(interval);
  }, [photos.length]);

  useEffect(() => {
    if (photos.length > 0 && currentIndex === photos.length - 1) {
      fetchPhotos(); // Refetch and shuffle if we've shown all photos
    }
  }, [currentIndex, photos, fetchPhotos]);

  let photo = photos[currentIndex];
  console.log(photo)
  let imageURL = photo?.url;
  if (imageURL && !imageURL.includes('https')) imageURL = `https://${imageURL}`;
  let workorderName = ''
  if (photo && photo.workorder && photo.workorder.jobName) {
    workorderName = photo?.workorder?.jobName
  } else if (photo && photo.client && photo.client.displayName) workorderName = photo.client.displayName
  let addy = ''
  if (photo && photo.workorder && photo.workorder.jobSiteAddress) addy = photo.workorder.jobSiteAddress
    return (
        <div className={classes.root}>
            {photos.length > 0 && (
                <div className={classes.photoContainer}>
                    <img src={imageURL} alt="Slideshow" className={classes.photoItem} />
                    <p className={classes.caption}>
                      <div>{photo.created_by}</div>
                      <div>{new Date(photo.created_at).toLocaleDateString({  date: 'short' })} {new Date(photo.created_at).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</div>
                      <div>{workorderName} {photo?.workorder?.number ? `(#${photo.workorder.number})` : ''} </div>
                      <div>{addy}</div>
                      {
                        (photo && photo.mobileUploadType) ? <div>{photo.mobileUploadType === 'before' ? 'Before Photo' : photo.mobileUploadType === 'after' ? 'After Photo' : photo.mobileUploadType}</div> : null
                      }
                    </p>
                </div>
            )}
        </div>
    );
};

export default PhotoSlideshow;
