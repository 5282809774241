import React from 'react'
import { Button, CircularProgress, IconButton }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  groupContainer: {
    border: '3px solid #ddd',
    borderRadius: 8,
    padding: 10,
    marginBottom: theme.spacing(3),
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    alignItems: 'center'
  },
  headerArea: {
    display: 'flex',
    flexGrow: 1,
    // backgroundColor: 'red',
    fontSize: '1.3em',
    fontWeight: 'bold'
  },
  detailsArea: {
    display: 'flex',
    flexGrow: 3,
    justifyContent: 'space-evenly',
    alignContent: 'flex-start',
    alignSelf: 'stretch'
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  logItemContainer: {
    display: 'flex',
    justifyContent: 'space-between', 
    margin: '5px', 
    padding: '10px'
  },
  logItemDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'center',
    flexGrow: 3
  },
  dumpFeeArea: {
    marginLeft: 20,
    alignItems: 'center',
    width: '15%',
    justifyContent: 'center',
    display: 'flex',
  },
  actionArea: {
    marginLeft: 20,
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  hoursContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  biggerText: {
    fontSize: '1.2em',
    color: '#bbb',
    fontWeight: 400
  },
  biggestText: {
    fontSize: '1.4em',
    color: '#aaa',
    fontWeight: 800
  }
}))

const ADOTLogData = (props) => {
  const classes = useStyles();
  // console.log("ADOT LOG", props)
  // Get the shit grouped by agreement
  const ourAgs = props.ags.filter(item => (item.customerType === 'ADOT'))
  // console.log('Our Ags', ourAgs)
  let groupedData = []
  let totSupervisorHours = 0
  let totLaborHours = 0
  let totDumpFees = 0
  props.data.map((item, index) => {
    const laborHours = parseFloat((item.crewSize - 1) * Number(item.hoursWorked)).toFixed(2)
    totLaborHours += Number(laborHours)
    const superVisorHours = item.hoursWorked
    totSupervisorHours += Number(superVisorHours)
    item.dumpFees.map(dumpFee => {
      totDumpFees += Number(dumpFee.fee)
    })
    // totDumpFees += Number(item.dump)
    const agreementIndex = ourAgs.findIndex(ag => (ag._id === item.mtcAgreement))
    // console.log('INDEX:', agreementIndex)
    if (agreementIndex > -1) {
      // console.log('We didit ')
      const agreement = ourAgs[agreementIndex]
      // console.log('Agreement associated', agreement)
      // console.log('Log Data:', item)
      let grpIndex = groupedData.findIndex(groupItem => (groupItem.mtcAgreement === item.mtcAgreement))
      if (grpIndex > -1) {
        // console.log('We already have this one!!!')
  
          let newData = { ...groupedData[grpIndex] }
          // console.log('Use this', newData)
          newData.hoursWorked += Number(item.hoursWorked)
          // console.log('After save', newData)
          let currentLogs = newData.dailyData
          currentLogs.push(item)
          newData.dailyData = currentLogs
          groupedData[grpIndex] = newData
        
      } else {
        // console.log('create this shit')
        groupedData.push({
          mtcAgreement: item.mtcAgreement,
          crewName: agreement.jobName,
          hoursWorked: item.hoursWorked,
          dailyData: [ item ]
        })
      }
      // console.log('Gruped data!!', groupedData)
    }
  })

  const handleDeleteLog = (dailyLogId) => {
    // console.log('Delete this one:', dailyLogId)
    // console.log(groupedData[index])
    const arrItem = [ ...props.data ]
    let index = arrItem.findIndex(item => {
      // console.log('Item to check', item)
      return (item._id === dailyLogId)
    })
    // console.log('Index:', index)
    if (index > -1) {
      const itemToRemove = arrItem[index]
      // console.log('Delete this one', itemToRemove)
      props.deleteDailyLogItem(itemToRemove)
    }
  }

  const handleEditLog = (itemToEdit) => {
    props.editDailyLogItem(itemToEdit)
  }

  console.log(groupedData)
  return (
    <div className={classes.paper}>
      ADOT LOG DATA
      {
        groupedData.map((item, index) => {
          // const sortedDailyData = item.dailyData.sort(())
          let sortedDailyData = [...item.dailyData].sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB;
          });
          // console.log('Sorted:', sortedDailyData)
          // console.log('Grouped data', item)
          let crewLaborHours = 0
          let crewSupervisorHours = 0
          let crewDumpFees = 0
          return (
            <div key={index} className={classes.groupContainer}>
              <div className={classes.topRow}>
                <div className={classes.headerArea}>{item.crewName} </div>
                <div className={classes.detailsArea}>
                  <div>
                    {item.hoursWorked} Total Hours 
                  </div>
                  <div>
                    { item.dailyData.length ? `(${item.dailyData.length} Logs)` : null }
                  </div>
                </div>
              </div>
          


              <div style={{ padding: '10px', margin: '5px' }}>
                {sortedDailyData.map((dailyLog, dailyLogIndex) => {
                  // console.log('Daily log', dailyLog)
                  const hoursWorkedOnLog = Number(dailyLog.hoursWorked)
                  const laborHoursCalculated = parseFloat((dailyLog.crewSize - 1) * hoursWorkedOnLog).toFixed(2)
                  const laborHoursOnThisLog = dailyLog.totalLaborHours ? dailyLog.totalLaborHours : laborHoursCalculated
                  const superVisorHours = dailyLog.totalSupervisorHours ? dailyLog.totalSupervisorHours : hoursWorkedOnLog                 
                  crewLaborHours += Number(laborHoursOnThisLog)
                  crewSupervisorHours += Number(superVisorHours)
                  // console.log('Labor %d     %d ', laborHoursOnThisLog, crewLaborHours)
                  // console.log('Supervisor %d     %d ', superVisorHours, crewSupervisorHours)
                  dailyLog.dumpFees.map(dumpFee => {
                    crewDumpFees += Number(dumpFee.fee)
                  })

                  let bgColor = dailyLogIndex %2 === 0 ? '#ddd' : '#fff'
                  let dumpFeeTotal = 0
                  let dumpFees = []
                  if (dailyLog && dailyLog.dumpFees && dailyLog.dumpFees.length) dumpFees = dailyLog.dumpFees
                  dumpFees.map(dumpFee => {
                    dumpFeeTotal += Number(dumpFee.fee)
                  })
                  // console.log(dailyLog)
                  // const laborHours = parseFloat((dailyLog.crewSize - 1) * Number(dailyLog.hoursWorked)).toFixed(2)
                  var formattedNumber = Math.round(laborHoursOnThisLog*100)/100
                  return (
                    <div key={dailyLogIndex} className={classes.logItemContainer} style={{ backgroundColor: bgColor }}>
                      <div className={classes.logItemDetails} >
                        <div>{dailyLog.date}</div>
                        <div>{dailyLog.crewSize} Men {dailyLog.crewName ? `on ${dailyLog.crewName} ` : null} ({`${dailyLog.hoursWorked} hours`})</div>
                        <div className={classes.hoursContainer} >
                          <div>Supervisor Hours: {superVisorHours} </div>
                          <div>Labor Hours: {laborHoursOnThisLog} </div>
                        </div>
                      </div>
                      <div className={classes.dumpFeeArea}>{ dumpFeeTotal > 0 ? `Dump Fees: ${currencyFormat(dumpFeeTotal)}` : '   ' }</div>
                      <div className={classes.actionArea}>
                      <Button
                          variant="contained"
                          color="secondary"
                          onClick={e => handleEditLog(dailyLog)}>Edit</Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={e => handleDeleteLog(dailyLog._id)}>Delete</Button>
                         
                      </div>
                    </div>
                  )
                })}
              </div>
              <div ><span className={classes.biggerText}>Total Supervisor Hours: {currencyFormatWithOutFlair(crewSupervisorHours)}</span></div>
              <div><span className={classes.biggerText}>Total Labor Hours: {currencyFormatWithOutFlair(crewLaborHours)}</span></div>
              <div><span className={classes.biggerText}>Total Dump Fees: {currencyFormat(crewDumpFees)}</span></div>
          </div>
          )
        })
      }
      <div><span className={classes.biggestText}>Total Supervisor Hours: {currencyFormatWithOutFlair(totSupervisorHours)}</span></div>
      <div><span className={classes.biggestText}>Total Labor Hours: {currencyFormatWithOutFlair(totLaborHours)}</span></div>
      <div><span className={classes.biggestText}>Total Dump Fees: {currencyFormat(totDumpFees)}</span></div>
    </div>
  )
}

export default ADOTLogData