import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Home from './components/Home/Home'
import Login from './components/Login/Login'
import Logout from './components/Logout/Logout'
// import Registration from './components/Registration/Registration'
import ActivationHash from './components/ActivationHash/ActivationHash'
import Recovery from './components/Recovery/Recovery'
import RecoveryHash from './components/RecoveryHash/RecoveryHash'
import Error from './components/Error/Error'
import Navigation from './components/Navigation/Navigation'
import Profile from './components/Profile/Profile'
import Statement from './components/Statement/Statement'
import Customer from './components/Customer/Customer'
import Services from './components/Services/Service'
import CustomerDetail from './components/Customer/CustomerDetail'
import Dashboard from './components/Dashboard/Dashboard'
import Reports from './components/Reports/Reports'
import Employees from './components/Employees/Employee'
import Entities from './components/Entities/Entities'
import Crews from './components/Crews/Crew'
import Equipment from './components/Equipment/Equipment'
import DailyRoster from './components/DailyRoster/DailyRoster'
import Schedule from './components/Schedule/ViewWorkorder'
import AR from './components/AccountsReceivable/AccountsReceivable'
import Timesheet from './components/Timesheet/Timesheet'
import Userlist from './components/Users/Userlist'
import UserDetail from './components/Users/UserDetail'
import AssignWorkordersComponent from './components/Workorders/AssignWorkorders'
import WorkordersByCrew from './components/Workorders/WorkorderByCrew'
import ShedStore from './components/Inventory/ShedView'
import ShedInventoryMainPage from './components/Inventory/InventoryMainPage'
import ShedOrders from './components/Inventory/ShedOrder'
import InventoryList from './components/Inventory/InventoryList'
import TakeOffInventoryList from './components/Inventory/TakeOffInventoryList'
import InventoryCheckPage from './components/Inventory/InventoryCheck'
import BHLSupplyList from './components/Inventory/BHLSupplyList'
import Settings from './components/Settings/Settings'
import TaxSettings from './components/Settings/TaxSettings'
import Vendors from './components/Vendors/Vendors'
import { connect } from 'react-redux'
import { setUrl, setAuth, setUser, setNotifications, setPaypal, setGPS, setPerms, setServices, setScopes, setSalesReps, setClients, setEmployees, setTasks, setEntities, setUsers, setCrews, setSnoutWeevilButtonActive, setConfigs, setFertilizationButtonActive } from './actions/connectionActions'
// import ReactNotification from "react-notifications-component" 
// import "react-notifications-component/dist/theme.css"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "animate.css"
import ProfileModify from './components/ProfileModify/ProfileModify'
import axios from 'axios'
import { CssBaseline } from '@material-ui/core'
import Timeclock from './components/Timesheet/Timeclock'
import CompleteInvitation from './components/Registration/CompleteInvitation'
import RegistrationFailed from './components/Registration/RegistrationFailed'
import RestrictedContent from './components/Error/Restricted'
import CustomerIssues from './components/CustomerIssues/CustomerIssueComponent'
import Debug from './components/Vendors/BulkUpload' // Crews/SateliteView' // './components/Proposals/SigTest'
import SateliteView from './components/Crews/SateliteView'
import SateliteViewReports from './components/Reports/SateliteView'
import EmailBlastDashboard from './components/Customer/EmailBlastDashboard'
import OpenInvoiceList from './components/Invoices/OpenInvoiceList'
import ServiceVisits from './components/ServiceVisits/ServiceVisits'
import GPSActivityList from './components/Crews/GPSActivity'
import BHLDashboard from './components/BHL/Dashboard'
import ProjectDashboard from './components/Projects/Dashboard'
import ViewProposal from './components/Proposals/ViewOnlineProposal'
import ReceiveDeposit from './components/Proposals/ReceiveDeposit'
import BulkUploads from './components/Vendors/BulkUpload'
import CustomerDetailPage from './components/Customer/CustomerDetailPage'
import ThankYouPage from './components/Proposals/ThankYouPage'
import PODashboard from './components/PurchaseOrders/PurchaseOrderDashboard'
import TaskDashboard from './components/Tasks/TaskList'
import ProposalScopeOfWork from './components/Proposals/ChooseScopes'
import ProposalWizardContainer from './components/Proposals/ProposalWizardContainer'
import ScopesSettings from './components/Settings/Scopes'
import ProposalObjectivesList from './components/Settings/ProposalSettings/Objectives'
import ProposalOptions from './components/Settings/ProposalSettings/ProposalOptions'
import SizeSettings from './components/Settings/Sizes'
import DebugComponent from './components/Projects/MilestoneKanBan'
import ProjectDetailsPage from './components/Projects/ProjectDetailsPage'
import HMIDashboard from './components/HMI/HMIDashboard'
import POApproval from './components/PurchaseOrders/POApproval'
import ViewPOOnline from './components/PurchaseOrders/ViewPOOnline'
import OpenEstimateDashboard from './components/Proposals/OpenEstimatesModule/OpenEstimateDashboard'
import CavansQueue from './components/Queues/CavansQueue'
import MtcRoutes from './components/MtcRoutes/Dashboard'
import CrewDailyLog from './components/Crews/DailyLog'
import ViewPoolProposal from './components/Pools/ViewOnlinePoolProposal'
import ViewWorkorderOnline from './components/Workorders/ViewOnline'
import ViewRouteMap from './components/Common/Maps/MapView'
import MapTest from './components/Common/Maps/MapTest'
import InputEmployeePhone from './components/Employees/AddPhoneNumber'
import EmployeePINSelfVerification from './components/Employees/EmployeePINSelfVerification'
import HMIMapView from './components/HMI/HMIMapView'
import FieldUpdates from './components/FieldUpdates/FieldUpdateList'
import ADOTRepSignaturePage from './components/HMI/ADOTRepSignaturePage'
import MobileAppDownloadPage from './components/Home/MobileAppDownloadPage'
import MtcChecklistItems from './components/MtcRoutes/ChecklistComponent'
import HMICrewAssignmentPage from './components/HMI/HMICrewAssignmentPage'
import HMIMtcSchedulePage from './components/HMI/HMIMtcSchedulePage'
import HMIMtcMapView from './components/HMI/MaintenanceStuff/MtcMapView'
import HMIIrrigationAssetDashboard from './components/HMI/HMIIrrigationAssetDashboard'
import HMIDailyLogs from './components/HMI/HMIDailyLogs'
import HMISiteReviews from './components/HMI/SiteReviewList'
import HMILogDashboard from './components/HMI/DailyLogDashboard'
import ProposalPaperStyleComponent from './components/Proposals/PaperStyleEstimate/ProposalPaperStyleComponent'
import MyEstimateList from './components/Proposals/MyEstimateList'
import OnlineInvoiceComponent from './components/Invoices/OnlineInvoiceComponent'
import HmiRosterDataForTV from './components/HMI/HmiRosterDataForTV'
import SimulateMobileAppView from './components/Employees/EmployeeMobileAppView'
import HMIOfficeDashboard from './components/HMI/HMIOfficeDashboard'
import HMIPhotosForTV from './components/HMI/HMIPhotosForTV'
import LotInventory from './components/LotInventory/Dashboard'
import LotMap from './components/LotInventory/LotMap'
import CustomerMapView from './components/HMI/CustomerMapview'
import TimeOffRequestPage from './components/Employees/TimeOffRequest'
import OTRequestPage from './components/Crews/OvertimeApprovalPage.js'
import InvoiceGenerator from './components/Invoices/InvoiceGenerator.js'
import DailyScheduleView from './components/HMI/MaintenanceStuff/DailyScheduleMapView.js'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MyPOList from './components/PurchaseOrders/MyPOList.js'
import RouteDashboard from './components/HMI/MaintenanceStuff/RouteDashboard'
import PendingInvoiceList from './components/Invoices/PendingInvoices.js'
import SeasonalFlowers from './components/Customer/SeasonalFlowersOrderingPage.js'
import ContactList from './components/Customer/ContactsDashboard.js'

const appStyle = {
  backgroundImage: `url("${process.env.PUBLIC_URL}/background.jpg")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'rgba(222, 222, 222, .3)',
  backgroundBlendMode: 'hue',
  minHeight: '100vh',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  // color: 'red',
  fontSize: '1.2em'
};


toast.configure({
  autoClose: 3000,
  draggable: true,
  position: toast.POSITION.BOTTOM_LEFT
})

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route { ...rest }
    render={(props) => (
      authenticated ?
      <Component { ...props } /> :
      <Redirect to='/login' />
    )}
  />
)

class App extends Component {
  constructor(props) {
    // console.log('App Props?? ', props)
    // console.log('Use History:', useHistory)
    super(props)
    this.url = `https://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`
    if (process.env.REACT_APP_PORT === 'null') {
      this.url = `https://${process.env.REACT_APP_HOST}`
    }
    // console.log(this.url)
    this.paypal = {
      clientId: ''
    }
    this.props.setNotifications(this.notification)
    this.props.setUrl(this.url)
    this.props.setPaypal(this.paypal)
    axios.defaults.withCredentials = true
    axios.interceptors.request.use(
      (config) => {
        // Do something before the request is sent (optional)
        return config;
      },
      (error) => {
        // Handle and log errors
        console.error('Axios Request Error:', error);
        return Promise.reject(error);
      }
    );
    
    // After receiving the response
    axios.interceptors.response.use(
      (response) => {
        // Do something with the response data (optional)
        return response;
      },
      (error) => {
        // Handle and log response errors
        console.error('Axios Response Error:', error);
        // If you want to see the server's response in the error:
        if (error.response) {
          console.error('Server Response:', error.response.data);
        }
        return Promise.reject(error);
      }
    );
    
  }

  async componentDidMount() {
    axios.interceptors.response.use(undefined, (error) => {
      // console.log('AXIOS INTERCEPTION ERROR', error)
      if(error && error.response && error.response.status === 401) {
        // console.log('401 - err')
        this.props.setAuth(false)
        this.notification({
          type: 'danger',
          title: 'Failed!',
          message: 'Not logged in!'
        })
        return Promise.reject(error)
      }
      if(error && error.response && error.response.status === 403) {
        // console.log('Pro /ps: ', props)
      // console.log('This.props', this.props)
        this.notification({
          type: 'danger',
          title: 'Unauthorized!',
          message: 'Sorry, this request is invalid. You are not authorized to access this resource.'
        })
        // this.props.history.push('/')
        return 'Unauthorized'
      }
    })
    const authenticated = await axios.post(`${this.url}/api/auth/check`)
      // console.log('Authenticated: 101', authenticated)
    if (authenticated && authenticated.data && authenticated.data.success) {
      this.props.setAuth(authenticated.data.success)
      if (authenticated.data.success) {
        this.props.setUser(authenticated.data.user)
        const getOurStuff = await axios.get(`${this.url}/api/company/getStuff`)
        // console.log('Got Our stuff', getOurStuff)
        if (getOurStuff.data && getOurStuff.data.clients) this.props.setClients(getOurStuff.data.clients)
        if (getOurStuff.data && getOurStuff.data.employees) this.props.setEmployees(getOurStuff.data.employees)
        if (getOurStuff.data && getOurStuff.data.salesReps) this.props.setSalesReps(getOurStuff.data.salesReps)
        if (getOurStuff.data && getOurStuff.data.tasks) this.props.setTasks(getOurStuff.data.tasks)
        if (getOurStuff.data && getOurStuff.data.services) this.props.setServices(getOurStuff.data.services)
        if (getOurStuff.data && getOurStuff.data.users) this.props.setUsers(getOurStuff.data.users)
        if (getOurStuff.data && getOurStuff.data.crews) this.props.setCrews(getOurStuff.data.crews)
        if (getOurStuff.data && getOurStuff.data.entities) this.props.setEntities(getOurStuff.data.entities)
        if (getOurStuff.data && getOurStuff.data.scopes) this.props.setScopes(getOurStuff.data.scopes)
        if (getOurStuff && getOurStuff.data && getOurStuff.data.configs && getOurStuff.data.configs.length) {
          // console.log('Got our Configs!!", ', getOurStuff.data.configs)
          // Use DFL al the time
          let isFertActive = getOurStuff.data.configs[0].fertilizationButtonActive
          let isSnoutWeevileActive = getOurStuff.data.configs[0].snoutWeevilButtonActive
          this.props.setFertilizationButtonActive(isFertActive)
          this.props.setSnoutWeevilButtonActive(isSnoutWeevileActive)
          this.props.setConfigs(getOurStuff.data.configs)
        }
      }
    } else {
      // console.log('Not Logged IN')
      this.props.setUser(null)
      this.props.setCrews([])
      this.props.setUsers([])
      this.props.setSalesReps([])
      this.props.setAuth(false)
    }
  }

  

  notification(options) {
    let timeToClose = 3000
    let { type, title, message, autoClose } = options
    if (autoClose) {
      timeToClose = autoClose
    }
    if (type === 'danger') {
      type = 'warning'
    }
    if (type === 'lastNameChange') {
      toast['success'](
        <div><b>{title}</b><br/>{message}</div>
      )
    } else {
      toast[type](<div><b>{title}</b><br/>{message}</div>, { autoClose: timeToClose })
    }
  }

  render() {
    return (
        <BrowserRouter>
          <MuiThemeProvider theme={ createMuiTheme(
            {
              palette: 
              {
                neutral: {
                  main: '#5c6ac4',
                  contrastText: '#fff'
                }, 
                type: this.props.theme,
                lightGreen: {
                  main: '#2a9e0d',
                  light: '#65d047',
                  dark: '#006e00',
                  contrastText: '#fff'
                },
                osecondary: {
                  main: 'rgb(81,110,118)',
                  light: 'rgb(172,202,205)',
                  dark: 'rgb(119,156,164)',
                  contrastText: '#ddd'
                },
                secondary: {
                  main: '#9e7747',
                  light: '#d1a673',
                  dark: '#6d4b1d',
                  contrastText: '#ddd'
                },
                primary: {
                  main: 'rgb(119,156,164)',
                  light: 'rgb(172,202,205)',
                  dark: 'rgb(81,110,118)',
                  contrastText: '#fff'
                }
              }
            }
          ) }>
          <CssBaseline />
          <div style={appStyle} >
            <Navigation />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Switch>
              <Route path="/" component={ Home } exact />
              <Route path="/restricted" component={ RestrictedContent } exact />
              <Route path="/login" component={ Login } exact />
              <Route path="/logout" component={ Logout } exact />
              {/* <Route path="/registration" component={ Registration } exact /> */}
              <Route path="/activation/:hash" component={ ActivationHash } exact />
              <Route path="/recovery" component={ Recovery } exact />
              <Route path="/recovery/:hash" component={ RecoveryHash }/>
              {/* <Route path="/estimate/view/:estimateId" component={ ViewProposal }/> */}
              <Route path="/estimate/view/:estimateId/:contactId" component={ ViewProposal }/>
              <Route path="/estimate/postApproval/:estimateId/:contactId" component={ ThankYouPage }/>
              <Route path="/estimate/deposit/:estimateId/:contactId" component={ ReceiveDeposit }/>
              <Route path="/online/invoice/:invoiceId/:contactId" component={ OnlineInvoiceComponent }/>
              <Route path="/estimate/pool/:bidId" component={ ViewPoolProposal } />
              <Route path="/view/seasonalflowers/:clientId?" component={ SeasonalFlowers } />

              <Route path="/view/updateemployeephone" component={InputEmployeePhone} />
              <Route path="/view/verifyEmployeePin/:requestId" component={EmployeePINSelfVerification} exact />
              <Route path = "/view/workorder/:workorderId" component={ViewWorkorderOnline} exact />
              <Route path = "/online/hmiRosterDataForTV" component={HmiRosterDataForTV} exact />
              <Route path= "/online/hmiphotosfortv" component={HMIPhotosForTV} exact />
              <Route path="/downloadmobileapp" component={MobileAppDownloadPage } exact />
              <Route path="/timeOffRequestForm/:phoneNumber" component={ TimeOffRequestPage } exact />
              <Route path="/view/adotrepsignaturepage/:dailyLogId/:contactId" component={ ADOTRepSignaturePage } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/routes" component={ MtcRoutes } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/otrequest/:requestId" component={ OTRequestPage } authenticated={ this.props.authenticated } exact />              
              <PrivateRoute path="/routeview" component={ ViewRouteMap } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/crewDailyLog" component={ CrewDailyLog } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/myqueue" component={ CavansQueue } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/myestimates" component={ MyEstimateList } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/mypurchaserequests" component={ MyPOList } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/debug" component={ MapTest } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/tasks" component={ TaskDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/purchaseorders" component = { PODashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/poapprovals/:poId" component={ POApproval } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/viewpo/:poId" component={ ViewPOOnline } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/bulkuploads" component={ BulkUploads } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/dashboard" component={ Dashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/inventory" component={ InventoryList } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/takeoffinventory" component={ TakeOffInventoryList } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/bhlsupplylist" component={ BHLSupplyList } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/shed" component={ ShedInventoryMainPage } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/shedPOS" component={ ShedStore } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/shedOrders" component={ ShedOrders } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/inventoryUpdate" component={ InventoryCheckPage } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/roster" component={ DailyRoster } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/reports" component={ Reports } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/clients" component={ Customer } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/bhl" component={ BHLDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmi" component={ HMIDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/lotmap" component={ LotMap } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmidailylogs" component={ HMIDailyLogs } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmisitereviews" component={ HMISiteReviews } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/clients/:clientId" component={ CustomerDetailPage } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/clients/:clientId/:type/:_id" component={ CustomerDetailPage } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/clientmapview/:jobsiteId" component={ CustomerMapView } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/emailblast" component={ EmailBlastDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/customerissues" component={ CustomerIssues } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/statements" component={ Statement } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path='/profile' component={ Profile } authenticated={ this.props.authenticated } exact/>
              <PrivateRoute path='/profile/modify' component={ ProfileModify } authenticated={ this.props.authenticated } exact/>
              <PrivateRoute path='/services' component={ Services } authenticated={ this.props.authenticated } exact/>              
              <PrivateRoute path="/entities" component={ Entities } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/employees" component={ Employees } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/vendors" component={ Vendors } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/equipment" component={ Equipment } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/crews" component={ Crews } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/arfinance" component={ AR } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/schedule" component={ Schedule } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/timesheets" component={ Timesheet } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/assignworkorders" component={ AssignWorkordersComponent } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/crewWorkorders" component={ WorkordersByCrew } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/birdseyeview" component={ SateliteView } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/map" component={ SateliteViewReports } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/settings" component={ Settings } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/tax-details" component={ TaxSettings } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/scopes" component={ ScopesSettings } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/sizes" component={ SizeSettings } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/service-visits" component={ ServiceVisits } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/gps-activity" component={ GPSActivityList } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/projects" component={ ProjectDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/openEstimates" component={ OpenEstimateDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/projects/:projectId" component={ ProjectDetailsPage } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/proposals/:estimateId" component={ ProposalWizardContainer } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/newestimateview/:estimateId" component={ ProposalPaperStyleComponent } authenticated={ this.props.authenticated } exact />              
              <PrivateRoute path="/proposalobjectives" component={ProposalObjectivesList} authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/proposaloptions/:optionType" component={ProposalOptions} authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/maintenanceoptions/checklistitems" component={MtcChecklistItems} authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmimapview" component={ HMIMapView } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmicrewmtcassignment" component={ HMICrewAssignmentPage } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmimtcschedule" component={ HMIMtcSchedulePage } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmimtcmapview" component={ HMIMtcMapView } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmiirrigationasset" component={ HMIIrrigationAssetDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/hmilogdashboard" component={ HMILogDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/dailyscheduleview" component={ DailyScheduleView } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/routingdashboard" component={ RouteDashboard } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/contacts" component={ ContactList } authenticated={ this.props.authenticated } exact />
              <Route path="/timeclock" component={ Timeclock } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/users" component={ Userlist } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/pending-invoices" component={ PendingInvoiceList } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/open-invoices" component={ OpenInvoiceList } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/users/:userId" component={ UserDetail } authenticated={ this.props.authenticated } />
              <PrivateRoute path="/simulatemobileappview" component={ SimulateMobileAppView } authenticated={ this.props.authenticated } />
              <PrivateRoute path="/hmiofficedashboard" component={ HMIOfficeDashboard } authenticated={ this.props.authenticated } />
              <PrivateRoute path="/lotinventory" component={ LotInventory } authenticated={ this.props.authenticated } exact />
              <Route path="/completeInvitation/:invitationId" component={ CompleteInvitation } />
              <Route path="/RegistrationFailed" component={ RegistrationFailed } hideAppBar={true}/>
              <PrivateRoute path="/fieldupdates" component={ FieldUpdates } authenticated={ this.props.authenticated } exact />
              <PrivateRoute path="/generate-invoice/:clientId" component={ InvoiceGenerator } authenticated={ this.props.authenticated } exact />
             <Route component={ Error } />
            </Switch>
            </MuiPickersUtilsProvider>
          </div>
        </MuiThemeProvider>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    theme: state.theme,
    perms: state.perms
  }
}

const mapDispatchToProps = (dispatch) => {
  // console.log('DISTPACTH: ', dispatch)
  return {
    setUrl: url => { dispatch(setUrl(url)) },
    setSalesReps: (salesReps) => { dispatch(setSalesReps(salesReps)) },
    setAuth: authenticated => { dispatch(setAuth(authenticated)) },
    setUser: (user) => { dispatch(setUser(user)) },
    setUsers: (users) => { dispatch(setUsers(users)) },
    setPerms: (perms) => { dispatch(setPerms(perms)) },
    setNotifications: notifications => { dispatch(setNotifications(notifications)) },
    setPaypal: paypal => { dispatch(setPaypal(paypal)) },
    setGPS:  (position) => { dispatch(setGPS(position)) },
    setEmployees: (employees) => { dispatch(setEmployees(employees)) },
    setTasks: (tasks) => { dispatch(setTasks(tasks)) },
    setCrews: (crews) => { dispatch(setCrews(crews)) },
    setClients: (clients) => { dispatch(setClients(clients)) },
    setServices: (services) => { dispatch(setServices(services)) },
    setScopes: (scopes) => { dispatch(setScopes(scopes)) },
    setEntities: (entities) => { dispatch(setEntities(entities)) },
    setFertilizationButtonActive: (fertilizationButtonActive) => { dispatch(setFertilizationButtonActive(fertilizationButtonActive)) },
    setSnoutWeevilButtonActive: (snoutWeevilButtonActive) => { dispatch(setSnoutWeevilButtonActive(snoutWeevilButtonActive)) },
    setConfigs: (configs) => { dispatch(setConfigs(configs)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
