import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, emphasize }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AssignedEntityComponent from '../Entities/AssignedEntityComponent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  gridItem: {
    width: '800px'
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  }
}));

const defaultStateData = {
  crew: {},
  foreman: {
    displayName: ''
  },
  vehicle: {
    name: ''
  },
  isDriver: false,
  isBilingual: false,
  isActive: false,
  toAutomate: false,
  npkwy: false,
  mowingCrew: false,
  adot: false,
  asssignedADOTCrew: null
}

const crewTypes = [
  'Temp',
  'Floater',
  'Crewman',
  'Lead Man',
  'Foreman',
  'Bobcat Operator',
  'Crane Operator',
  'Supervisor',
  'Manager',
  'El Jefe',
  'Yard Crew',
  'Mechanic',
  'Office Staff',
  'Sales'
]

const divisions = [
  'Customer Service',
  'Specialty',
  'Irrigation and Lighting',
  'Maintenance',
  'Landscape Install',
  'Hardscape',
  'Pool/Spa',
  'Yard'
]

const ServiceDetailsModal = (props) => {
// console.log('Got our Crew Modal modal', props)
// console.log('User:', props.user)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [adotCrews, setAdotCrews] = React.useState([])
  const categories = [
    'Monthly Maintenance', 'Landscapes', 'Specialty', 'Clean-Ups', 'Irrigation', 'Tree Trimming', 'Lighting', 'Pest Control', 'Water Feature', 'Flowers', 'Other'
  ]
  const [state, setState] = React.useState(defaultStateData);

React.useEffect(() => {
  let isMounted = true
  const fetchADOTCrews = async () => {
    setLoading(true)
    axios({
      method: 'get',
      url:  `${props.url}/api/hmi/getActiveMtcAgreements?customerType=ADOT`
    }).then(response => {
      // console.log('Axios response to fetch adot logs ', response)
      if (isMounted) {
        setLoading(false)
        setAdotCrews(response.data)
      }
    })
  }

  fetchADOTCrews()

  return () => {
    isMounted = false
  }
}, [])

React.useEffect(() => {
  // console.log('Use effect CREW MODAL Details', props)
    // let service = props.service
    // let assignedCrew 
    if (props.crew && props.crew._id) {
      let newState = {
        foreman: null,
        crew: props.crew,
        isActive: props.crew.isActive || false,
        vehicle: props.crew.vehicle,
        toAutomate: props.crew.toAutomate
      }
      if (props.crew.foreman) {
        let ourIndex = props.employees.map(function (e) { 
          // console.log('WHHH?? ', e)
          return e._id
        }).indexOf(props.crew.foreman)
        // console.log('OUR FOREMAN INDEX: ', ourIndex)
        // console.log('Foreman: ', props.employees[ourIndex])
        newState.foreman = props.employees[ourIndex]
      }
      const crew = props.crew
      if (crew && crew.crewOptions && crew.crewOptions.length) {
        for (const opt of crew.crewOptions) {
          console.log('Option Selected', opt)
          newState[opt] = true
        }
      }

      if (crew && crew.asssignedADOTCrew) {
        let crewIndex = adotCrews.findIndex(item => (item._id === crew.asssignedADOTCrew))
        console.log('crew index', crewIndex)
        if (crewIndex > -1) newState.asssignedADOTCrew = adotCrews[crewIndex]
      }
        setState({...defaultStateData, ...newState})
      } else {
        setState(defaultStateData)
      }
      

    // if (props.crew.vehicle) {
    // // console.log('Got a vehicle, ', props.crew.vehicle)
    //   // let vehicleIndex = props.vehicles.map(function (e) { 
    //   //   // console.log('WHHH?? ', e)
    //   //   return e._id
    //   // }).indexOf(props.crew.vehicle)
    //   // let ourVehicle = props.vehicles[vehicleIndex]
    //   // console.log('Our Vehicle!', ourVehicle)
    //   if (props.crew.vehicle && props.crew.vehicle._id) {
    //     setState({...state,
    //       vehicle: props.crew.vehicle
    //     })
    //   }
    // }
  }, [props.crew]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

const handleSubmit = () => {
// console.log('Update this crew!', state)
  setLoading(true)
// console.log('handle save', state)
  // console.log(props)
  let crewOpts = []
  if (state.npkwy) crewOpts.push('npkwy')
  if (state.adot) crewOpts.push('adot')
  if (state.mowingCrew) crewOpts.push('mowingCrew')
  let stuffToSend = {...state}
  const currentCrew = state.crew
  delete stuffToSend.crew
  const crewObj = {
    ...currentCrew,
    ...state,
    crewOptions: crewOpts
  }
  console.log('Crew object:', crewObj)
  let obj = {
    // details: state,
    crew: crewObj,
    user: props.user
  }
  console.log('Obj to send', obj)
  axios({
    method: 'post',
    url:  `${props.url}/api/crews/updateCrew`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update service ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved Changes to that Crew!',
        message: 'Well Done!!'
      })
      // setState({...state,
      //   name: response.data.name,
      //   category: response.data.category || 0,
      //   description: response.data.description
      // })
      // handleCloseModal()
      // props.updateParent('update', response.data)
      handleCloseModal()
     
      // setOpen(false)
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Error With Associated Workorder (CrewModal 260)!'
      })
    }
    
  }).catch(err => {
  // console.log('Error saving workorder: ', err)
  })
}

const handleCloseModal = () => {
  setState(defaultStateData)
  props.updateParent('closeModal')
}

const CloseButton = () => {
  return (
    <IconButton onClick={handleCloseModal} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
      <CloseIcon />
    </IconButton>
  )
}

const setServiceName = (name) => {
  setState({...state, name: name})
}

const updateCrewDivision = (id, value) => {
  // console.log('Update crew: ', id)
  // if (value === 'ADOT') fetchADOTCrews()
// console.log('Value: ', value)
  setState({...state, crew: {...state.crew, division: value}})
}

const updateCrewForeman = (id, value) => {
  // console.log('Update crew: ', id)
  // console.log('Value: ', value)
  if (value && value._id) {
    // console.log('295')
    setState({...state, foreman: value, crew: {...state.crew, foreman: value._id, foremanName: `${value.firstName} ${value.lastName}`}})
  } else {
    // console.log('297')
    setState({...state, foreman: { displayName: '' }, crew: {...state.crew, foreman: null, foremanName: ''}})
  }
}

const updateAssignedVehicle = (e, truck) => {
  // console.log('Update vehicle; ', truck)
  if (truck && truck._id) {
    // console.log('306')
    setState({
      ...state,
      vehicle: truck,
      crew: {
        ...state.crew,
        vehicle: truck._id
      }
    })
  } else {
    // console.log('316')
    setState({
      ...state,
      vehicle: {
        name: ''
      },
      crew: {
        ...state.crew,
        vehicle: null
      }
    })
  }
}

const updateAssignedCrew = (event, crew) => {
// console.log('Update crew')
// console.log('Event: ', event)
// console.log('Option: ', crew)
  if (crew && crew._id) {
    setState({
      ...state,
      assignedCrew: crew,
      crew: {
        ...state.crew,
        crew: crew._id,
        crewName: crew.name
      }
    })
  } else {
    setState({
      ...state,
      assignedCrew: {},
      crew: {
        ...state.crew,
        crew: null,
        crewName: 'None'
      }
    })
  }
  // console.log(event.target.id)
  // let split = event.target.id.split('-')
  // let crewNum = split[3]
  // let optIndex = split[1]
  // console.log('Crew Numb: ', crewNum)
  // console.log('Opt Index: ', optIndex)
  // // // console.log(state.jobDetails[optIndex])
  // // let jobDetail = state.service[optIndex]
  // let newCrew = props.crews[crewNum]
  // console.log('nEW Crew:', newCrew)
  // let newWO = state.workorder
  // newWO.crewNumber = newCrew['agValue']
  // console.log('New Crew: ', newCrew)
  // console.log('For this job: ', jobDetail)
  // let newJobDetails = state.jobDetails
  // let ourIndex = state.jobDetails.map(function (e) { 
  //   // console.log('WHHH?? ', e)
  //   return e.number
  // }).indexOf(jobDetail.number)
  // newJobDetails[ourIndex]['crew'] = newCrew
  // newJobDetails[ourIndex]['requiresUpdate'] = true
  // setState({...state, crew: {...crew, crew}})
}

const handleUpdateAssignedEntity = (newEntity) => {
// console.log('New ent', newEntity)
  let newCrew = {...state.crew}
  newCrew.entity = newEntity._id
// console.log('Set state cerw:', newCrew)
  setState({...state, crew: newCrew })
}

const updateAssignedADOTCrew = (e, newItem) => {
  setState({...state, asssignedADOTCrew: newItem })
}
  return (
    <div>
        <Dialog
          // fullScreen={false}
          // maxWidth={'lg'}
          // width='lg'
          fullScreen
          // maxwidth='lg'
          // style={{width: '90hw'}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">Crew Details -  {state.crew.name}</DialogTitle>
          
          <DialogContent>
          <CloseButton />
            <Grid container spacing={2} className={classes.gridItem}>
              <Grid item lg={6} xs={6}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Name"
                    label="Crew Name"
                    type="text"
                    onChange={(e) => setState({...state, crew: {...state.crew, name: e.target.value}})}
                    value={state.crew.name}
                    fullWidth
                />
              </Grid>
              <Grid item lg={2} xs={2}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Name"
                    label="Crew Number"
                    type="text"
                    onChange={(e) => setState({...state, crew: {...state.crew, crewNumber: e.target.value}})}
                    value={state.crew.crewNumber}
                    fullWidth
                />
              </Grid>
              <Grid item lg={2} xs={2}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Name"
                    label="AG Value"
                    type="text"
                    onChange={(e) => setState({...state, crew: {...state.crew, agValue: e.target.value}})}
                    value={state.crew.agValue}
                    fullWidth
                />
              </Grid>
              <Grid item lg={2} xs={2}>
                  {/* <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Name"
                    label="Roster Order"
                    type="text"
                    onChange={(e) => setState({...state, crew: {...state.crew, rosterOrder: e.target.value}})}
                    value={state.crew.rosterOrder}
                    fullWidth
                /> */}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AssignedEntityComponent entity={state.crew.entity} title={'Assigned Entity'} disabled={loading} user={props.user} updateParent={handleUpdateAssignedEntity} />
              </Grid>
              <Grid item lg={12} xs={12}>
              <Autocomplete
                id="Division"
                options={divisions ? divisions : []}
                // getOptionLabel={(option) => option.name}
                // style={{ width: 200 }}
                onChange={updateCrewDivision}
                // onChange={ (e) => updateCrew(e.target.id, e.target.value) }
                value = {state.crew.division}
                // onChange={ e => setState({...state, crew: {...state.crew, division: e.target.value}, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Division" variant="outlined" />}
              />
              </Grid>
              {
                (state.crew && state.crew.division && state?.crew?.division === 'ADOT') ? (
                  <Grid item lg={12} xs={12}>
                    <Autocomplete
                      id="adotCrew"
                      options={adotCrews || []}
                      getOptionLabel={(option) => {
                        let label = `${option.jobName}`
                        return label
                      }}
                      // style={{ width: 200 }}
                      onChange={updateAssignedADOTCrew}
                      // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                      value = {state.asssignedADOTCrew}
                      // onChange={ e => setState({...state, crew: {...state.crew, crewType: e.target.value}, requiresUpdate: true }) }
                      renderInput={(params) => <TextField {...params} label="Assigned ADOT Crew" variant="outlined" />}
                    />
                  </Grid>
                ) : null
              }
              <Grid item lg={12} xs={12}>
              <Autocomplete
                id="CrewForeman"
                options={props.employees ? props.employees : []}
                getOptionLabel={(option) => (option && option.displayName) ? option.displayName : ''}
                // style={{ width: 200 }}
                onChange={updateCrewForeman}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.foreman}
                // onChange={ e => setState({...state, crew: {...state.crew, crewType: e.target.value}, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Crew Foreman" variant="outlined" />}
              />
              </Grid>
              <Grid item lg={12} xs={12}>
                <Autocomplete
                  id="CrewVehicle"
                  options={props.vehicles ? props.vehicles : []}
                  getOptionLabel={(option) => {
                    let label = `${option.name} (${option.number}) - ${option?.year} ${option?.manufacturer} ${option?.make} ${option?.model}`
                    return label
                  }}
                  // style={{ width: 200 }}
                  onChange={updateAssignedVehicle}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.vehicle}
                  // onChange={ e => setState({...state, crew: {...state.crew, crewType: e.target.value}, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Assigned Vehicle" variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
              <TextField
                id="notes"
                label="Notes"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                // className={classes.textSaveLineBreaks}
                value={state.crew.notes}
                onChange={e => setState({...state, crew: {...state.crew, notes: e.target.value}})}
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
            />


              </Grid>


              <Grid item lg={4} xs={6}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Active Crew</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isActive}
                      onChange={ e => setState({...state, isActive: !state.isActive, crew: {...state.crew, isActive: !state.isActive } }) }
                      name="Active" />}
                      label={state.isActive ? 'Is Active' : 'Inactive'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Automate Mtc Workorders</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.toAutomate}
                      onChange={ e => setState({...state, toAutomate: !state.toAutomate, crew: {...state.crew, toAutomate: !state.toAutomate } }) }
                      name="Automate Workorders" />}
                      label={state.toAutomate ? 'Workorders Will Auto Generate' : 'Inactive'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Mowing Crew</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.mowingCrew}
                      onChange={ e => setState({...state, mowingCrew: !state.mowingCrew, crew: {...state.crew, mowingCrew: !state.mowingCrew } }) }
                      name="Mowing Crew" />}
                      label={state.mowingCrew ? 'Is a Mowing Crew' : 'Not a Mowing Crew'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Northern Parkway</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.npkwy}
                      onChange={ e => setState({...state, npkwy: !state.npkwy, crew: {...state.crew, npkwy: !state.npkwy } }) }
                      name="Northern Parkway" />}
                      label={state.npkwy ? 'Works Northern Parkway' : 'Not a Northern Parkway Crew'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">Works ADOT</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.adot}
                      onChange={ e => setState({...state, adot: !state.adot, crew: {...state.crew, adot: !state.adot } }) }
                      name="ADOT" />}
                      label={state.adot ? 'Works ADOT' : 'Not an ADOT Crew'}
                    />
                  </FormGroup>
                  
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ServiceDetailsModal)