import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import { fade, makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import POListComponent from './PurchaseOrderList';
import { socket } from '../SocketIo/Socket'
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  circle: {
    gridRow: 1,
    gridColumn: 1,
    display: 'grid',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    // textAlign: 'center'
  },
  bar: {
    gridRow: 1,
    gridColumn: 1,
    // margin: '0 auto',
    zIndex: 1,
  },
  track: {
    gridRow: 1,
    gridColumn: 1,
    // margin: '0 auto',
    color: theme.palette.action.hover,
  },
  label: {
    gridRow: 2,
    gridColumn: 1,
    // textAlign: 'center',
    fontSize: '13px',
    color: '#ccc',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    // margin: '0 auto',
    // marginTop: '20px',
    marginLeft: '2.5%',
    // alignItems: 'center'
  }
}));


const defaultValues = {
  purchaseOrders: [],
  percentReconciled: 0
}

const PODashboard = (props) => {
  const classes = useStyles();
// console.log('PO Dashbaord: ', props)

  // let msg = 'Open the PO' + poId
  // if (poId) window.alert(msg)
  const [state, setState] = React.useState(defaultValues)
  const [loading, setLoading] = React.useState(false)
  const [activeEntity, setActiveEntity] = React.useState({})
  const [activePO, setActivePO] = React.useState(null)
  const stateRef = React.useRef(state);
  const activeEntityRef = React.useRef(activeEntity);

  const fetchDownloadAndReconciliationData = (entityToUse, list) => {
    console.log('Get oru reconciliation data now...')
    axios({
      method: 'get',
      url: `${props.url}/api/purchaseOrders/list?entity=${entityToUse}&details=true`
    }).then(response => {
      console.log('Got the details!!', response.data)
      stateRef.current = {...state, purchaseOrders: response.data };
      setState({...state, purchaseOrders: response.data })
    })
  }

  const fetchPOs = async (entityToUse) => {
  // console.log('Fetch POS called')
    setLoading(true)
    setState({...state, purchaseOrders: []})
    axios({
      method: 'get',
      url: `${props.url}/api/purchaseOrders/list?entity=${entityToUse}&details=false`
    }).then(response => {
      // console.log('Got our pos; ', response)
      setLoading(false)
      if (response.data) {
        // setEstimates(response.data)
        // setFilteredRows(response.data)
        if (response && response.data && response.data.length) {
          let numReconciled = 0
          // response.data.map(item => {
          //   if (item && item.reconciledToInvoice) numReconciled++
          // })
          // console.log('Num Reconciled: ', numReconciled)
          let percentReconciled = numReconciled / response.data.length

          // console.log(percentReconciled)
          percentReconciled = parseInt(percentReconciled * 100)
          setState({...state, purchaseOrders: response.data, percentReconciled })
          stateRef.current = {...state, purchaseOrders: response.data, percentReconciled };
          fetchDownloadAndReconciliationData(entityToUse, response.data)
        }
      }
    })
  }

  const dealWithOurSocketUpdate = (data) => {
  // console.log('state:', activeEntity)
  // console.log('Deal with our socket data:', stateRef.current);
  // console.log('Active entity:', activeEntityRef.current);
  // console.log('New data:', data);
    let itemToUpdate = data.po
    let purchaseOrders = stateRef.current.purchaseOrders
    if (data.type === 'new') {
    // console.log('add this:', data)
      purchaseOrders.unshift(itemToUpdate)
      // setState({...state, purchaseOrders: purchaseOrders})
    }
    if (data.type === 'delete') {
    // console.log('Delete it', data)
      let index = purchaseOrders.findIndex(item => (item._id.toString() === itemToUpdate._id.toString() ))
      if (index > -1) {
        purchaseOrders.splice(index, 1)
        // setState({...state, purchaseOrders: purchaseOrders})
      }
    }
    if (data.type === 'update') {
    // console.log('UPdate this:', data)
      let index = purchaseOrders.findIndex(item => (item._id.toString() === itemToUpdate._id.toString() ))
      if (index > -1) {
        purchaseOrders[index] = itemToUpdate
      }
    }
    setState({...state, purchaseOrders: purchaseOrders})
    stateRef.current.purchaseOrders = purchaseOrders
  }

  React.useEffect(() => {
    let isMounted = true
    // stateRef.current = state
    // activeEntityRef.current = activeEntity
    socket.on('poList', function (data) {
    // console.log('POS have updated!!', data)

      if (isMounted) dealWithOurSocketUpdate(data)
      // fetchPOs('All')
      // console.log('PO LIST UPdate: ', po)
      // let pos = state.purchaseOrders
      // pos.unshift(po)
      // setState({...state, purchaseOrders: pos })
    })
    return () => {
      isMounted = false
      socket.off('poList')
    }
  }, [])


  React.useEffect(() => {
    fetchPOs('All')
  }, [])

 

  // const updatePOsIncluded = (includeOrNot, entityToUse) => {
  //   // console.log('Update POS: Include REconciled: ', includeOrNot)
  //   let query = `${entityToUse || 'All'}`
  //   if (includeOrNot) query = '&reconciledToInvoice=true'
  //   axios({
  //     method: 'get',
  //     url: `${props.url}/api/purchaseOrders/list?${query}`
  //   }).then(response => {
  //     if (response && response.data && response.data.length) {
  //       let numReconciled = 0
  //       response.data.map(item => {
  //         if (item && item.reconciledToInvoice) numReconciled++
  //       })
  //       // console.log('Num Reconciled: ', numReconciled)
  //       let percentReconciled = numReconciled / response.data.length

  //       // console.log(percentReconciled)
  //       percentReconciled = parseInt(percentReconciled * 100)
  //       setState({...state, purchaseOrders: response.data, percentReconciled })
  //     }
  //   })
  // }

  const handleDeleteItem = async (itemToDelete) => {
    // let purchaseOrders = state.purchaseOrders
    // let index = purchaseOrders.findIndex(item => (item._id.toString() === itemToDelete._id.toString() ))
    // if (index > -1) {
    //   purchaseOrders.splice(index, 1)
    axios({
      method: 'post',
      data: itemToDelete,
      url: `${props.url}/api/purchaseOrders/delete`
    }).then(response => {
      if (response.data && response.data.message) {
        props.notification({
          type: 'success',
          message: response.data.message,
          title: 'Deletion Complete'
        })
      }
    })
      // setState({...state, purchaseOrders: purchaseOrders})
    // }
  }

  const handleUpdateVisibleEntity = (newEntity) => {
  // console.log('Change entity', newEntity)
    if (newEntity && newEntity._id) {
      setActiveEntity(newEntity)
      fetchPOs(newEntity._id)
    } else {
      setActiveEntity({})
      fetchPOs('All')
    }
  }
const value = 10
const size = 5
const thickness = 10
  return (
    <Grid container style={{margin: 5}} spacing={2}>
      
        <Grid item lg={6} xs={12} sm={12} md={6}>
          <h1>Purchase Orders</h1>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper style={{  marginRight: 20, padding: 10 }}>
              {
                (props.user && props.user.entities && props.user.entities.length > 1) ? (
                  <Grid container style={{padding: '10px'}}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <ChooseEntityComponent allowAll={true} title={'Visible Entities'} disabled={loading} user={props.user} updateParent={handleUpdateVisibleEntity} />
                  </Grid>
                </Grid>
                ) : ''
              }
            {/* <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} /> */}
            {/* <div className={classes.root}>
              
                <Box
                  // key={index}
                  className={classes.circle}
                >
                  <CircularProgress
                    size={`${size}%`}
                    value={state.percentReconciled}
                    thickness={thickness}
                    variant="static"
                    color="primary"
                    className={classes.bar}
                  />
                  <CircularProgress
                    size={`${size}%`}
                    value={100}
                    thickness={thickness}
                    variant="static"
                    className={classes.track}
                  />
                  <div className={classes.label}>
                  {`                       % Reconciled` }
                  </div>
                
                </Box>
            </div> */}
            {/* <CircularProgress
                size={`${2}%`}
                value={10}
                thickness={10}
                variant="static"
                color="primary"
                // className={classes.bar}
              /> */}
            {
              loading ? (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress size={84} style={{ color: '#769ba2' }} /> 
              </div>
              ) : <POListComponent history={props.history} ourList={state.purchaseOrders} deleteItem={handleDeleteItem} activePO={activePO} />
            }
          </Paper>
        </Grid>
  
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(PODashboard)