import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EditPOModal from './EditPurchaseOrderModal'
import Chip from '@material-ui/core/Chip';
import ProtectedButton from '../../actions/protectedButtons'
import moment from 'moment'
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import DeleteDialog from '../Common/DeleteDialog'
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ImageIcon from '@material-ui/icons/Image'
import Badge from '@material-ui/core/Badge'

const ListContainer = styled.div`
display: flex;
flex-direction: column;
padding: 10px;
border: 5px solid #ccc;
border-radius: 10px;
overflow: hidden;
min-height: 550px;
background-color: #eee;
`;

const ListItemRow = styled.div`
  border: 4px solid green;
  display: flex;
  align-self: 'stretch';
  padding: 5px;
  margin: 5px;
  background-color: #fff;
  border-radius: 15px;
  flex-direction: row;
  align-items: center;
`;

const ListItem = styled.div`
  color: #555;
  font-size: 16px;
  display: flex;
  margin: 5px;
  min-width: 60px;
  align-self: ${props => (props.grow ? 'stretch' : 'center')};
  flex-grow: ${props => (props.grow ? 3 : 0)};
`;

const ListItemHeaderRow = styled.div`

display: flex;
align-self: 'stretch';
padding: 5px;
margin: 5px;
background-color: #fff;
border-radius: 15px;
flex-direction: row;
`;

const ListHeaderItem = styled.div`
color: #aaa;
font-size: 18px;
display: flex;
font-weight: 800;
align-self: 'stretch';
padding: 5px;
margin: 5px;
min-width: 60px;
align-self: ${props => (props.grow ? 'stretch' : 'center')};
flex-grow: ${props => (props.grow ? 3 : 0)};
`;


function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'number', numeric: false, disablePadding: true, label: 'Number' },
  { id: 'entity', numeric: false, disablePadding: true, label: 'Entity' },
  { id: 'vendorName', numeric: false, disablePadding: false, label: 'Vendor' },
  { id: 'poType', numeric: false, disablePadding: false, label: 'Client/PO Type' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'price', numeric: false, disablePadding: false, label: 'Price' },
  { id: 'division', numeric: false, disablePadding: false, label: 'Division' },
  // { id: 'needed_by', numeric: false, disablePadding: false, label: 'Needed By' },

];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort, num } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.center ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  moveIcon: {
    '&:hover': {
      background: "#FFFF00",
      cursor: 'pointer',
      borderRadius: 20,
      color: 'red'
   }
  },
  table: {
    width: '100%',
    padding: 0,
    margin: 0
  },
  // tableheadRow: {
  //   padding: 10
  // },
  tableHeadCell: {
    fontSize: 14,
    color: '#bbb'
  },
  tableRow: {
    height: 25,
    padding: 10,
    margin: 7
  },
  tableCell: {
    height: 25,
    margin: 10,
    color: '#888',
    padding: 5
  }
}));

const POListComponent = (props) => {
// console.log('PO LIST: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [rows, setRows] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [activePO, setActivePO] = React.useState({})
  const [showEditPOModal, setShowEditPOModal] = React.useState(false)
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  // const [activePO, setActivePO] = React.useState({})

  const fetchDownloads = (list) => {
    console.log(list)
    
  }

  React.useEffect(() => {
    setFilteredRows(props.ourList)
    setRows(props.ourList)
    let poIds = props.ourList.map(item => (item._id))
    fetchDownloads(poIds)
  }, [props.ourList])

  React.useEffect(() => {
  // console.log('PO LIST PROPS UPDATED', props)
    let search = null
    if (props.history && props.history.location && props.history.location.search) {
      search = props.history.location.search
      const params = new URLSearchParams(search)
    // console.log('URL PARAMS:', params)
      const poId = params.get('id')
      if (poId) {
      // console.log('PO ID...', poId)
        // setActivePO()
        let index = props.ourList.findIndex(item => (item._id === poId))
      // console.log('PO ID INDEX', index)
        if (index > -1) {
          setActivePO(props.ourList[index])
          setShowEditPOModal(true)
        } else {
          setActivePO({})
          setShowEditPOModal(false)
        }
      }
    }
    // if (props.activePO) {
    //   // window.alert('We have an active PO')
    // // console.log('Props.active PO', props.activePO)
    //   setActivePO(props.activePO)
    //   setShowEditPOModal(true)
    // // console.log('We have a po...', props.activePO)
    // } else {
    // // console.log('No Active PO...')
    //   setShowEditPOModal(false)
    //   setActivePO({})
    // }
  }, [props])

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  const handleOpenItem = (item, index) => {
    props.handleOpenItem(item, index)
  }

  function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
  }

  const handleMoveItem = (direction, item, index) => {
    // console.log('Move Item #%d %s ', index, direction)
    let newList = props.ourList
    let updatedList = null
    if (direction === 'up' && index > 0) {
      let newIndex = index - 1
      updatedList = arraymove(newList, index, newIndex)
      // console.log(updatedList)
      // setList(updatedList)
      // setState({...state, list: updatedList})
    }
    if (direction === 'down' && index < props.ourList.length) {
      let newIndex = index - 0 + 1
       updatedList = arraymove(newList, index, newIndex)
      // console.log(updatedList)
      // setList(updatedList)
      // setState({...state, list: updatedList})
    }
    props.updateList(updatedList)
  }

  const handleDeleteItem = (d, i) => {
    setShowDeleteDialog(true)
    setActivePO(d)
    // if (d.status === 'New') {
    //   if (window.confirm(`Are you sure you wish to delete PO #${d.number}? This is NOT reversible.`)) {
    //     props.deleteItem(d)
    //   }
    // }
    // if (d.status === 'Reconciled') {
    //   props.notification({
    //     type: 'warning',
    //     title: 'Cannot Delete',
    //     message: `PO #${d.number} has already been reconciled. We cannot delete it now.`
    //   })
    // }
  }

  const handleDeleteActiveItem = () => {
    setShowDeleteDialog(false)
    props.deleteItem(activePO)
    setActivePO({})
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 const filterOut = (r, searchText) => {
  // console.log('FIlter out: %s - %s', r, searchText)
  let searchableKeys = [
    'number',
    'clientName',
    'vendorName',
    'requestorName',
    'description',
    'projectName',
    'amount'
  ]
  let string = searchText.toLowerCase()
  let newArr = []
  r.filter(o => {
    // console.log('Object:  ', o)
    searchableKeys.some(k => {
      // console.log(o[k])
      if(o[k]) {
        // console.log('OK: ', o[k])
        let str = o[k].toString().toLowerCase() 
        // console.log('String', str)
        if (str.includes(string.toLowerCase())) {
          // console.log('Mathc')
          return newArr.push(o)
        } else {
          return null
        }
      } else {
        return null
      }
    })
    // return newArr
  })
  setFilteredRows(newArr)

}

 const handleOpenEditPOModal = (item) => {
 // console.log('Open Modal: ', item)
   setShowEditPOModal(true)
   setActivePO(item)
 }

 const handleCloseEditModal = (data) => {
console.log('Close the modal...update our item', data)
  // setActivePO(null)
  setShowEditPOModal(false)
  setActivePO({})
  if (data && data._id) {
    // New Po to add to our list
    let newPos = [ ...rows ]
    let index = newPos.findIndex(item => (item._id === data._id))
    if (index > -1) {
      newPos[index] = data
    }
    console.log('New Pos:', newPos)
    // console.log(newPos[])
    setRows(newPos)
    filterOut(newPos, searchValue)
  }
  // setTimeout(() => { props.history.push('/purchaseorders') }, 500)
 }

 const handleChange = (event) => {
  // console.log('Searching: ', event.target.value)
  setsearchValue(event.target.value)
  if (event.target.value === '') {
    setFilteredRows(rows)
  } else {
    filterOut(rows, event.target.value)
  }
  setPage(0)
}

const updateFromDeleteDialog = (data) => {
// console.log('Do we delete it ?', data)
  setShowDeleteDialog(false)
  if (data) {
    handleDeleteActiveItem()
  }
}

const handleRequestSort = (event, property) => {
  const isDesc = orderBy === property && order === 'desc';
  setOrder(isDesc ? 'asc' : 'desc');
  // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
  setOrderBy(property);
};

const handleSelectAllClick = event => {
  if (event.target.checked) {
    const newSelecteds = rows.map(n => n._id);
    // console.log(newSelecteds)
    setSelected(newSelecteds);
    return;
  }
  setSelected([]);
};

let pendingApproval = 0
filteredRows.map(item => {
  if (item.status === 'Pending Approval') pendingApproval++
})

  return (
    <div style={{ overflowX: 'auto' }}>
      <DeleteDialog
        title="Delete Purchase Order?"
        verificationText={`Are you sure you wish to Delete PO# ${activePO ? activePO.number : ''}?`}
        confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
        confirmationButton="DELETE"
        denyText="NO"
        open={showDeleteDialog}
        closeModal={updateFromDeleteDialog}
      />
      <EditPOModal open={showEditPOModal} po={activePO} closeModal={handleCloseEditModal} />
      <Grid container>
        <Grid item lg={4} md={6} sm={12} xs={12}>
            <TextField
              name="search" 
              value={ searchValue } 
              onChange={ handleChange } 
              type="search"
              label="Search POs"
              variant="outlined"
              margin="normal"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
          />
        </Grid>
        {/* <Grid item lg={3} xs={6} style={{marginTop: 25}}>
          <FormControlLabel
            control={<Checkbox checked={includeReconciled} onChange={setShowAllOrNot} name="includeReconciled" />}
            label="Show Reconciled POs"
          />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          Matching Purchase Orders: {filteredRows.length}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          Purchase Requests Needing Approval: {pendingApproval}
        </Grid>
      </Grid>
      <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  let needInLessThan2Days = false
                  const redBG = ((item.isUrgent && item.status === 'Pending Approval') || needInLessThan2Days) ? true : false
                  let daysUntilWeNeedIt = moment.duration(moment(item.needed_by).diff(moment(new Date()))).asDays()
              
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(item._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // setsearchMatches(rows.length)
                  // console.log(row)
                  return (
                    
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={item._id}
                      selected={isItemSelected}
                      style={{ backgroundColor: redBG ? 'rgba(222, 54, 54, 0.2)' : 'inherit' }}
                    >
                      <TableCell className={ classes.tableCell } style={{ width: '15%'}}>
                        <POCreationDetails item={item} handleOpenEditPOModal={handleOpenEditPOModal} />
                       
                       
                      
                  
                     </TableCell>
                     <TableCell className={ classes.tableCell } >
                           <div className={classes.rowContainer} >
                             <div style={{ textAlign: 'center' }}>
                               <img alt="Logo" src={`${process.env.PUBLIC_URL}/${item.entity}_NavLogo.png`} style={{margin: '5px', maxWidth: 75, maxHeight: 35}} />
                             </div>
                           </div>
                     </TableCell>
                     <TableCell className={ classes.tableCell }>
                       { (item.vendorName && item.vendorName) ? item.vendorName : '' }
                     </TableCell>
                     <TableCell className={ classes.tableCell }>
                     { (item.clientName && item.clientName) ? `${item.clientName}` : item.poType }
                     { (item.projectName && item.projectName) ? `${item.projectName}` : '' }
                     {
                       item.warranty ? <span style={{ marginTop: '26px', marginLeft: 10 }}><OfflinePinIcon size="small" style={{ color: '#39a33d' }} /></span> : null
                     }
                     </TableCell>

                     <TableCell className={ classes.tableCell } style={{ fontSize: '0.9em'}}>
                       {item.description}
                     </TableCell>
                     <TableCell className={ classes.tableCell }>
                       {currencyFormat(item.amount)}
                     </TableCell>
                     <TableCell className={ classes.tableCell }>
                       {item.division || 'N/A'}
                     </TableCell>
                     {/* <TableCell className={ classes.tableCell }>
                       {moment(item.needed_by).format('MM/DD/YY')}
                     </TableCell> */}
                     <TableCell className={ classes.tableCell }>
                       <ProtectedButton type="Purchase Orders" kind="delete" perms={props.perms}>
                         <IconButton onClick={e => handleDeleteItem(item, index)} aria-label="move-up">
                           <DeleteIcon size="small" />
                         </IconButton>
                       </ProtectedButton>
                     </TableCell>
                   </TableRow>
                  )})}
                  

        </TableBody>
      </Table>
      <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          // onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
    // <ListContainer>
    //   <ListItemHeaderRow>
    //   <ListHeaderItem>
    //       Reorder
    //     </ListHeaderItem>
    //   <ListHeaderItem>
    //       QUANTITY
    //     </ListHeaderItem>
    //     <ListHeaderItem>
    //       UNIT
    //     </ListHeaderItem>
    //     <ListHeaderItem grow={true}>
    //       DESCRIPTION
    //     </ListHeaderItem>
    //     <ListHeaderItem>
    //       PRICE
    //     </ListHeaderItem>
    //     <ListHeaderItem>
    //       DELETE
    //     </ListHeaderItem>
    //   </ListItemHeaderRow>
    //   {
    //     list.map((item, index) => {
          
    //     // console.log('ITEM 206: ', item)
    //       return (
          
    //             <ListItemRow onDoubleClick={e => handleOpenItem(item, index)} key={index}         >
    //               <ListItem>
    //                 <IconButton onClick={e => handleMoveItem('up', item, index)} style={{ backgroundColor: 'transparent' }} aria-label="move-up" disabled={index === 0}>
    //                   <ArrowUpwardIcon  className={classes.moveIcon}  />
    //                 </IconButton>
    //                 <IconButton style={{ backgroundColor: 'transparent' }} onClick={e => handleMoveItem('down', item, index)} aria-label="move-down" disabled={index === list.length - 1}>
    //                   <ArrowDownwardIcon className={classes.moveIcon}  />
    //                 </IconButton>
    //               </ListItem>
                 
                              
    //               <ListItem>
    //                 {item.qty}
    //               </ListItem>
    //               <ListItem>
    //                 {item.unit} 
    //               </ListItem>
    //               <ListItem grow={true}>
    //                 {item.description} 
    //                 { item.inventoryItem ? '*' : '' }
    //               </ListItem>
    //               <ListItem>
    //                 {item.totalPrice}
    //               </ListItem>
    //               <ListItem>
    //                 <IconButton onClick={e => handleDeleteItem(item, index)} aria-label="move-up">
    //                   <DeleteIcon />
    //                 </IconButton>
    //               </ListItem>
    //             </ListItemRow>

    //     )
    //   })}
    // </ListContainer>
    )
}

const POCreationDetails = ({ handleOpenEditPOModal, item }) => {
// console.log('Do we hvae approved by info??', item)
  return (
    <div>
      <div>
        <span onClick={(e) => handleOpenEditPOModal(item) } style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>
            { item.number ? item.number : '' } 
        </span>
        {
          item.status === 'Reconciled' ? <Chip style={{color: '#fff', background: '#39a33d', marginLeft: 10 }} size="small" label="Reconciled" /> : null
        }
        {
          item.status === 'Pending Approval' ? <Chip style={{color: '#fff', background: '#808080', marginLeft: 10 }} size="small" label="Pending Approval" /> : null
        }
        {
          item.status === 'Approved' ? <Chip style={{color: '#fff', background: '#3B719F', marginLeft: 10 }} size="small" label="Approved" /> : null
        }
        {
          item.status === 'Denied' ? <Chip style={{color: '#fff', background: '#911', marginLeft: 10 }} size="small" label="Denied" /> : null
        }
         {
            (item && (item.upload || (item.numberOfUploads && item.numberOfUploads > 0))) ? (
              <Badge badgeContent={item.numberOfUploads} color="primary">
                <ImageIcon size="18" />
              </Badge>
            ) : null
          }
      </div>
      <div style={{ fontSize: '0.75em', color: '#aaa'}}>
        Created By {item.requestorName} at {moment(item.created_at).format('MM/DD/YY hh:mma')}
      </div>
      {
        (item.approved_by && item.approved_by._id) ? (
          <div style={{ fontSize: '0.75em', color: '#aaa'}}>
           Approved By {item.approved_by.name} at {moment(item.approved_at).format('MM/DD/YY')}
          </div>
        ) : null
      }
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms
  }
}

export default React.memo(connect(mapStateToProps)(POListComponent))
