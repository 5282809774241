import React, { useState } from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';
import Paper from '@material-ui/core/Paper';
import { Link, useLocation, useHistory  } from 'react-router-dom'
import WorkorderList from './MultiWorkorderInvoicing'
import { socket } from '../SocketIo/Socket'
// import PaymentOptions from './PaymentMethodOptionsRadioButtons'
// import PaymentManager from './PaymentManager';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
    backgroundColor: '#ddd',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: '80vh',
  },
  navigationContainer: {
    // backgroundColor: 'red',
    width: '100%',
    padding: '20px',
  }

}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: '',
  clientId: null,
  project: {},
  projects: []
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const logoURL = `${process.env.PUBLIC_URL}/logowide2.png`

const InvoiceGenerator = (props) => {
  const history = useHistory()
  
  console.log('INVOICE GENERATOR PROPS:', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultStateValues)
  const [errorDetails, setErrorDetails] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [workorders, setWorkorders] = React.useState([])
  const [filteredWorkorders, setFilteredWorkorders] = useState([])

  let clientId = props.match.params.clientId
  React.useEffect(() => {
  // console.log('Online Pay', props.match)
    let isMounted = true

    const fetchInvoiceDetails = async (clientId) => {
      setLoading(true)
      console.log('Fetch Invoice details', clientId)
      let newState = {
        clientId: props.match.params.clientId
      }
      const response = await axios.get(`${props.url}/api/invoice/getDetailsForInvoiceGeneration?clientId=${clientId}`)
      if (response && response.data) {
        console.log('STUFF: ', response.data)
        if (isMounted) {
          console.log('Set Our Details', response.data)
          if (response.data?.wos?.length) {
            console.log('Set these', response.data.wos)
            setWorkorders(response.data.wos)
            setFilteredWorkorders(response.data.wos)
          }
          setState(prevState => ({...prevState, ...newState }))
        }
        
        // console.log('Got the invoice', response.data)
        //   if (response.data.error) {
        //   // console.log('Error', response.data.message)
        //     props.notification({
        //       type: 'warning',
        //       title: 'We Ran Into A Problem!',
        //       message: response.data.message
        //     })
        //     setErrorDetails(response.data)
        //   }
        //   if (response.data && response.data._id) {
        //     // const inv = {
        //     //   ...response.data.invoice,
        //     //   client: response.data.client,
        //     //   payments: response.data.payments
        //     // }
        //     // console.log('INV:::', inv)
        //     const client = response.data.client
        //     setInvoice(response.data)
        //     setClient(client)
        //     setLoading(false)
        //     // setState({...state, amount: response.data.balance})
        //   }
        //   // if (response.data.client && response.data.client._id) setClient(response.data.client)
        // }
      }
    }

    if (props.match && props.match.params && props.match.params.clientId) {
      fetchInvoiceDetails(props.match.params.clientId)
     
    }

    return () => {
      isMounted = false
    }
  }, [props.match.params.clientId])



  const updateCustomer = (client) => {
    console.log('Fetch Customer details', client)
    history.push(`/generate-invoice/${client._id}`, { replace: true })
    // fetch
    // setClient(client)
  }
  const handleUpdateProject = (val) => {
    console.log('Update Project', val)
    let filteredWos = workorders.filter(wo => (wo?.project?._id === val._id))
    console.log('Our filtered wos', filteredWos)
    setFilteredWorkorders(filteredWos)
    setState(prevState => ({...prevState, project: val }))
  }
  
  const handleTabInteraction = (e) => {
    let string = e.target.value
    let newArr = []
  // console.log('String Tabbed on', string)
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'customername') {
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updateCustomer(newArr[0])
        }
      }
      if (e.target.id === 'project') {
        // Deal w/ project update via tab
      }
    }
  }
  const handleUpdateFromWorkorderList = ({ obj, invoice }) => {
    console.log('UPdate from workorder list', obj)
    if (invoice) {
      console.log('Invoice created')
      const invoicedAmounts = {};
      obj.lineItems.forEach(item => {
        if (!invoicedAmounts[item.workorder]) {
          invoicedAmounts[item.workorder] = 0;
        }
        invoicedAmounts[item.workorder] += Number(item.amount);
      });
  
      // Update the workorders
      setWorkorders(prevWorkorders => 
        prevWorkorders.map(workorder => {
          if (invoicedAmounts[workorder._id]) {
            return {
              ...workorder,
              invoicedAmount: (workorder.invoicedAmount || 0) + invoicedAmounts[workorder._id],
              status: workorder.price <= (workorder.invoicedAmount || 0) + invoicedAmounts[workorder._id] ? 'Fully Invoiced' : 'Partially Invoiced'
            };
          }
          return workorder;
        })
      );
      setFilteredWorkorders(prevWorkorders => 
        prevWorkorders.map(workorder => {
          if (invoicedAmounts[workorder._id]) {
            return {
              ...workorder,
              invoicedAmount: (workorder.invoicedAmount || 0) + invoicedAmounts[workorder._id],
              status: workorder.price <= (workorder.invoicedAmount || 0) + invoicedAmounts[workorder._id] ? 'Fully Invoiced' : 'Partially Invoiced'
            };
          }
          return workorder;
        })
      );
    }
  }

  React.useEffect(() => {
    console.log('Socket on invoicegenerator')
    socket.on(`invoicegenerator`, function (msg) {
  
      if (msg && msg.obj) {
        console.log('Update Our Invoices!!!', msg)
        handleUpdateFromWorkorderList(msg)
      }
    })
    return () => {
      socket.off(`invoicegenerator`)
    }
  }, [])

  const handleOldUpdate = ({ data, invoice }) => {
    console.log('Update parent...', data)
  }

  const client = clientId ? props?.clients.find(item => (item._id === clientId)) : null

  return (
    <div className={classes.root}>
      <NavigationArea updateCustomer={updateCustomer} client={client} clients={props.clients || []} />
      <ProjectFilter workorders={workorders} project={state.project} updateProject={handleUpdateProject} handleTabInteraction={handleTabInteraction} />
      <WorkorderList workorders={filteredWorkorders} url={props.url} clientId={state.clientId} notification={props.notification} client={client} updateParent={handleOldUpdate} />
    </div>
  )
}

const NavigationArea = (props) => {
  console.log('Nav:', props)
  const clients = props?.clients || []
  const classes = useStyles()
  const updateCustomer = (e, newCus) => {
    
    props.updateCustomer(newCus)
  }
  return (
    <div className={classes.navigationContainer}>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            id={`customername`}
            options={props.clients || []}
            getOptionLabel={(option) => {
              // console.log('Options: ', option)
              return option.displayName || ''
            }}
            // style={{ width: 200 }}
            // style={{marginBottom: '20px'}}
            onChange={updateCustomer}
            // disabled={loading}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {props.client}
            // ref={inputRef.client}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={props.handleTabInteraction} variant="outlined" />}
          />

        </Grid>
      </Grid>
    </div>
  )
}

const ProjectFilter = (props) => {
  const classes = useStyles()
  console.log('Project filter', props)
  const projects = [];
  const projectIds = new Set();  // This set will store the unique project IDs
  
  props.workorders.forEach(wo => {
    if (wo.project?._id && !projectIds.has(wo.project._id)) {
      projects.push(wo.project);
      projectIds.add(wo.project._id);  // Add the ID to the set
    }
  });
  
  const updateProject = (e, newProj) => {
    props.updateProject(newProj)
  }

  console.log('Projects', projects)
  return (
    <div className={classes.navigationContainer}>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            id={`project`}
            options={projects || []}
            getOptionLabel={(option) => {
              // console.log('Options: ', option)
              return option?._id ? `${option.name} (${option.number})` : 'Filter By Project'
            }}
            // style={{ width: 200 }}
            // style={{marginBottom: '20px'}}
            onChange={updateProject}
            // disabled={loading}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {props.project}
            // ref={inputRef.client}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Project" helperText="Would You Like to Filter The Workorders by Project?" onKeyDown={props.handleTabInteraction} variant="outlined" />}
          />

        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    user: state.authenticated,
    clients: state.clients
  }
}

export default connect(mapStateToProps)(InvoiceGenerator)
