import React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';

const CusomMarker = (props) => {
    // console.log('Custom Marker: ', props)
    const [state, setState] = React.useState({
        showInfoWindow: false,
        stopHide: false,
        isActive: false
    })
    const timeoutRef = React.useRef()

    React.useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    React.useEffect(() => {
        if (props.activeMarker && props.activeMarker._id && props.activeMarker._id === props.jobsite._id) {
            setState({...state, isActive: true })
        }
    }, [props])
 
    
    const handleMouseOver = e => {
        setState(prevState => ({
            ...prevState,
            showInfoWindow: true
        }))
        // if (props.jobSite) props.updateActiveMarker(props.jobSite)
    };

const handleMouseExit = e => {
    // Clear any existing timeouts to ensure we don't have multiple timeouts running
    if (state.isActive && !props.hideInfoWindowOnActiveItem) {
        // console.log('Dont close this one')
    } else {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            if (!state.stopHide) {
                setState(prevState => ({
                    ...prevState,
                    showInfoWindow: false
                }));
            }
        }, 2000);
    }
};


    const handleInfoWindowMouseOver = e => {
        setState(prevState => ({
            ...prevState,
            showInfoWindow: true,
            stopHide: true
        }));

    }
    const handleInfoWindowMouseExit = e => {
        // setState(prevState => ({
        //     ...prevState,
        //     stopHide: false
        // }));
        // call handleMouseExit to start the delay before hiding the InfoWindow
        handleMouseExit();
    };

    const handleClick = (e) => {
        console.log('Click on this one!!', props)
        console.log(e)
       
        setState(prevState => ({
            ...prevState,
            showInfoWindow: true,
            stopHide: true
        }));
        props.updateActiveMarker(props.jobsite)
    }

    const ourPos = props.position
    // console.log('Position 87', ourPos)
    if (ourPos && ourPos.lat && ourPos.lng) {
        const lat = Number(ourPos.lat)
        const lng = Number(ourPos.lng)
        let theColor = props.color
        // if (props.jobsite && props.jobsite.assignedCrew)
        if (props.jobsite && props.jobsite.color) theColor = props.jobsite.color
        let scale = 6
        let strokeColor = 'black'

        let theIcon = {
            path: window.google.maps.SymbolPath.CIRCLE,
            // url: 
            scale: scale,
            fillColor: theColor,
            fillOpacity: 0.8,
            strokeWeight: 2,
            rotation: 0,
            strokeColor: strokeColor
        }
        let clientName = (props.jobsite && props.jobsite.clientName) ? props.jobsite.clientName : 'CLIENT'
        let clientId = ''
        if (props.jobsite && props.jobsite.client) {
            clientId = props.jobsite.client
            if (props.jobsite.client._id) {
                clientId = props.jobsite.client._id
            }
        }

        let noCrewAssigned = true
        if (props.jobsite && props.jobsite.assignedCrew && props.jobsite.assignedCrew._id) noCrewAssigned = false
        if (props.jobsite && props.jobsite.crew && props.jobsite.crew._id) noCrewAssigned = false
        // if (state.isActive && noCrewAssigned) theIcon = undefined
        // console.log('CustomMarker', props)
        // console.log('state', state)
        // // console.log(clientId)
        // console.log('Jobsite:', props.jobSite)
        // console.log('Icon::', theIcon)
        let activeMarkerId = props?.activeMarker?._id
        let jobSiteId = props?.jobsite?._id
        if (activeMarkerId === jobSiteId) theIcon = undefined
        let backOn = null
        let siteName = `${props.jobsite.name}`
        // console.log('Is Active: %s  --- activeId: %s    jobsiteID: %s ', state.isActive, activeMarkerId, jobSiteId)
        return (
            <Marker position={{ lat, lng }} icon={theIcon} 
                onClick={handleClick}
                onMouseOver={handleMouseOver}
                title={siteName}
                onMouseOut={handleMouseExit}>
                {/* {
                    ((!props.hideInfoWindowOnActiveItem && state.isActive && backOn) || (state.showInfoWindow  && props.jobsite)) && (
                        <InfoWindow
                            position={{ lat, lng }}  // Add this line
                            onCloseClick={() => setState(prev => ({ ...prev, showInfoWindow: false }))}
                        >
                            <>
                                <h4>Client: 
                                    <a style={{ cursor: 'pointer' }} href={`/clients/${clientId}/Customer/${clientId}`} target="_blank">
                                        {clientName}
                                    </a>
                                </h4>
                                <h4>Site Name: {props.jobsite.name}</h4>
                                <h4>Address: {props.jobsite.address}</h4>
                                <h4>Crew: {props.jobsite.crewName}</h4>
                                <h4>Frequency: {props.jobsite?.frequency} {props.jobsite?.frequency === 'Bi-Weekly' ? `(${props.jobsite.evenOrOddWeeks})` : ''}</h4>
                                <h4>Preferred Day: {props.jobsite?.preferredDay}</h4>
                                <h4>Hours: {props.jobsite?.estimatedHours} </h4>
                            </>
                        </InfoWindow>
                    ) 
                } */}
               
            </Marker>
        );
    } else {
        console.log('invalid marker...')
        return null
    }


}

export default React.memo(CusomMarker)
