import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios'
import moment from 'moment';
import CustomMarker from './CustomMarker'
import { isEven } from '../../../services/common'
import ServiceScheduler from './ServiceScheduler';
import { addDays, subDays } from 'date-fns';
import AssignmentConfirmationDialog from './AssignmentConfirmationDialog';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '90vh',
    
  },
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: theme.spacing(2),
  },
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  calendarPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#ccc',
    minHeight: '20vh',
    maxHeight: '30vh',
    marginBottom: 5
  },
  crewPaper: {
    // padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#eee',
    maxHeight: '70vh',
    overflowY: 'auto',
    paddingBottom: 80,
    marginBottom: 80,
    width: '100%'
  },
  unassignedJobsPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#eee',
    maxHeight: '90vh',
    overflowY: 'auto'
  },
  mapContainer: {
    position: 'relative',
    height: '100%',
  },
  map: {
    width: '100%',
    height: '100%',
  },
  selectedCrewContainer: {
    backgroundColor: '#eee',
    width: '100%',
    height: '40%',
    position: 'absolute',
    bottom: 20,
    left: 0,
    zIndex: 1,
    overflow: 'hidden',
    // padding: theme.spacing(2),
    // margin: 5,
    marginBottom: 50,
    boxSizing: 'border-box',
  },
  unassignedJobItem: {
    border: '2px solid #000',
    margin: 5,
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: 12
  },
  activeJobItem: {
    border: '3px solid red',
    margin: 5,
    padding: 5,
    backgroundColor: '#ddd',
    borderRadius: 12
  },
  crewList: {
    backgroundColor: '#eee',
    margin: 3,
    padding: 5,
    width: '100%',
  },
  crewDetailsTopLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  crewDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  crewBlockColor: {
    width: '80px',
    height: '80px',
    borderRadius: 5
  },
  dateArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-evenly'
  },
  mapOverlayActionArea: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    top: 30,
    left: 250,
    // width: '250px',
    // height: '80px',
    // backgroundColor: 'rgba(0, 0, 0, 0.09)',
    // border: '3px solid rgba(0, 0, 0, 0.09)',
    // borderRadius: 5,
    // margin: 5,
    // padding: 10
  },
  crewsStopsContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '200px',
    padding: 5,
    // margin: 3,
    width: '100%'
  },
  nearestSiteItemContainerOLD: {
    border: '1px solid #ddd',
    borderRadius: 5,
    marginBottom: 5,
    padding: 10,
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  nearestSiteItemContainer: {
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    overflow: 'hidden',
    cursor: 'pointer',
  },
  colorBar: {
    width: 8,
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  smallColumn: {
    flex: '0 0 20%',
    marginRight: theme.spacing(2),
  },
  largeColumn: {
    flex: 1,
  },
  infoItem: {
    marginBottom: theme.spacing(1),
  },
  infoValue: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  infoLabel: {
    fontSize: '0.65rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const defaultCenter = {
  lat: 33.50547813946138,
  lng: -112.15212417301781
};

const position = {
  lat: 33.5,
  lng: -112.1
}

const days = ['M', 'T', 'W', 'Th', 'F'];
const fullDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const getBackgroundColor = (hours) => {
  if (hours > 8) return 'rgba(212,102,102,0.5)';
  if (hours > 6) return 'rgba(240,223,113,0.5)';
  if (hours > 4) return 'rgba(203,250,192,0.5)';
  return '#eee';
};

const findNextMatchingDate = (site, startDate = moment()) => {
  const { frequency, evenOrOddWeeks, preferredDay, preferredDays } = site;
  let currentDate = moment(startDate);

  const fullDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const isEvenWeek = (date) => moment(date).week() % 2 === 0;

  const getNextWeekday = (date, targetDay) => {
    console.log('Get next weekday ', date.format('YYYY-MM-DD'), targetDay);
    if (!targetDay || targetDay === 'None') {
      return date.clone(); // Return the same day if no target day specified
    }

    const targetIndex = fullDays.indexOf(targetDay.toLowerCase());
    if (targetIndex === -1) {
      throw new Error('Invalid preferred day');
    }

    let daysToAdd = targetIndex - date.day();
    if (daysToAdd <= 0) daysToAdd += 7;
    console.log('Days To Add', daysToAdd);
    return date.clone().add(daysToAdd, 'days');
  };

  const getDayToCheck = () => {
    if (preferredDay && preferredDay !== 'None') {
      return preferredDay.toLowerCase();
    }
    if (preferredDays && preferredDays.length > 0) {
      return preferredDays[0].toLowerCase();
    }
    return null; // No preference specified
  };

  const findNextDate = () => {
    const dayToCheck = getDayToCheck();
    console.log('Day to check: %s  - Frequency: %s ', dayToCheck, frequency.toLowerCase());

    switch (frequency.toLowerCase()) {
      case 'monthly':
      case 'quarterly':
      case 'semi-annually':
      case 'on-demand':
        if (!dayToCheck) return currentDate; // No matching day, use today
        let nextDate = currentDate.clone();
        if (frequency.toLowerCase() === 'monthly') nextDate.add(1, 'month').startOf('month');
        if (frequency.toLowerCase() === 'quarterly') nextDate.add(1, 'quarter').startOf('quarter');
        if (frequency.toLowerCase() === 'semi-annually') nextDate.add(6, 'months').startOf('month');
        return getNextWeekday(nextDate, dayToCheck);

      case 'weekly':
        return dayToCheck ? getNextWeekday(currentDate, dayToCheck) : currentDate;

      case 'bi-weekly':
        if (!dayToCheck) return currentDate; // No matching day, use today
        let biWeeklyDate = getNextWeekday(currentDate, dayToCheck);
        const targetEvenWeek = evenOrOddWeeks.toLowerCase() === 'even';
        while (isEvenWeek(biWeeklyDate) !== targetEvenWeek) {
          biWeeklyDate.add(1, 'week');
        }
        return biWeeklyDate;

      case 'twice a week':
        if (currentDate.day() < 3) { // Before Wednesday
          return currentDate.day(3); // Set to Wednesday
        } else if (currentDate.day() < 6) { // Wednesday to Friday
          return currentDate.day(6); // Set to Saturday
        } else { // Saturday or Sunday
          return currentDate.add(1, 'week').day(3); // Set to next Wednesday
        }

      case 'every other day (m-w-f)':
        const nextDays = [1, 3, 5]; // Monday, Wednesday, Friday
        const nextDay = nextDays.find(day => day > currentDate.day()) || nextDays[0];
        return currentDate.day(nextDay);

      default:
        return currentDate; // For any unhandled frequency, use today
    }
  };

  return findNextDate();
};

const calculateHours = (filteredSites) => {
  let totalHours = 0;
  const crewHours = {};

  filteredSites.forEach(site => {
    const hours = parseFloat(site.estimatedHours) || 0;
    totalHours += hours;

    if (site.crew) {
      crewHours[site.crew] = (crewHours[site.crew] || 0) + hours;
    }
  });

  return { totalHours, crewHours };
};

const formatDistance = (meters) => {
  return Math.round(Number(meters / 1609) * 100) / 100
}

const defaultStateVals = {
  assigningToService: null,
  servicesRemaining: []
}

const SchedulerComponent = (props) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultStateVals)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [totalHours, setTotalHours] = useState(0);
  const [unassignedJobs, setUnassignedJobs] = useState([]);
  const [crews, setCrews] = useState([]);
  const [filteredCrews, setFilteredCrews] = useState([])
  const [markers, setMarkers] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState([])
  const [activeMarker, setActiveMarker] = React.useState({})
  const [jobSites, setJobSites] = useState([])
  const [selectedCrew, setSelectedCrew] = useState({})
  const [allFilteredMarkers, setAllFilteredMarkers] = useState([]);
  const [weeklyCrewHours, setWeeklyCrewHours] = useState({});
  const [zoomLevel, setZoomLevel] = useState(9)
  const [center, setCenter] = useState(defaultCenter)
  const [jobSiteDetails, setJobSiteDetails] = useState({})
  const [crewLocations, setCrewLocations] = useState([])
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GMAPKEY
  })
  const [map, setMap] = useState(null)
  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const dayOfWeek = moment(selectedDate).format('dddd')
  let weekOfTheYear = moment(selectedDate).week()
  let evenWeek = isEven(weekOfTheYear)
  let thisWeek = evenWeek ? 'Even' : 'Odd'

  useEffect(() => {
    let isMounted = true

    const fetchMarkers = async () => {
      const activeSites = await axios.get(`${props.url}/api/hmi/getActiveSites`)
      console.log('Got the sites', activeSites)
      if (isMounted) {
        setJobSites(activeSites.data)
        updateData(activeSites.data)
      }
    }

    const fetchCrewLocations = async () => {
      const activeSites = await axios.get(`${props.url}/api/hmi/genericGetRequest?type=crewlocations`)
      console.log('Got the fetchCrewLocations', activeSites)
      if (isMounted && activeSites?.data?.data?.length) {
        setCrewLocations(activeSites.data.data)
      }
    }

    fetchMarkers()
    fetchCrewLocations()

  }, [])

  useEffect(() => {
    // Fetch unassigned jobs and crews data
    // Update state accordingly
    // Also update markers for the map
  }, [selectedDate]);

  const calculateWeeklyHours = (date, sites, crews) => {
    const startOfWeek = moment(date).startOf('week');
    let weeklyHours = {};
  
    // Initialize weekly hours for each crew
    crews.forEach(crew => {
      weeklyHours[crew._id] = {
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
        Saturday: 0,
        Sunday: 0,
        total: 0
      };
    });
  
    // Calculate hours for each day of the week
    for (let i = 0; i < 7; i++) {
      const currentDate = moment(startOfWeek).add(i, 'days');
      const dayName = currentDate.format('dddd');
      const filteredSitesForDay = filterSitesForDate(sites, currentDate);
      const { crewHours } = calculateHours(filteredSitesForDay);
      console.log('198 crew hours', crewHours)
      // Add daily hours to weekly total
      
      Object.keys(crewHours).forEach(crewId => {
        const dailyHours = crewHours[crewId] || 0;
        if (!weeklyHours[crewId]) {
          console.error(`Undefined crewId ${crewId} on ${dayName}. Initializing...`)
          weeklyHours[crewId] = {
            Monday: 0,
            Tuesday: 0,
            Wednesday: 0,
            Thursday: 0,
            Friday: 0,
            Saturday: 0,
            Sunday: 0,
            total: 0
          }
        }
        weeklyHours[crewId][dayName] = dailyHours;
        weeklyHours[crewId].total += dailyHours;
      });
    }
  
    return weeklyHours;
  };

  const filterSitesForDate = (sites, date) => {
    const dayOfWeek = moment(date).format('dddd');
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const isWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(dayOfWeek);
    const weekOfTheYear = moment(date).week();
    const evenWeek = isEven(weekOfTheYear);
    const thisWeek = evenWeek ? 'Even' : 'Odd';
  
    return sites.filter(site => {
      let includeSite = false;
  
      switch (site.frequency) {
        case 'Daily':
          includeSite = isWeekday;
          break;
        case 'Every Other Day (M-W-F)':
          includeSite = ['Monday', 'Wednesday', 'Friday'].includes(dayOfWeek);
          break;
        case 'Bi-Weekly':
          if (thisWeek === site.evenOrOddWeeks) {
            includeSite = site.preferredDay === dayOfWeek;
          }
          break;
        case 'Monthly':
        case 'Quarterly':
        case 'Semi-Annually':
        case 'Annually':
        case 'On-Demand':
          if (site.scheduledDates && site.scheduledDates.length > 0) {
            includeSite = site.scheduledDates.includes(formattedDate);
          }
          break;
        default:
          includeSite = site.preferredDay === dayOfWeek || (site.preferredDays && site.preferredDays.includes(dayOfWeek));
          break;
      }
  
      if (site.scheduledDates && site.scheduledDates.length > 0) {
        includeSite = includeSite || site.scheduledDates.includes(formattedDate);
      }
  
      return includeSite;
    });
  };

  const updateData = (sitesPassed) => {
    let unassignedSites = []
    console.log('UpdateData:: Props::', props)
    let sites = sitesPassed?.length ? sitesPassed : jobSites?.length ? jobSites : []
    console.log('SIteS:', sites)
    const uniqueCrewIds = [...new Set(sites.map(site => site.crew))]
    const uniqueCrews = []
    console.log('Unique Crew Ids', uniqueCrewIds)
    if (uniqueCrewIds.length) {
      uniqueCrewIds.forEach((crewId, uniqueCrewIndex) => {
        // console.log('Find CrewId', crewId)
        let crewIndex = [...props.crews].findIndex(crew => (crew._id === crewId))
        console.log(crewIndex)
        if (crewIndex > -1) {
          // console.log('CREW: 953', props.crews[crewIndex])
          const crewItem = props.crews[crewIndex]
          let hexColor = distinctHexColors[uniqueCrewIndex]
          console.log('crewItem 976', crewItem)
          const crewObj = {
            _id: crewItem._id,
            color: hexColor,
            name: crewItem?.name ? `${crewItem.name} ${crewItem.foremanName ? `(${crewItem.foremanName})` : ''}` : 'Un-Named Crew'
          }
          uniqueCrews.push(crewObj)
        } else {
          console.log('No valid crew!!!', crewId)
        }
      })
    }
    console.log('Set our Unique Crews', uniqueCrews)
    setCrews(uniqueCrews)
    setFilteredCrews(uniqueCrews)
    sites.forEach(siteItem => {
      if (siteItem.crew) {
        console.log('SIte...crew', siteItem.crew)
        let crewIndex = [...uniqueCrewIds].findIndex(crewId => (crewId === siteItem.crew))
        console.log('483', crewIndex)
        if (crewIndex > -1) {
          const crewItem = uniqueCrews[crewIndex]
          console.log('Crew item', crewItem)
          siteItem.color = crewItem?.color || '#000'
        } else {
          console.log('489 invalid color....')
        }
      } else {
        unassignedSites.push(siteItem)
      }
    })
    console.log('Sites w/ color...', sites)
    console.log('Unassigned Sites', unassignedSites)
    setUnassignedJobs(unassignedSites)
    setMarkers(sites)
    filterTheMarkers(sites, new Date(), uniqueCrews)
  }

  const filterTheMarkers = (sites, date, crewsPassed) => {
    console.log('Filter the Markers!!!', crewsPassed)
    let crewsToUse = [...crews]
    if (crewsPassed?.length) crewsToUse = crewsPassed
    console.log('Date to filter:', date)
    const dayOfWeek = moment(date).format('dddd')
    const formattedDate = moment(date).format('YYYY-MM-DD')
    const currentYear = moment().year();
    const isWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(dayOfWeek);
    const filteredSitesNew = filterSitesForDate(sites, date);
    console.log('Filtered Sites filteredSitesNew', filteredSitesNew)

    let filteredSites = [];
    let newUnassignedJobs = [];
    sites.forEach((site, index) => {
      // console.log('SIte...', site)
      const siteDayOfWeek = moment(site.preferredDay, 'dddd');
      const frequency = site.frequency;
      let includeSite = false

      switch (frequency) {
        case 'Daily':
          includeSite = isWeekday;
          break;
        case 'Every Other Day (M-W-F)':
          includeSite = ['Monday', 'Wednesday', 'Friday'].includes(dayOfWeek);
          break;
        case 'Bi-Weekly':
          if (thisWeek === site.evenOrOddWeeks) {
            includeSite = site.preferredDay === dayOfWeek
          }
          break;
        case 'Monthly':
        case 'Quarterly':
        case 'Semi-Annually':
        case 'Annually':
        case 'On-Demand':
          if (site.scheduledDates && site.scheduledDates.length > 0) {
            includeSite = site.scheduledDates.includes(formattedDate);
            
            // Check if there are any future dates this year
            const hasFutureDates = site.scheduledDates.some(date => 
              moment(date).year() === currentYear && moment(date).isAfter(moment())
            );
            
            if (!hasFutureDates) {
              newUnassignedJobs.push(site);
            }
          } else {
            // If no scheduled dates, add to unassigned jobs
            newUnassignedJobs.push(site);
          }
          break;
        default:
          includeSite = site.preferredDay === dayOfWeek || (site.preferredDays && site.preferredDays.includes(dayOfWeek));
          break;
      }
        // If the site has scheduledDates, always check against them
      if (site.scheduledDates && site.scheduledDates.length > 0) {
        includeSite = includeSite || site.scheduledDates.includes(formattedDate);
      }
      if (includeSite) {
        filteredSites.push(site);
      }
    })
    console.log('Old FIltered SItes', filteredSites)

    setFilteredMarkers(filteredSitesNew)
    setAllFilteredMarkers(filteredSitesNew);
    setUnassignedJobs(prevUnassignedJobs => {
      // Combine existing unassigned jobs with new ones, removing duplicates
      const combinedUnassignedJobs = [...prevUnassignedJobs, ...newUnassignedJobs];
      return Array.from(new Set(combinedUnassignedJobs.map(job => job._id)))
        .map(id => combinedUnassignedJobs.find(job => job._id === id));
    });

    const { totalHours, crewHours } = calculateHours(filteredSites);
    setTotalHours(totalHours);
    const weeklyHours = calculateWeeklyHours(date, sites, crewsToUse);
    setWeeklyCrewHours(weeklyHours);
    console.log('Weekly Hours', weeklyHours)
    const updatedCrews = crewsToUse.map(crew => ({
      ...crew,
      hours: Math.round(100 * crewHours[crew._id] || 0) / 100,
      weeklyHours: weeklyHours[crew._id],
      todayHours: Math.round(100 * (weeklyHours[crew._id][dayOfWeek] || 0)) / 100
    }))
    // .sort((a, b) => a.todayHours - b.todayHours)
    console.log('Updated crews and sorted', updatedCrews)
    setFilteredCrews(updatedCrews);

  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    filterTheMarkers(markers, date, crews)
  };


  const updateActiveMarker = (markerClicked) => {
    console.log('Update active marker...', markerClicked)
    const crew = crews.find(crewItem => (crewItem._id === markerClicked.crew))
    if (crew?._id && selectedCrew && selectedCrew._id === crew._id) {
      // If the clicked crew is already selected, deselect it
      // setSelectedCrew(null);
      // Reset to show all filtered markers
      // filterTheMarkers(jobSites, selectedDate, crews);
    } else {
      setSelectedCrew(crew);
      // Only display markers associated with this crew for the current date
      const crewSites = filterSitesForCrewAndDate(jobSites, selectedDate, crew._id);
      setFilteredMarkers(crewSites);
    }

  }

  const handleClickAssign = (d) => {
    console.log('Assign this one', d)
    let updatedMarkers = [
      ...filteredMarkers.filter(marker => (marker._id !== activeMarker?._id)),
      // d
    ]
    setFilteredMarkers(updatedMarkers)
    setActiveMarker(d)
    // Set Zoom to 11
    // Center Map on this marker
    setZoomLevel(13)
    let lng = d?.location?.coordinates?.length ? d.location.coordinates[0] : d.longitude
    let lat = d?.location?.coordinates?.length ? d.location.coordinates[1] : d.latitude
    let newCenter = {
      lat: lat || defaultCenter.lat,
      lng: lng || defaultCenter.lng
    }
    setCenter(newCenter)
    getDetailsAboutThisSite(d)
    setState(prevState => ({ ...prevState, assigningToService: false }))
  }

  const getDetailsAboutThisSite = async (site) => {
    console.log('Get the closest jobsite and shit like that from the backend for this guy', site)
    const siteDetails = await axios.post(`${props.url}/api/hmi/getNearestSiteDetails`, site)
    console.log('Got the site details', siteDetails)
    // Lets merge the distance w/ the active sites
    let nearestSites = []
    let data = {
      ...siteDetails.data
    }
    if (siteDetails?.data?.nearestSites?.length) {
      let filteredSites = siteDetails.data.nearestSites.filter(item => (item._id !== site._id))
      let markersWithDistance = []
      filteredSites.forEach((siteItem, index) => {
        let markerIndex = markers.findIndex(markerItem => (markerItem._id === siteItem._id))
        if (markerIndex > -1) {
          console.log('Merge this shit', markers[markerIndex])
          console.log('Site', siteItem)
          let itemToReturn = {
            ...markers[markerIndex],
            distance: formatDistance(siteItem.distance),
          }
          markersWithDistance.push(itemToReturn)
        }
      })
      console.log('Markers w/ Distance', markersWithDistance)
      data.nearestSites = markersWithDistance
      // filterTheMarkers(markersWithDistance, selectedDate, crews);
      // setFilteredMarkers(markersWithDistance)
      let currentFilteredMarkers = filteredMarkers.map(item => {
        let markerWithDistance = markersWithDistance.find(markerItem => (markerItem?._id === item._id))
        if (markerWithDistance?._id) {
          return ({
            ...item,
            distance: markerWithDistance.distance
          })
        }
      })
      console.log('Filtered markers w/ Distance...', currentFilteredMarkers)
      // setFilteredMarkers(currentFilteredMarkers)
      // setAllFilteredMarkers(markersWithDistance);
    }
    console.log('Set the data', data)
    setJobSiteDetails(data || {})
  }

  const handleClickOnCrew = (crew) => {
    console.log('Click on crew', crew)
    if (selectedCrew && selectedCrew._id === crew._id) {
      // If the clicked crew is already selected, deselect it
      // setSelectedCrew(null);
      // // Reset to show all filtered markers
      // filterTheMarkers(jobSites, selectedDate, crews);
    } else {
      setSelectedCrew(crew);
      // Only display markers associated with this crew for the current date
      const crewSites = filterSitesForCrewAndDate(jobSites, selectedDate, crew._id);
      setFilteredMarkers(crewSites);
    }
    // if (selectedCrew && selectedCrew._id === crew._id) {
    //   // If the clicked crew is already selected, deselect it
    //   setSelectedCrew(null);
    //   let updatedMarkers = [
    //     ...allFilteredMarkers.filter(marker => (marker._id !== activeMarker?._id)),
    //     // activeMarker
    //   ]
    //   console.log('Set these', updatedMarkers)
    //   // Reset to show all filtered markers
    //   setFilteredMarkers(allFilteredMarkers);
    // } else {
    //   setSelectedCrew(crew);
    //   // Only display markers associated with this crew
    //   const crewSites = allFilteredMarkers.filter(site => site.crew === crew._id);
    //   let updatedMarkers = [
    //     ...crewSites.filter(marker => (marker._id !== activeMarker?._id)),
    //     // activeMarker
    //   ]
    //   console.log('Display thes', updatedMarkers)
    //   setFilteredMarkers(crewSites);
    // }
  }

  const filterSitesForCrewAndDate = (sites, date, crewId) => {
    const dayOfWeek = moment(date).format('dddd');
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const isWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(dayOfWeek);
    const weekOfTheYear = moment(date).week();
    const evenWeek = isEven(weekOfTheYear);
    const thisWeek = evenWeek ? 'Even' : 'Odd';
  
    return sites.filter(site => {
      if (site.crew !== crewId) return false;
  
      let includeSite = false;
  
      switch (site.frequency) {
        case 'Daily':
          includeSite = isWeekday;
          break;
        case 'Every Other Day (M-W-F)':
          includeSite = ['Monday', 'Wednesday', 'Friday'].includes(dayOfWeek);
          break;
        case 'Bi-Weekly':
          if (thisWeek === site.evenOrOddWeeks) {
            includeSite = site.preferredDay === dayOfWeek;
          }
          break;
        case 'Monthly':
        case 'Quarterly':
        case 'Semi-Annually':
        case 'Annually':
        case 'On-Demand':
          if (site.scheduledDates && site.scheduledDates.length > 0) {
            includeSite = site.scheduledDates.includes(formattedDate);
          }
          break;
        default:
          includeSite = site.preferredDay === dayOfWeek || (site.preferredDays && site.preferredDays.includes(dayOfWeek));
          break;
      }
  
      if (site.scheduledDates && site.scheduledDates.length > 0) {
        includeSite = includeSite || site.scheduledDates.includes(formattedDate);
      }
  
      return includeSite;
    });
  };


  const handleClickOnDayForCrew = (crew, day) => {
    console.log('Click on Day', day)
    console.log(crew)
    // Calculate the date for the clicked day
    const clickedDate = moment(selectedDate).day(day);
    
    // Filter markers for this crew and day
    const filteredSitesForCrewAndDay = filterSitesForCrewAndDate(jobSites, clickedDate, crew._id);
    
    // // Update the filtered markers
    setFilteredMarkers(filteredSitesForCrewAndDay);
    
    // Update the selected date
    setSelectedDate(clickedDate.toDate());
    
    // Update the selected crew
    setSelectedCrew(crew);
  }

  const handleUnassignment = (site) => {
    console.log('Unassign this site...', site)
    let currentMarkers = [...markers]
    let index = currentMarkers.findIndex(item => (item._id === site._id))
    if (index > -1) {
      const siteItem = currentMarkers[index]
      siteItem.crew = null
      siteItem.crewName = 'Unassigned'
    }
    console.log('Index', index)
    console.log('Markers to set', currentMarkers)
    filterTheMarkers(currentMarkers, selectedDate, crews)
    let currentUnassignedJobs = [...unassignedJobs]
    currentUnassignedJobs.unshift(site)
    setUnassignedJobs(currentUnassignedJobs)
  }

  const handleAssignToCrew = (crew, site) => {
    console.log('Assign to crew', crew)
    console.log(site)
    console.log(selectedDate)
    console.log(activeMarker)
    // setActiveMarker({})
    // setSelectedCrew({})
    // let currentMarkers = [...markers]
    // let index = currentMarkers.findIndex(item => (item._id === site._id))
    // if (index > -1) {
    //   const siteItem = currentMarkers[index]
    //   siteItem.crew = crew._id
    //   siteItem.crewName = crew.name
    // }
    // console.log('Index', index)
    // console.log('Markers to set', currentMarkers)
    // filterTheMarkers(currentMarkers, selectedDate, crews)
    // let currentUnassignedJobs = [...unassignedJobs]
    // // currentUnassignedJobs.unshift(site)
    // let unassignedJobsIndex = currentUnassignedJobs.findIndex(item => (item._id === site._id))
    // if (unassignedJobsIndex > -1) {
    //   currentUnassignedJobs.splice(unassignedJobsIndex, 1)
    //   setUnassignedJobs(currentUnassignedJobs)
    // }
  }

  const handleUpdateDateFromScheduler = (passedProps) => {
    console.log('Set selected date...', passedProps)
    const newDate = passedProps.date
    const {
      service
    } = passedProps
    setState(prevState => ({ ...prevState, assigningToService: service, servicesRemaining: jobSiteDetails?.servicesRemaining }))
    console.log('Other Data')
    console.log('Crews:', crews)
    console.log(markers)
    console.log('Filtered Markers', allFilteredMarkers)
    setSelectedDate(newDate)
    // filter the crews from the markers??
    const uniqueCrewIds = [...new Set(allFilteredMarkers.map(site => site.crew))]
    const uniqueCrews = []
    console.log('Unique Crew Ids 586', uniqueCrewIds)
    if (uniqueCrewIds.length) {
      uniqueCrewIds.forEach((crewId, uniqueCrewIndex) => {
        // console.log('Find CrewId', crewId)
        let crewIndex = [...props.crews].findIndex(crew => (crew._id === crewId))
        console.log(crewIndex)
        if (crewIndex > -1) {
          // console.log('CREW: 953', props.crews[crewIndex])
          const crewItem = props.crews[crewIndex]
          let hexColor = distinctHexColors[uniqueCrewIndex]
          console.log('crewItem 596', crewItem)
          const crewObj = {
            _id: crewItem._id,
            color: hexColor,
            name: crewItem?.name ? `${crewItem.name} ${crewItem.foremanName ? `(${crewItem.foremanName})` : ''}` : 'Un-Named Crew'
          }
          uniqueCrews.push(crewObj)
        } else {
          console.log('No valid crew!!!', crewId)
        }
      })
    }
    console.log('Set our Unique Crews', uniqueCrews)
    filterTheMarkers(markers, newDate, uniqueCrews)
  }

  const handlePreviousDay = () => {
    const yesterday = subDays(selectedDate, 1);
    handleDateChange(yesterday);
  }

  const handleNextDay = () => {
    const tomorrow = addDays(selectedDate, 1);
    handleDateChange(tomorrow);
  }

  const clearFilters = () => {
    // console.log('Clear Filteres')
    setSelectedCrew(null);
      // Reset to show all filtered markers
    filterTheMarkers(jobSites, selectedDate, crews);
  }

  const handleClickOnSiteNearby = (site) => {
    console.log('Click On this nearby site...', site)
    const nextDate = findNextMatchingDate(site);
    console.log('Next matching date:', nextDate);    
    // Filter markers for this crew and day
    const filteredSitesForCrewAndDay = filterSitesForCrewAndDate(jobSites, nextDate, site.crew);
    
    // // Update the filtered markers
    setFilteredMarkers(filteredSitesForCrewAndDay);
    const date = new Date(nextDate)
    console.log('Date object', date)
    console.log(nextDate.toDate())
    // Update the selected date
    setSelectedDate(date);
    const crew = filteredCrews.find(item => (item._id === site.crew))
    console.log('Crews: ', crews)
    console.log('props.crews', props.crews)
    console.log('filtered crews', filteredCrews)
    // Update the selected crew
    console.log('Set this crew', crew)
    setSelectedCrew(crew);
  }
  
  let filteredHours = 0
  let filteredStopsQty = 0
  filteredMarkers.forEach(item => {
    // console.log('Filter hours...', item)
    filteredHours += Number(item?.estimatedHours || 0)
    filteredStopsQty++
    // console.log(filteredHours)
  })
  // console.log('Filtered Hours', filteredHours)
  if (activeMarker?._id) {
    filteredMarkers.push(activeMarker)
  }
  console.log('active marker', activeMarker)
  // Color our Crews
  let crewLocsWithColor = []
  crewLocations.forEach(item => {
    let crewIndex = [...crews].findIndex(crew => (crew._id === item._id))
    if (crewIndex > -1) {
      const crewItem = crews[crewIndex]
      console.log('CREW: 684', crewItem)
      let hexColor = crewItem.color
      let itemWithColor = {
        ...item,
        color: hexColor
      }
      crewLocsWithColor.push(itemWithColor)
    }
  })
  console.log('820 selectedDate', selectedDate)
  // console.log('crew', crews)
  console.log('filteredCrews to sort....', filteredCrews)
  // console.log('Selected Crew', selectedCrew)
  // console.log('Crews w/ colors', crewLocsWithColor)
  let sortedCrews = filteredCrews.sort((a, b) => a.todayHours - b.todayHours) // .sort()
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={3}>
          <Paper className={classes.calendarPaper}>
            <CalendarTabArea />
            <Typography variant="h7">
              Total Properties: {markers.length}
              <br />
            </Typography>
            <div className={classes.dateArea} >
                <Button onClick={handlePreviousDay}>PREV</Button>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  animateYearScrolling
                />
                <Button onClick={handleNextDay}>NEXT</Button>
            </div>
          
            <Typography variant="h5">
              {dayOfWeek} (Week #{weekOfTheYear} - {thisWeek})
            </Typography>
            <Typography variant="h6">
              Total Hours: {Math.round(100 * totalHours) / 100}
            </Typography>
            <Typography variant="h7">
              Filtered Stops: {filteredStopsQty}
              <br />
              Filtered Hours: {Math.round(100 * filteredHours) / 100}
            </Typography>
          </Paper>
          <Paper className={classes.crewPaper}>
            <div className={classes.crewList}>
                {sortedCrews.map((crew) => {
                  const clickOnCrewDay = (day) => {
                    handleClickOnDayForCrew(crew, day)
                  }
                  // console.log('Crew Item', crew)
                    
                    return (
                  <div 
                    key={crew._id} 
                    style={{ 
                      borderRight: `12px solid ${crew.color}`, 
                      cursor: 'pointer', 
                      backgroundColor: '#fff',
                      padding: 10, 
                      margin: 5, 
                      borderRadius: 8,
                      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }} 
                    onClick={e => handleClickOnCrew(crew)}
                  >
                    <div className={classes.crewDetailsContainer}>
                      <div className={classes.crewDetailsTopLine}>
                    
                              <InfoItem 
                              label="Crew Name" 
                              value={`${crew.name}`}
                              
                            />
                              <InfoItem 
                              label="Hours Today" 
                              value={`${crew?.todayHours}`} 
                              centerItem
                            />
                          <InfoItem 
                              label="Hours This Week" 
                              value={`${crew?.weeklyHours?.total.toFixed(2)}`}
                              centerItem
                            />
                         
                      </div>
                      {/* <div className={classes.crewBlockColor} style={{ backgroundColor: crew?.color || '#fff' }} >&nbsp;</div> */}
                    
                    <WeeklyHoursDisplay 
                      weeklyHours={crew.weeklyHours}
                      handleDayClick={clickOnCrewDay}
                      selectedDay={selectedCrew && selectedCrew._id === crew._id ? moment(selectedDate).format('dddd') : null}
                    />
                    </div>
                  </div>
                )
              })}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
          <div className={classes.mapContainer}>
            <div className={classes.map}>
              {
                isLoaded ? (
                  <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={center}
                      zoom={zoomLevel}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      {filteredMarkers.map((marker, index) => {
                        // console.log("marker", marker)
                        let latitude = marker?.location?.coordinates[1] || 0
                        let longitude = marker?.location?.coordinates[0] || 0

                        let markerPos = { lat: latitude, lng: longitude }
                        let color = "#fff"
                        let icon = "dfl"
                        return (
                          <CustomMarker
                            key={index}
                            position={markerPos}
                            // position={markerPosition}
                            icon={icon}
                            color={color}
                            jobsite={marker}
                            activeMarker={activeMarker}
                            updateActiveMarker={updateActiveMarker}
                            hideInfoWindowOnActiveItem={true}
                          />
                      )
                      })}
                      {
                        !activeMarker?._id && crewLocsWithColor.map((crewMarker, index) => {
                          console.log('Display Crew', crewMarker)
                          let latitude = crewMarker?.lastLocation?.latitude
                          let longitude = crewMarker?.lastLocation?.longitude
                          let theIcon = {
                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            // url: 
                            scale: 3,
                            fillColor: crewMarker.color,
                            fillOpacity: 0.8,
                            strokeWeight: 2,
                            rotation: 0,
                            strokeColor: crewMarker.color
                        }
                          return (
                            <Marker
                                key={index}
                                icon={theIcon}
                                position={{ lat: latitude, lng: longitude }}
                                title={crewMarker.name}
                                color={crewMarker.color}
                              />
                          )
                          }
                        )
                      }
                    </GoogleMap>
                ) : <></>
              }

            </div>
              
                {
                  selectedCrew?._id && (
                    <div className={classes.selectedCrewContainer}>
                    <SelectedCrewDetails
                      crew={selectedCrew}
                      crews={filteredCrews}
                      date={selectedDate} 
                      stops={filteredMarkers} 
                      activeMarker={activeMarker} 
                      jobSiteDetails={jobSiteDetails} 
                      handleUnassignment={handleUnassignment} 
                      handleAssignToCrew={handleAssignToCrew}
                      markers={markers}
                    />
                    
                  </div>
                  )
                }
              <div className={classes.mapOverlayActionArea}>
                {
                  selectedCrew?._id && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={clearFilters}
                    >Clear Filters</Button>
                  )
                }
                {
                  state.assigningToService && (
                    <div style={{ marginTop: 5, backgroundColor: '#fff', padding: 10, borderRadius: 5, border: '2px solid #ccc' }}>
                      Assigning to {state.assigningToService?.name} {state.assigningToService?.year} Visit
                    </div>
                  )
                }
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.unassignedJobsPaper}>
            <Typography variant="h6">Unassigned Jobs {unassignedJobs.length} </Typography>
            {
              activeMarker?._id ? (
                <div>
                  <div>
                    <div>
                      <Button 
                        onClick={() => {
                          setActiveMarker({})
                          setState(prevState => ({ ...prevState, assigningToService: null }))
                          // setFilteredMarkers(allFilteredMarkers)
                          filterTheMarkers(markers, selectedDate, crews)
                        }}
                        variant="contained"
                        color="primary"
                        style={{ margin: '10px 0' }}
                      >
                        Show All Unassigned Jobs
                      </Button>
                    </div>
                    { activeMarker?.name }
                    <br />
                    { activeMarker?.frequency}
                    <br />
                    Preferred Day: { activeMarker?.preferredDay}
                    <br />
                    Estimated Hours: { activeMarker?.estimatedHours}
                    <br />
                    Last Workorder Date: { moment(jobSiteDetails?.lastWorkorder?.scheduledDate).format('ddd MM/DD/YY') || ''}
                    <br />
                    Services Remaining: {jobSiteDetails?.totalServicesRemaining || ''}
                    <br />
                    {/* {
                      jobSiteDetails?.servicesRemaining?.length && <ServiceScheduler servicesRemaining={jobSiteDetails?.servicesRemaining} updateDate={handleUpdateDateFromScheduler} />
                    } */}
                  
                  </div>
                  <div style={{ overflowY: 'auto', margin: 5, padding: 5 }}>
                      <ClosestLocationDetails jobSiteDetails={jobSiteDetails} site={activeMarker} crews={filteredCrews} handleClickOnSiteNearby={handleClickOnSiteNearby} />
                  </div>
                  
                </div>
              ) : (
                <List>
                {unassignedJobs.map((job) => {
                  let isActiveJob = activeMarker?._id === job._id
                  return (
                  <ListItem key={job._id} className={isActiveJob ? classes.activeJobItem : classes.unassignedJobItem}>
                    <ListItemText
                      primary={job.name}
                      secondary={`Frequency: ${job.frequency}`}
                    />
                    <ListItemText
                      primary={`Hours: ${job?.estimatedHours || 0} `}
                    />
                    <Button variant="contained" color="primary" onClick={ e => handleClickAssign(job) }>
                      Assign
                    </Button>
                  </ListItem>
                )
              })}
              </List>
              )
            }

          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const SelectedCrewDetails = ({ crew, stops, date, activeMarker, jobSiteDetails, handleUnassignment, handleAssignToCrew, crews, markers }) => {
  const classes = useStyles()
  const [isAssignmentDialogOpen, setIsAssignmentDialogOpen] = useState(false);

  const openAssignmentDialog = () => {
    setIsAssignmentDialogOpen(true);
  };

  const closeAssignmentDialog = () => {
    setIsAssignmentDialogOpen(false);
  };

  const handleConfirmAssignment = (assignmentDetails) => {
    handleAssignToCrew(assignmentDetails);
  };

  console.log('Stops', stops)
  console.log('Date', date)
 
  let totaHours = 0
  let withThisJobHours = Number(activeMarker?.estimatedHours || 0)
  let withThisJobWeeklyHours = Number(activeMarker?.estimatedHours || 0)
  let crewStops = [...stops].filter(site => { 
    return (site.crew === crew._id)
  })
  console.log('Crew:', crew)
  // let filteredStopsW
  console.log('Crew Stops', crewStops)
  crewStops.forEach(stop => {
    console.log('Hours so far %s   add estimated hours %s ', withThisJobHours, stop.estimatedHours)
    withThisJobHours += Number(stop.estimatedHours || 0)
    totaHours += Number(stop?.estimatedHours || 0)
    if (jobSiteDetails?.nearestSites?.length) {
      let nearestSite = jobSiteDetails.nearestSites.find(site => (site._id === stop._id))
      if (nearestSite?._id) {
        console.log('959 -- ', nearestSite)
        if (nearestSite?._id) {
          stop.distance = nearestSite.distance
        }
      }
    }
  })
  console.log('Find Weekly Horus in cerws', crews)
  let crewIndex = crews.findIndex(item => (item._id === crew._id))
  if (crewIndex > -1) {
    let crewItem = crews[crewIndex]
    console.log('This is the item w/ weekly hours', crewItem)
    withThisJobWeeklyHours += Number(crewItem?.weeklyHours?.total)
  }
  console.log('Crew Details ', date)
  return (
    <div style={{ backgroundColor: '#fff', borderTop: `14px solid ${crew.color}`, borderRadus: 20, overflow: 'hidden', padding: 5 }}>
      <div style={{ fontSize: '16px', }}>
      
        Current Working Hours: {Math.round(100 * totaHours) / 100}   - Hours w/ This Job: {Math.round(100 * withThisJobHours) / 100} (For The Week: {withThisJobWeeklyHours || 0})
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div>{crew.name} {crew?.foremanName ? `(${crew.foremanName})` : ''} </div>
        <div>
          {
            activeMarker?._id && (
              <Button
                variant="contained"
                color="primary"
                onClick={openAssignmentDialog}
                >Assign</Button>
            )
          }
        
        </div>
      </div>
     
      <div className={classes.crewsStopsContainer}>
        {
          [...crewStops].map((site, index) => {
            return (
              <div key={index} style={{ width: '100%', margin: 5, padding: 5, display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', border: '1px solid #ccc' }}>
                <div>
                  <div>{site?.name}</div>
                  <div>{site.address}  -  {site?.estimatedHours}hrs  { site?.distance ? `(${site.distance}mi)` : '' }</div>
                </div>
                  <div>{site.frequency} { site?.frequency === 'Bi-Weekly' ? `${site.evenOrOddWeeks}` : '' } </div>
                <div>
                  <Button
                    onClick={() => handleUnassignment(site)}
                    >Un-Assign</Button>
                </div>
                
              </div>
            )
          })
        }
      
       
      </div>
      {/* <div style={{ fontSize: '16px', marginTop: 10 }}>
        
        Current Working Hours: {Math.round(100 * totaHours) / 100}   - Hours w/ This Job: {Math.round(100 * withThisJobHours) / 100}
      </div> */}
      <div style={{ marginTop: 20, height: '100px'}}>&nbsp;</div>
      <AssignmentConfirmationDialog
        open={isAssignmentDialogOpen}
        onClose={closeAssignmentDialog}
        crew={crew}
        site={activeMarker}
        selectedDate={date}
        onConfirm={handleConfirmAssignment}
        jobSiteDetails={jobSiteDetails}
        markers={markers}
      />
    </div>
  )
}

const ClosestLocationDetails = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  // console.log('Closest Location Details', props)
  const longtermFrequencies = [
    'Monthly',
    'Quarterly',
    'Semi-Annually',
    'Annual'
  ]
  const {
    nearestSites = [],
    lastWorkorder,
  } = props?.jobSiteDetails
  if (!nearestSites?.length) return null

  const site = props.site

  let filteredSites = nearestSites.filter(item => {
    let includeThis = false
    if (!item.crew || item?.crewName === 'Unassigned') return false
    if (item._id !== site._id) {
      // console.log('Not our active marker...', item)
      if (!longtermFrequencies.includes(site.frequency)) {
        // Short term frequency - dont show our longterms
        includeThis = !longtermFrequencies.includes(item.frequency)
      } else {
        includeThis = true
      }
      if (site.frequency === item.frequency) includeThis = true
    }
    // if (includeThis) {
    //   let crew = props?.crews.find(crewItem => (crewItem._id === site.crew))
    //   if (crew?._id) site.crewName = `${crew.name} ${crew.foremanName ? `(${crew.foremanName})` : ''}`
    // }
    return includeThis
  }).slice(0, 10)
  
  let closestMatchSites = nearestSites.filter(item => {
    if (!item.crew || item?.crewName === 'Unassigned') return false
    let includeThis = false
    if (item._id !== site._id) {
      if (site.frequency === item.frequency) includeThis = true
    }
    return includeThis
  }).slice(0, 10)

  let perfectMatches = nearestSites.filter(item => {
    if (!item.crew || item?.crewName === 'Unassigned') return false
    let includeThis = false
    // console.log('Seek Perfect Matches', site)
    if (item._id !== site._id && item?.crewName !== 'Unassigned' ) {
      // console.log('This one might work', item)
      if (site.frequency === item.frequency) {
        // console.log('Frequency Match')
        if (site.preferredDay === item.preferredDay) {
          // console.log('Days Match')
          if (site.frequency === 'Bi-Weekly') {
            if (site.evenOrOddWeeks === item.evenOrOddWeeks) {
              // console.log('Even or odd matches')
              includeThis = true
            }
          } else {
            includeThis = true
          }
        }
      }
    }
    return includeThis
  }).slice(0, 10)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // console.log('Perfect Matches', perfectMatches)
  return (
    <div className={classes.tabsRoot}>
      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="closest sites tabs"
        >
          <Tab label="Closest Sites" />
          <Tab label="Closest Matching Sites" />
          <Tab label="Perfect Matches" />
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <Typography variant="h6" gutterBottom>Nearest Locations:</Typography>
        <SiteList 
          nearestSites={filteredSites} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby} 
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Typography variant="h6" gutterBottom>Nearest Matching Locations:</Typography>
        <SiteList 
          nearestSites={closestMatchSites} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby} 
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Typography variant="h6" gutterBottom>Perfect Matching Locations:</Typography>
        <SiteList 
          nearestSites={perfectMatches} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby} 
        />
      </TabPanel>
    </div>
  )
}

const InfoItem = ({ label, value, centerItem }) => {
  const classes = useStyles();
  return (
    <div className={classes.infoItem}>
      <Typography className={classes.infoValue} style={ centerItem ? { textAlign: 'center' } : null }>{value}</Typography>
      <Typography className={classes.infoLabel}>{label}</Typography>
    </div>
  );
};

const SiteList = ({ nearestSites, handleClickOnSiteNearby, closestMatchSites }) => {
  const classes = useStyles();

  return (
    <div>
      {nearestSites.map((site, index) => (
        <div key={index} className={classes.nearestSiteItemContainer} onClick={() => handleClickOnSiteNearby(site)}>
          <div className={classes.colorBar} style={{ backgroundColor: site.color }} />
          <div className={classes.contentContainer}>
            <div className={`${classes.column} ${classes.smallColumn}`}>
              <InfoItem 
                label="Distance" 
                value={`${Math.round(100 * site?.distance) / 100} mi`}
              />
              <InfoItem 
                label="Estimated Hours" 
                value={Math.round(100 * site?.estimatedHours) / 100} 
              />
             
            </div>
            <div className={`${classes.column} ${classes.largeColumn}`}>
              <InfoItem 
                label="Name" 
                value={site?.name} 
              />
              <InfoItem 
                label="Frequency" 
                value={`${site?.frequency} ${site?.frequency === 'Bi-Weekly' ? `(${site.evenOrOddWeeks})` : ''}`} 
              />
            </div>
            <div className={`${classes.column} ${classes.largeColumn}`}>
              <InfoItem 
                label="Preferred Day" 
                value={site.preferredDay} 
              />
              <InfoItem 
                label="Assigned Crew" 
                value={site.crewName} 
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
const WeeklyHoursDisplay = ({ weeklyHours, handleDayClick, selectedDay }) => {

  // console.log('Weekly Hours Display', weeklyHours)
  if (!weeklyHours) return null
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #ccc', paddingTop: '10px', marginTop: '10px' }}>
      {days.map((day, index) => (
        <div key={day} 
          style={
            {
              textAlign: 'center', 
              flex: 1, 
              cursor: 'pointer',
              backgroundColor: fullDays[index] === selectedDay ? '#e0e0e0' : 'transparent',
              borderRadius: '4px',
              padding: '2px'
            }
          } 
          onClick={e => handleDayClick(fullDays[index])}>
          <div style={{ fontWeight: 'bold' }}>{day}</div>
          <div>{weeklyHours[fullDays[index]].toFixed(1)}</div>
        </div>
      ))}
    </div>
  );
};

const CalendarTabArea = () => {

  return (
    <div style={{ height: '30px', backgroundColor: 'blue', width: '100%'}}>
      <div>Day</div>
      <div>Week</div>
      <div>Month</div>
      <div>Quarter</div>
    </div>
  )
}
const WeeklyAgenda = ({ crew }) => (
  <div>
    <h3>{crew.name}</h3>
    <p>Today: {crew.todayHours} hrs | Week: {crew.weeklyHours.total.toFixed(2)} hrs</p>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {Object.entries(crew.weeklyHours)
        .filter(([day]) => day !== 'total')
        .map(([day, hours]) => (
          <div key={day} style={{ textAlign: 'center' }}>
            <div>{day.slice(0, 3)}</div>
            <div>{hours.toFixed(1)}</div>
          </div>
        ))}
    </div>
  </div>
);



const distinctHexColors = [    
  "#FF0000", // Red
  "#00FF00", // Lime
  "#0000FF", // Blue
  "#FFFF00", // Yellow
  "#FF00FF", // Magenta
  "#00FFFF", // Cyan
  "#FFA500", // Orange
  "#800080", // Purple
  "#008000", // Green
  "#FFC0CB", // Pink
  "#A52A2A", // Brown
  "#808080", // Gray
  // "#111", // Black
  "#FFFFFF", // White
  "#FFD700", // Gold
  "#4B0082", // Indigo
  "#7FFF00", // Chartreuse
  "#FF4500", // OrangeRed
  "#1E90FF", // DodgerBlue
  "#8B4513"  // SaddleBrown
]

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    crews: state.crews,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(SchedulerComponent)