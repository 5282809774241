import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Checkbox
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { addWeeks, addDays, isWeekend } from 'date-fns';
import moment from 'moment';
import { isEven } from '../../../services/common'

const AssignmentConfirmationDialog = ({
  open,
  onClose,
  crew,
  site,
  selectedDate,
  onConfirm,
  jobSiteDetails,
  markers
}) => {
  const [confirmDate, setConfirmDate] = useState(selectedDate);
  const [scheduledDates, setScheduledDates] = useState({});
  const [crewHoursByDate, setCrewHoursByDate] = useState({});
  const [selectedServices, setSelectedServices] = useState({});

  console.log('Jobsite details', jobSiteDetails)
  useEffect(() => {
    if (selectedDate) {
      setConfirmDate(selectedDate);
      updateFutureAssignments(selectedDate);
    }
  }, [selectedDate, jobSiteDetails]);

  useEffect(() => {
    if (jobSiteDetails?.servicesRemaining) {
      const initialSelectedServices = {};
      jobSiteDetails.servicesRemaining.forEach((service, index) => {
        initialSelectedServices[`${service.type}-${service.name}-${service.year}`] = true;
      });
      setSelectedServices(initialSelectedServices);
    }
  }, [jobSiteDetails]);

  const handleDateChange = (date) => {
    setConfirmDate(date);
    updateFutureAssignments(date);
  };

  const getNextBusinessDay = (date) => {
    let nextDay = addDays(date, 1);
    while (isWeekend(nextDay)) {
      nextDay = addDays(nextDay, 1);
    }
    return nextDay;
  };

  const findSameBusinessDay = (baseDate, targetDate) => {
    let adjustedDate = targetDate;
    const baseDayOfWeek = baseDate.getDay();

    // Adjust to the same day of the week
    while (adjustedDate.getDay() !== baseDayOfWeek) {
      adjustedDate = getNextBusinessDay(adjustedDate);
    }

    return adjustedDate;
  };

  let chosenDate = moment(selectedDate);
  const initialDayOfWeek = chosenDate.day();
  const initialIsEvenWeek = chosenDate.week() % 2 === 0;


  let weekOfTheYear = chosenDate.week()
  const isEvenWeek = isEven(weekOfTheYear)

  const findNextMatchingDate = (currentDate, frequency, preferredDay, evenOrOddWeeks) => {
    console.log('Find NExt Date: %s  - %s  - %s  ', currentDate, frequency, preferredDay, evenOrOddWeeks)
    let nextDate = moment(currentDate);
    const initialDayOfWeek = nextDate.day();
    const initialIsEvenWeek = nextDate.week() % 2 === 0;


    let weekOfTheYear = nextDate.week()
    const isEvenWeek = isEven(weekOfTheYear)
  
    const getNextSameDayOfWeek = (date) => {
      let nextSameDay = date.clone().add(1, 'week');
      while (nextSameDay.day() !== initialDayOfWeek) {
        nextSameDay.add(1, 'day');
      }
      return nextSameDay;
    };

    switch (frequency.toLowerCase()) {
      case 'month':
        nextDate.add(1, 'month').startOf('month');
        while (nextDate.day() !== initialDayOfWeek) {
          nextDate.add(1, 'day');
        }
        if (evenOrOddWeeks) {
          while (isEvenWeek !== initialIsEvenWeek) {
            nextDate = getNextSameDayOfWeek(nextDate);
          }
          // If we've moved to the next month, go back to the previous occurrence
          if (nextDate.month() !== moment(currentDate).add(1, 'month').month()) {
            nextDate.subtract(1, 'week');
          }
        }
        break;
        case 'quarter':
          nextDate.add(1, 'quarter').startOf('quarter');
          break;
        case 'weekly':
          nextDate.add(1, 'week');
          break;
        case 'bi-weekly':
          nextDate.add(2, 'weeks');
          break;
      }
  
    // if (preferredDay) {
    //   nextDate = getNextWeekday(nextDate, preferredDay);
    // }
  
    if (evenOrOddWeeks && (frequency.toLowerCase() === 'weekly' || frequency.toLowerCase() === 'bi-weekly')) {
      const targetEvenWeek = evenOrOddWeeks.toLowerCase() === 'even';
      while (isEvenWeek(nextDate) !== targetEvenWeek) {
        nextDate.add(1, 'week');
      }
    }
  
    return nextDate.toDate();
  };

  const updateFutureAssignments = (baseDate) => {
    if (!jobSiteDetails?.servicesRemaining) {
      setScheduledDates({});
      return;
    }
  
    const newScheduledDates = {};
    let currentDate = moment(baseDate);
    jobSiteDetails.servicesRemaining.forEach((service, index) => {
      let assignmentDate;
      if (index === 0) {
        assignmentDate = currentDate.toDate();
      } else {
        assignmentDate = findNextMatchingDate(currentDate.toDate(), service.type);
      }

      const dateString = moment(assignmentDate).format('YYYY-MM-DD');
      newScheduledDates[`${service.type}-${service.name}-${service.year}`] = assignmentDate;
      
      const hoursOnThatDay = calculateCrewHoursOn(dateString, crew._id, markers)
      // Dummy calculation of hours, replace with real data fetching logic
      if (!crewHoursByDate[dateString]) {
        crewHoursByDate[dateString] = Math.random() * 8; // Simulate crew hours for the example
      }
      console.log('Hours on that day', hoursOnThatDay)
      if (hoursOnThatDay) {
        let roundedHours = Math.round(100 * (Number(hoursOnThatDay) + Number(site.estimatedHours))) / 100
        console.log('Rounded Hours', roundedHours)
        crewHoursByDate[dateString] = roundedHours
      }
      currentDate = moment(assignmentDate);
    });

    setScheduledDates(newScheduledDates);
    setCrewHoursByDate(crewHoursByDate);
  };

  const handleCheckboxChange = (event, serviceKey) => {
    setSelectedServices(prev => ({
      ...prev,
      [serviceKey]: event.target.checked
    }));
  };
  const handleConfirm = () => {
    const confirmedDates = Object.keys(scheduledDates).reduce((acc, key) => {
      if (selectedServices[key]) {
        acc[key] = scheduledDates[key];
      }
      return acc;
    }, {});

    onConfirm({
      crew,
      site,
      date: confirmDate,
      scheduledDates: confirmedDates,
    });
    onClose();
  };

  const dayOfTheWeek = moment(selectedDate).format('dddd')
  const evenOrOdd = isEvenWeek ? '(Even)' : '(Odd)'
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog open={open} onClose={onClose} aria-labelledby="assignment-confirmation-dialog" maxWidth="md" fullWidth>
        <DialogTitle id="assignment-confirmation-dialog">Confirm Assignment</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            Assigning {site.frequency} Job ({site.name}) to {crew.name}
          </Typography>
          <Typography variant="subtitle1">
            {dayOfTheWeek} {evenOrOdd}
          </Typography>
          {/* <DatePicker
            label="Initial Assignment Date"
            value={confirmDate}
            onChange={handleDateChange}
            fullWidth
            margin="normal"
          /> */}
          {jobSiteDetails?.servicesRemaining && (
            <TableContainer component={Paper}>
              <Table aria-label="service scheduling table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">Confirm</TableCell>
                    <TableCell>Service Period</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell>Scheduled Date</TableCell>
                    <TableCell>Crew Hours That Day</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobSiteDetails.servicesRemaining.map((service, index) => {
                    // Calculate HOurs for this date
                    const serviceKey = `${service.type}-${service.name}-${service.year}`;
                    const scheduledDate = scheduledDates[serviceKey];
                    const dateString = moment(scheduledDate).format('YYYY-MM-DD');
                    return (
                     <TableRow
                      key={index}
                     
                   >
                      <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedServices[serviceKey] || false}
                            onChange={(event) => handleCheckboxChange(event, serviceKey)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {service.name}
                          </TableCell>
                          <TableCell>{service.year}</TableCell>
                          <TableCell>
                            {scheduledDate?.toLocaleDateString()}
                         </TableCell> 
                         <TableCell> {Math.round(100 * crewHoursByDate[dateString] || 0) / 100}</TableCell>      
                    </TableRow>
                  
                  )
                })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Confirm Assignment
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

const calculateCrewHoursOn = (date, crewId, markers) => {
  console.log('Get Crew Hours', date, crewId)
  console.log(markers)
  const sitesForThisDay = filterSitesForCrewAndDate(markers, date, crewId)
  console.log('Got these sites', sitesForThisDay)
  let hoursThatDay = 0
  sitesForThisDay.forEach(site => {
    hoursThatDay += Number(site.estimatedHours)
  })
  return hoursThatDay
}


const filterSitesForCrewAndDate = (sites, date, crewId) => {
  const dayOfWeek = moment(date).format('dddd');
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const isWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(dayOfWeek);
  const weekOfTheYear = moment(date).week();
  const evenWeek = isEven(weekOfTheYear);
  const thisWeek = evenWeek ? 'Even' : 'Odd';

  return sites.filter(site => {
    if (site.crew !== crewId) return false;

    let includeSite = false;

    switch (site.frequency) {
      case 'Daily':
        includeSite = isWeekday;
        break;
      case 'Every Other Day (M-W-F)':
        includeSite = ['Monday', 'Wednesday', 'Friday'].includes(dayOfWeek);
        break;
      case 'Bi-Weekly':
        if (thisWeek === site.evenOrOddWeeks) {
          includeSite = site.preferredDay === dayOfWeek;
        }
        break;
      case 'Monthly':
      case 'Quarterly':
      case 'Semi-Annually':
      case 'Annually':
      case 'On-Demand':
        if (site.scheduledDates && site.scheduledDates.length > 0) {
          includeSite = site.scheduledDates.includes(formattedDate);
        }
        break;
      default:
        includeSite = site.preferredDay === dayOfWeek || (site.preferredDays && site.preferredDays.includes(dayOfWeek));
        break;
    }

    if (site.scheduledDates && site.scheduledDates.length > 0) {
      includeSite = includeSite || site.scheduledDates.includes(formattedDate);
    }

    return includeSite;
  });
};

const filterSitesForDate = (sites, date) => {
  const dayOfWeek = moment(date).format('dddd');
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const isWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(dayOfWeek);
  const weekOfTheYear = moment(date).week();
  const evenWeek = isEven(weekOfTheYear);
  const thisWeek = evenWeek ? 'Even' : 'Odd';

  return sites.filter(site => {
    let includeSite = false;

    switch (site.frequency) {
      case 'Daily':
        includeSite = isWeekday;
        break;
      case 'Every Other Day (M-W-F)':
        includeSite = ['Monday', 'Wednesday', 'Friday'].includes(dayOfWeek);
        break;
      case 'Bi-Weekly':
        if (thisWeek === site.evenOrOddWeeks) {
          includeSite = site.preferredDay === dayOfWeek;
        }
        break;
      case 'Monthly':
      case 'Quarterly':
      case 'Semi-Annually':
      case 'Annually':
      case 'On-Demand':
        if (site.scheduledDates && site.scheduledDates.length > 0) {
          includeSite = site.scheduledDates.includes(formattedDate);
        }
        break;
      default:
        includeSite = site.preferredDay === dayOfWeek || (site.preferredDays && site.preferredDays.includes(dayOfWeek));
        break;
    }

    if (site.scheduledDates && site.scheduledDates.length > 0) {
      includeSite = includeSite || site.scheduledDates.includes(formattedDate);
    }

    return includeSite;
  });
};

export default AssignmentConfirmationDialog;